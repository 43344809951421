import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import { preventChangeOnWheelScrollOnNumberInputs } from '@/helpers/forms/preventChangeOnWheelScrollOnNumberInputs'

import type { ProductFormValidity } from '@/components/CreateOrEditProduct2/types'

type ProductAvailableQuantityFieldProps = {
  value: number
  min: number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onValidityChange?: (name: keyof ProductFormValidity, isValid: boolean) => void
  disabled?: boolean
}

const ProductAvailableQuantityField = (props: ProductAvailableQuantityFieldProps) => {
  const { min, value: availableQuantity, onChange: handleAvailableQuantityChange, disabled = false } = props
  const { t } = useTranslation()

  const preventInputWheelOnNumberInput = useCallback(preventChangeOnWheelScrollOnNumberInputs, [])

  return (
    <Field
      disabled={disabled}
      label={t('productFormAvailableQuantityLabel')}
      max={1000}
      min={min}
      name="available-quantity"
      pattern="^\d{1,}$"
      required={true}
      step={1}
      type="number"
      value={availableQuantity}
      onChange={handleAvailableQuantityChange}
      onValidityChange={(isValid: boolean) => props.onValidityChange?.('availableQuantity', isValid)}
      onWheel={preventInputWheelOnNumberInput}
    />
  )
}

export default ProductAvailableQuantityField
