import { useTranslation } from 'react-i18next'

import Tag, { TagStatus } from '@/components/Tag/Tag'
import { ShipmentStatus } from '@/network/graphql/types.generated'

interface ShipmentStatusProps {
  status: ShipmentStatus
  onClick?: (e: any) => void
}

export const ShipmentStatusTag = (props: ShipmentStatusProps) => {
  const { t } = useTranslation()
  const { status, onClick = () => undefined } = props

  switch (status) {
    case ShipmentStatus.LabelCreated:
      return <Tag content={t('shippedShipmentsTableStatusShipped')} status={TagStatus.INFO} onClick={onClick} />
    case ShipmentStatus.ReadyToShip:
      return <Tag content={t('shippedShipmentsTableStatusShipped')} status={TagStatus.INFO} onClick={onClick} />
    case ShipmentStatus.Shipped:
      return <Tag content={t('shippedShipmentsTableStatusShipped')} status={TagStatus.INFO} onClick={onClick} />
    case ShipmentStatus.ReadyToPickup:
      return <Tag content={t('shippedShipmentsTableStatusDelivered')} status={TagStatus.SUCCESS} onClick={onClick} />
    case ShipmentStatus.Delivered:
      return <Tag content={t('shippedShipmentsTableStatusDelivered')} status={TagStatus.SUCCESS} onClick={onClick} />
    case ShipmentStatus.Received:
      return <Tag content={t('shippedShipmentsTableStatusDelivered')} status={TagStatus.SUCCESS} onClick={onClick} />
    default:
      return <p>-</p>
  }
}
