import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import { useGetSellerConfigQuery } from '@/network/graphql/common/query/operations.generated'

import './StripeAccountIssueBanner.scss'

type StripeAccountIssueBannerProps = unknown

export const StripeAccountIssueBanner = (_props: StripeAccountIssueBannerProps) => {
  const { t } = useTranslation()

  const { data, loading } = useGetSellerConfigQuery()
  const { viewer } = data || {}
  const { sellerConfig, username } = viewer || {}
  const { stripeAccountChargesEnabled, stripeAccountPayoutsEnabled, stripeMissingInfoDueDate } = sellerConfig || {}
  const dueDate = stripeMissingInfoDueDate ? dayjs(stripeMissingInfoDueDate).format('DD/MM/YYYY') : undefined
  const emailSubject = encodeURIComponent(t('bannerStripeAccountIssueEmailSubject', { username }))

  const handleContactSupportClick = useCallback(() => {
    window.location.href = `mailto:${t('supportMailTo')}?subject=${emailSubject}`
  }, [t, emailSubject])

  if (stripeAccountChargesEnabled && stripeAccountPayoutsEnabled && !stripeMissingInfoDueDate) return null // all good

  let i18nContentKey = 'bannerStripeAccountIssueContentSoonRestricted'
  let level: 'danger' | 'warning' = 'warning'

  if (!stripeAccountPayoutsEnabled) {
    i18nContentKey = 'bannerStripeAccountIssueContentRestrictedPayout'
  }

  if (!stripeAccountChargesEnabled) {
    i18nContentKey = 'bannerStripeAccountIssueContentRestrictedCharges'
    level = 'danger'
  }

  if (loading) {
    return null
  }

  return (
    <div className="stripe-issue-alert">
      <Alert emphasis="high" type={level} dismissible>
        <div className="flex gap-4 items-center justify-between">
          <p className="mb-0">{t(i18nContentKey, { dueDate, interpolation: { escapeValue: false } })}</p>
          <Button
            className="contact-support-action primary"
            label={t('bannerStripeAccountIssueButton')}
            onClick={handleContactSupportClick}
          />
        </div>
      </Alert>
    </div>
  )
}
