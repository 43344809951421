import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { fetchSellerBillingData } from '@/actions/fetch-seller-billing-data'
import ErrorPageAccessDenied from '@/components/Error/ErrorPageAccessDenied/ErrorPageAccessDenied'
import { SellerPayoutInfoContainer } from '@/components/SellerPayoutInfo/SellerPayoutInfo.container'
import { StripeAutoDebitAgreementCard } from '@/components/Stripe/StripeAutoDebitAgreement/StripeAutoDebitAgreementCard/StripeAutoDebitAgreementCard'
import Loader from '@/components/ui/Loader/Loader'
import { TabBar } from '@/components/ui/TabBar/TabBar'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useUser } from '@/contexts/user/User.context'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { useAppSelector } from '@/reducers'
import BankInfo from '@/views/Account/components/BankInfo/BankInfo'

import BillingInfo from './components/BillingInfo/BillingInfo'
import BrandPack from './components/BrandPack/BrandPack'
import Moderation from './components/Moderation/Moderation'
import { ShippingPreferences } from './components/ShippingPreferences/ShippingPreferences'
import { SubAccounts } from './components/SubAccounts/SubAccounts'

import './Account.scss'

const { VITE_APP_STRIPE_PUBLISHABLE_KEY } = import.meta.env

function Account() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  useDocumentTitle(t('accountHeaderTitle'))

  const stripePromise = useMemo(() => loadStripe(VITE_APP_STRIPE_PUBLISHABLE_KEY!), [VITE_APP_STRIPE_PUBLISHABLE_KEY])

  const { user: user2, fetchUser, isLoading } = useUser()
  const { sellerConfig } = user2 || {}
  const {
    shouldAcceptStripeDebitAgreement = false,
    canManageAccountSettings,
    canManageSubAccounts,
  } = sellerConfig || {}

  const initialTab = new URLSearchParams(location.search).get('tab') || undefined

  const user = useAppSelector((state) => state.authentication.user)

  const handleAccepted = useCallback(async () => {
    await fetchUser()
  }, [fetchUser])

  useEffect(() => {
    dispatch(fetchSellerBillingData())
  }, [])

  const handleTabChange = useCallback(
    (tab: string) => {
      const url = `/account?tab=${tab}`
      history.push(url)
    },
    [history]
  )

  if (isLoading) return <Loader />

  return (
    <Elements stripe={stripePromise}>
      <ViewContainer id="account" leftContent={t('accountHeaderTitle')}>
        <TabBar
          initial={initialTab}
          tabs={[
            {
              key: 'shippingPreferences',
              title: t('accountShippingPreferencesTab'),
              content: canManageAccountSettings ? <ShippingPreferences /> : <ErrorPageAccessDenied />,
              disabled: !canManageAccountSettings,
            },
            {
              key: 'moderation',
              title: t('accountModerationTab'),
              content: <Moderation />,
            },
            {
              key: 'teamAccess',
              title: t('accountTeamAccessTab'),
              content: canManageSubAccounts ? <SubAccounts /> : <ErrorPageAccessDenied />,
              disabled: !canManageSubAccounts,
            },
            {
              key: 'billing',
              title: t('accountBillingTab'),
              content: canManageAccountSettings ? (
                <div className="account-billing-info">
                  <BillingInfo />
                  {user && <SellerPayoutInfoContainer user={user} />}
                </div>
              ) : (
                <ErrorPageAccessDenied />
              ),
              disabled: !canManageAccountSettings,
            },
            {
              key: 'banking',
              title: t('accountBankingTab'),
              content: canManageAccountSettings ? (
                <div className="account-banking-info">
                  {shouldAcceptStripeDebitAgreement && <StripeAutoDebitAgreementCard onAccepted={handleAccepted} />}
                  <BankInfo />
                </div>
              ) : (
                <ErrorPageAccessDenied />
              ),
              disabled: !canManageAccountSettings,
            },
            {
              key: 'brand',
              title: t('accountBrandTab'),
              content: <BrandPack />,
            },
          ]}
          onChange={handleTabChange}
        />
      </ViewContainer>
    </Elements>
  )
}

export default Account
