import { message } from 'antd'
import dayjs from 'dayjs'
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaRegBell, FaCircleCheck } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import Config from '@/config/config'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import {
  GetShowNotificationDocument,
  useGetShowNotificationQuery,
  useSendShowNotificationMutation,
} from '@/views/Show/components/SendShowNotification/operations.generated'
import SendShowNotificationModal from '@/views/Show/components/SendShowNotification/ShowNotificationModal/SendShowNotificationModal'

import './SendShowNotification.scss'

const { VITE_APP_LONG_TIMEOUT } = Config
interface NotificationContainerProps {
  showId: number
}

const SendShowNotification = (props: NotificationContainerProps) => {
  const { showId } = props
  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showGlobalId = `Show|${showId}`
  const { data: showNotificationData } = useGetShowNotificationQuery({
    skip: !showGlobalId,
    variables: {
      nodeId: showGlobalId,
    },
  })
  const [sendShowNotificationMutation, { loading: isSendNotificationLoading }] = useSendShowNotificationMutation({
    variables: {
      input: {
        showId: showGlobalId,
      },
    },
    context: {
      timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
    },
    onError: (err) => {
      // TODO: We should probably display a custom error message here instead
      message.error(err.message)
      trackError(err, { meta: { showId, scope: 'NotificationContainer.sendShowNotificationMutation' } })
    },
    onCompleted: () => {
      trackEvent('SHOW_SEND_NOTIFICATION', { showId })
    },
    refetchQueries: [GetShowNotificationDocument],
  })

  const notification =
    showNotificationData?.node.__typename === 'Show' ? showNotificationData.node.notification : undefined
  const hasBeenSent = Boolean(notification?.sentAt)

  const handleSendNotification = useCallback(async () => {
    await sendShowNotificationMutation()
  }, [sendShowNotificationMutation])

  const handleCancel = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const handleClick = useCallback(() => {
    if (hasBeenSent) {
      return
    }

    setIsModalOpen(true)
  }, [hasBeenSent, setIsModalOpen])

  const { sentAt, title = '', message: notifMessage = '' } = notification || {}
  const formattedSentAt = sentAt ? dayjs(sentAt).format('HH:mm') : ''

  return (
    <>
      <Button
        className={`send-show-notification-action secondary ${hasBeenSent ? 'is-sent' : ''}`}
        tooltipPosition="bottom right"
        icon={
          <>
            <FaRegBell color="black" />
            {hasBeenSent && <FaCircleCheck className="sent-marker" />}
          </>
        }
        tooltip={
          hasBeenSent
            ? t('showNotificationSentAtMessage', { hour: formattedSentAt })
            : t('notificationSlidingPanelCatchPhraseSendNotification')
        }
        onClick={handleClick}
      />
      {notifMessage && title && (
        <SendShowNotificationModal
          isLoading={isSendNotificationLoading}
          message={notifMessage}
          open={isModalOpen}
          success={hasBeenSent}
          title={title}
          onCancel={handleCancel}
          onSubmit={handleSendNotification}
        />
      )}
    </>
  )
}

export default memo(SendShowNotification)
