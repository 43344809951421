import { Modal } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCircleInfo } from 'react-icons/fa6'

import { NewFeatureIntroducer } from '@/components/NewFeatureIntroducer/NewFeatureIntroducer'

import { UserType, getUsernameColorClass } from '../../chatbox.helper'

const CommandLine = ({ command, description }: { description: string; command: string }) => {
  return (
    <>
      <span className="font-bold">{command}</span>: {description}
    </>
  )
}

export const ChatCommandsAndInfos = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const commands = [
    {
      command: '/mod username',
      description: t('showUserActionsPopupPromoteAction'),
    },
    {
      command: '/unmod username',
      description: t('showUserActionsPopupDemoteAction'),
    },
    {
      command: '/mods',
      description: t('showUserActionsPopupModeratorListAction'),
    },
    {
      command: '/ban username',
      description: t('showUserActionsPopupBanFromShowAction'),
    },
    {
      command: '/unban username',
      description: t('showUserActionsPopupUnBanFromShowAction'),
    },
    {
      command: '/bandef username',
      description: t('showUserActionsPopupBanFromSellerAction'),
    },
    {
      command: '/unbandef username',
      description: t('showUserActionsPopupUnBanFromSellerAction'),
    },
    {
      command: '/raid username',
      description: t('showUserActionsPopupRaidAction'),
    },
  ]

  const renderCommandContent = () => {
    return (
      <div>
        <h1 className="text-base font-semibold mb-4">{t('chatCommandTitle')}</h1>
        <ul>
          {commands.map(({ command, description }) => (
            <li key={command}>
              <CommandLine command={command} description={description} />
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const renderUserColorNoticeContent = () => {
    const userTypes = Object.values(UserType)

    return (
      <div>
        <h1 className="text-base font-semibold mb-4">{t('chatboxColorLegendTitle')}</h1>
        <ul>{userTypes.map((userType) => renderColorLegend(userType))}</ul>
      </div>
    )
  }

  const renderColorLegend = (userType: UserType) => {
    const colorI18n = `common${userType}`
    return (
      <li key={userType} className={getUsernameColorClass(userType)}>
        {t(colorI18n)}
      </li>
    )
  }

  return (
    <>
      <NewFeatureIntroducer alreadySeenKey="hasSeenRaid" content={<p>{t('showRaidExplanation')}</p>}>
        <span className="cursor-pointer" onClick={showModal}>
          <FaCircleInfo className="text-slate-400" />
        </span>
      </NewFeatureIntroducer>
      <Modal className="w-96" open={isModalOpen} title={t('chatCommandTitle')} onCancel={handleCancel} onOk={handleOk}>
        <div className="flex flex-col gap-4">
          {renderCommandContent()} {renderUserColorNoticeContent()}
        </div>
      </Modal>
    </>
  )
}
