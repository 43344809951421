import { useMemo, useState, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { FaDownload } from 'react-icons/fa6'
import { useLocation, useHistory } from 'react-router-dom'

import CreateShowButton from '@/components/CreateShowButton/CreateShowButton'
import CreateShowModal from '@/components/CreateShowModal/CreateShowModal'
import { handleVideoUploadTranscodingSubscriptionData } from '@/components/CreateShowModal/VideoUploader/videoUploader.helper'
import { BreakerCannotCreateShow } from '@/components/SellerTier/BreakerCannotCreateShow/BreakerCannotCreateShow'
import Button from '@/components/ui/Button/Button'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'

import { useOnPreShowTeaserVideoTranscodingStatusChangedSubscription } from '../Show/operations.generated'
import { ShopsList } from '../Shows/components/ShowList/ShopsList'

import { useShops } from './hooks/use-shops'

import type { Show } from '@/network/graphql/types.generated'

const CSVHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'Date', key: 'date' },
  { label: 'Shop', key: 'name' },
  { label: 'Shop total', key: 'total' },
  { label: 'Commission', key: 'commission' },
  { label: 'Refunded', key: 'refunded' },
  { label: 'Shipping label', key: 'shipping' },
  { label: 'Net revenue', key: 'revenue' },
  { label: 'Currency', key: 'currency' },
]

export const Shops = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const isQueryParamOpenModal = query.get('action') === 'create'

  const [shopCreationModal, setShopCreationModal] = useState<boolean>(isQueryParamOpenModal)
  const [shopCreated, setShopCreated] = useState<Show | null>(null)

  const { shops, loading } = useShops()

  useDocumentTitle(t('sideMenuMyShopsTitle'))

  useEffect(() => {
    if (isQueryParamOpenModal) history.replace('/shops')
  }, [shopCreationModal])

  const CSVData = useMemo(() => {
    return shops.map((s) => ({
      id: s.legacyId,
      date: s.startAt,
      name: s.name,
      total: s.totalAmountOrdersSuccessInCurrencyCents / 100,
      commission: s.totalCommissionInCurrencyCents / 100,
      shipping: s.totalDeductedShippingCostInCurrencyCents / 100,
      revenue: s.totalNetIncomeInCurrencyCents / 100,
      refunded: s.totalAmountRefundedInCurrencyCents / 100,
      currency: s.currency,
    }))
  }, [shops])

  const onShopCreated = (shop: Show) => {
    setShopCreated(shop)
  }

  // Subscribe to update the show teaser video url
  useOnPreShowTeaserVideoTranscodingStatusChangedSubscription({
    // We do not want to subscribe to this event if we already have the transcoding info
    skip: !shopCreated?.id || !!shopCreated?.preShowTeaserVideoInfo?.transcodingInfo,
    variables: {
      showId: shopCreated?.id as string,
    },
    onData: (data) => {
      handleVideoUploadTranscodingSubscriptionData(data?.data)
    },
  })

  return (
    <ViewContainer isLoading={loading} leftContent={t('shopsPageHeaderTitle')}>
      {shopCreationModal && (
        <CreateShowModal createShop={true} setIsModalVisible={setShopCreationModal} onShowCreated={onShopCreated} />
      )}

      {!isMobileOnly ? (
        <>
          <div className="w-full mb-4">
            <BreakerCannotCreateShow className="mb-4" />
          </div>

          <div className="w-full">
            <p className="text-lg font-semibold mb-2">{t('ShowsListMyShops')}</p>

            <div className="w-full layout-card">
              <div className="p-4 flex items-center justify-between border-b border-slate-200">
                <div className="flex gap-4 items-center">
                  <CreateShowButton handleOnClick={() => setShopCreationModal(true)} isShop={true} />
                </div>

                <CSVLink
                  className="cursor-pointer flex items-center gap-1"
                  data={CSVData}
                  filename={`shops-export-${new Date().getTime()}`}
                  headers={CSVHeaders}
                >
                  <Button
                    className="export-shows-action"
                    icon={<FaDownload className="h-[16x] w-[16px]" />}
                    label={t('ShowsListExportMe')}
                  />
                </CSVLink>
              </div>
              <ShopsList mobile={false} />
            </div>
          </div>
        </>
      ) : (
        <>
          <BreakerCannotCreateShow className="mb-2" />

          <ShopsList mobile={true} />
          <div className="fixed bottom-0 left-0 right-0 p-2 text-base">
            <CreateShowButton handleOnClick={() => setShopCreationModal(true)} />
          </div>
        </>
      )}
    </ViewContainer>
  )
}
