import { Popover } from 'antd'
import { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'

import type { ReactNode } from 'react'

import './style.scss'

type NewFeatureIntroducerProps = {
  className?: string
  content: ReactNode
  children: ReactNode
  alreadySeenKey: string
}

export const NewFeatureIntroducer = (props: NewFeatureIntroducerProps) => {
  const { t } = useTranslation()
  const { children, className = '', content, alreadySeenKey } = props

  const hasAlreadySeenTooltip = getFromLocalStorage(alreadySeenKey) === 'true'
  const [isPopoverOpen, setIsPopoverOpen] = useState(!hasAlreadySeenTooltip && !isMobileOnly)

  const handlePopoverClick = () => {
    if (!hasAlreadySeenTooltip) {
      setToLocalStorage(alreadySeenKey, 'true')
    }
    setIsPopoverOpen(false)
  }

  const classNames = `feature-introducer ${className}`.trim()

  return (
    <Popover
      className={classNames}
      open={isPopoverOpen}
      placement="topLeft"
      trigger="click"
      content={
        <div
          className={`feature-introducer-content feature-introducer-content-${alreadySeenKey}`}
          onClick={handlePopoverClick}
        >
          {content}
          <Button className="cancel-action close-action secondary" label={t('NewsPopoverLink')} />
        </div>
      }
      title={
        <p className="feature-introducer-title" onClick={handlePopoverClick}>
          {t('NewsPopoverTitle')}
        </p>
      }
    >
      <div onClick={handlePopoverClick}>{children}</div>
    </Popover>
  )
}
