import { createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { internalRequest } from '@/util/request'

import type { UpdateShowInput } from '@/components/CreateShowModal/types'
import type { RootState } from '@/reducers'

export const updateShow = createAsyncThunk(
  'update-show',
  async (input: UpdateShowInput, { rejectWithValue, getState, dispatch }) => {
    const {
      showId,
      name,
      note,
      categoryId,
      shippingCategory,
      startAt,
      paymentPreAuthorizationAmount,
      gmvTargetAmount,
      isReusingPaidShippingFeesFromPreviousShows,
      languageId,
    } = input

    const {
      authentication: { token },
    } = getState() as RootState

    try {
      const { data } = await internalRequest({
        headers: { authorization: token, ...(await getAdditionalHeaders()) },
        data: {
          name,
          startAt,
          note,
          categoryId,
          shippingCategory,
          paymentPreAuthorizationAmount,
          gmvTargetAmount,
          isReusingPaidShippingFeesFromPreviousShows,
          languageId,
        },
        url: `/shows/${showId}`,
        method: 'PUT',
        dispatch,
      })
      return data
    } catch (error: any) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }
    }
  }
)
