import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import HourglassIcon from '@/components/ui/Icons/HourglassIcon/HourglassIcon'

type ShowEventApplicationFormStep3Props = {
  handleCloseModal: () => void
}

const ShowEventApplicationFormStep3 = ({ handleCloseModal }: ShowEventApplicationFormStep3Props) => {
  const { t } = useTranslation()

  return (
    <div className="show-event-form-step3">
      <div className="form-step3-container">
        <div className="form-step3-content">
          <HourglassIcon />
          <div className="form-step3-content">
            <p className="form-step3-title">{t('showEventApplicationConfirmationTitle')}</p>
            <p className="form-step3-subtitle">{t('showEventApplicationConfirmationSubtitle')}</p>
          </div>
        </div>

        <div>
          <p className="form-step3-description">{t('showEventApplicationConfirmationDescription')}</p>
        </div>

        <div className="form-step3-button-container">
          <Button className="form-step3-button primary is-success" onClick={handleCloseModal}>
            {t('showEventApplicationAmazingAction')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ShowEventApplicationFormStep3
