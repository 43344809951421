import dayjs from 'dayjs'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '../../../../../../components/ui/Alert/Alert'
import User from '../../../../../../components/ui/User/User'

export interface JoinItemProps {
  username?: string
  avatarWebPUrl?: string | null
  timestamp?: Date
}

const JoinItem = ({ username, avatarWebPUrl, timestamp }: JoinItemProps) => {
  const { t } = useTranslation()
  const random = Math.floor(Math.random() * 3) + 1

  const renderEmpty = () => (
    <Alert className="chat-join-alert" type="primary">
      <span>{t('showCommentsListJoinActivityEmptyMessage')}</span>
    </Alert>
  )

  const renderJoin = () => {
    return (
      <Alert className="chat-join-alert" type="primary">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center max-w-full">
            <User src={avatarWebPUrl} isSmall>
              {username}
            </User>
            <span className="pl-1">{`${t(`showCommentsWelcomeMessage${random}`)}`}</span>
          </div>
          <span className="text-black-120 pl-1">{dayjs(timestamp).format('HH:mm')}</span>
        </div>
      </Alert>
    )
  }

  return username ? renderJoin() : renderEmpty()
}

export default memo(JoinItem)
