import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from '@/contexts/user/User.context'
import { computeMostUsedCurrency, useCurrencyFormatter } from '@/helpers/currencyFormatter'

import type { ShowItemFragment } from '../operations.generated'
import type { Currency } from '@/network/graphql/types.generated'

export interface ShowsPanelHeaderProps {
  shows: Array<ShowItemFragment>
  mobile?: boolean
}

export const ShowsPanelHeader = ({ shows, mobile }: ShowsPanelHeaderProps) => {
  const { t, i18n } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canAccessHomeMetrics } = sellerConfig || {}

  const month = dayjs(shows[0].startAt)
    .locale(i18n.resolvedLanguage || '')
    .format('MMMM YYYY')

  const currency: Currency = useMemo(() => {
    return computeMostUsedCurrency(shows)
  }, [shows])

  const incomes = useMemo(() => {
    return shows.reduce((a, b) => a + b.totalNetIncomeInCurrencyCents, 0)
  }, [shows])

  if (mobile) {
    return <h2 className="callout_2 first-letter:capitalize ml-2">{month}</h2>
  }

  return (
    <div className="flex text-sm items-center h-full divide-x divide-slate-200">
      <div className="flex gap-2 justify-center px-4">
        <p className="m-0 font-semibold">{t('ShowPanelHeaderShows')}</p>
        <p className="m-0">{shows.length}</p>
      </div>

      {canAccessHomeMetrics && (
        <div className="flex gap-2 justify-center px-2">
          <p className="m-0 font-semibold">{t('ShowPanelHeaderNetIncome')}</p>
          <p className="m-0">{currencyFormatter(incomes, currency)}</p>
        </div>
      )}
    </div>
  )
}
