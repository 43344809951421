import { RightOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

import { useShops } from '@/views/Shops/hooks/use-shops'

import { ShowsTable } from '../ShowsTable/ShowsTable'

import style from './style.module.scss'

export interface ShowsListProps {
  mobile?: boolean
}

export const ShopsList = ({ mobile }: ShowsListProps) => {
  const { shops } = useShops()
  const history = useHistory()

  if (mobile) {
    return (
      <>
        {shops.map((show) => (
          <div key={show.id}>
            <div
              className={`${style.mobile_show_list} mt-2`}
              onClick={() => {
                history.push(`/shops/${show.legacyId}`)
              }}
            >
              <div className={`${style.mobile_show_list_image} overflow-hidden ml-2`}>
                <img
                  alt={`thumbnail-${show.name}`}
                  className="w-[52px] h-[52px] rounded-lg object-cover"
                  src={show.thumbnailWebPUrl ?? '/badges/empty-picture.png'}
                />
              </div>
              <div className="ml-4">
                <h3 className="callout_4">{show.name}</h3>
                <p className="text-black-120">{dayjs(show.startAt).format('DD/MM/YYYY')}</p>
              </div>
              <div className={`${style.mobile_show_list_icon}`}>
                <RightOutlined />
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }

  return <ShowsTable isShop={true} shows={shops} />
}
