import { memo, useEffect } from 'react'

import type { ReactionFragment } from '../../../../../Shows/operations.generated'

interface ReactionItemProps {
  reaction: ReactionFragment
  removeReaction: (reactionId: ReactionFragment['id']) => void
}

const ANIMATION_DURATION = 2000

export const ReactionItem = memo(({ reaction, removeReaction }: ReactionItemProps) => {
  // Reaction expiration management
  useEffect(() => {
    const timer = setTimeout(() => {
      removeReaction(reaction.id) // Notify parent component that this reaction has expired
    }, ANIMATION_DURATION)

    return () => clearTimeout(timer)
  }, [reaction.id, removeReaction])

  return (
    <div
      className="absolute animate-fireup text-xl"
      style={{ left: `${Math.floor(Math.random() * 90)}%`, bottom: '40px' }}
    >
      {reaction.emoji}
    </div>
  )
})
