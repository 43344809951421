import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InventoryProductFragment = { __typename?: 'InventoryProduct', id: string, name: string, description?: string | null, totalQuantity: number, availableQuantity: number, defaultType: Types.ProductType, brand?: string | null, categoryName?: string | null, color?: string | null, condition?: string | null, fixedAmount?: number | null, gender?: string | null, legacyId: string, model?: string | null, size?: string | null, startingAmount?: number | null, createdAt: Date, images: Array<{ __typename?: 'ImageUpload', id: string, webPUrl: string }> };

export type GetInventoryProductQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filterByText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterByPositiveTotalQuantityForPastShows?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterByPositiveTotalQuantityForAllShows?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterByShows?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  showIdToExclude?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  sortBy?: Types.InputMaybe<Types.SortInventoryProductsBy>;
}>;


export type GetInventoryProductQuery = { __typename?: 'Query', inventoryProducts: { __typename?: 'InventoryProductConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ __typename?: 'InventoryProductEdge', node: { __typename?: 'InventoryProduct', availableProductCountInOfflineShows: number, availableProductCountInShows: number, id: string, name: string, description?: string | null, totalQuantity: number, availableQuantity: number, defaultType: Types.ProductType, brand?: string | null, categoryName?: string | null, color?: string | null, condition?: string | null, fixedAmount?: number | null, gender?: string | null, legacyId: string, model?: string | null, size?: string | null, startingAmount?: number | null, createdAt: Date, images: Array<{ __typename?: 'ImageUpload', id: string, webPUrl: string }> } }> } };

export type InitializeInventoryMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type InitializeInventoryMutation = { __typename?: 'Mutation', initializeInventory: { __typename?: 'InitializeInventoryPayload', ok: boolean } };

export type RemoveInventoryProductMutationVariables = Types.Exact<{
  input: Types.RemoveInventoryProductInput;
}>;


export type RemoveInventoryProductMutation = { __typename?: 'Mutation', removeInventoryProduct: { __typename?: 'RemoveInventoryProductPayload', ok: boolean } };

export type GetInventoryProductImagesQueryVariables = Types.Exact<{
  nodeId: Types.Scalars['ID']['input'];
}>;


export type GetInventoryProductImagesQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign' } | { __typename?: 'AutocompleteSearchItem' } | { __typename?: 'Badge' } | { __typename?: 'Category' } | { __typename?: 'CommercialEvent' } | { __typename?: 'Country' } | { __typename?: 'Emote' } | { __typename?: 'FavoriteProduct' } | { __typename?: 'FavoriteShow' } | { __typename?: 'ImageUpload' } | { __typename?: 'InventoryProduct', id: string, legacyId: string, images: Array<{ __typename?: 'ImageUpload', id: string, url: string, webPUrl: string }> } | { __typename?: 'Language' } | { __typename?: 'Order' } | { __typename?: 'OrderedProduct' } | { __typename?: 'ParentCategory' } | { __typename?: 'Product' } | { __typename?: 'ProductForOrdering' } | { __typename?: 'Promotion' } | { __typename?: 'PromotionV2' } | { __typename?: 'RatingFromCustomer' } | { __typename?: 'SellerLedgerPayout' } | { __typename?: 'SellerLedgerTransaction' } | { __typename?: 'SellerTier' } | { __typename?: 'Shipment' } | { __typename?: 'Shop' } | { __typename?: 'Show' } | { __typename?: 'ShowOrdersGroup' } | { __typename?: 'ShowProductNode' } | { __typename?: 'User' } };

export type AddInventoryProductsToShowMutationVariables = Types.Exact<{
  input: Types.AddInventoryProductsToShowInput;
}>;


export type AddInventoryProductsToShowMutation = { __typename?: 'Mutation', addInventoryProductsToShow: { __typename?: 'AddInventoryProductsToShowPayload', ok: boolean } };

export type RemoveInventoryProductsMutationVariables = Types.Exact<{
  input: Types.RemoveInventoryProductsInput;
}>;


export type RemoveInventoryProductsMutation = { __typename?: 'Mutation', removeInventoryProducts: { __typename?: 'RemoveInventoryProductsPayload', ok: boolean } };

export type GetPastShowsQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  lastNDaysOnly?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetPastShowsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, shows: { __typename?: 'ShowConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ __typename?: 'ShowEdge', node: { __typename?: 'Show', id: string, legacyId: number, startAt: Date, name: string } }> } } | null };

export const InventoryProductFragmentDoc = gql`
    fragment InventoryProduct on InventoryProduct {
  id
  name
  description
  totalQuantity
  availableQuantity
  defaultType
  brand
  categoryName
  color
  condition
  fixedAmount
  gender
  images {
    id
    webPUrl(options: {width: 52})
  }
  legacyId
  model
  size
  startingAmount
  createdAt
}
    `;
export const GetInventoryProductDocument = gql`
    query GetInventoryProduct($first: Int!, $offset: Int, $filterByText: String, $filterByPositiveTotalQuantityForPastShows: Boolean, $filterByPositiveTotalQuantityForAllShows: Boolean, $filterByShows: [ID!], $showIdToExclude: ID, $sortBy: SortInventoryProductsBy) {
  inventoryProducts(
    first: $first
    offset: $offset
    filterByText: $filterByText
    filterByPositiveTotalQuantityForPastShows: $filterByPositiveTotalQuantityForPastShows
    filterByPositiveTotalQuantityForAllShows: $filterByPositiveTotalQuantityForAllShows
    sortBy: $sortBy
    filterByShows: $filterByShows
    showIdToExclude: $showIdToExclude
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...InventoryProduct
        availableProductCountInOfflineShows
        availableProductCountInShows
      }
    }
  }
}
    ${InventoryProductFragmentDoc}`;

/**
 * __useGetInventoryProductQuery__
 *
 * To run a query within a React component, call `useGetInventoryProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryProductQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filterByText: // value for 'filterByText'
 *      filterByPositiveTotalQuantityForPastShows: // value for 'filterByPositiveTotalQuantityForPastShows'
 *      filterByPositiveTotalQuantityForAllShows: // value for 'filterByPositiveTotalQuantityForAllShows'
 *      filterByShows: // value for 'filterByShows'
 *      showIdToExclude: // value for 'showIdToExclude'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetInventoryProductQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryProductQuery, GetInventoryProductQueryVariables> & ({ variables: GetInventoryProductQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryProductQuery, GetInventoryProductQueryVariables>(GetInventoryProductDocument, options);
      }
export function useGetInventoryProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryProductQuery, GetInventoryProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryProductQuery, GetInventoryProductQueryVariables>(GetInventoryProductDocument, options);
        }
export function useGetInventoryProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetInventoryProductQuery, GetInventoryProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInventoryProductQuery, GetInventoryProductQueryVariables>(GetInventoryProductDocument, options);
        }
export type GetInventoryProductQueryHookResult = ReturnType<typeof useGetInventoryProductQuery>;
export type GetInventoryProductLazyQueryHookResult = ReturnType<typeof useGetInventoryProductLazyQuery>;
export type GetInventoryProductSuspenseQueryHookResult = ReturnType<typeof useGetInventoryProductSuspenseQuery>;
export type GetInventoryProductQueryResult = Apollo.QueryResult<GetInventoryProductQuery, GetInventoryProductQueryVariables>;
export const InitializeInventoryDocument = gql`
    mutation InitializeInventory {
  initializeInventory {
    ok
  }
}
    `;
export type InitializeInventoryMutationFn = Apollo.MutationFunction<InitializeInventoryMutation, InitializeInventoryMutationVariables>;

/**
 * __useInitializeInventoryMutation__
 *
 * To run a mutation, you first call `useInitializeInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeInventoryMutation, { data, loading, error }] = useInitializeInventoryMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitializeInventoryMutation(baseOptions?: Apollo.MutationHookOptions<InitializeInventoryMutation, InitializeInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitializeInventoryMutation, InitializeInventoryMutationVariables>(InitializeInventoryDocument, options);
      }
export type InitializeInventoryMutationHookResult = ReturnType<typeof useInitializeInventoryMutation>;
export type InitializeInventoryMutationResult = Apollo.MutationResult<InitializeInventoryMutation>;
export type InitializeInventoryMutationOptions = Apollo.BaseMutationOptions<InitializeInventoryMutation, InitializeInventoryMutationVariables>;
export const RemoveInventoryProductDocument = gql`
    mutation RemoveInventoryProduct($input: RemoveInventoryProductInput!) {
  removeInventoryProduct(input: $input) {
    ok
  }
}
    `;
export type RemoveInventoryProductMutationFn = Apollo.MutationFunction<RemoveInventoryProductMutation, RemoveInventoryProductMutationVariables>;

/**
 * __useRemoveInventoryProductMutation__
 *
 * To run a mutation, you first call `useRemoveInventoryProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInventoryProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInventoryProductMutation, { data, loading, error }] = useRemoveInventoryProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveInventoryProductMutation(baseOptions?: Apollo.MutationHookOptions<RemoveInventoryProductMutation, RemoveInventoryProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveInventoryProductMutation, RemoveInventoryProductMutationVariables>(RemoveInventoryProductDocument, options);
      }
export type RemoveInventoryProductMutationHookResult = ReturnType<typeof useRemoveInventoryProductMutation>;
export type RemoveInventoryProductMutationResult = Apollo.MutationResult<RemoveInventoryProductMutation>;
export type RemoveInventoryProductMutationOptions = Apollo.BaseMutationOptions<RemoveInventoryProductMutation, RemoveInventoryProductMutationVariables>;
export const GetInventoryProductImagesDocument = gql`
    query GetInventoryProductImages($nodeId: ID!) {
  node(id: $nodeId) {
    ... on InventoryProduct {
      images {
        id
        url
        webPUrl(options: {width: 512})
      }
      id
      legacyId
    }
  }
}
    `;

/**
 * __useGetInventoryProductImagesQuery__
 *
 * To run a query within a React component, call `useGetInventoryProductImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryProductImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryProductImagesQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetInventoryProductImagesQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryProductImagesQuery, GetInventoryProductImagesQueryVariables> & ({ variables: GetInventoryProductImagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryProductImagesQuery, GetInventoryProductImagesQueryVariables>(GetInventoryProductImagesDocument, options);
      }
export function useGetInventoryProductImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryProductImagesQuery, GetInventoryProductImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryProductImagesQuery, GetInventoryProductImagesQueryVariables>(GetInventoryProductImagesDocument, options);
        }
export function useGetInventoryProductImagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetInventoryProductImagesQuery, GetInventoryProductImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInventoryProductImagesQuery, GetInventoryProductImagesQueryVariables>(GetInventoryProductImagesDocument, options);
        }
export type GetInventoryProductImagesQueryHookResult = ReturnType<typeof useGetInventoryProductImagesQuery>;
export type GetInventoryProductImagesLazyQueryHookResult = ReturnType<typeof useGetInventoryProductImagesLazyQuery>;
export type GetInventoryProductImagesSuspenseQueryHookResult = ReturnType<typeof useGetInventoryProductImagesSuspenseQuery>;
export type GetInventoryProductImagesQueryResult = Apollo.QueryResult<GetInventoryProductImagesQuery, GetInventoryProductImagesQueryVariables>;
export const AddInventoryProductsToShowDocument = gql`
    mutation AddInventoryProductsToShow($input: AddInventoryProductsToShowInput!) {
  addInventoryProductsToShow(input: $input) {
    ok
  }
}
    `;
export type AddInventoryProductsToShowMutationFn = Apollo.MutationFunction<AddInventoryProductsToShowMutation, AddInventoryProductsToShowMutationVariables>;

/**
 * __useAddInventoryProductsToShowMutation__
 *
 * To run a mutation, you first call `useAddInventoryProductsToShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInventoryProductsToShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInventoryProductsToShowMutation, { data, loading, error }] = useAddInventoryProductsToShowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInventoryProductsToShowMutation(baseOptions?: Apollo.MutationHookOptions<AddInventoryProductsToShowMutation, AddInventoryProductsToShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInventoryProductsToShowMutation, AddInventoryProductsToShowMutationVariables>(AddInventoryProductsToShowDocument, options);
      }
export type AddInventoryProductsToShowMutationHookResult = ReturnType<typeof useAddInventoryProductsToShowMutation>;
export type AddInventoryProductsToShowMutationResult = Apollo.MutationResult<AddInventoryProductsToShowMutation>;
export type AddInventoryProductsToShowMutationOptions = Apollo.BaseMutationOptions<AddInventoryProductsToShowMutation, AddInventoryProductsToShowMutationVariables>;
export const RemoveInventoryProductsDocument = gql`
    mutation RemoveInventoryProducts($input: RemoveInventoryProductsInput!) {
  removeInventoryProducts(input: $input) {
    ok
  }
}
    `;
export type RemoveInventoryProductsMutationFn = Apollo.MutationFunction<RemoveInventoryProductsMutation, RemoveInventoryProductsMutationVariables>;

/**
 * __useRemoveInventoryProductsMutation__
 *
 * To run a mutation, you first call `useRemoveInventoryProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInventoryProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInventoryProductsMutation, { data, loading, error }] = useRemoveInventoryProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveInventoryProductsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveInventoryProductsMutation, RemoveInventoryProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveInventoryProductsMutation, RemoveInventoryProductsMutationVariables>(RemoveInventoryProductsDocument, options);
      }
export type RemoveInventoryProductsMutationHookResult = ReturnType<typeof useRemoveInventoryProductsMutation>;
export type RemoveInventoryProductsMutationResult = Apollo.MutationResult<RemoveInventoryProductsMutation>;
export type RemoveInventoryProductsMutationOptions = Apollo.BaseMutationOptions<RemoveInventoryProductsMutation, RemoveInventoryProductsMutationVariables>;
export const GetPastShowsDocument = gql`
    query GetPastShows($first: Int!, $after: String, $lastNDaysOnly: Int) {
  viewer {
    id
    shows: pastAndCurrentShowsByStartDateDesc(
      first: $first
      after: $after
      lastNDaysOnly: $lastNDaysOnly
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          legacyId
          startAt
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetPastShowsQuery__
 *
 * To run a query within a React component, call `useGetPastShowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPastShowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPastShowsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      lastNDaysOnly: // value for 'lastNDaysOnly'
 *   },
 * });
 */
export function useGetPastShowsQuery(baseOptions: Apollo.QueryHookOptions<GetPastShowsQuery, GetPastShowsQueryVariables> & ({ variables: GetPastShowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPastShowsQuery, GetPastShowsQueryVariables>(GetPastShowsDocument, options);
      }
export function useGetPastShowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPastShowsQuery, GetPastShowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPastShowsQuery, GetPastShowsQueryVariables>(GetPastShowsDocument, options);
        }
export function useGetPastShowsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPastShowsQuery, GetPastShowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPastShowsQuery, GetPastShowsQueryVariables>(GetPastShowsDocument, options);
        }
export type GetPastShowsQueryHookResult = ReturnType<typeof useGetPastShowsQuery>;
export type GetPastShowsLazyQueryHookResult = ReturnType<typeof useGetPastShowsLazyQuery>;
export type GetPastShowsSuspenseQueryHookResult = ReturnType<typeof useGetPastShowsSuspenseQuery>;
export type GetPastShowsQueryResult = Apollo.QueryResult<GetPastShowsQuery, GetPastShowsQueryVariables>;