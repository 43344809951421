import Button from '@/components/ui/Button/Button'
import MoreOptionsIcon from '@/components/ui/Icons/MoreOptionsIcon/MoreOptionsIcon'

type ChatMoreOptionsButtonProps = {
  onClick: () => void
}

const ChatMoreOptionsButton = (props: ChatMoreOptionsButtonProps) => {
  const { onClick } = props

  return <Button className="chat-more-options-action" icon={<MoreOptionsIcon />} onClick={onClick} />
}

export default ChatMoreOptionsButton
