import * as Types from '../../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShipmentByIdForPackingSlipFragment = { __typename?: 'Shipment', id: string, trackingNumber?: string | null, shippingProvider?: string | null, customer: { __typename?: 'User', id: string, username: string }, shippingAddress: { __typename?: 'ShippingAddress', id: string, name: string, inputLine1: string, inputLine2?: string | null, inputPostalCode: string, inputCity: string, inputCountry: string }, orderedProducts: { __typename?: 'OrderedProductsConnection', edges: Array<{ __typename?: 'OrderedProductsEdge', node: { __typename?: 'OrderedProduct', id: string, order: { __typename?: 'Order', id: string, legacyId: number, name: string, product: { __typename?: 'Product', id: string, name: string, description?: string | null } } } }> } };

export type GetShipmentByIdForPackingSlipQueryVariables = Types.Exact<{
  nodeId: Types.Scalars['ID']['input'];
}>;


export type GetShipmentByIdForPackingSlipQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign' } | { __typename?: 'AutocompleteSearchItem' } | { __typename?: 'Badge' } | { __typename?: 'Category' } | { __typename?: 'CommercialEvent' } | { __typename?: 'Country' } | { __typename?: 'Emote' } | { __typename?: 'FavoriteProduct' } | { __typename?: 'FavoriteShow' } | { __typename?: 'ImageUpload' } | { __typename?: 'InventoryProduct' } | { __typename?: 'Language' } | { __typename?: 'Order' } | { __typename?: 'OrderedProduct' } | { __typename?: 'ParentCategory' } | { __typename?: 'Product' } | { __typename?: 'ProductForOrdering' } | { __typename?: 'Promotion' } | { __typename?: 'PromotionV2' } | { __typename?: 'RatingFromCustomer' } | { __typename?: 'SellerLedgerPayout' } | { __typename?: 'SellerLedgerTransaction' } | { __typename?: 'SellerTier' } | { __typename?: 'Shipment', id: string, trackingNumber?: string | null, shippingProvider?: string | null, customer: { __typename?: 'User', id: string, username: string }, shippingAddress: { __typename?: 'ShippingAddress', id: string, name: string, inputLine1: string, inputLine2?: string | null, inputPostalCode: string, inputCity: string, inputCountry: string }, orderedProducts: { __typename?: 'OrderedProductsConnection', edges: Array<{ __typename?: 'OrderedProductsEdge', node: { __typename?: 'OrderedProduct', id: string, order: { __typename?: 'Order', id: string, legacyId: number, name: string, product: { __typename?: 'Product', id: string, name: string, description?: string | null } } } }> } } | { __typename?: 'Shop' } | { __typename?: 'Show' } | { __typename?: 'ShowOrdersGroup' } | { __typename?: 'ShowProductNode' } | { __typename?: 'User' } };

export type GeneratePackingSlipForShipmentsMutationVariables = Types.Exact<{
  input: Types.GeneratePackingSlipForShipmentsInput;
}>;


export type GeneratePackingSlipForShipmentsMutation = { __typename?: 'Mutation', generatePackingSlipForShipments: { __typename?: 'GeneratePackingSlipForShipmentsPayload', url: string } };

export type GeneratePackingSlipForOrderedProductsMutationVariables = Types.Exact<{
  input: Types.GeneratePackingSlipForUnshippedOrderedProductsInput;
}>;


export type GeneratePackingSlipForOrderedProductsMutation = { __typename?: 'Mutation', generatePackingSlipForUnshippedOrderedProducts: { __typename?: 'GeneratePackingSlipForUnshippedOrderedProductsPayload', url: string } };

export const ShipmentByIdForPackingSlipFragmentDoc = gql`
    fragment ShipmentByIdForPackingSlip on Shipment {
  id
  customer {
    id
    username
  }
  shippingAddress {
    id
    name
    inputLine1
    inputLine2
    inputPostalCode
    inputCity
    inputCountry
  }
  trackingNumber
  shippingProvider
  orderedProducts(first: 10000) {
    edges {
      node {
        id
        order {
          id
          legacyId
          name
          product {
            id
            name
            description
          }
        }
      }
    }
  }
}
    `;
export const GetShipmentByIdForPackingSlipDocument = gql`
    query GetShipmentByIdForPackingSlip($nodeId: ID!) {
  node(id: $nodeId) {
    ... on Shipment {
      ...ShipmentByIdForPackingSlip
    }
  }
}
    ${ShipmentByIdForPackingSlipFragmentDoc}`;

/**
 * __useGetShipmentByIdForPackingSlipQuery__
 *
 * To run a query within a React component, call `useGetShipmentByIdForPackingSlipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentByIdForPackingSlipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentByIdForPackingSlipQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetShipmentByIdForPackingSlipQuery(baseOptions: Apollo.QueryHookOptions<GetShipmentByIdForPackingSlipQuery, GetShipmentByIdForPackingSlipQueryVariables> & ({ variables: GetShipmentByIdForPackingSlipQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentByIdForPackingSlipQuery, GetShipmentByIdForPackingSlipQueryVariables>(GetShipmentByIdForPackingSlipDocument, options);
      }
export function useGetShipmentByIdForPackingSlipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentByIdForPackingSlipQuery, GetShipmentByIdForPackingSlipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentByIdForPackingSlipQuery, GetShipmentByIdForPackingSlipQueryVariables>(GetShipmentByIdForPackingSlipDocument, options);
        }
export function useGetShipmentByIdForPackingSlipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShipmentByIdForPackingSlipQuery, GetShipmentByIdForPackingSlipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShipmentByIdForPackingSlipQuery, GetShipmentByIdForPackingSlipQueryVariables>(GetShipmentByIdForPackingSlipDocument, options);
        }
export type GetShipmentByIdForPackingSlipQueryHookResult = ReturnType<typeof useGetShipmentByIdForPackingSlipQuery>;
export type GetShipmentByIdForPackingSlipLazyQueryHookResult = ReturnType<typeof useGetShipmentByIdForPackingSlipLazyQuery>;
export type GetShipmentByIdForPackingSlipSuspenseQueryHookResult = ReturnType<typeof useGetShipmentByIdForPackingSlipSuspenseQuery>;
export type GetShipmentByIdForPackingSlipQueryResult = Apollo.QueryResult<GetShipmentByIdForPackingSlipQuery, GetShipmentByIdForPackingSlipQueryVariables>;
export const GeneratePackingSlipForShipmentsDocument = gql`
    mutation GeneratePackingSlipForShipments($input: GeneratePackingSlipForShipmentsInput!) {
  generatePackingSlipForShipments(input: $input) {
    url
  }
}
    `;
export type GeneratePackingSlipForShipmentsMutationFn = Apollo.MutationFunction<GeneratePackingSlipForShipmentsMutation, GeneratePackingSlipForShipmentsMutationVariables>;

/**
 * __useGeneratePackingSlipForShipmentsMutation__
 *
 * To run a mutation, you first call `useGeneratePackingSlipForShipmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePackingSlipForShipmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePackingSlipForShipmentsMutation, { data, loading, error }] = useGeneratePackingSlipForShipmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePackingSlipForShipmentsMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePackingSlipForShipmentsMutation, GeneratePackingSlipForShipmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePackingSlipForShipmentsMutation, GeneratePackingSlipForShipmentsMutationVariables>(GeneratePackingSlipForShipmentsDocument, options);
      }
export type GeneratePackingSlipForShipmentsMutationHookResult = ReturnType<typeof useGeneratePackingSlipForShipmentsMutation>;
export type GeneratePackingSlipForShipmentsMutationResult = Apollo.MutationResult<GeneratePackingSlipForShipmentsMutation>;
export type GeneratePackingSlipForShipmentsMutationOptions = Apollo.BaseMutationOptions<GeneratePackingSlipForShipmentsMutation, GeneratePackingSlipForShipmentsMutationVariables>;
export const GeneratePackingSlipForOrderedProductsDocument = gql`
    mutation GeneratePackingSlipForOrderedProducts($input: GeneratePackingSlipForUnshippedOrderedProductsInput!) {
  generatePackingSlipForUnshippedOrderedProducts(input: $input) {
    url
  }
}
    `;
export type GeneratePackingSlipForOrderedProductsMutationFn = Apollo.MutationFunction<GeneratePackingSlipForOrderedProductsMutation, GeneratePackingSlipForOrderedProductsMutationVariables>;

/**
 * __useGeneratePackingSlipForOrderedProductsMutation__
 *
 * To run a mutation, you first call `useGeneratePackingSlipForOrderedProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePackingSlipForOrderedProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePackingSlipForOrderedProductsMutation, { data, loading, error }] = useGeneratePackingSlipForOrderedProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePackingSlipForOrderedProductsMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePackingSlipForOrderedProductsMutation, GeneratePackingSlipForOrderedProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePackingSlipForOrderedProductsMutation, GeneratePackingSlipForOrderedProductsMutationVariables>(GeneratePackingSlipForOrderedProductsDocument, options);
      }
export type GeneratePackingSlipForOrderedProductsMutationHookResult = ReturnType<typeof useGeneratePackingSlipForOrderedProductsMutation>;
export type GeneratePackingSlipForOrderedProductsMutationResult = Apollo.MutationResult<GeneratePackingSlipForOrderedProductsMutation>;
export type GeneratePackingSlipForOrderedProductsMutationOptions = Apollo.BaseMutationOptions<GeneratePackingSlipForOrderedProductsMutation, GeneratePackingSlipForOrderedProductsMutationVariables>;