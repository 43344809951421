import { message } from 'antd'
import { t } from 'i18next'

import { VideoTranscodingStatus } from './VideoUploader.container'

// TODO: find better typing and fix ts-ignore
export const handleVideoUploadTranscodingSubscriptionData = (subscriptionData: any) => {
  const { data } = subscriptionData || {}
  const { preShowTeaserVideoTranscodingStatusChanged } = data || {}
  const { status } = preShowTeaserVideoTranscodingStatusChanged || {}

  const isSuccessful = status === VideoTranscodingStatus.SUCCEEDED
  const isError = status === VideoTranscodingStatus.FAILED

  if (isSuccessful) {
    message.success(t('videoUploadTranscodingSuccess'))
  } else if (isError) {
    message.error(t('videoUploadTranscodingError'))
  }
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
