import { RightOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

import { groupByKey } from '@/util/lodash-replacer'

import Collapsible from '../../../../components/ui/Collapsible/Collapsible'
import { useShows } from '../../hooks/use-shows'
import { ShowsPanelHeader } from '../ShowsPanelHeader'
import { ShowsTable } from '../ShowsTable/ShowsTable'

import style from './style.module.scss'

import './ShowsList.scss'

export interface ShowsListProps {
  mobile?: boolean
}

export const ShowsList = ({ mobile }: ShowsListProps) => {
  const { shows } = useShows()
  const showsByMonthAndYear = shows.map((show) => ({
    ...show,
    panelValue: dayjs(show.startAt).format('YYYY-MM'),
  }))
  const groupedShowsByMonthAndYear = groupByKey(showsByMonthAndYear, 'panelValue')
  const showsMonthAndYear = Object.keys(groupedShowsByMonthAndYear)
  const history = useHistory()

  if (mobile) {
    return (
      <>
        {showsMonthAndYear.map((showDate) => (
          <div key={showDate} className="shows-list mb-10">
            <ShowsPanelHeader mobile={true} shows={groupedShowsByMonthAndYear[showDate]} />
            {groupedShowsByMonthAndYear[showDate].map((show) => (
              <div key={show.id}>
                <div
                  className={`${style.mobile_show_list} mt-2`}
                  onClick={() => {
                    history.push(`/shows/${show.legacyId}`)
                  }}
                >
                  <div className={`${style.mobile_show_list_image} overflow-hidden ml-2`}>
                    <img
                      alt={`thumbnail-${show.name}`}
                      className="w-[52px] h-[52px] rounded-lg object-cover"
                      src={show.thumbnailWebPUrl ?? '/badges/empty-picture.png'}
                    />
                  </div>
                  <div className="ml-4">
                    <h3 className="callout_4">{show.name}</h3>
                    <p className="text-black-120">{dayjs(show.startAt).format('DD/MM/YYYY')}</p>
                  </div>
                  <div className={`${style.mobile_show_list_icon}`}>
                    <RightOutlined />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      {showsMonthAndYear.map((showDate) => (
        <Collapsible
          key={showDate}
          headerContent={<ShowsPanelHeader shows={groupedShowsByMonthAndYear[showDate]} />}
          opened={dayjs().isSame(showDate, 'month')}
          title={dayjs(showDate).format('MMMM YYYY')}
        >
          <ShowsTable shows={groupedShowsByMonthAndYear[showDate]} />
        </Collapsible>
      ))}
    </>
  )
}
