import { useTranslation } from 'react-i18next'
import { FaInfo } from 'react-icons/fa6'

import Card from '@/components/ui/Card/Card'
import Popover from '@/components/ui/Popover/Popover'
import { Currency } from '@/network/graphql/types.generated'

import { useGetMonthlyStatisticsQuery } from '../../operations.generated'

import './Performances.scss'
export const Performances = () => {
  const { t } = useTranslation()

  const { data, loading } = useGetMonthlyStatisticsQuery()
  const sellerMonthlyStatistics = data?.viewer?.sellerMonthlyStatistics
  const currency = data?.viewer?.sellerConfig?.sellerCurrency
  const currencyFormatted = currency === Currency.Gbp ? '$' : '€'

  if (loading) return null

  return (
    <div className="performances-metrics">
      <Card className="gmv">
        <div className="header-metric total-gmv">
          <h3>{t('homePerformancesTotalGMV')}</h3>
          <Popover content={t('homePerformancesTotalGMVTooltip')}>
            <FaInfo />
          </Popover>
        </div>
        <div className="metrics">
          <div className="icon-container">
            <span className="icon">💰</span>
          </div>
          <p className="value">
            {sellerMonthlyStatistics?.totalGmvMtd ?? t('commonNotAvailableValue')}
            {currencyFormatted}
          </p>
          <div className="last-month">
            <span>{t('homePerformancesLastMonths')}</span>
            <span>
              {sellerMonthlyStatistics?.totalGmvLastMonth ?? t('commonNotAvailableValue')}
              {currencyFormatted}
            </span>
          </div>
        </div>
      </Card>

      <Card>
        <div className="header-metric sold-items">
          <h3>{t('homePerformancesItemsSold')}</h3>
        </div>
        <div className="metrics">
          <div className="icon-container">
            <span className="icon">🗄</span>
          </div>
          <p className="value">{sellerMonthlyStatistics?.soldItemsCountMtd ?? t('commonNotAvailableValue')}</p>
          <div className="last-month">
            <span>{t('homePerformancesLastMonths')}</span>
            <span>{sellerMonthlyStatistics?.soldItemsCountLastMonth ?? t('commonNotAvailableValue')}</span>
          </div>
        </div>
      </Card>

      <Card>
        <div className="header-metric shows-streak">
          <h3>{t('homePerformancesShowsStreak')}</h3>
        </div>
        <div className="metrics">
          <div className="icon-container">
            <span className="icon">🔥</span>
          </div>
          <p className="value">{sellerMonthlyStatistics?.showCountMtd ?? t('commonNotAvailableValue')}</p>
          <div className="last-month">
            <span>{t('homePerformancesLastMonths')}</span>
            <span>{sellerMonthlyStatistics?.showCountLastMonth ?? t('commonNotAvailableValue')}</span>
          </div>
        </div>
      </Card>

      <Card>
        <div className="header-metric viewers">
          <h3>{t('homePerformancesViewers')}</h3>
        </div>
        <div className="metrics">
          <div className="icon-container">
            <span className="icon">👀</span>
          </div>
          <p className="value">{sellerMonthlyStatistics?.viewerCountMtd ?? t('commonNotAvailableValue')}</p>
          <div className="last-month">
            <span>{t('homePerformancesLastMonths')}</span>
            <span>{sellerMonthlyStatistics?.viewerCountLastMonth ?? t('commonNotAvailableValue')}</span>
          </div>
        </div>
      </Card>

      <Card>
        <div className="header-metric comments">
          <h3>{t('homePerformancesComments')}</h3>
        </div>
        <div className="metrics">
          <div className="icon-container">
            <span className="icon">💬</span>
          </div>
          <p className="value">{sellerMonthlyStatistics?.commentCountMtd ?? t('commonNotAvailableValue')}</p>
          <div className="last-month">
            <span>{t('homePerformancesLastMonths')}</span>
            <span>{sellerMonthlyStatistics?.commentCountLastMonth ?? t('commonNotAvailableValue')}</span>
          </div>
        </div>
      </Card>

      <Card>
        <div className="header-metric followers">
          <h3>{t('homePerformancesNewFollowers')}</h3>
        </div>
        <div className="metrics">
          <div className="icon-container">
            <span className="icon">👥</span>
          </div>
          <p className="value positive">
            + {sellerMonthlyStatistics?.newFollowerCountMtd ?? t('commonNotAvailableValue')}
          </p>
          <div className="last-month">
            <span>{t('homePerformancesLastMonths')}</span>
            <span>+ {sellerMonthlyStatistics?.newFollowerCountLastMonth ?? t('commonNotAvailableValue')}</span>
          </div>
        </div>
      </Card>
    </div>
  )
}
