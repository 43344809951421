import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { fetchShippingAddress } from '@/actions/fetch-shipping-address'
import { notificationDanger } from '@/components/ui/Notification/Notification'
import { useAppSelector } from '@/reducers'
import { trackEvent } from '@/util/eventTracker'
import { parseShippingMethodError } from '@/views/Shipments/utils/shipments.util'

import type { ShippingMethod } from './utils/shipments.util'
import type { ShippingAddress } from '@/network/graphql/types.generated'
import type { ApolloError } from '@apollo/client'
import type { ReactNode } from 'react'

interface ShipmentContextState {
  usernameSearchValue: string
  onShippingMethodError(error: ApolloError, shippingMethod: ShippingMethod): void
  onSetUsernameSearchValue(value: string): void
}

interface ShipmentProviderProps {
  children: ReactNode
}

const ShipmentContext = createContext({} as ShipmentContextState)

export default function ShipmentProvider({ children }: ShipmentProviderProps) {
  const dispatch = useDispatch()
  const sellerAddress: ShippingAddress | null | undefined = useAppSelector(
    (state) => state.authentication.user?.shippingAddress
  )

  const [usernameSearchValue, setUsernameSearchValue] = useState<string>('')

  const onSetUsernameSearchValue = useCallback((value: string) => {
    setUsernameSearchValue(value)
    trackEvent('SHIPMENT_USER_SEARCH')
  }, [])

  const onShippingMethodError = (error: ApolloError) => {
    const parsedShippingMethodError = parseShippingMethodError(error)
    if (parseShippingMethodError.length > 0) {
      const errorMessages = parsedShippingMethodError.join(', ')
      notificationDanger(errorMessages)
    }
  }

  const value: ShipmentContextState = {
    usernameSearchValue,
    onShippingMethodError,
    onSetUsernameSearchValue,
  }

  useEffect(() => {
    if (!sellerAddress) dispatch(fetchShippingAddress())
  }, [sellerAddress])

  return <ShipmentContext.Provider value={value}>{children}</ShipmentContext.Provider>
}

export const useShipmentContext = () => useContext(ShipmentContext)
