import { message } from 'antd'
import { useTranslation } from 'react-i18next'

import { trackError } from '@/util/sentry'

import { useCancelRaidMutation, useOpenRaidMutation, useSendRaidMutation } from '../operations.generated'

interface useRaidProps {
  showGlobalId: string
}

export const useRaid = (props: useRaidProps) => {
  const { showGlobalId } = props
  const { t } = useTranslation()
  const [sendRaidMutation, { loading: sendRaidMutationLoading }] = useSendRaidMutation()
  const [openRaidMutation, { loading: openRaidLoading }] = useOpenRaidMutation()
  const [cancelRaidMutation, { loading: cancelRaidMutationLoading }] = useCancelRaidMutation()

  const sendRaid = async () => {
    sendRaidMutation({
      variables: { showId: showGlobalId },
      onCompleted: () => {
        //[Remove antd] Replace with custom notification when we have it
        message.success(t('showRaidSentConfirmation'))
      },
      onError: (err) => {
        //[Remove antd] Replace with custom notification when we have it
        message.error(err?.message)
        trackError(err, {
          meta: {
            feature: 'raid.hooks.useRaid',
          },
        })
      },
    })
  }

  const openRaid = async (destinationShow: string) => {
    openRaidMutation({
      variables: {
        input: {
          showId: showGlobalId,
          destinationShowId: destinationShow,
        },
      },
      onError: (err) => {
        //[Remove antd] Replace with custom notification when we have it
        message.error(err?.message)
        trackError(err, {
          meta: {
            feature: 'raid.hooks.useRaid',
          },
        })
      },
    })
  }

  const cancelRaid = async () => {
    cancelRaidMutation({
      variables: { showId: showGlobalId },
      onCompleted: () => {
        //[Remove antd] Replace with custom notification when we have it
        message.success(t('showRaidCancelledConfirmation'))
      },
      onError: (err) => {
        //[Remove antd] Replace with custom notification when we have it
        message.error(err?.message)
        trackError(err, {
          meta: {
            feature: 'raid.hooks.useRaid',
          },
        })
      },
    })
  }

  return { sendRaid, sendRaidMutationLoading, cancelRaid, cancelRaidMutationLoading, openRaid, openRaidLoading }
}
