import { useEffect, useState } from 'react'

import { useGetSellerTierLazyQuery } from '@/components/SellerTier/graphql/operations.generated'
import { trackError } from '@/util/sentry'

export const useSellerTier = () => {
  const [loadSellerTier] = useGetSellerTierLazyQuery()
  const [sellerTier, setSellerTier] = useState<any>()
  const [loading, setLoading] = useState(true)

  // TODO: Is the useEffect below really necessary?
  useEffect(() => {
    loadSellerTier({
      onCompleted: (data) => {
        setLoading(false)
        setSellerTier(data?.viewer?.sellerConfig?.sellerTier)
      },
      onError: (err) => {
        trackError(err, { meta: { scope: 'useSellerTier' } })
        setLoading(false)
      },
    })
  }, [loadSellerTier])

  return { sellerTier, loading }
}
