import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '../../../components/ui/Card/Card'
import { useCurrencyFormatter } from '../../../helpers/currencyFormatter'

import type { ShowItemFragment } from '../operations.generated'

type PartialShow = Pick<ShowItemFragment, 'startAt' | 'totalAmountOrdersSuccessInCurrencyCents' | 'currency'>

export interface LatestShowsPerformancesProps {
  shows: Array<PartialShow>
}

export const LatestShowsPerformances = ({ shows }: LatestShowsPerformancesProps) => {
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()

  const pastShows = useMemo(() => shows.filter((s) => dayjs(s.startAt).isBefore(new Date())), [shows])
  const lastShow: PartialShow | null = useMemo(() => {
    if (!pastShows.length) return null

    return pastShows[0]
  }, [pastShows])

  const showsInLast60days = useMemo(() => {
    const twoMonthsAgo = dayjs(new Date()).subtract(2, 'month')

    return shows.filter((s) => dayjs(s.startAt).isAfter(twoMonthsAgo) && dayjs(s.startAt).isBefore(new Date()))
  }, [shows])

  const average =
    useMemo(() => {
      return (
        showsInLast60days.reduce((a, b) => a + b.totalAmountOrdersSuccessInCurrencyCents, 0) / showsInLast60days.length
      )
    }, [showsInLast60days]) || 0

  const performanceComparedToAverage = useMemo(() => {
    if (!lastShow) return 0

    if (lastShow.totalAmountOrdersSuccessInCurrencyCents === 0) return -100

    return (lastShow.totalAmountOrdersSuccessInCurrencyCents * 100) / average - 100
  }, [average, lastShow])

  return (
    <Card title={t('LatestShowsPerformancesTitle')}>
      <div className="w-full flex flex-col divide-y divide-slate-100">
        <div className="w-full flex justify-between items-center py-2 first:pt-0">
          <p className="text-black-200 m-0">{t('LatestShowsPerformancesLastShow')}</p>

          <p className="text-black-400 m-0">
            {lastShow ? currencyFormatter(lastShow.totalAmountOrdersSuccessInCurrencyCents, lastShow.currency) : 'NA'}
          </p>
        </div>

        <div className="w-full flex justify-between items-center py-2">
          <p className="text-black-200 m-0">{t('LatestShowsPerformancesAverage')}</p>

          <p className="text-black-400 m-0">{lastShow ? currencyFormatter(average, lastShow.currency) : 'NA'}</p>
        </div>

        <div className="w-full flex justify-between items-center py-2">
          <p className="text-black-200 m-0">{t('LatestShowsPerformancesLastShowVsAverage')}</p>

          <p className="text-black-400 m-0">
            {average ? (
              <>
                {performanceComparedToAverage > 0 && '+'}
                {performanceComparedToAverage.toFixed(0)}%
              </>
            ) : (
              '/'
            )}
          </p>
        </div>
      </div>
    </Card>
  )
}
