import { useState } from 'react'

import Button from '@/components/ui/Button/Button'
import Popover from '@/components/ui/Popover/Popover'
import { setToLocalStorage } from '@/helpers/localstorage'

import { FontSizes, useChatBoxContext } from '../../../ChatBoxProvider'

export const ChatFontSize = () => {
  const { fontSize, setFontSize } = useChatBoxContext()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleFontSizeChange = (value: FontSizes) => {
    setFontSize(value)
    setIsOpen(false)
    setToLocalStorage('chat_font_size', value)
  }

  return (
    <Popover
      className="chat-font-size-popover"
      classNameContent="chat-font-size-popover-content"
      hideOnMouseOut={false}
      isOpen={isOpen}
      openOnMouseHover={false}
      positions={['bottom', 'left']}
      content={
        <div className="chat-font-size">
          <div
            className={fontSize === FontSizes.small ? 'selected' : ''}
            onClick={() => handleFontSizeChange(FontSizes.small)}
          >
            <p className="chat-font-small">Small</p>
          </div>

          <div
            className={fontSize === FontSizes.normal ? 'selected' : ''}
            onClick={() => handleFontSizeChange(FontSizes.normal)}
          >
            <p className="chat-font-normal">Normal</p>
          </div>

          <div
            className={fontSize === FontSizes.medium ? 'selected' : ''}
            onClick={() => handleFontSizeChange(FontSizes.medium)}
          >
            <p className="chat-font-medium">Medium</p>
          </div>

          <div
            className={fontSize === FontSizes.large ? 'selected' : ''}
            onClick={() => handleFontSizeChange(FontSizes.large)}
          >
            <p className="chat-font-large">Large</p>
          </div>
        </div>
      }
      onClickOutside={() => setIsOpen(false)}
    >
      <Button className="chat-font-size-action" label="Aa" onClick={() => setIsOpen(!isOpen)} />
    </Popover>
  )
}
