import { type Country } from '@/data/countriesClusters'

type VoggtUniversityLinksByCountry = {
  [key in Country]: string
}

export const voggtUniversityLinkByCountry: VoggtUniversityLinksByCountry = {
  FR: 'https://voggt.notion.site/L-Universit-Voggt-64f79cf8e9664a36b241b98946b820b6',
  BE: 'https://voggt.notion.site/L-Universit-Voggt-64f79cf8e9664a36b241b98946b820b6',
  CH: 'https://voggt.notion.site/L-Universit-Voggt-64f79cf8e9664a36b241b98946b820b6',
  GB: 'https://voggt.notion.site/Voggt-University-4bde12738cc4483fab7ce21ada91ae77',
  NL: 'https://voggt.notion.site/Voggt-University-4bde12738cc4483fab7ce21ada91ae77',
  ES: 'https://voggt.notion.site/Universidad-Voggt-aa65f7db744447efa00b27daaa308455',
  DE: 'https://voggt.notion.site/Voggt-Universit-t-9e22afbafc3d47e4a360f162b142b31a?pvs=4',
  AT: 'https://voggt.notion.site/Voggt-Universit-t-9e22afbafc3d47e4a360f162b142b31a?pvs=4',
}
