import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from '@/components/ui/Dialog/Dialog'
import PollIcon from '@/components/ui/Icons/PollIcon/PollIcon'
import Popover from '@/components/ui/Popover/Popover'
import ChatMoreOptionsButton from '@/views/Show/components/ChatBox/components/Chat/ChatMoreOptions/ChatMoreOptionsButton/ChatMoreOptionsButton'
import useLaunchPoll from '@/views/Show/components/ChatBox/components/Chat/ChatMoreOptions/useLaunchPoll'
import CreatePollForm, {
  type PollInputData,
} from '@/views/Show/components/ChatBox/components/CreatePoll/CreatePollForm/CreatePollForm'

import './ChatMoreOptions.scss'

type ChatMoreOptionsProps = {
  showId: number // TODO: this should probably be retrieved from a ShowContext
  disabled: boolean
  onNewContentAdded: () => void // TODO: should take the new content as argument (and also the type of content?)
}

const ChatMoreOptions = (props: ChatMoreOptionsProps) => {
  const { showId, disabled, onNewContentAdded } = props
  const { t } = useTranslation()

  const { launchPoll, loading: isPollLaunching } = useLaunchPoll({
    showId,
    onLaunched: () => {
      onNewContentAdded()
    },
  })

  const [isOpen, setIsOpen] = useState(false)
  const [isPollCreationOpen, setIsPollCreationOpen] = useState(false)

  const handleTriggerPollCreation = () => {
    if (disabled) {
      return
    }

    setIsOpen(false)
    setIsPollCreationOpen(true)
  }

  const handleMoreOptionsClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const handleCreatePollComplete = useCallback(async (data?: PollInputData) => {
    if (data) await launchPoll(data)
    setIsPollCreationOpen(false)
  }, [])

  return (
    <>
      <Popover
        className="chat-activity-popover"
        classNameContent="chat-activity-popover-content"
        hideOnMouseOut={false}
        isOpen={isOpen}
        openOnMouseHover={false}
        positions={['top', 'left']}
        content={
          // TODO: the following should probably be a <menu> and maybe a <ChatMoreOptionsMenu>
          <div className="activities">
            {/* TODO: the following should probably be a button */}
            <div className={`activity-poll ${disabled ? 'is-disabled' : ''}`} onClick={handleTriggerPollCreation}>
              <header className="title">
                <PollIcon />
                <h2>{t('pollsPopoverTitle')}</h2>
              </header>
              <p>{t('pollsPopoverContent')}</p>
            </div>
          </div>
        }
      >
        <ChatMoreOptionsButton onClick={handleMoreOptionsClick} />
      </Popover>
      {isPollCreationOpen && (
        <Dialog
          className="create-poll-dialog"
          isOpen={true}
          title={t('pollModalTitle')}
          onClose={() => handleCreatePollComplete()}
        >
          <CreatePollForm isLoading={isPollLaunching} onComplete={handleCreatePollComplete} />
        </Dialog>
      )}
    </>
  )
}

export default ChatMoreOptions
