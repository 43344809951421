import { useCallback, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import { preventChangeOnWheelScrollOnNumberInputs } from '@/helpers/forms/preventChangeOnWheelScrollOnNumberInputs'

import type { ProductFormValidity } from '@/components/CreateOrEditProduct2/types'

const ENABLE_FIXED_AMOUNT_IN_PRODUCT = import.meta.env.VITE_APP_ENABLE_FIXED_AMOUNT_IN_PRODUCT === 'true'

export const BUY_NOW_PRICE_MAX_IN_CENTS = 10000000
export const BUY_NOW_PRICE_MIN_IN_CENTS = 100

type ProductBuyNowPriceFieldProps = {
  // !!! Note !!!
  // We use string values below because we don't want to set default value, but still want to have controlled inputs
  value: string
  startingPrice: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onValidityChange?: (name: keyof ProductFormValidity, isValid: boolean) => void
  disabled?: boolean
}

const ProductBuyNowPriceField = (props: ProductBuyNowPriceFieldProps) => {
  const {
    value: buyNowPrice,
    startingPrice,
    onChange: handleBuyNowPriceChange,
    onValidityChange = () => undefined,
    disabled = false,
  } = props
  const { t } = useTranslation()

  const preventInputWheelOnNumberInput = useCallback(preventChangeOnWheelScrollOnNumberInputs, [])

  if (!ENABLE_FIXED_AMOUNT_IN_PRODUCT) {
    return null
  }

  return (
    <Field
      disabled={disabled}
      label={t('productFormBuyNowPriceLabel')}
      max={BUY_NOW_PRICE_MAX_IN_CENTS / 100}
      min={BUY_NOW_PRICE_MIN_IN_CENTS / 100}
      name="buy-now-price"
      type="number"
      value={buyNowPrice}
      validators={[
        {
          test: (val) =>
            startingPrice && val
              ? parseInt(val?.toString() || '', 10) > parseInt(startingPrice?.toString() || '', 10)
              : true,
          errorMessage: t('instantBuyPriceShouldNotBeLowerThanStarting'),
        },
      ]}
      onChange={handleBuyNowPriceChange}
      onValidityChange={(isValid: boolean) => onValidityChange('buyNowPrice', isValid)}
      onWheel={preventInputWheelOnNumberInput}
    />
  )
}

export default ProductBuyNowPriceField
