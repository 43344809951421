import dayjs from 'dayjs'
import { useMemo } from 'react'

import { uniqBy } from '@/util/lodash-replacer'

import { useGetAllShowsQuery } from '../operations.generated'

import type { ShowItemFragment } from '../operations.generated'

export const useShows = () => {
  const { data, loading } = useGetAllShowsQuery({ fetchPolicy: 'cache-first' })

  const shows: Array<ShowItemFragment> = useMemo(() => {
    let shows: Array<ShowItemFragment> = []

    if (data?.viewer?.showsByStartAtDescForSeller?.edges) {
      shows = data?.viewer?.showsByStartAtDescForSeller?.edges.map((edge) => edge.node)
    }

    return uniqBy(shows, (show) => show.legacyId).sort(
      (a, b) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime()
    )
  }, [data])

  const highlightedShow: ShowItemFragment | null = useMemo(() => {
    const maybeLiveShow: ShowItemFragment | undefined = shows.find((show) => show.isBroadcasting)
    const maybeNextShow: ShowItemFragment | undefined = shows
      .filter((show) => dayjs(show.startAt).isAfter(new Date()))
      .sort((a, b) => new Date(b.startAt).getTime() - new Date(a.startAt).getTime())
      .pop()

    return maybeLiveShow ?? maybeNextShow ?? null
  }, [shows])

  return { shows, highlightedShow, loading }
}
