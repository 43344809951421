import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSellerConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSellerConfigQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, username: string, sellerConfig?: { __typename?: 'SellerConfig', canCreateShow: boolean, canAccessPreAuth: boolean, canRequestOrderCancellation: boolean, canAccessWebRTC: boolean, canAccessInventory: boolean, isInventoryInitialized: boolean, sellerCurrency: Types.Currency, stripeAccountChargesEnabled: boolean, stripeAccountPayoutsEnabled: boolean, stripeMissingInfoDueDate?: Date | null, country: { __typename?: 'Country', id: string, name: string, iso2Code: string } } | null } | null };

export type GetSellerBankAccountSupportedCountriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSellerBankAccountSupportedCountriesQuery = { __typename?: 'Query', sellerBankAccountSupportedCountries: Array<{ __typename?: 'SellerBankAccountSupportedCountries', code: any, label: string }> };

export type GetIsSupplierEligibleQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIsSupplierEligibleQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, isSupplierEligible: boolean, sellerConfig?: { __typename?: 'SellerConfig', country: { __typename?: 'Country', id: string, iso2Code: string } } | null } | null };


export const GetSellerConfigDocument = gql`
    query GetSellerConfig {
  viewer {
    id
    username
    sellerConfig {
      canCreateShow
      canAccessPreAuth
      canRequestOrderCancellation
      country {
        id
        name
        iso2Code
      }
      canAccessWebRTC
      canAccessInventory
      isInventoryInitialized
      sellerCurrency
      stripeAccountChargesEnabled
      stripeAccountPayoutsEnabled
      stripeMissingInfoDueDate
    }
  }
}
    `;

/**
 * __useGetSellerConfigQuery__
 *
 * To run a query within a React component, call `useGetSellerConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSellerConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetSellerConfigQuery, GetSellerConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellerConfigQuery, GetSellerConfigQueryVariables>(GetSellerConfigDocument, options);
      }
export function useGetSellerConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellerConfigQuery, GetSellerConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellerConfigQuery, GetSellerConfigQueryVariables>(GetSellerConfigDocument, options);
        }
export function useGetSellerConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSellerConfigQuery, GetSellerConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSellerConfigQuery, GetSellerConfigQueryVariables>(GetSellerConfigDocument, options);
        }
export type GetSellerConfigQueryHookResult = ReturnType<typeof useGetSellerConfigQuery>;
export type GetSellerConfigLazyQueryHookResult = ReturnType<typeof useGetSellerConfigLazyQuery>;
export type GetSellerConfigSuspenseQueryHookResult = ReturnType<typeof useGetSellerConfigSuspenseQuery>;
export type GetSellerConfigQueryResult = Apollo.QueryResult<GetSellerConfigQuery, GetSellerConfigQueryVariables>;
export const GetSellerBankAccountSupportedCountriesDocument = gql`
    query GetSellerBankAccountSupportedCountries {
  sellerBankAccountSupportedCountries {
    code
    label
  }
}
    `;

/**
 * __useGetSellerBankAccountSupportedCountriesQuery__
 *
 * To run a query within a React component, call `useGetSellerBankAccountSupportedCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerBankAccountSupportedCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerBankAccountSupportedCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSellerBankAccountSupportedCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSellerBankAccountSupportedCountriesQuery, GetSellerBankAccountSupportedCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellerBankAccountSupportedCountriesQuery, GetSellerBankAccountSupportedCountriesQueryVariables>(GetSellerBankAccountSupportedCountriesDocument, options);
      }
export function useGetSellerBankAccountSupportedCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellerBankAccountSupportedCountriesQuery, GetSellerBankAccountSupportedCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellerBankAccountSupportedCountriesQuery, GetSellerBankAccountSupportedCountriesQueryVariables>(GetSellerBankAccountSupportedCountriesDocument, options);
        }
export function useGetSellerBankAccountSupportedCountriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSellerBankAccountSupportedCountriesQuery, GetSellerBankAccountSupportedCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSellerBankAccountSupportedCountriesQuery, GetSellerBankAccountSupportedCountriesQueryVariables>(GetSellerBankAccountSupportedCountriesDocument, options);
        }
export type GetSellerBankAccountSupportedCountriesQueryHookResult = ReturnType<typeof useGetSellerBankAccountSupportedCountriesQuery>;
export type GetSellerBankAccountSupportedCountriesLazyQueryHookResult = ReturnType<typeof useGetSellerBankAccountSupportedCountriesLazyQuery>;
export type GetSellerBankAccountSupportedCountriesSuspenseQueryHookResult = ReturnType<typeof useGetSellerBankAccountSupportedCountriesSuspenseQuery>;
export type GetSellerBankAccountSupportedCountriesQueryResult = Apollo.QueryResult<GetSellerBankAccountSupportedCountriesQuery, GetSellerBankAccountSupportedCountriesQueryVariables>;
export const GetIsSupplierEligibleDocument = gql`
    query GetIsSupplierEligible {
  viewer {
    id
    isSupplierEligible
    sellerConfig {
      country {
        id
        iso2Code
      }
    }
  }
}
    `;

/**
 * __useGetIsSupplierEligibleQuery__
 *
 * To run a query within a React component, call `useGetIsSupplierEligibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsSupplierEligibleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsSupplierEligibleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsSupplierEligibleQuery(baseOptions?: Apollo.QueryHookOptions<GetIsSupplierEligibleQuery, GetIsSupplierEligibleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsSupplierEligibleQuery, GetIsSupplierEligibleQueryVariables>(GetIsSupplierEligibleDocument, options);
      }
export function useGetIsSupplierEligibleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsSupplierEligibleQuery, GetIsSupplierEligibleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsSupplierEligibleQuery, GetIsSupplierEligibleQueryVariables>(GetIsSupplierEligibleDocument, options);
        }
export function useGetIsSupplierEligibleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIsSupplierEligibleQuery, GetIsSupplierEligibleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIsSupplierEligibleQuery, GetIsSupplierEligibleQueryVariables>(GetIsSupplierEligibleDocument, options);
        }
export type GetIsSupplierEligibleQueryHookResult = ReturnType<typeof useGetIsSupplierEligibleQuery>;
export type GetIsSupplierEligibleLazyQueryHookResult = ReturnType<typeof useGetIsSupplierEligibleLazyQuery>;
export type GetIsSupplierEligibleSuspenseQueryHookResult = ReturnType<typeof useGetIsSupplierEligibleSuspenseQuery>;
export type GetIsSupplierEligibleQueryResult = Apollo.QueryResult<GetIsSupplierEligibleQuery, GetIsSupplierEligibleQueryVariables>;