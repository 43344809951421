import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import Loader from '@/components/ui/Loader/Loader'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { useUser } from '@/contexts/user/User.context'
import { trackError } from '@/util/sentry'

import { useSetSellerShippingAddressMutation } from './operations.generated'

import type { ChangeEvent } from 'react'

import './SellerShippingAddressForm.scss'
type SellerShippingAddressValidity = {
  city: boolean
  countryIso2Code: boolean
  email: boolean
  firstName: boolean
  houseNumber: boolean
  lastName: boolean
  phoneNumber: boolean
  street: boolean
  street2: boolean
  zipCode: boolean
}

const initialValidity: SellerShippingAddressValidity = {
  city: false,
  countryIso2Code: false,
  email: false,
  firstName: false,
  houseNumber: false,
  lastName: false,
  phoneNumber: false,
  street: false,
  street2: false,
  zipCode: false,
}
type InputChangeEvent = ChangeEvent<HTMLInputElement>
type SelectChangeEvent = ChangeEvent<HTMLSelectElement>

export const SellerShippingAddressForm = () => {
  const { t } = useTranslation()

  const { user, isLoading } = useUser()
  const { sellerConfig } = user || {}
  const { shippingAddress } = sellerConfig || {}

  const [validity, setValidity] = useState<SellerShippingAddressValidity>(initialValidity)

  const [loading, setLoading] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>(shippingAddress?.firstName || '')
  const [lastName, setLastName] = useState<string>(shippingAddress?.lastName || '')
  const [houseNumber, setHouseNumber] = useState<string>(shippingAddress?.houseNumber || '')
  const [street, setStreet] = useState<string>(shippingAddress?.street || '')
  const [street2, setStreet2] = useState<string>(shippingAddress?.street2 || '')
  const [zipCode, setZipCode] = useState<string>(shippingAddress?.zipCode || '')
  const [city, setCity] = useState<string>(shippingAddress?.city || '')
  const [countryIso2Code, setCountryIso2Code] = useState<string>(shippingAddress?.countryIso2Code || 'FR')
  const [phoneNumber, setPhoneNumber] = useState<string>(shippingAddress?.phoneNumber || '')
  const [email, setEmail] = useState<string>(shippingAddress?.email || '')

  const handleValidityChange = useCallback((name: keyof SellerShippingAddressValidity, isValid: boolean) => {
    setValidity((prev) => ({ ...prev, [name]: isValid }))
  }, [])

  const handleFirstNameChange = useCallback((e: InputChangeEvent) => setFirstName(e.target.value), [])
  const handleLastNameChange = useCallback((e: InputChangeEvent) => setLastName(e.target.value), [])
  const handleHouseNumberChange = useCallback((e: InputChangeEvent) => setHouseNumber(e.target.value), [])
  const handleStreetChange = useCallback((e: InputChangeEvent) => setStreet(e.target.value), [])
  const handleStreet2Change = useCallback((e: InputChangeEvent) => setStreet2(e.target.value), [])
  const handleZipCodeChange = useCallback((e: InputChangeEvent) => setZipCode(e.target.value), [])
  const handleCityChange = useCallback((e: InputChangeEvent) => setCity(e.target.value), [])
  const handleCountryChange = useCallback((e: SelectChangeEvent) => setCountryIso2Code(e.target.value), []) //todo select
  const handlePhoneNumberChange = useCallback((e: InputChangeEvent) => setPhoneNumber(e.target.value), [])
  const handleEmailChange = useCallback((e: InputChangeEvent) => setEmail(e.target.value), [])

  const [setSellerShippingAddress] = useSetSellerShippingAddressMutation()

  const isValid =
    validity.firstName &&
    validity.lastName &&
    validity.houseNumber &&
    validity.street &&
    validity.zipCode &&
    validity.city &&
    validity.city &&
    validity.countryIso2Code &&
    validity.phoneNumber &&
    validity.email
  const houseNumberLenght = houseNumber?.length || 0
  const streetMaxLenght = 31 - houseNumberLenght // the max length of house number + space + street is 32 char max

  const countryOptions = useMemo(
    () => [
      {
        label: t('commonFrance'),
        value: 'FR',
      },
      {
        label: t('commonGermany'),
        value: 'DE',
      },
      {
        label: t('commonBelgium'),
        value: 'BE',
      },
      {
        label: t('commonLuxembourg'),
        value: 'LU',
      },
      {
        label: t('commonAustria'),
        value: 'AT',
      },
      {
        label: t('commonNetherlands'),
        value: 'NL',
      },
      {
        label: t('commonGreatBritain'),
        value: 'GB',
      },
      {
        label: t('commonSpain'),
        value: 'ES',
      },
    ],
    [t]
  )

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    const input = {
      firstName,
      lastName,
      houseNumber,
      street,
      street2,
      zipCode,
      city,
      countryIso2Code,
      phoneNumber,
      email,
    }

    await setSellerShippingAddress({
      variables: {
        input,
      },
      onCompleted: () => {
        notificationSuccess(t('successfullyAddedSellerShippingAddress'))
      },
      onError: (error) => {
        notificationDanger(error.message)
        trackError(error, { meta: { ...input, scope: 'SellerShippingAddressForm.setSellerShippingAddress' } })
      },
    })
    setLoading(false)
  }, [firstName, lastName, houseNumber, street, street2, zipCode, city, countryIso2Code, phoneNumber, email])

  if (isLoading) return <Loader />
  return (
    <Card className="seller-shipping-address">
      <Form className="seller-shipping-address-form" onSubmit={handleSubmit}>
        <div className="seller-shipping-address-form-fields">
          <Field
            label={t('sellerShippingAddressFirstName')}
            name="firstname"
            value={firstName}
            required
            onChange={handleFirstNameChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('firstName', isValid)}
          />
          <Field
            label={t('sellerShippingAddressLastName')}
            name="lastname"
            value={lastName}
            required
            onChange={handleLastNameChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('lastName', isValid)}
          />

          <Field
            label={t('sellerShippingAddressHouseNumber')}
            name="houseNumber"
            value={houseNumber}
            required
            onChange={handleHouseNumberChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('houseNumber', isValid)}
          />
          <Field
            label={t('sellerShippingAddressStreet')}
            maxLength={streetMaxLenght}
            name="street"
            value={street}
            required
            onChange={handleStreetChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('street', isValid)}
          />

          <Field
            label={t('sellerShippingAddressStreet2')}
            name="street2"
            value={street2}
            onChange={handleStreet2Change}
            onValidityChange={(isValid: boolean) => handleValidityChange('street2', isValid)}
          />
          <Field
            label={t('sellerShippingAddressZipCode')}
            name="zipCode"
            value={zipCode}
            required
            onChange={handleZipCodeChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('zipCode', isValid)}
          />

          <Field
            label={t('sellerShippingAddressCity')}
            name="city"
            value={city}
            required
            onChange={handleCityChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('city', isValid)}
          />
          <Field
            label={t('sellerShippingAddressCountry')}
            name="country"
            options={countryOptions}
            type="select"
            value={countryIso2Code}
            required
            onChange={handleCountryChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('countryIso2Code', isValid)}
          />

          <Field
            label={t('sellerShippingAddressPhoneNumber')}
            name="phoneNumber"
            type="tel"
            value={phoneNumber}
            required
            onChange={handlePhoneNumberChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('phoneNumber', isValid)}
          />
          <Field
            label={t('sellerShippingAddressEmail')}
            name="email"
            type="email"
            value={email}
            required
            onChange={handleEmailChange}
            onValidityChange={(isValid: boolean) => handleValidityChange('email', isValid)}
          />
        </div>

        <div className="seller-shipping-address-form-submit">
          <Button
            className="validate-action primary"
            disabled={!isValid}
            isLoading={loading}
            label={t('sellerShippingAddressSubmit')}
            type="submit"
          />
        </div>
      </Form>
    </Card>
  )
}
