import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'

import Field from '@/components/Form/Field/Field'

import Button from '../../Button/Button'
import Dialog from '../../Dialog/Dialog'
import SettingsIcon from '../../Icons/SettingsIcon/SettingsIcon'
import { FiltersType, type Filter, type FiltersValue } from '../Table'

import type { ReactNode } from 'react'

type TableFiltersProps = {
  dialogRef: any
  filters?: {
    options: Filter[]
    onFilter: (filters: FiltersValue[]) => void
    extra?: ReactNode
  }
  filtersValues: FiltersValue[]
  handleFilterValueString: (name: string, value: string) => void
  handleFilterValueRange: (name: string, type: 'from' | 'to', value: string) => void
  handleFilter: () => void
  header: ReactNode
  activeFilters: number
  handleResetFilters: (e: any) => void
  handleFilterClose: () => void
  handleFilterOpen: () => void
  isFiltersOpen: boolean
}

const TableFilters = (props: TableFiltersProps) => {
  const {
    dialogRef,
    filters,
    filtersValues,
    handleFilterValueString,
    handleFilterValueRange,
    handleFilter,
    header,
    activeFilters,
    handleResetFilters,
    isFiltersOpen,
    handleFilterClose,
    handleFilterOpen,
  } = props
  const { t } = useTranslation()

  return (
    <>
      <Dialog
        className="table-filters-dialog is-drawer is-animated"
        innerRef={dialogRef}
        isOpen={isFiltersOpen}
        modal={true}
        title={t('tableFilterDialogTitle')}
        onClose={handleFilterClose}
      >
        <div className="table-filters-options">
          {filters?.options?.map((filter) => {
            const currentFilter = filtersValues.find((f) => f.name === filter.name)
            const filterValue = currentFilter?.value

            return (
              <div key={filter.name} className="table-filter" id={filter.name}>
                {filter.type === FiltersType.select && filter.filterOptions && (
                  <Field
                    label={filter.label}
                    name={filter.name}
                    options={filter.filterOptions}
                    type="select"
                    value={(filterValue as string) || ''}
                    onChange={(e: any) => handleFilterValueString(filter.name, e.target.value)}
                  />
                )}
                {filter.type === FiltersType.inputNumber && (
                  <Field
                    label={filter.label}
                    name={filter.name}
                    placeholder={t('tableFilterInputPlaceholder')}
                    type="number"
                    value={(filterValue as string) || ''}
                    onChange={(e: any) => handleFilterValueString(filter.name, e.target.value)}
                  />
                )}
                {filter.type === FiltersType.inputText && (
                  <Field
                    label={filter.label}
                    name={filter.name}
                    placeholder={t('tableFilterInputTextPlaceholder')}
                    type="text"
                    value={(filterValue as string) || ''}
                    onChange={(e: any) => handleFilterValueString(filter.name, e.target.value)}
                  />
                )}
                {filter.type === FiltersType.rangeNumber &&
                  (typeof filterValue === 'object' || typeof filterValue === 'undefined') && (
                    <div className="range-filter">
                      <span>{filter.label}</span>
                      <div className="range-filter-fields">
                        <Field
                          name={filter.name}
                          placeholder={t('tableFilterRangeNumberFromPlaceholder')}
                          type="number"
                          value={filterValue?.from || ''}
                          onChange={(e: any) => handleFilterValueRange(filter.name, 'from', e.target.value)}
                        />
                        <Field
                          name={filter.name}
                          placeholder={t('tableFilterRangeNumberToPlaceholder')}
                          type="number"
                          value={filterValue?.to || ''}
                          onChange={(e: any) => handleFilterValueRange(filter.name, 'to', e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                {filter.type === FiltersType.rangeDate &&
                  (typeof filterValue === 'object' || typeof filterValue === 'undefined') && (
                    <div className="range-filter">
                      <span>{filter.label}</span>
                      <div className="range-filter-fields">
                        <Field
                          name={filter.name}
                          type="date"
                          value={filterValue?.from || ''}
                          onChange={(e: any) => handleFilterValueRange(filter.name, 'from', e.target.value)}
                        />
                        <Field
                          name={filter.name}
                          type="date"
                          value={filterValue?.to || ''}
                          onChange={(e: any) => handleFilterValueRange(filter.name, 'to', e.target.value)}
                        />
                      </div>
                    </div>
                  )}
              </div>
            )
          })}
          {filters?.extra}
        </div>
        <div className="table-filters-action">
          <Button className="filter-action primary" label={t('tableDialogFilterButton')} onClick={handleFilter} />
        </div>
      </Dialog>

      <div className="table-header">
        <div className="table-header-content">{header}</div>
        {filters && (
          <div className="table-filters">
            <Button
              className="filter-action secondary"
              icon={<SettingsIcon />}
              label={t('tableFilterButton')}
              onClick={handleFilterOpen}
            >
              {activeFilters > 0 && (
                <div className="table-active-filters" onClick={handleResetFilters}>
                  <span>{activeFilters}</span> <FaXmark />
                </div>
              )}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default memo(TableFilters)
