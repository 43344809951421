import { Input, Form, DatePicker, Select, Checkbox, AutoComplete } from 'antd'
import moment from 'moment'
import { useState, type Dispatch, type SetStateAction } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch } from 'react-redux'

import InputLabel from '@/components/InputLabel'
import Button from '@/components/ui/Button/Button'
import { setToLocalStorage } from '@/helpers/localstorage'
import { logout } from '@/reducers/authentication'
import { trackEvent } from '@/util/eventTracker'

import {
  useGetParentCategoriesOrderedWithCategoriesQuery,
  useSearchSellerByUsernameLazyQuery,
} from '../../operations.generated'

import type { GetParentCategoriesOrderedWithCategoriesQuery } from '../../operations.generated'
import type { ContactInformation } from '../SignupForm/types'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { Moment } from 'moment'

import 'react-phone-number-input/style.css'
import './style.scss'

type InformationSlideProps = {
  current: number
  setCurrent: (value: number) => void
  hasPhoneNumber: boolean
  isProfessional: boolean
  setIsProfessional: (value: boolean) => void
  contactInformation: ContactInformation
  setContactInformation: Dispatch<SetStateAction<ContactInformation>>
}

enum ProfessionnalOrIndividual {
  professional = 'professional',
  individual = 'individual',
}

const extractParentCategories = (parentCategoriesData: GetParentCategoriesOrderedWithCategoriesQuery) => {
  return (
    parentCategoriesData?.parentCategoriesOrderedByPosition.edges.map(({ node }) => {
      const { __typename, allCategories, ...others } = node
      return {
        ...others,
        categories: allCategories.map(({ __typename, ...rest }) => ({ ...rest })),
      }
    }) || []
  )
}

const InformationSlide = (props: InformationSlideProps) => {
  const {
    current,
    setCurrent,
    hasPhoneNumber,
    isProfessional,
    setIsProfessional,
    contactInformation,
    setContactInformation,
  } = props
  const { t } = useTranslation()
  const {
    firstname,
    lastname,
    birthdate,
    address,
    zipcode,
    city,
    country,
    state,
    phoneNumber,
    favoriteParentCategoryId,
    favoriteCategoryId,
    externalSalesAmount,
    canSellerShipFromTheirCountry,
  }: ContactInformation = contactInformation
  const phoneIsValid = phoneNumber && isValidPhoneNumber(phoneNumber)
  const dispatch = useDispatch<any>()
  const { Option } = Select

  const { data: parentCategoriesData, loading: loadingParentCategories } =
    useGetParentCategoriesOrderedWithCategoriesQuery({
      variables: { first: 100 },
    })
  const [searchSellerByUsername] = useSearchSellerByUsernameLazyQuery()
  const [sellersOptions, setSellersOptions] = useState<{ value: string; label: string }[]>([])
  const [username, setUsername] = useState<string>('')
  const [countryToSendFrom, setCountryToSendFrom] = useState<string>('')
  const parentCategories =
    !loadingParentCategories && parentCategoriesData ? extractParentCategories(parentCategoriesData) : []
  const categories =
    parentCategories.find((parentCategory) => parentCategory.id === favoriteParentCategoryId)?.categories || []

  let timeoutHandleSearchId: any
  const handleSearch = async (value: any) => {
    clearTimeout(timeoutHandleSearchId)

    timeoutHandleSearchId = setTimeout(async () => {
      const username = value || undefined
      if (!username) return setSellersOptions([])
      await searchSellerByUsername({
        variables: { username, first: 20 },
        onCompleted: (data) =>
          setSellersOptions(
            data.searchSellersByUsername.edges.map(({ node }) => ({ value: node.id, label: node.username })) as any
          ),
      })
    }, 500)
  }

  useEffect(() => {
    if (country === 'BE') {
      setCountryToSendFrom(t(`signupFormCountryFranceOrBelgium`))
    } else {
      setCountryToSendFrom(t(`signupFormCountry${country}`))
    }
  }, [country])

  const isInformationComplete = () => {
    return (
      firstname &&
      lastname &&
      birthdate &&
      address &&
      zipcode &&
      city &&
      country &&
      favoriteParentCategoryId &&
      favoriteCategoryId &&
      externalSalesAmount &&
      (hasPhoneNumber || (!hasPhoneNumber && phoneNumber))
    )
  }

  const isDateDisabled = (e: Moment) => {
    return moment(e).isAfter(moment().subtract(13, 'year')) || parseInt(moment(e).format('YYYY')) < 1901
  }

  const setInformation = (key: keyof ContactInformation, value: any) => {
    // TODO: debounce this?
    setContactInformation(() => {
      const updatedContactInformation = {
        ...contactInformation,
        [key]: value,
      } as ContactInformation

      setToLocalStorage('onboardingSellerInfo', JSON.stringify(updatedContactInformation))

      return updatedContactInformation
    })
  }

  const handleLogout = () => {
    dispatch(logout())
    trackEvent('SIGNUP_LOGOUT')
  }

  const handleGoToNextStep = () => {
    trackEvent('SELLER_ONBOARDING_INFORMATION_SUBMITTED', { ...contactInformation })
    setToLocalStorage('isSellerRegistering', 'true')
    setCurrent(current + 1)
  }

  useEffect(() => {
    if (window) window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    trackEvent('SELLER_ONBOARDING_INFORMATION_OPENED')
  }, [])

  const handleIndividualOrProfessionalChange = (value: ProfessionnalOrIndividual) => {
    if (value === ProfessionnalOrIndividual.professional) {
      setIsProfessional(true)
    } else {
      setIsProfessional(false)
    }
  }
  const isProfessionnalOrIndividual = isProfessional
    ? ProfessionnalOrIndividual.professional
    : ProfessionnalOrIndividual.individual

  return (
    <Form initialValues={{ remember: true }} layout="vertical" name="basic" size="large" style={{ padding: 0 }}>
      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelIsIndividualOrProfessional')} mandatory />
        <Select
          defaultValue={isProfessionnalOrIndividual}
          style={{ width: '100%' }}
          onChange={(value: ProfessionnalOrIndividual) => handleIndividualOrProfessionalChange(value)}
        >
          <Option value={ProfessionnalOrIndividual.individual}>{t('signupFormIsIndividual')}</Option>
          <Option value={ProfessionnalOrIndividual.professional}>{t('signupFormIsProfessional')}</Option>
        </Select>
      </Form.Item>

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelParentCategory')} mandatory />
        <Select
          defaultValue={favoriteParentCategoryId}
          style={{ width: '100%' }}
          onChange={(value) => setInformation('favoriteParentCategoryId', value)}
        >
          {parentCategories.map((parentCategory) => (
            <Option key={parentCategory.id} value={parentCategory.id}>
              {parentCategory.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {!!favoriteParentCategoryId && categories.length > 0 && (
        <Form.Item rules={[{ required: true, message: 'requis' }]}>
          <InputLabel title={t('signupFormLabelCategory')} mandatory />
          <Select
            defaultValue={favoriteCategoryId}
            style={{ width: '100%' }}
            onChange={(value) => setInformation('favoriteCategoryId', value)}
          >
            {categories.map((category) => (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelFirstname')} mandatory />
        <Input
          autoComplete="given-name"
          defaultValue={firstname /* TODO: FIXME */}
          name="firstname"
          placeholder={t('sellerOnboardingNameHelpText')}
          onChange={({ target }) => setInformation('firstname', target.value)}
        />
      </Form.Item>
      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelLastname')} mandatory />
        <Input
          autoComplete="family-name"
          defaultValue={lastname}
          name="lastname"
          onChange={({ target }) => setInformation('lastname', target.value)}
        />
      </Form.Item>
      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel
          noticePopover={{ content: t('signupFormHelpNoticeMustBeOver18') }}
          title={t('signupFormLabelBirthdate')}
          mandatory
        />

        <DatePicker
          allowClear={false}
          autoComplete="bday"
          defaultPickerValue={moment().subtract(18, 'year')}
          defaultValue={birthdate ? moment(birthdate) : undefined}
          disabledDate={isDateDisabled}
          format="DD/MM/YYYY"
          name="birthdate"
          style={{ width: '100%' }}
          onChange={(date) => setInformation('birthdate', date)}
        />
      </Form.Item>

      {!hasPhoneNumber && (
        <Form.Item
          help={!phoneIsValid && phoneNumber?.length > 0 && t('signupFormLabelInvalidPhoneNumberError')}
          validateStatus={!phoneIsValid ? 'error' : undefined}
        >
          <InputLabel title={t('signupFormLabelPhoneNumber')} mandatory />
          <PhoneInput
            autoComplete="tel"
            defaultCountry={country}
            defaultValue={phoneNumber}
            name="phoneNumber"
            onChange={(value) => setInformation('phoneNumber', value)}
          />
        </Form.Item>
      )}

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelExternalSales')} mandatory />
        <Select
          defaultValue={externalSalesAmount}
          style={{ width: '100%' }}
          onChange={(value) => setInformation('externalSalesAmount', value)}
        >
          <Option value="0">0</Option>
          <Option value="< 250">&lsaquo; 250 €</Option>
          <Option value="251 - 500">251 - 500 €</Option>
          <Option value="501 - 1000">501 - 1000 €</Option>
          <Option value="1001 - 2000">1001 - 2000 €</Option>
          <Option value="> 2000">&rsaquo; 2000 €</Option>
        </Select>
      </Form.Item>

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelReferrer')} />
        <AutoComplete
          options={sellersOptions}
          value={username}
          onSearch={handleSearch}
          onChange={(value, option) => {
            // @ts-expect-error antd types are wrong we will use our own form when refactoring this
            setUsername(option.label)
          }}
          onSelect={(value) => {
            setInformation('referrerUserId', value)
          }}
        />
      </Form.Item>

      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelAddress')} mandatory />
        <Input
          autoComplete="street-address"
          defaultValue={address}
          name="address"
          onChange={({ target }) => setInformation('address', target.value)}
        />
      </Form.Item>
      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelZipcode')} mandatory />

        <Input
          autoComplete="postal-code"
          defaultValue={zipcode}
          name="zipcode"
          onChange={({ target }) => setInformation('zipcode', target.value)}
        />
      </Form.Item>
      <Form.Item rules={[{ required: true, message: 'requis' }]}>
        <InputLabel title={t('signupFormLabelCity')} mandatory />

        <Input
          autoComplete="address-level2"
          defaultValue={city}
          name="city"
          onChange={({ target }) => setInformation('city', target.value)}
        />
      </Form.Item>
      <Form.Item>
        <InputLabel title={t('signupFormLabelRegionState')} />

        <Input
          autoComplete="address-level1"
          defaultValue={state}
          name="state"
          onChange={({ target }) => setInformation('state', target.value)}
        />
      </Form.Item>
      <Form.Item>
        <InputLabel title={t('signupFormLabelCountry')} mandatory />
        <Select
          defaultValue={country}
          optionFilterProp="children"
          showSearch={true}
          style={{ width: '100%' }}
          filterOption={(input, option) => {
            const { children } = option || {}
            return (typeof children === 'string' ? children : '').toLowerCase().includes(input.toLowerCase())
          }}
          onChange={(value) => setInformation('country', value)}
        >
          <Option value="AT">{t('commonAustria')}</Option>
          <Option value="BE">{t('commonBelgium')}</Option>
          <Option value="DE">{t('commonGermany')}</Option>
          {/* <Option value="ES">{t('commonSpain')}</Option> */}
          <Option value="FR">{t('commonFrance')}</Option>
          {/* <Option value="GR">{t('commonGreece')}</Option> */}
          {/* <Option value="IE">{t('commonIreland')}</Option> */}
          <Option value="LU">{t('commonLuxembourg')}</Option>
          <Option value="NL">{t('commonNetherlands')}</Option>
        </Select>
      </Form.Item>

      {(country === 'AT' ||
        country === 'DE' ||
        country === 'FR' ||
        country === 'BE' ||
        country === 'LU' ||
        country === 'NL') && (
        <Form.Item>
          <Checkbox
            checked={canSellerShipFromTheirCountry}
            defaultChecked={canSellerShipFromTheirCountry}
            name="canSellerShipFromTheirCountry"
            onChange={(event: CheckboxChangeEvent) =>
              setInformation('canSellerShipFromTheirCountry', event.target.checked)
            }
          >
            {t('signupFormLabelCanSellerShipFromTheirCountry')} {countryToSendFrom} ?
            <span className="required-indicator">*</span>
          </Checkbox>
        </Form.Item>
      )}

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 50,
        }}
      >
        <Button className="cancel-action secondary" label={t('commonLogout')} onClick={handleLogout} />
        <Button
          className="validate-action next-step-action primary"
          disabled={!isInformationComplete() || !canSellerShipFromTheirCountry}
          label={t('commonNext')}
          onClick={handleGoToNextStep}
        />
      </div>
    </Form>
  )
}

export default InformationSlide
