import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { computeBrandFieldOptions } from '@/components/CreateOrEditProduct2/CreateOrEditProduct2.helpers'
import Field from '@/components/Form/Field/Field'

type ProductBrandFieldProps = {
  value?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const ProductBrandField = (props: ProductBrandFieldProps) => {
  const { value: brand, onChange: handleBrandChange, disabled = false } = props
  const { t } = useTranslation()

  const brandOptions = useMemo(() => computeBrandFieldOptions(t), [t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormBrandLabel')}
      name="brand"
      options={brandOptions}
      placeholder={t('productFormBrandPlaceholder')}
      type="datalist"
      value={brand}
      onChange={handleBrandChange}
    />
  )
}

export default ProductBrandField
