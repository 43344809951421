import dayjs from 'dayjs'
import i18next from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPenToSquare, FaXmark } from 'react-icons/fa6'
import { useHistory } from 'react-router-dom'

import Button from '@/components/ui/Button/Button'
import Link from '@/components/ui/Link/Link'
import Popover from '@/components/ui/Popover/Popover'
import { useShow } from '@/contexts/show/Show.context'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import {
  SortDirection,
  SoldProductSortColumn,
  OrderPaymentStatus,
  OrderStatus,
} from '@/network/graphql/types.generated'
import { OrderCancellationModal } from '@/views/Shipments/Details/components/OrderCancellation/OrderCancellationModal'

import Card from '../../ui/Card/Card'
import Table from '../../ui/Table/Table'
import { SoldStatusTag } from '../SoldStatusTag/SoldStatusTag'

import type { OrderedProduct } from '@/network/graphql/types.generated'
import type { SortingState } from '@tanstack/react-table'

import './Products.scss'

interface ProductsProps {
  sellerId?: string
  onAddNoteProduct: (product: OrderedProduct) => void
  onCancelOrderedProductWithFailedPayment: () => void
}

const sortingOptionsMap: { [key: string]: SoldProductSortColumn } = {
  createdAt: SoldProductSortColumn.OrderCreatedAt,
  amount: SoldProductSortColumn.Amount,
  status: SoldProductSortColumn.OrderStatus,
  name: SoldProductSortColumn.Name,
}

export function SoldProducts(props: ProductsProps) {
  const { sellerId, onAddNoteProduct, onCancelOrderedProductWithFailedPayment } = props

  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()

  const history = useHistory()

  const [sorting, setSorting] = useState<SortingState>([])
  const [loading, setLoading] = useState(false)

  const {
    itemsPerPage,
    handleSetItemsPerPage,
    loadingSoldProducts,
    sold: soldProducts,
    totalSoldProducts,
    fetchSoldProducts,
    setSoldSortBy,
    currentPageSold: currentPage,
    handleSoldPageChange,
  } = useShow()

  const instantBuyColumns = [
    {
      header: t('soldProductsColumnProduct'),
      id: 'name',
      accessorKey: 'name',
      cell: (item: any) => {
        const record = item.row.original
        const {
          name,
          images,
          // description
        } = record
        return (
          <div className="column-product">
            <div>
              <div className="image">
                <img alt={`thumbnail-${name}`} src={images?.[0]?.webPUrl || '/badges/empty-pic.png'} />
              </div>
              <div className="details">
                <p>{name}</p>
                {/* <span>{description}</span> */}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      header: t('soldProductsColumnCustomer'),
      id: 'customer',
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { order } = record
        const { customer } = order
        return <p>@{customer.username}</p>
      },
    },
    {
      header: t('soldProductsColumnStatus'),
      id: 'status',
      accessorKey: 'status',
      cell: (item: any) => {
        const record = item.row.original
        return <SoldStatusTag product={record} />
      },
    },
    {
      header: t('soldProductsColumnAmount'),
      id: 'amount',
      accessorKey: 'amount',
      cell: (item: any) => {
        const record = item.row.original
        const { amountWithCurrency } = record
        return <p>{currencyFormatter(amountWithCurrency.amount, amountWithCurrency.currency)}</p>
      },
    },
    {
      header: t('soldProductsColumnDate'),
      id: 'createdAt',
      accessorKey: 'createdAt',
      cell: (item: any) => {
        const record = item.row.original
        const { createdAt } = record.order

        return (
          <Popover content={dayjs(createdAt).format('DD/MM/YYYY HH:mm')}>
            {dayjs(createdAt)
              .locale(i18next.resolvedLanguage || '')
              .fromNow()}
          </Popover>
        )
      },
    },
    {
      header: t('soldProductsColumnPaymentAction'),
      id: 'action',
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { order } = record
        const { paymentStatus, status, note } = order

        const isCancelable = sellerId && paymentStatus !== OrderPaymentStatus.Success && status === OrderStatus.Pending

        return (
          <div className="column-sold-action">
            <Popover content={note ? t('showOrderDrawerEditNoteTitle') : t('showOrderDrawerCreateNoteTitle')}>
              <Button className="add-note-action" icon={<FaPenToSquare />} onClick={() => onAddNoteProduct(record)} />
            </Popover>
            {isCancelable && (
              <OrderCancellationModal
                order={order}
                sellerId={sellerId}
                customButtonContent={
                  <Popover content={t('commonCancelOrder')}>
                    <Button className="is-danger" icon={<FaXmark />} />
                  </Popover>
                }
                onSubmittedCallback={() => onCancelOrderedProductWithFailedPayment()}
              />
            )}
          </div>
        )
      },
    },
  ]

  const handleItemPerPageChange = async (itemsPerPage: number) => {
    handleSetItemsPerPage(itemsPerPage)
    handleSoldPageChange(1)
  }

  const handlePageChange = async (page: number) => {
    handleSoldPageChange(page)
    setLoading(true)
    await fetchSoldProducts(page)
    setLoading(false)
  }

  const handleSortingChange = async (sort: SortingState) => {
    setSorting(sort)
    setLoading(true)
    handleSoldPageChange(1)
    if (!sort[0]) {
      setSoldSortBy(undefined)
    } else {
      setSoldSortBy({
        column: sortingOptionsMap[sort[0]?.id],
        direction: sort[0]?.desc ? SortDirection.Desc : SortDirection.Asc,
      })
    }
    setLoading(false)
  }

  const handleQuickAdd = () => {
    const currentUrl = window.location.pathname
    const url = `${currentUrl}products/new?productType=auction`
    history.push(url)
  }

  return (
    <Card className="sold-products sold-products-list" noPadding={true}>
      {soldProducts && soldProducts.length === 0 && totalSoldProducts === 0 && !loadingSoldProducts ? (
        <div className="no-products">
          <svg fill="none" height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M27.899 56.5399C29.219 57.0733 30.6083 57.3373 31.995 57.3373C33.387 57.3373 34.7763 57.0733 36.1017 56.5373L41.819 54.1906C42.691 53.8306 43.7043 53.8599 44.5923 54.2679L46.6137 55.2493C48.0403 55.9293 49.6697 55.8306 50.9977 54.9933C52.3257 54.1533 53.1177 52.7133 53.115 51.1399L53.0883 18.4467C53.0883 11.1774 48.7283 6.66272 41.7123 6.66272H22.2484C15.1631 6.66272 10.9337 11.0681 10.9337 18.4441L10.8857 51.1373C10.8831 52.7133 11.6831 54.1533 13.0217 54.9906C14.3524 55.8226 15.9871 55.9026 17.3871 55.2226L19.3844 54.2733C20.2778 53.8626 21.2911 53.8333 22.1684 54.1906L27.899 56.5399Z"
              fill="black"
              opacity="0.4"
            />
            <path
              clipRule="evenodd"
              d="M38.1801 40.5286H25.6787C24.572 40.5286 23.6787 39.6326 23.6787 38.5286C23.6787 37.4246 24.572 36.5286 25.6787 36.5286H38.1801C39.2841 36.5286 40.1801 37.4246 40.1801 38.5286C40.1801 39.6326 39.2841 40.5286 38.1801 40.5286Z"
              fill="black"
              fillRule="evenodd"
            />
            <path
              clipRule="evenodd"
              d="M35.9901 32.5018C35.4807 32.5018 34.9661 32.3072 34.5767 31.9152L31.9901 29.3338L29.4034 31.9152C28.6194 32.6992 27.3554 32.6938 26.5767 31.9152C25.7954 31.1338 25.7954 29.8698 26.5767 29.0885L29.1607 26.5046L26.5714 23.9152C25.79 23.1339 25.79 21.8699 26.5714 21.0886C27.3501 20.3099 28.6141 20.3046 29.3981 21.0886L31.9901 23.6752L34.5821 21.0886C35.3661 20.3046 36.6301 20.3099 37.4087 21.0886C38.1901 21.8699 38.1901 23.1339 37.4087 23.9152L34.8194 26.5046L37.4034 29.0885C38.1847 29.8698 38.1847 31.1338 37.4034 31.9152C37.0141 32.3072 36.5021 32.5018 35.9901 32.5018Z"
              fill="black"
              fillRule="evenodd"
            />
          </svg>

          <p>{t('noSoldProducts')}</p>
          <Link onClick={handleQuickAdd}>{t('quickAddProduct')}</Link>
        </div>
      ) : (
        <Table
          columns={instantBuyColumns}
          data={soldProducts}
          loading={loadingSoldProducts || loading}
          pagination={{
            total: totalSoldProducts,
            currentPage: currentPage,
            perPage: itemsPerPage,
            onPageChange: handlePageChange,
            onItemPerPageChange: handleItemPerPageChange,
          }}
          sort={{
            sorting,
            onSortingChange: handleSortingChange,
          }}
        />
      )}
    </Card>
  )
}
