import { type ProductType, type ShowGiveawayAudience } from '@/network/graphql/types.generated'

export type ProductImage = { url: string; id: string; file?: File; isDeleted: boolean }

export type ProductInputData = {
  images: ProductImage[]
  category: string | null
  title: string
  description: string
  startingPrice: number | undefined
  buyNowPrice: number | undefined
  price: number | undefined
  availableQuantity: number
  type: ProductType
  giveawayAudience?: ShowGiveawayAudience | null
  // Fashion/sneakers attributes
  brand?: string | null
  color?: string | null
  condition?: string | null
  gender?: string | null
  model?: string | null
  size?: string | null
  // TCG attributes
  cardCondition?: string | null
  cardGrade?: string | null
  cardGradingService?: string | null
  cardLanguage?: string | null
  cardType?: string | null
}

export enum PRODUCT_FORM_ACTION {
  CREATE = 'create',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
}

export type ProductFormValidity = {
  category: boolean
  title: boolean
  type: boolean
  startingPrice: boolean
  buyNowPrice: boolean
  price: boolean
  availableQuantity: boolean
}

export type ExtraProductFormOptions = { createAndLaunch?: boolean; createMore?: boolean }
