import { Modal, Upload } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTrash, FaPlay, FaEyeLowVision } from 'react-icons/fa6'

import InputLabel from '../../InputLabel'
import ProgressBar from '../../ProgressBar'

import { VideoUploadedStatus } from './VideoUploader.container'
import { formatBytes } from './videoUploader.helper'

import type { UploadFile } from 'antd'

import style from './style.module.scss'

interface VideoUploaderProps {
  isRequired?: boolean | null
  title: string
  info?: string | null
  noticePopover?: {
    title?: any
    content: string
  }
  name: string
  valuePropName?: string
  getValueFromEvent?: (e: any) => void
  accept: string
  maxCount: number
  beforeUpload?: (e: any) => void
  onChange: (e: any) => void
  onRemove: (e: any) => void
  customRequest: (e: any) => void
  errorMessage?: string
  uploadStatus?: VideoUploadedStatus
  videoUrl?: string | null
  parsedVideoUrl?: string | null
  contentDragAndDrop?: any
  progress: number
  disabled: boolean
  originalFileInfo: any
}

export const VideoUploader = ({
  isRequired,
  title,
  info,
  noticePopover,
  name,
  valuePropName,
  getValueFromEvent,
  accept,
  maxCount,
  beforeUpload,
  onChange,
  onRemove,
  customRequest,
  uploadStatus,
  videoUrl,
  parsedVideoUrl,
  errorMessage,
  contentDragAndDrop,
  progress,
  disabled,
  originalFileInfo,
}: VideoUploaderProps) => {
  const { t } = useTranslation()

  const [file, setFile] = useState<UploadFile | null>(null)
  const [playModalOpen, setPlayModalOpen] = useState(false)
  const [videoName, setVideoName] = useState<string>('')
  const [videoSize, setVideoSize] = useState<number>(0)

  useEffect(() => {
    const name = originalFileInfo?.name ? originalFileInfo.name : file?.name || t('defaultTrailerVideoName')
    setVideoName(name)
    const size = originalFileInfo?.size ? originalFileInfo.size : file?.size || 0
    setVideoSize(size)
  }, [originalFileInfo, file])

  const renderVideoSnapshot = (videoUrl: string, isBig: boolean = false) => {
    return (
      <div className={style.video_box}>
        {(file && file.type === 'video/mp4') || uploadStatus === VideoUploadedStatus.VIDEO_PREVIOUSLY_UPLOADED ? (
          <video className={isBig ? style.video_big : style.video_thumbnail} controls={isBig} src={videoUrl}></video>
        ) : (
          <FaEyeLowVision />
        )}
      </div>
    )
  }

  const handleDeleteVideo = () => {
    Modal.confirm({
      title: t('videoModalDeleteTitle'),
      icon: null,
      content: t('videoModalDeleteContent'),
      okText: t('videoModalDeleteOkText'),
      cancelText: t('videoModalDeleteCancelText'),
      onOk: async (e) => {
        onRemove(e)
      },
    })
  }

  const isVideoUploaded = useMemo(
    () =>
      (uploadStatus === VideoUploadedStatus.VIDEO_UPLOADED && parsedVideoUrl) ||
      (uploadStatus === VideoUploadedStatus.VIDEO_PREVIOUSLY_UPLOADED && videoUrl),
    [videoUrl, parsedVideoUrl, uploadStatus]
  )

  const renderDeleteButton = () => (
    <span className={style.video_control} onClick={() => handleDeleteVideo()}>
      <FaTrash color={'#FF0000'} height={18} width={20} />
    </span>
  )

  const renderVideoContent = (isBig: boolean = false) => {
    return (
      <>
        {uploadStatus === VideoUploadedStatus.NO_VIDEO && (
          <div className={style.drag_and_drop_video}>
            <img alt="Upload video" src={'/video-upload.png'} />
            {contentDragAndDrop}
          </div>
        )}
        {uploadStatus === VideoUploadedStatus.VIDEO_UPLOADING && progress && (
          <div className={style.drag_and_drop_video}>
            <div className={style.video_loading}>
              <h3>{t('videoUploadProgress')}</h3>
              <ProgressBar percent={progress} />
            </div>
          </div>
        )}

        {uploadStatus === VideoUploadedStatus.VIDEO_UPLOADED &&
          parsedVideoUrl &&
          renderVideoSnapshot(parsedVideoUrl, isBig)}

        {uploadStatus === VideoUploadedStatus.VIDEO_PREVIOUSLY_UPLOADED &&
          videoUrl &&
          renderVideoSnapshot(videoUrl, isBig)}

        {uploadStatus === VideoUploadedStatus.VIDEO_TRANSCODING && (
          <div className={style.drag_and_drop_video}>
            <div className={style.video_loading}>
              <h3 className={style.video_transcoding_content}>
                {t('videoUploadTranscodingInProgress')}
                {renderDeleteButton()}
              </h3>
            </div>
          </div>
        )}
      </>
    )
  }

  const handleOnChange = (info: any) => {
    setFile(info.file)
    onChange(info)
  }

  const renderVideoItem = () => {
    const videoSizeFormatted = formatBytes(videoSize)
    return (
      <div className={style.video_controls}>
        <span className="text-black-140">
          {videoName} ({videoSizeFormatted})
        </span>
        <span className={style.video_control} onClick={() => setPlayModalOpen(true)}>
          <FaPlay />
        </span>
        {renderDeleteButton()}
      </div>
    )
  }

  return (
    <>
      <FormItem
        getValueFromEvent={getValueFromEvent}
        valuePropName={valuePropName}
        rules={[
          {
            required: !!isRequired,
            message: t('createShowModalDetailsFormFieldRequired'),
          },
        ]}
      >
        <InputLabel info={info} mandatory={!!isRequired} noticePopover={noticePopover} title={title} />
        <Upload.Dragger
          accept={accept}
          beforeUpload={beforeUpload}
          customRequest={customRequest}
          disabled={disabled}
          itemRender={() => null}
          maxCount={maxCount}
          name={name}
          onChange={handleOnChange}
          onRemove={onRemove}
        >
          {renderVideoContent()}
        </Upload.Dragger>

        {isVideoUploaded && renderVideoItem()}
        {errorMessage && <p className={style.error_upload}>{errorMessage}</p>}
      </FormItem>
      <Modal
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        open={playModalOpen}
        title={videoName}
        onCancel={() => setPlayModalOpen(false)}
        onOk={() => setPlayModalOpen(false)}
      >
        {renderVideoContent(true)}
      </Modal>
    </>
  )
}
