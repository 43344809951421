import { createContext, useCallback, useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  useNewOrderInShowSubscription,
  useOrderPaymentStatusChangedInShowSubscription,
  useShowProductsChangedSubscription,
} from '@/components/ProductList/operations.generated'
import {
  useGetAuctionProductsForSellerQuery,
  useGetInstantBuyProductsForSellerQuery,
  useGetSoldProductsForSellerQuery,
  useGetGiveawayProductsForSellerQuery,
  type GetAuctionProductsForSellerQuery,
  type GetInstantBuyProductsForSellerQuery,
  type GetGiveawayProductsForSellerQuery,
  type GetSoldProductsForSellerQuery,
} from '@/components/ProductList/operations.generated'
import { notificationSuccess } from '@/components/ui/Notification/Notification'
import { DEFAULT_ITEM_PER_PAGE } from '@/components/ui/Table/Table'
import {
  useGetShowMetricsQuery,
  useGetShowPresentUsersCountQuery,
  useOnPresentUsersCountChangedSubscription,
} from '@/contexts/show/operations.generated'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { logger, LOGGER_LEVEL } from '@/network/common/logger'
import {
  OrderPaymentStatus,
  ProductType,
  type Product,
  type SortProductsBy,
  type SortSoldProductsBy,
} from '@/network/graphql/types.generated'

import type { ApolloError } from '@apollo/client'
import type { ReactNode } from 'react'

interface IShowContext {
  totalAmount: number | null
  totalOrders: number | null
  totalViewers: number | null
  isLoading: boolean

  itemsPerPage: number
  handleSetItemsPerPage: any

  search: string
  handleSearchChange: any

  filterByPositiveQuantity: boolean
  setFilterByPositiveQuantity: any

  refetchProducts: (type?: ProductType) => void

  sortByProducts?: SortProductsBy
  setSortByProducts: any

  loadingAuctionProducts: boolean
  errorAuctionProducts?: ApolloError
  auctions?: Product[]
  totalAuctions: number
  fetchAuctionsProducts: any
  setAuctionSortBy: any
  auctionSortBy?: SortProductsBy
  currentPageAuction: number
  handleAuctionPageChange: any

  loadingInstantBuyProducts: boolean
  errorInstantBuyProducts?: ApolloError
  instantBuys?: Product[]
  totalInstantBuys: number
  fetchInstantBuyProducts: any
  setInstantBuySortBy: any
  instantBuySortBy?: SortProductsBy
  currentPageInstantBuy: number
  handleInstantBuyPageChange: any

  loadingGiveawayProducts: boolean
  errorGiveawayProducts?: ApolloError
  giveaways?: Product[]
  totalGiveaways: number
  fetchGiveawayProducts: any
  setGiveawaySortBy: any
  giveawaySortBy?: SortProductsBy
  currentPageGiveaway: number
  handleGiveawayPageChange: any

  loadingSoldProducts: boolean
  errorSoldProducts?: ApolloError
  sold?: Product[]
  totalSoldProducts: number
  fetchSoldProducts: any
  setSoldSortBy: any
  currentPageSold: number
  handleSoldPageChange: any
}

export const ShowContext = createContext<IShowContext>({
  totalAmount: null,
  totalOrders: null,
  totalViewers: null,
  isLoading: false,

  itemsPerPage: DEFAULT_ITEM_PER_PAGE,
  handleSetItemsPerPage: () => undefined,

  search: '',
  handleSearchChange: () => undefined,

  filterByPositiveQuantity: false,
  setFilterByPositiveQuantity: () => undefined,

  refetchProducts: () => undefined,

  sortByProducts: undefined,
  setSortByProducts: () => undefined,

  loadingAuctionProducts: false,
  errorAuctionProducts: undefined,
  auctions: [],
  totalAuctions: 0,
  fetchAuctionsProducts: () => undefined,
  setAuctionSortBy: () => undefined,
  auctionSortBy: undefined,
  currentPageAuction: 1,
  handleAuctionPageChange: () => undefined,

  loadingInstantBuyProducts: false,
  errorInstantBuyProducts: undefined,
  instantBuys: [],
  totalInstantBuys: 0,
  fetchInstantBuyProducts: () => undefined,
  setInstantBuySortBy: () => undefined,
  instantBuySortBy: undefined,
  currentPageInstantBuy: 1,
  handleInstantBuyPageChange: () => undefined,

  loadingGiveawayProducts: false,
  errorGiveawayProducts: undefined,
  giveaways: [],
  totalGiveaways: 0,
  fetchGiveawayProducts: () => undefined,
  setGiveawaySortBy: () => undefined,
  giveawaySortBy: undefined,
  currentPageGiveaway: 1,
  handleGiveawayPageChange: () => undefined,

  loadingSoldProducts: false,
  errorSoldProducts: undefined,
  sold: [],
  totalSoldProducts: 0,
  fetchSoldProducts: () => undefined,
  setSoldSortBy: () => undefined,
  currentPageSold: 1,
  handleSoldPageChange: () => undefined,
})

type ShowProviderProps = {
  showId: string
  children: ReactNode
}

const extractAuctions = (products: GetAuctionProductsForSellerQuery | undefined) => {
  if (!products || products.node.__typename !== 'Show') return []
  if (products.node.auctionProductsForSeller.edges) {
    return products.node.auctionProductsForSeller.edges.map((edge) => edge.node.showProduct as Product)
  }
}
const extractInstantBuys = (products: GetInstantBuyProductsForSellerQuery | undefined) => {
  if (!products || products.node.__typename !== 'Show') return []
  if (products.node.instantBuyProductsForSeller.edges) {
    return products.node.instantBuyProductsForSeller.edges.map((edge) => edge.node.showProduct as Product)
  }
}
const extractGiveaways = (products: GetGiveawayProductsForSellerQuery | undefined) => {
  if (!products || products.node.__typename !== 'Show') return []
  if (products.node.giveawayProductsForSeller.edges) {
    return products.node.giveawayProductsForSeller.edges.map((edge) => edge.node.showProduct as Product)
  }
}

const extractSoldProducts = (products: GetSoldProductsForSellerQuery | undefined) => {
  if (!products || products.node.__typename !== 'Show') return []
  if (products.node.soldProductsForSeller.edges) {
    return products.node.soldProductsForSeller.edges.map((edge) => edge.node.showProduct as Product)
  }
}

export const ShowProvider = (props: ShowProviderProps) => {
  const { showId, children } = props
  const { t } = useTranslation()

  const showGlobalId = `Show|${showId}`

  const perPageStored = getFromLocalStorage('default_per_page_show')
  const defaultPerPage = perPageStored ? parseInt(perPageStored) : DEFAULT_ITEM_PER_PAGE
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaultPerPage)

  const handleSetItemsPerPage = (value: number) => {
    setItemsPerPage(value)
    setToLocalStorage('default_per_page_show', value.toString())
  }

  const [search, setSearch] = useState('')
  const [filterByPositiveQuantity, setFilterByPositiveQuantity] = useState<boolean>(
    !!getFromLocalStorage('show_filter_sold_out')
  )

  const [sortByProducts, setSortByProducts] = useState<SortProductsBy | undefined>(undefined)

  const [auctionSortBy, setAuctionSortBy] = useState<SortProductsBy>()
  const [currentPageAuction, setCurrentPageAuction] = useState<number>(1)

  const [instantBuySortBy, setInstantBuySortBy] = useState<SortProductsBy>()
  const [currentPageInstantBuy, setCurrentPageInstantBuy] = useState<number>(1)

  const [giveawaySortBy, setGiveawaySortBy] = useState<SortProductsBy>()
  const [currentPageGiveaway, setCurrentPageGiveaway] = useState<number>(1)

  const [soldSortBy, setSoldSortBy] = useState<SortSoldProductsBy>()
  const [currentPageSold, setCurrentPageSold] = useState<number>(1)

  // showMetrics
  const {
    data: showMetricsData,
    loading: isMetricsLoading,
    refetch: refetchMetrics,
  } = useGetShowMetricsQuery({ skip: !showId, variables: { showId: `Show|${showId}` } })
  const { data: presentUsersData, loading: isPresentUsersCountLoading } = useGetShowPresentUsersCountQuery({
    skip: !showId,
    variables: { showId: `Show|${showId}` },
  })

  const showMetrics =
    showMetricsData?.node.__typename === 'Show' && showMetricsData?.node ? showMetricsData?.node : null
  let presentUsers = presentUsersData?.node?.__typename === 'Show' ? presentUsersData?.node.presentUsers : null

  const computeTotalAmount = useCallback((showMetrics) => {
    return showMetrics && 'totalAmountOrdersSuccessInCurrencyCents' in showMetrics
      ? showMetrics.totalAmountOrdersSuccessInCurrencyCents
      : null
  }, [])

  const totalAmount = computeTotalAmount(showMetrics)
  const totalOrders = showMetrics?.orderedProducts?.totalCount ?? null
  const totalViewers = presentUsers?.totalCount ?? null
  const isLoading = isMetricsLoading || isPresentUsersCountLoading

  // auctions
  const {
    data: auctionProducts,
    loading: loadingAuctionProducts,
    error: errorAuctionProducts,
    fetchMore: fetchMoreAuctionProducts,
    refetch: refetchAuctions,
  } = useGetAuctionProductsForSellerQuery({
    skip: !showId,
    variables: {
      showId: showGlobalId,
      first: itemsPerPage,
      sortBy: auctionSortBy || sortByProducts,
      filterByText: search,
      filterByPositiveQuantity: filterByPositiveQuantity,
      offset: (currentPageAuction - 1) * itemsPerPage,
    },
  })
  const auctions = extractAuctions(auctionProducts)
  const totalAuctions =
    auctionProducts && auctionProducts.node.__typename === 'Show'
      ? auctionProducts?.node.auctionProductsForSeller.totalCount
      : 0
  const fetchAuctionsProducts = async (page: number) => {
    setCurrentPageAuction(page)
    await fetchMoreAuctionProducts({
      variables: {
        offset: (page - 1) * itemsPerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
  }

  //instant buys
  const {
    data: instantBuyProducts,
    loading: loadingInstantBuyProducts,
    error: errorInstantBuyProducts,
    fetchMore: fetchMoreInstantBuyProducts,
    refetch: refetchInstantBuys,
  } = useGetInstantBuyProductsForSellerQuery({
    skip: !showId,
    variables: {
      showId: showGlobalId,
      first: itemsPerPage,
      sortBy: instantBuySortBy || sortByProducts,
      filterByText: search,
      filterByPositiveQuantity: filterByPositiveQuantity,
      offset: (currentPageInstantBuy - 1) * itemsPerPage,
    },
  })
  const instantBuys = extractInstantBuys(instantBuyProducts)
  const totalInstantBuys =
    instantBuyProducts && instantBuyProducts.node.__typename === 'Show'
      ? instantBuyProducts?.node.instantBuyProductsForSeller.totalCount
      : 0
  const fetchInstantBuyProducts = async (page: number) => {
    setCurrentPageInstantBuy(page)
    await fetchMoreInstantBuyProducts({
      variables: {
        offset: (page - 1) * itemsPerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
  }

  //giveaways
  const {
    data: giveawayProducts,
    loading: loadingGiveawayProducts,
    error: errorGiveawayProducts,
    fetchMore: fetchMoreGiveawayProducts,
    refetch: refetchGiveaways,
  } = useGetGiveawayProductsForSellerQuery({
    skip: !showId,
    variables: {
      showId: showGlobalId,
      first: itemsPerPage,
      sortBy: giveawaySortBy || sortByProducts,
      filterByText: search,
      filterByPositiveQuantity: filterByPositiveQuantity,
      offset: (currentPageGiveaway - 1) * itemsPerPage,
    },
  })
  const giveaways = extractGiveaways(giveawayProducts)
  const totalGiveaways =
    giveawayProducts && giveawayProducts.node.__typename === 'Show'
      ? giveawayProducts?.node.giveawayProductsForSeller.totalCount
      : 0
  const fetchGiveawayProducts = async (page: number) => {
    setCurrentPageGiveaway(page)
    await fetchMoreGiveawayProducts({
      variables: {
        offset: (page - 1) * itemsPerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
  }

  //sold
  const {
    data: soldProducts,
    loading: loadingSoldProducts,
    error: errorSoldProducts,
    fetchMore: fetchMoreSoldProducts,
    refetch: refetchSoldProducts,
  } = useGetSoldProductsForSellerQuery({
    skip: !showId,
    variables: {
      showId: showGlobalId,
      first: itemsPerPage,
      sortBy: soldSortBy,
      filterByText: search,
    },
  })
  const sold = extractSoldProducts(soldProducts)
  const totalSoldProducts =
    soldProducts && soldProducts.node.__typename === 'Show' ? soldProducts?.node.soldProductsForSeller.totalCount : 0
  const fetchSoldProducts = async (page: number) => {
    await fetchMoreSoldProducts({
      variables: {
        offset: (page - 1) * itemsPerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return fetchMoreResult
      },
    })
  }

  useOnPresentUsersCountChangedSubscription({
    skip: !showId,
    variables: { showId: `Show|${showId}` },
    onData: async (data) => {
      presentUsers = data?.data?.data?.showPresentUsersChanged.show.presentUsers ?? null
    },
  })

  useOrderPaymentStatusChangedInShowSubscription({
    skip: !showId,
    variables: { showId: showGlobalId },
    onData: async ({ data: { data } }) => {
      const order = data?.orderPaymentStatusChangedInShow.order
      if (order && order.paymentStatus === OrderPaymentStatus.Success) {
        if (order.product.type === ProductType.Auction) {
          notificationSuccess(
            t('useShowProductsOrderRegularise', {
              username: order.customer?.username,
              productName: order.product.name,
            })
          )
          logger({
            level: LOGGER_LEVEL.INFO,
            message: 'auction payment status changed received',
            meta: { showId: showGlobalId, data },
          })
        }
      }
      await refetchMetrics()
      refetchSoldProducts()
    },
  })

  useShowProductsChangedSubscription({
    skip: !showId,
    variables: { showId: showGlobalId },
    onData: async ({ data: { data } }) => {
      if (data?.showProductsChanged.product?.type === ProductType.Auction) {
        await refetchAuctions()
      } else if (data?.showProductsChanged.product?.type === ProductType.InstantBuy) {
        await refetchInstantBuys()
      } else if (data?.showProductsChanged.product?.type === ProductType.Giveaway) {
        await refetchGiveaways()
      }
    },
  })

  useNewOrderInShowSubscription({
    skip: !showId,
    variables: { nodeId: showGlobalId },
    onData: ({ data: { data } }) => {
      const order = data?.newOrderedProductInShow.orderedProduct.order
      const product = order?.product
      if (product && product.type === ProductType.InstantBuy) {
        notificationSuccess(
          <Trans
            i18nKey="showInstantBuySoldNotificationMessage"
            components={{
              s: <span className="callout_2" />,
            }}
            values={{
              productName: product.name,
              buyer: order?.customer?.username,
            }}
          />
        )
      }
      refetchSoldProducts()
    },
  })

  const handleAuctionPageChange = useCallback((page: number) => {
    setCurrentPageAuction(page)
  }, [])
  const handleInstantBuyPageChange = useCallback((page: number) => {
    setCurrentPageInstantBuy(page)
  }, [])
  const handleGiveawayPageChange = useCallback((page: number) => {
    setCurrentPageGiveaway(page)
  }, [])
  const handleSoldPageChange = useCallback((page: number) => {
    setCurrentPageSold(page)
  }, [])
  const handleSearchChange = useCallback((value: string) => {
    setCurrentPageAuction(1)
    setCurrentPageInstantBuy(1)
    setCurrentPageGiveaway(1)
    setSearch(String(value))
  }, [])

  const refetchProducts = async (type?: ProductType) => {
    if (type) {
      if (type === ProductType.Auction) {
        await refetchAuctions()
      }
      if (type === ProductType.InstantBuy) {
        await refetchInstantBuys()
      }
      if (type === ProductType.Giveaway) {
        await refetchGiveaways()
      }
    } else {
      await refetchAuctions()
      await refetchInstantBuys()
      await refetchGiveaways()
      await refetchSoldProducts()
    }
  }

  const value = {
    totalAmount,
    totalOrders,
    totalViewers,
    isLoading,

    itemsPerPage,
    handleSetItemsPerPage,

    search,
    handleSearchChange,

    filterByPositiveQuantity,
    setFilterByPositiveQuantity,

    refetchProducts,

    sortByProducts,
    setSortByProducts,

    loadingAuctionProducts,
    errorAuctionProducts,
    auctions,
    totalAuctions,
    fetchAuctionsProducts,
    setAuctionSortBy,
    auctionSortBy,
    currentPageAuction,
    handleAuctionPageChange,

    loadingInstantBuyProducts,
    errorInstantBuyProducts,
    instantBuys,
    totalInstantBuys,
    fetchInstantBuyProducts,
    setInstantBuySortBy,
    instantBuySortBy,
    currentPageInstantBuy,
    handleInstantBuyPageChange,

    loadingGiveawayProducts,
    errorGiveawayProducts,
    giveaways,
    totalGiveaways,
    fetchGiveawayProducts,
    setGiveawaySortBy,
    giveawaySortBy,
    currentPageGiveaway,
    handleGiveawayPageChange,

    loadingSoldProducts,
    errorSoldProducts,
    sold,
    totalSoldProducts,
    fetchSoldProducts,
    setSoldSortBy,
    currentPageSold,
    handleSoldPageChange,
  }

  return <ShowContext.Provider value={value}>{children}</ShowContext.Provider>
}

export const useShow = () => useContext(ShowContext)
