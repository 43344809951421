import { BarElement, CategoryScale, Chart as ChartJS, defaults, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import Card from '@/components/ui/Card/Card'
import { computeMostUsedCurrency, useCurrencyFormatter } from '@/helpers/currencyFormatter'

import { useShows } from '../../hooks/use-shows'

import type { Currency } from '@/network/graphql/types.generated'
import type { TooltipYAlignment } from 'chart.js'

import globalStyle from '@/styles/_export.module.scss'

export const ShowsSalesAveragePerDayOfTheWeek = () => {
  const { shows } = useShows()
  const { t, i18n } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

  defaults.font.family = 'Gantari-Regular'
  defaults.font.weight = '600'

  const currency: Currency = useMemo(() => {
    return computeMostUsedCurrency(shows)
  }, [shows])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    borderRadius: 8,
    scales: { x: { grid: { display: false } }, y: { display: true } },
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        caretSize: 0,
        padding: 8,
        yAlign: 'bottom' as TooltipYAlignment,
        titleFont: { size: 12 },
        titleColor: globalStyle.black160,
        bodyFont: { size: 18 },
        displayColors: false,
        backgroundColor: globalStyle.white,
        callbacks: {
          labelTextColor: () => globalStyle.black160,
          label: function (context: any) {
            const amount = context.dataset.data[context.dataIndex]
            return currencyFormatter(amount * 100, currency)
          },
        },
      },
    },
  }

  // INFO: Sunday is 0, Monday is 1, and so on.
  const daysInteger = useMemo(() => [1, 2, 3, 4, 5, 6, 0], [])
  const labels = daysInteger.map((d) =>
    dayjs()
      .locale(i18n.resolvedLanguage || '')
      .day(d)
      .format('dddd')
  )

  const values: number[] = useMemo(() => {
    const twoMonthsAgo = dayjs(new Date()).subtract(2, 'month')

    const targetShows = shows.filter((show) => dayjs(show.startAt).isAfter(twoMonthsAgo))

    const _amounts: number[] = []
    for (const i of daysInteger) {
      const showsInDay = targetShows.filter((show) => new Date(show.startAt).getDay() === i)

      _amounts.push(showsInDay.reduce((a, b) => a + b.totalNetIncomeInCurrencyCents / 100, 0) / showsInDay.length)
    }

    return _amounts
  }, [daysInteger, shows])

  const data = {
    datasets: [{ data: values, dates: labels, backgroundColor: globalStyle.black160 }],
    labels,
  }

  return (
    <Card subtitle={t('ShowsSumSubtitle')} title={t('ShowsSumTitle')}>
      <div className="w-full h-full">
        <Bar data={data} options={options} />
      </div>
    </Card>
  )
}
