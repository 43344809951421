import { useEffect, useState } from 'react'
import { Popover as TinyPopover } from 'react-tiny-popover'

import type { ReactNode } from 'react'

export type PopoverPosition = 'top' | 'bottom' | 'left' | 'right'

export type PopoverProps = {
  content: ReactNode
  children: ReactNode
  title?: string
  isOpen?: boolean
  onOpenChange?: (value: boolean) => void // TODO: this should rather be transformed into an 'onClose' callback
  className?: string
  classNameContent?: string
  positions?: PopoverPosition | PopoverPosition[]
  hideOnMouseOut?: boolean
  openOnMouseHover?: boolean
  onClickOutside?: () => void
}

// INFO: Component is based on React Tiny Popover : https://www.npmjs.com/package/react-tiny-popover
const Popover = (props: PopoverProps) => {
  const {
    content,
    children,
    title,
    isOpen = false,
    className,
    classNameContent,
    positions = ['top', 'bottom', 'left', 'right'],
    hideOnMouseOut = true,
    openOnMouseHover = true,
    onClickOutside,
  } = props
  const [isPopoverOpen, setIsPopoverOpen] = useState(isOpen)

  useEffect(() => {
    setIsPopoverOpen(isOpen)
  }, [isOpen])
  useEffect(() => {
    props.onOpenChange?.(isPopoverOpen)
  }, [isPopoverOpen])

  return (
    <TinyPopover
      isOpen={isPopoverOpen}
      padding={4}
      positions={positions}
      reposition={true}
      content={
        <div className="text-default rounded-md border bg-white border-slate-200 w-full shadow">
          {title && (
            <div className="py-2 px-2 border-b border-slate-200">
              <h1 className="mb-0">{title}</h1>
            </div>
          )}

          <div className={`py-2 px-2 ${classNameContent ?? undefined}`}>{content}</div>
        </div>
      }
      onClickOutside={() => {
        setIsPopoverOpen(false)
        onClickOutside && onClickOutside()
      }}
    >
      <div
        className={className ?? undefined}
        onMouseOut={() => hideOnMouseOut && setIsPopoverOpen(false)}
        onMouseOver={() => openOnMouseHover && setIsPopoverOpen(true)}
      >
        {children}
      </div>
    </TinyPopover>
  )
}

export default Popover
