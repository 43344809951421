export interface ShippingOption {
  minutesBetweenTwoShows?: number
  disabledDates?: string[]
  disabledSlots?: [
    {
      start: {
        hours: number
        minutes: number
      }
      end: {
        hours: number
        minutes: number
      }
    },
  ]
  isSuperSellerBypassingRules?: string[]
}

interface ShowShippingCategories {
  [key: string]: {
    [key: number | string]: ShippingOption
  }
}

// you can prevent dates from being selected by using disabledDates : ['DD/MM/YYYY','DD/MM/YYYY',...]
const calendarRules: ShowShippingCategories = {
  DE: {
    25: {
      minutesBetweenTwoShows: 30,
    },
    55: {
      minutesBetweenTwoShows: 30,
    },
    56: {
      minutesBetweenTwoShows: 30,
    },
    57: {
      minutesBetweenTwoShows: 30,
    },
    58: {
      minutesBetweenTwoShows: 30,
    },
    default: {},
  },
  AT: {
    25: {
      minutesBetweenTwoShows: 30,
    },
    55: {
      minutesBetweenTwoShows: 30,
    },
    56: {
      minutesBetweenTwoShows: 30,
    },
    57: {
      minutesBetweenTwoShows: 30,
    },
    58: {
      minutesBetweenTwoShows: 30,
    },
    default: {},
  },
  FR: {
    14: {
      minutesBetweenTwoShows: 30,
    },
    default: {},
  },
  CH: {
    default: {},
  },
  BE: {
    14: {
      minutesBetweenTwoShows: 30,
    },
    default: {},
  },
  GB: {
    default: {},
  },
  ES: {
    58: {
      minutesBetweenTwoShows: 60,
    },
    57: {
      minutesBetweenTwoShows: 60,
    },
    56: {
      minutesBetweenTwoShows: 60,
    },
    55: {
      minutesBetweenTwoShows: 60,
    },
    25: {
      minutesBetweenTwoShows: 60,
    },
    default: {
      minutesBetweenTwoShows: 30,
    },
  },
  NL: {
    default: {},
    14: {
      minutesBetweenTwoShows: 30,
    },
  },
  default: {
    default: {},
  },
}

const getCalendarRules = (sellerCountry: string, showCategory: number) => {
  if (calendarRules[sellerCountry] && calendarRules[sellerCountry][showCategory])
    return calendarRules[sellerCountry][showCategory]

  if (calendarRules[sellerCountry] && calendarRules[sellerCountry].default) return calendarRules[sellerCountry].default

  return calendarRules.default.default
}

export default getCalendarRules
