import { Divider } from 'antd'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import { DEFAULT_COUNTRY_ID } from '@/config/config'
import { useUser } from '@/contexts/user/User.context'
import { findNextAvailableSlots, isDayDisabled } from '@/helpers/showSlotFinder'
import { trackEvent } from '@/util/eventTracker'

import CreateShowModal from '../../../components/CreateShowModal/CreateShowModal'

import type { Show } from '@/types'
import type { Moment } from 'moment'

import style from './style.module.scss'

type SlotsListProps = {
  shows: Show[]
  from: Moment
  showsToGet: number
  category: number
}

const SlotsList = (props: SlotsListProps) => {
  const { shows, from, showsToGet, category } = props
  const { t } = useTranslation()

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canCreateShow, country } = sellerConfig || {}
  const sellerCountry = country?.iso2Code || DEFAULT_COUNTRY_ID

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [slotsToShow, setSlotsToShow] = useState<number>(10)
  const [providedSlot, setProvidedSlot] = useState<null | Moment>()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const showsAfterStart = shows.filter((show) => moment(show.startAt).isAfter(from))
  const sortedShows = showsAfterStart.sort((a, b) => moment(a.startAt).diff(b.startAt))
  const til = sortedShows[0] ? moment(sortedShows[0]?.startAt) : moment(from).endOf('day')

  const slots = from
    ? !isDayDisabled(from, sellerCountry, category)
      ? findNextAvailableSlots(from, til, showsToGet, shows, sellerCountry, category)
      : []
    : []

  const handleModalVisible = (value: boolean) => {
    if (!value) setProvidedSlot(null)
    setIsModalVisible(value)
  }

  const handleSlotSelect = (slot: Moment) => {
    setProvidedSlot(slot)
    setIsModalVisible(true)
  }

  const incrementSlotsToShow = () => {
    setSlotsToShow(slotsToShow + 10)
  }

  useEffect(() => {
    if (isModalVisible) trackEvent('CALENDAR_OPEN_CREATE_SHOW_MODAL_FROM_SLOTS_LIST')
  }, [isModalVisible])

  if (!canCreateShow) return null

  return (
    <>
      {isModalVisible && (
        <CreateShowModal
          providedCategory={category}
          providedSlot={moment(providedSlot)}
          setIsModalVisible={handleModalVisible}
        />
      )}
      {slots.length > 0 && (
        <div className={style.slotsList__block_container}>
          <div className={style.slotsList__divider}>
            <Divider />
          </div>
          <div className={style.slotsList__container}>
            <div className={style.slotsList__main_content} onClick={() => setIsOpen(!isOpen)}>
              <span className={style.slotsList__data_content}>
                <span className={`${style.slots__title_bold} callout_4`}>
                  {slots.length} {t('slotsListAvailableSlotsMessage')}
                </span>
                <span className="callout_3">
                  {t('commonFrom')} {slots[0].format('HH:mm')} {t('commonTo')} {til.format('HH:mm')}
                </span>
              </span>

              <div className={style.slotsList__button}>
                {isOpen ? <FaCaretUp fill="white" size={7} /> : <FaCaretDown fill="white" size={7} />}
              </div>
            </div>

            <div className={`${style.slotsList__list_container} ${isOpen && style.slotsList__list_container_open}`}>
              <ul>
                {slots.map((slot, i) => {
                  if (i < slotsToShow)
                    return (
                      <li key={`${moment(from).format('DD/MM/YYY')} ${i}}`} className={style.slotsList__list_slot}>
                        <Button
                          className="select-slot-action primary"
                          label={moment(slot).format('HH:mm')}
                          onClick={() => handleSlotSelect(slot)}
                        />
                      </li>
                    )

                  return false
                })}
              </ul>
              {slotsToShow < slots.length && (
                <Button className="more-action secondary" label={t('commonSeeMore')} onClick={incrementSlotsToShow} />
              )}
            </div>
          </div>
          <div className={style.slotsList__divider}>
            <Divider />
          </div>
        </div>
      )}
    </>
  )
}

export default SlotsList
