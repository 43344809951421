import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

import CreateProductTypeSelector from '@/components/CreateProductTypeSelector/CreateProductTypeSelector'
import Field from '@/components/Form/Field/Field'
import ColumnImageAndName from '@/components/ProductsColumns/ColumnImageAndName'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import Dialog from '@/components/ui/Dialog/Dialog'
import DeleteIcon from '@/components/ui/Icons/DeleteIcon/DeleteIcon'
import EditIcon from '@/components/ui/Icons/EditIcon/EditIcon'
import SearchIcon from '@/components/ui/Icons/SearchIcon/SearchIcon'
import Link from '@/components/ui/Link/Link'
import Loader from '@/components/ui/Loader/Loader'
import { notificationSuccess } from '@/components/ui/Notification/Notification'
import Popover from '@/components/ui/Popover/Popover'
import Table, { FiltersType } from '@/components/ui/Table/Table'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { ProductType } from '@/network/graphql/types.generated'

import { FILTER_BY_SHOWS } from './InventoryContainer'

import type { ExtraProductFormOptions } from '@/components/CreateOrEditProduct2/types'
import type { FiltersValue } from '@/components/ui/Table/Table'
import type { InventoryProduct, Product, SellerConfig } from '@/network/graphql/types.generated'
import type { SortingState } from '@tanstack/react-table'

import './Inventory.scss'

interface InventoryProps {
  inventoryProducts: InventoryProduct[]
  total: number | undefined
  itemsPerPage: number
  currentPage: number
  currency: SellerConfig['sellerCurrency']
  isInventoryInitialized: SellerConfig['isInventoryInitialized']
  isImporter?: boolean
  selectedRows: string[]
  loadingInventory: boolean
  sorting: SortingState
  filterByPositiveTotalQuantity: boolean
  pastShowsOptions: { value: string; label: string }[]
  filterByShows: string[] | undefined
  loadingImporter: boolean
  handleFilterByPositiveTotalQuantity: (value: boolean) => void
  handleDelete: (product: InventoryProduct) => void
  handleSearch: (value: any) => void
  handleImportInShow: () => void
  updateInventory: () => void
  handleBulkRemove: () => void
  handleSelectedRowsChange: (selectedRows: string[]) => void
  handleItemPerPageChange: (perPage: number) => void
  handlePageChange: (page: number) => void
  handleSortingChange: (sorting: SortingState) => void
  handleFilter: (filters: FiltersValue[]) => void
}

export const Inventory = (props: InventoryProps) => {
  const {
    inventoryProducts,
    total,
    itemsPerPage,
    currentPage,
    currency,
    isInventoryInitialized,
    isImporter = false,
    selectedRows,
    loadingInventory,
    sorting,
    filterByPositiveTotalQuantity,
    pastShowsOptions,
    filterByShows,
    loadingImporter,
    handleFilterByPositiveTotalQuantity,
    handleDelete,
    handleSearch,
    handleImportInShow,
    updateInventory,
    handleBulkRemove,
    handleSelectedRowsChange,
    handleItemPerPageChange,
    handlePageChange,
    handleSortingChange,
    handleFilter,
  } = props

  const { t } = useTranslation()
  const history = useHistory()
  const currencyFormatter = useCurrencyFormatter()
  useDocumentTitle(t('sideMenuInventoryTitle'))

  const [inventoryProductId, setInventoryProductId] = useState<string | undefined>(undefined)
  const [lastCreatedProduct, setLastCreatedProduct] = useState<Product | undefined>(undefined)
  const [editedProduct, setEditedProduct] = useState<InventoryProduct | undefined>(undefined)

  const [introducerModalIsOpen, setIntroducerModalIsOpen] = useState<boolean>(
    !getFromLocalStorage('hasSeenInventoryIntroducer')
  )

  const handleCreatedOrEditedProduct = useCallback(async (product?: Product, options?: ExtraProductFormOptions) => {
    const { createMore } = options || {}

    if (product) {
      if (createMore) {
        notificationSuccess(`${product.name} successfully created!`)
        setLastCreatedProduct(product)
      } else {
        setEditedProduct(undefined)
        setInventoryProductId(undefined)
      }
      updateInventory()
    } else {
      setEditedProduct(undefined)
      setInventoryProductId(undefined)
      updateInventory()
    }
  }, [])

  const handleEdit = (product: InventoryProduct) => {
    setEditedProduct(product)
    setInventoryProductId(product.id)
  }
  const handleClose = () => {
    const currentUrl = window.location.pathname + window.location.search
    const url = window.location.pathname
    if (currentUrl != url) history.push(url)
    setEditedProduct(undefined)
    setInventoryProductId(undefined)
  }

  const handleAdd = () => {
    setEditedProduct({ id: 'new' } as InventoryProduct)
    setInventoryProductId('new')
  }

  const allColumns = [
    {
      header: t('inventoryColumnProduct'),
      id: 'product',
      accessorKey: 'name',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const { name, images, description, color, id } = record
        return (
          <ColumnImageAndName
            color={color}
            description={description}
            id={id}
            image={images?.[0]?.webPUrl}
            name={name}
            onClick={() => handleEdit(record)}
          />
        )
      },
    },
    {
      header: t('inventoryColumnDefaultType'),
      id: 'defaultType',
      accessorKey: 'defaultType',
      enableColumnFilter: false,
      cell: (item: any) => {
        return renderDefaultTypeColumn(item.row.original)
      },
    },
    {
      header: t('inventoryColumnStartingAmount'),
      id: 'startingAmount',
      accessorKey: 'startingAmount',
      enableColumnFilter: false,
      cell: (item: any) => {
        return renderAmountColumn(item.row.original, 'startingAmount')
      },
    },
    {
      header: t('inventoryColumnFixedAmount'),
      id: 'fixedAmount',
      accessorKey: 'fixedAmount',
      enableColumnFilter: false,
      cell: (item: any) => {
        return renderAmountColumn(item.row.original, 'fixedAmount')
      },
    },
    {
      header: (
        <Popover content={t('inventoryColumnTotalQuantityPopover')}>
          <span className="flex items-center gap-2">
            {t('inventoryColumnTotalQuantity')} <FaInfoCircle />
          </span>
        </Popover>
      ),
      id: 'totalQuantity',
      accessorKey: 'totalQuantity',
      enableColumnFilter: false,
      cell: (item: any) => {
        return renderQuantityColumn(item.getValue())
      },
    },
    {
      header: t('inventoryColumnQtyInShows'),
      id: 'availableProductCountInShows',
      accessorKey: 'availableProductCountInShows',
      enableSorting: false,
      enableColumnFilter: true,
      cell: (item: any) => {
        return renderShowsColumn(item.getValue())
      },
    },
    {
      header: t('inventoryColumnQtyInShops'),
      id: 'availableProductCountInOfflineShows',
      accessorKey: 'availableProductCountInOfflineShows',
      enableSorting: false,
      enableColumnFilter: true,
      cell: (item: any) => {
        return renderShowsColumn(item.getValue())
      },
    },
    {
      header: t('inventoryColumnCreatedAt'),
      id: 'createdAt',
      accessorKey: 'createdAt',
      enableColumnFilter: false,
      cell: (item: any) => {
        return renderDateColumn(item.getValue())
      },
    },
    {
      header: '',
      id: 'action',
      enableSorting: false,
      enableColumnFilter: false,
      cell: (item: any) => {
        return renderActionColumn(item.row.original)
      },
    },
  ]

  const columns = isImporter
    ? allColumns.filter(
        (column) =>
          column.id !== 'action' &&
          column.id !== 'inventoryColumnQtyInShows' &&
          column.id !== 'inventoryColumnQtyInShops'
      )
    : allColumns

  const renderDefaultTypeColumn = (item: InventoryProduct) => {
    return (
      <div className="inventory-column">
        <p className="inventory-product-type">
          {item.defaultType === ProductType.Auction
            ? t('inventoryDefaultTypeAuction')
            : item.defaultType === ProductType.InstantBuy
              ? t('inventoryDefaultTypeInstantBuy')
              : item.defaultType === ProductType.Giveaway
                ? t('inventoryDefaultTypeGiveaway')
                : ''}
        </p>
      </div>
    )
  }

  const renderAmountColumn = (item: InventoryProduct, type: 'startingAmount' | 'fixedAmount') => {
    const value = type === 'startingAmount' ? item.startingAmount : item.fixedAmount
    return <div className="inventory-column">{value ? <p>{currencyFormatter(value, currency)}</p> : <p>-</p>}</div>
  }

  const renderQuantityColumn = (value: InventoryProduct['availableQuantity']) => {
    return (
      <div className="inventory-column">
        <p className={value === 0 ? 'sold-out' : ''}>{value}</p>
      </div>
    )
  }

  const renderDateColumn = (value: InventoryProduct['createdAt']) => {
    return (
      <div className="inventory-column">
        <p>{dayjs(value).format('DD/MM/YYYY')}</p>
      </div>
    )
  }

  const renderShowsColumn = (quantity: number) => {
    return <p>{quantity} </p>
  }

  const renderActionColumn = (item: InventoryProduct) => {
    return (
      <div className="inventory-column">
        <Button
          className="edit-action"
          icon={<EditIcon />}
          tooltip={t('inventoryProductEditActionTitle')}
          tooltipPosition="bottom right"
          onClick={() => handleEdit(item)}
        />
        <Button
          className="delete-action"
          icon={<DeleteIcon />}
          tooltip={t('inventoryProductRemoveActionTitle')}
          tooltipPosition="bottom right"
          onClick={() => handleDelete(item)}
        />
      </div>
    )
  }
  if (!isInventoryInitialized) {
    return (
      <ViewContainer leftContent={t('sideMenuInventoryTitle')}>
        <Card>
          <Loader />
        </Card>
      </ViewContainer>
    )
  }

  const renderContent = () => {
    return (
      <div className="inventory-card-content">
        <Table
          columns={columns}
          data={inventoryProducts || {}}
          loading={loadingInventory}
          filters={{
            options: [
              {
                type: FiltersType.select,
                name: FILTER_BY_SHOWS,
                label: t('inventoryFilterByShows'),
                filterOptions: pastShowsOptions,
                selected: !!filterByShows,
              },
            ],
            extra: !isImporter && (
              <div
                className="filter-sold-out"
                onClick={() => handleFilterByPositiveTotalQuantity(filterByPositiveTotalQuantity)}
              >
                <Field
                  checked={filterByPositiveTotalQuantity}
                  label={t('inventoryFilterByPositiveTotalQuantity')}
                  name="sold-out"
                  type="checkbox"
                />
              </div>
            ),
            onFilter: handleFilter,
          }}
          header={
            <div className="inventory-actions">
              <div className="search">
                <SearchIcon />
                <input placeholder={t('commonSearch')} type="search" onChange={(value) => handleSearch(value)} />
              </div>
              {!isImporter && (
                <div>
                  <Button
                    className="delete-action bulk-delete-inventory-products-action primary is-danger"
                    disabled={selectedRows.length === 0}
                    icon={<DeleteIcon />}
                    onClick={handleBulkRemove}
                  />
                </div>
              )}
            </div>
          }
          pagination={{
            total,
            currentPage: currentPage,
            perPage: itemsPerPage,
            onPageChange: (page: number) => handlePageChange(page),
            onItemPerPageChange: (perPage: number) => handleItemPerPageChange(perPage),
            extra: isImporter && (
              <Button
                className="validate-action primary"
                disabled={selectedRows?.length === 0}
                isLoading={loadingImporter}
                label={`${t('inventoryImportInShow')} (${selectedRows?.length})`}
                onClick={handleImportInShow}
              />
            ),
          }}
          selectable={{
            selectedRows,
            onSelectedRowsChange: handleSelectedRowsChange,
          }}
          sort={{
            sorting,
            onSortingChange: handleSortingChange,
          }}
        />
      </div>
    )
  }

  if (isImporter) {
    return <div className="inventory-card inventory-import">{renderContent()}</div>
  }

  return (
    <ViewContainer
      leftContent={t('sideMenuInventoryTitle')}
      rightContent={
        <div className="inventory-add">
          <CreateProductTypeSelector
            lastCreatedProduct={lastCreatedProduct}
            product={
              inventoryProductId === 'new'
                ? ({ id: 'new' } as Product)
                : ({
                    ...editedProduct,
                    type: editedProduct?.defaultType,
                    quantity: editedProduct?.availableQuantity,
                  } as unknown as Product)
            }
            onAddFromInventory={handleAdd}
            onCancelCreate={handleClose}
            onImported={updateInventory}
            onSuccessCreate={handleCreatedOrEditedProduct}
          />
        </div>
      }
    >
      <Dialog
        isOpen={introducerModalIsOpen}
        modal={true}
        title={t('inventoryIntroducerTitle')}
        onClose={() => {
          setToLocalStorage('hasSeenInventoryIntroducer', 'true')
          setIntroducerModalIsOpen(false)
        }}
      >
        <Trans i18nKey="inventoryIntroducerContent" />
        <Link href={t('inventoryIntroducerLink')} rel="noreferrer" target="_blank">
          {t('inventoryIntroducerLinkContent')}
        </Link>
      </Dialog>

      <Card className="inventory-card" noPadding={true}>
        {renderContent()}
      </Card>
    </ViewContainer>
  )
}
