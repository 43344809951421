import { message } from 'antd'
import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from '@/contexts/user/User.context'
import { getFromLocalStorage } from '@/helpers/localstorage'
import {
  useBanUserFromSellerMutation,
  useBanUserFromShowMutation,
  useUnbanUserFromSellerMutation,
  useUnbanUserFromShowMutation,
  useGetUsersBannedFromSellerQuery,
  useGetUsersBannedFromShowQuery,
} from '@/modules/ban-hammer/graphql/operations.generated'
import {
  usePromoteUserToModeratorMutation,
  useDemoteUserFromModeratorMutation,
  useGetModeratorsQuery,
} from '@/modules/moderators/graphql/operations.generated'
import { useGetShowViewersLazyQuery } from '@/modules/show/graphql/operations.generated'
import { trackEvent } from '@/util/eventTracker'

import type { Viewer } from './chatbox.helper'
import type { Show } from '@/types'
import type { ReactNode } from 'react'

interface ChatBoxContextState {
  sellerId: string
  showId: Show['id']
  moderators: Viewer[]
  bannedUsersFromSeller: Viewer[]
  bannedUsersFromShow: Viewer[]
  viewersLoading: boolean
  isShowBroadcasting: boolean
  isActivityRunning?: boolean
  fontSize: FontSizes
  setFontSize: (value: FontSizes) => void
  onBanUserFromSeller: (user: Viewer) => void
  onUnBanUserFromSeller: (user: Viewer) => void
  onBanUserFromShow: (user: Viewer) => void
  onUnBanUserFromShow: (user: Viewer) => void
  onPromoteUserToModerator: (user: Viewer) => void
  onDemoteUserFromModerator: (user: Viewer) => void
  onRefreshModerators: () => void
  onRefreshBannedUsersFromSeller: () => void
  onRefreshBannedUsersFromShow: () => void
}

interface ChatBoxProviderProps {
  children: ReactNode
  showId: number
  isShowBroadcasting: boolean
  isActivityRunning?: boolean
}

const ChatBoxContext = createContext({} as ChatBoxContextState)

export enum FontSizes {
  small = 'small',
  normal = 'normal',
  medium = 'medium',
  large = 'large',
}

export default function ChatBoxProvider(props: ChatBoxProviderProps) {
  const { children, showId, isActivityRunning, isShowBroadcasting } = props
  const { t } = useTranslation()

  const { user } = useUser()
  const { id: sellerId = '' } = user || {}
  const showGlobalId = `Show|${showId}`

  const [moderators, setModerators] = useState<Array<Viewer>>([])
  const [bannedUsersFromSeller, setBannedUsersFromSeller] = useState<Array<Viewer>>([])
  const [bannedUsersFromShow, setBannedUsersFromShow] = useState<Array<Viewer>>([])
  const [fontSize, setFontSize] = useState<FontSizes>(
    (getFromLocalStorage('chat_font_size') as FontSizes) || FontSizes.normal
  )

  const [banUserFromSellerWithoutPrefix] = useBanUserFromSellerMutation()
  const [banUserFromShowWithoutPrefix] = useBanUserFromShowMutation()
  const [unbanUserFromSellerWithoutPrefix] = useUnbanUserFromSellerMutation()
  const [unbanUserFromShowWithoutPrefix] = useUnbanUserFromShowMutation()
  const [handlePromoteUserToModerator] = usePromoteUserToModeratorMutation()
  const [handleDemoteUserToModerator] = useDemoteUserFromModeratorMutation()

  const [, { loading: viewersLoading }] = useGetShowViewersLazyQuery()

  const { data: _moderators, refetch: refetchModerators } = useGetModeratorsQuery({
    fetchPolicy: 'network-only',
  })

  const { data: bannedFromSellerUserList, refetch: refetchBannedFromSeller } = useGetUsersBannedFromSellerQuery({
    skip: !sellerId,
    variables: { sellerId },
    fetchPolicy: 'network-only',
  })

  const { data: bannedFromShowUserList, refetch: refetchBannedFromShow } = useGetUsersBannedFromShowQuery({
    variables: { showId: showGlobalId },
    fetchPolicy: 'network-only',
    skip: !showGlobalId,
  })

  useEffect(() => {
    // Set Moderators
    const m = _moderators?.viewer?.allModerators ?? []
    setModerators(m)

    // Set BannedFromSeller
    const bFromSeller =
      bannedFromSellerUserList?.node?.__typename === 'User' &&
      bannedFromSellerUserList.node?.bannedUsersFromSeller?.length
        ? bannedFromSellerUserList.node.bannedUsersFromSeller
        : []
    setBannedUsersFromSeller(bFromSeller)

    // Set BannedFromShow
    const bFromShow =
      bannedFromShowUserList?.node?.__typename === 'Show' && bannedFromShowUserList.node?.bannedUsersFromShow?.length
        ? bannedFromShowUserList.node.bannedUsersFromShow
        : []
    setBannedUsersFromShow(bFromShow)

    // Filter out banned users from moderators
    const filteredModerators = m.filter(
      (moderator) =>
        !bFromSeller.find((bannedUser) => bannedUser.id === moderator.id) &&
        !bFromShow.find((bannedUser) => bannedUser.id === moderator.id)
    )
    setModerators(filteredModerators)
  }, [_moderators, bannedFromSellerUserList, bannedFromShowUserList])

  const onBanUserFromSeller = async (user: Viewer) => {
    trackEvent('SHOW_BAN_FROM_SELLER_CLICKED', { show: showId })
    try {
      const result = await banUserFromSellerWithoutPrefix({
        variables: {
          input: { sellerId, userId: user.id },
        },
      })
      message.success(t('banUserFromSellerSuccess'))
      refetchBannedFromSeller({ sellerId })
      return result
    } catch (err) {
      message.error(t('banUserFromSellerError'))
    }
  }

  const onBanUserFromShow = async (user: Viewer) => {
    trackEvent('SHOW_BAN_FROM_SHOW_CLICKED', { show: showId })
    try {
      const result = await banUserFromShowWithoutPrefix({
        variables: {
          input: { showId: showGlobalId, userId: user.id },
        },
      })
      message.success(t('banUserFromShowSuccess'))
      refetchBannedFromShow({ showId: showGlobalId })
      return result
    } catch (err) {
      message.error(t('banUserFromShowError'))
    }
  }

  const onUnBanUserFromSeller = async (user: Viewer) => {
    trackEvent('SHOW_UNBAN_FROM_SELLER_CLICKED', { show: showId })

    try {
      const result = await unbanUserFromSellerWithoutPrefix({
        variables: {
          input: { sellerId: sellerId, userId: user.id },
        },
      })
      message.success(t('unbanUserFromSellerSuccess'))
      refetchBannedFromSeller({ sellerId: sellerId })
      return result
    } catch (err) {
      message.error(t('unbanUserFromSellerError'))
    }
  }

  const onUnBanUserFromShow = async (user: Viewer) => {
    trackEvent('SHOW_UNBAN_FROM_SHOW_CLICKED', { show: showId })
    try {
      const result = await unbanUserFromShowWithoutPrefix({
        variables: {
          input: { showId: showGlobalId, userId: user.id },
        },
      })
      message.success(t('unbanUserFromSellerSuccess'))
      refetchBannedFromShow({ showId: showGlobalId })
      // refetchViewers()
      return result
    } catch (err) {
      message.error(t('unbanUserFromSellerError'))
    }
  }

  const onPromoteUserToModerator = async (user: Viewer) => {
    trackEvent('SHOW_PROMOTE_TO_MODERATOR_CLICKED', { show: showId })
    try {
      const userId = user.id
      const result = await handlePromoteUserToModerator({ variables: { input: { userId } } })
      message.success(t('promoteModeratorMessageSuccess'))
      refetchModerators()
      return result
    } catch (err) {
      message.error(t('promoteModeratorMessageError'))
    }
  }

  const onDemoteUserFromModerator = async (user: Viewer) => {
    trackEvent('SHOW_PROMOTE_TO_MODERATOR_CLICKED', { show: showId })
    try {
      const userId = user.id
      const result = await handleDemoteUserToModerator({ variables: { input: { userId } } })
      message.success(t('demoteModeratorMessageSuccess'))
      refetchModerators()
      return result
    } catch (err) {
      message.error(t('demoteModeratorMessageError'))
    }
  }

  // Refreshes
  const onRefreshBannedUsersFromSeller = () => refetchBannedFromSeller({ sellerId: sellerId })

  const onRefreshBannedUsersFromShow = () => refetchBannedFromShow({ showId: showGlobalId })

  const value: ChatBoxContextState = {
    // Variables
    sellerId,
    showId,

    // User arrays
    moderators,
    bannedUsersFromSeller,
    bannedUsersFromShow,

    // Loading
    viewersLoading,

    // Activity
    isShowBroadcasting, // TODO: this should rather be in a show context
    isActivityRunning, // TODO: this should rather be in a show context

    fontSize,
    setFontSize,

    // Ban / Unban Seller
    onBanUserFromSeller,
    onUnBanUserFromSeller,

    // Ban / Unban Show
    onBanUserFromShow,
    onUnBanUserFromShow,

    // Promote / Demote
    onPromoteUserToModerator,
    onDemoteUserFromModerator,

    // Refresh
    onRefreshModerators: refetchModerators,
    onRefreshBannedUsersFromSeller,
    onRefreshBannedUsersFromShow,
  }

  return <ChatBoxContext.Provider value={value}>{children}</ChatBoxContext.Provider>
}

export const useChatBoxContext = () => useContext(ChatBoxContext)
