import { createContext, useContext } from 'react'

import { useGetUserQuery } from '@/contexts/user/operations.generated'

import type { GetUserQuery } from '@/contexts/user/operations.generated'
import type { ReactNode } from 'react'

type User = GetUserQuery['viewer']

interface IUserContext {
  user?: User
  fetchUser: () => Promise<void>
  isLoading: boolean
}

const UserContext = createContext<IUserContext>({
  user: undefined,
  fetchUser: () => Promise.resolve(),
  isLoading: false,
})

type UserProviderProps = {
  children: ReactNode
}

export const UserProvider = (props: UserProviderProps) => {
  const { children } = props

  const {
    data,
    loading: isLoading,
    refetch,
  } = useGetUserQuery({
    onError: () => {
      // nothing here for the moment
    },
  })
  const user = data?.viewer

  const fetchUser = async () => {
    await refetch()
  }

  const value = { user, fetchUser, isLoading }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
