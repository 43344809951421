import { Table } from 'antd'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaCircleInfo } from 'react-icons/fa6'
import { useHistory } from 'react-router-dom'

import { Body } from '@/components/Typography/Body'
import Popover from '@/components/ui/Popover/Popover'
import { useUser } from '@/contexts/user/User.context'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'

import type { ShowItemFragment } from '../../operations.generated'

import './style.scss'

export interface ShowsTableProps {
  shows: ShowItemFragment[]
  isShop?: boolean
}

export const ShowsTable = ({ shows, isShop = false }: ShowsTableProps) => {
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()
  const history = useHistory()

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canAccessHomeMetrics } = sellerConfig || {}

  const columns = [
    // Thumbnail
    {
      dataIndex: 'images',
      fixed: 'left' as const,
      title: null,
      width: 70,
      key: 'thumbnail',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'thumbnailWebPUrl' | 'name'>) => (
        <div className="w-[52px] h-[52px] rounded-lg overflow-hidden">
          <img
            alt={`thumbnail-${record.name}`}
            className="w-full h-full object-cover"
            src={record.thumbnailWebPUrl ?? '/badges/empty-picture.png'}
          />
        </div>
      ),
    },

    // ID
    {
      width: 1,
      dataIndex: 'id',
      fixed: 'left' as const,
      title: 'ID',
      key: 'legacyId',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'legacyId'>) => <Body variant="body3">{record.legacyId}</Body>,
    },

    // Start at
    {
      width: 1,
      dataIndex: 'startAt',
      fixed: 'left' as const,
      title: isShop ? t('shopsListDate') : t('showsListDate'),
      key: 'date',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'startAt'>) => (
        <Body variant="body3">{dayjs(record.startAt).format('DD/MM/YYYY')}</Body>
      ),
    },

    // Name
    {
      dataIndex: 'name',
      fixed: 'left' as const,
      title: isShop ? t('shopsListName') : t('showsListName'),
      key: 'name',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'name' | 'isSupplier' | 'startAt' | 'terminatedAt'>) => (
        <div className="show-table-name-column">
          <Body variant="body3">{record.name}</Body>{' '}
          {isShop && record.isSupplier && <span className="supplier-badge">{t('shopSupplierBadge')}</span>}
          {isShop && (record.terminatedAt || dayjs.utc().isAfter(dayjs.utc(record.startAt))) && (
            <span className="terminated-badge">{t('shopTerminatedBadge')}</span>
          )}
        </div>
      ),
    },

    // Total sold amount
    {
      width: 140,
      dataIndex: 'totalProductsSoldInCurrencyCents',
      fixed: 'right' as const,
      title: t('showsTableSoldProductHeaderTitle'),
      key: 'totalProductsSoldInCurrencyCents',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'totalProductsSoldInCurrencyCents' | 'currency'>) => (
        <Body variant="body3">{currencyFormatter(record.totalProductsSoldInCurrencyCents, record.currency)}</Body>
      ),
      sorter: {
        compare: (
          a: Pick<ShowItemFragment, 'totalProductsSoldInCurrencyCents'>,
          b: Pick<ShowItemFragment, 'totalProductsSoldInCurrencyCents'>
        ) => a.totalProductsSoldInCurrencyCents - b.totalProductsSoldInCurrencyCents,
        multiple: 1,
      },
    },

    // Total shipping fees amount
    {
      width: 140,
      dataIndex: 'totalShippingFeesInCurrencyCents',
      fixed: 'right' as const,
      title: t('showsTableTotalPerceivedShippingFeesHeaderTitle'),
      key: 'totalShippingFeesInCurrencyCents',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'totalShippingFeesInCurrencyCents' | 'currency'>) => (
        <Body variant="body3">{currencyFormatter(record.totalShippingFeesInCurrencyCents, record.currency)}</Body>
      ),
      sorter: {
        compare: (
          a: Pick<ShowItemFragment, 'totalShippingFeesInCurrencyCents'>,
          b: Pick<ShowItemFragment, 'totalShippingFeesInCurrencyCents'>
        ) => a.totalShippingFeesInCurrencyCents - b.totalShippingFeesInCurrencyCents,
        multiple: 1,
      },
    },

    {
      width: 140,
      dataIndex: 'totalDeductedShippingCostInCurrencyCents',
      fixed: 'right' as const,
      title: t('showsTableTotalShippingLabelFeesHeaderTitle'),
      key: 'totalDeductedShippingCostInCurrencyCents',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'totalDeductedShippingCostInCurrencyCents' | 'currency'>) => (
        <Body variant="body3">
          {`-${currencyFormatter(record.totalDeductedShippingCostInCurrencyCents, record.currency)}`}
        </Body>
      ),
      sorter: {
        compare: (
          a: Pick<ShowItemFragment, 'totalDeductedShippingCostInCurrencyCents'>,
          b: Pick<ShowItemFragment, 'totalDeductedShippingCostInCurrencyCents'>
        ) => a.totalDeductedShippingCostInCurrencyCents - b.totalDeductedShippingCostInCurrencyCents,
        multiple: 1,
      },
    },

    // Total refunded amount
    {
      width: 170,
      dataIndex: 'totalAmountRefundedInCurrencyCents',
      fixed: 'right' as const,
      title: t('commonShowRefundedAmount'),
      key: 'totalAmountRefundedInCurrencyCents',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'totalAmountRefundedInCurrencyCents' | 'currency'>) => (
        <Body variant="body3">
          {`-${currencyFormatter(record.totalAmountRefundedInCurrencyCents, record.currency)}`}
        </Body>
      ),
      sorter: {
        compare: (
          a: Pick<ShowItemFragment, 'totalAmountRefundedInCurrencyCents'>,
          b: Pick<ShowItemFragment, 'totalAmountRefundedInCurrencyCents'>
        ) => a.totalAmountRefundedInCurrencyCents - b.totalAmountRefundedInCurrencyCents,
        multiple: 1,
      },
    },

    // Total commission amount
    {
      width: 140,
      dataIndex: 'totalCommissionInCurrencyCents',
      fixed: 'right' as const,
      title: (
        <span className="commission-col-title">
          {t('commonCommission')}
          <Popover
            className="more-info more-info-commission"
            content={<Trans i18nKey="showsTableTotalCommissionsMoreInfoText" />}
            positions={'bottom'}
          >
            <FaCircleInfo className="text-gray-400" size={14} />
          </Popover>
        </span>
      ),
      key: 'totalCommissionInCurrencyCents',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'totalCommissionInCurrencyCents' | 'currency'>) => (
        <Body variant="body3">{`-${currencyFormatter(record.totalCommissionInCurrencyCents, record.currency)}`}</Body>
      ),
      sorter: {
        compare: (
          a: Pick<ShowItemFragment, 'totalCommissionInCurrencyCents'>,
          b: Pick<ShowItemFragment, 'totalCommissionInCurrencyCents'>
        ) => a.totalCommissionInCurrencyCents - b.totalCommissionInCurrencyCents,
        multiple: 1,
      },
    },

    // Total net income
    {
      width: 140,
      dataIndex: 'totalNetIncomeInCurrencyCents',
      fixed: 'right' as const,
      title: t('commonNetIncome'),
      key: 'totalNetIncomeInCurrencyCents',
      onCell: () => ({ colSpan: 1 }),
      render: (_: any, record: Pick<ShowItemFragment, 'totalNetIncomeInCurrencyCents' | 'currency'>) => (
        <Body className="text-[#4AB425]" variant="body4">
          {currencyFormatter(record.totalNetIncomeInCurrencyCents, record.currency)}
        </Body>
      ),
      sorter: {
        compare: (
          a: Pick<ShowItemFragment, 'totalNetIncomeInCurrencyCents'>,
          b: Pick<ShowItemFragment, 'totalNetIncomeInCurrencyCents'>
        ) => a.totalNetIncomeInCurrencyCents - b.totalNetIncomeInCurrencyCents,
        multiple: 1,
      },
    },
  ]

  const columnsToDisplay = useMemo(
    () =>
      canAccessHomeMetrics
        ? columns
        : columns.filter(
            ({ key }) =>
              key !== 'totalNetIncomeInCurrencyCents' &&
              key !== 'totalCommissionInCurrencyCents' &&
              key !== 'totalAmountRefundedInCurrencyCents' &&
              key !== 'totalDeductedShippingCostInCurrencyCents' &&
              key !== 'totalShippingFeesInCurrencyCents' &&
              key !== 'totalProductsSoldInCurrencyCents'
          ),
    [canAccessHomeMetrics, columns]
  )

  return (
    <Table
      className="shows-table"
      columns={columnsToDisplay}
      dataSource={shows}
      pagination={false}
      rowClassName="cursor-pointer"
      rowKey="id"
      size="small"
      onRow={(record: ShowItemFragment) => {
        if (isShop) {
          return {
            onClick: () => history.push(`/shops/${record.legacyId}`),
          }
        }
        return {
          onClick: () => history.push(`/shows/${record.legacyId}`),
        }
      }}
    />
  )
}
