import { getCoreRowModel, useReactTable, flexRender, functionalUpdate } from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa6'

import Button from '../Button/Button'
import { notificationSuccess } from '../Notification/Notification'

import TableFilters from './Components/TableFilters'
import TableHeader from './Components/TableHeader'
import TablePagination from './Components/TablePagination'
import TableRow from './Components/TableRow'

import type { SortingState, OnChangeFn } from '@tanstack/react-table'
import type { ReactNode } from 'react'

import './Table.scss'

export enum FiltersType {
  select = 'select',
  inputNumber = 'input-number',
  inputText = 'input-text',
  rangeNumber = 'range-number',
  rangeDate = 'range-date',
}
export type RangeFilter = { from: string; to: string }

export type Filter = {
  type: FiltersType
  name: string
  label: string
  filterOptions?: { value: string; label: string }[]
  selected?: boolean
  initialValue?: string
}

export type FiltersValue = { name: string; value: string | RangeFilter }

export type TableProps = {
  columns: any
  customPerPageOptions?: number[]
  data: any
  loading?: boolean
  onRowClick?: (item: any) => void
  onQuickAdd?: () => void
  sort?: {
    sorting: SortingState
    onSortingChange: (sorting: SortingState) => void
  }
  filters?: {
    options: Filter[]
    onFilter: (filters: FiltersValue[]) => void
    extra?: ReactNode
  }
  header?: ReactNode
  pagination?: {
    total?: number
    perPage: number
    currentPage: number
    onPageChange: (page: number) => void
    onItemPerPageChange: (perPage: number) => void
    extra?: ReactNode
  }
  selectable?: {
    selectedRows: string[]
    onSelectedRowsChange: (selectedRows: string[]) => void
  }
}

export const DEFAULT_ITEM_PER_PAGE = 25
export const DEFAULT_PER_PAGE_OPTIONS = [25, 50, 100]

const Table = (props: TableProps) => {
  const { t } = useTranslation()

  const dialogRef = useRef<HTMLDivElement>(null)

  const {
    columns,
    customPerPageOptions,
    data,
    sort,
    pagination,
    filters,
    header,
    loading,
    selectable,
    onRowClick = () => undefined,
    onQuickAdd,
  } = props
  const { total, perPage, currentPage, onPageChange, onItemPerPageChange } = pagination || {}
  const pages = total && perPage ? Math.ceil(total / perPage) : 0

  const [filtersValues, setFiltersValues] = useState<FiltersValue[]>([])

  const initialValues =
    filters?.options
      .map((filter) => {
        return { name: filter.name, value: filter.initialValue }
      })
      .filter((filter) => filter.value) ?? []

  useEffect(() => {
    if (initialValues.length > 0) {
      // compute the initial values and the prev that are not in the initialValues
      const newFilters = filtersValues.filter((filter) => !initialValues.find((f) => f.name === filter.name))
      setFiltersValues([...newFilters, ...(initialValues as FiltersValue[])])
    }
  }, [filters])

  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  const activeFilters = filters ? filters.options.filter((f) => !!f.selected).length : 0
  const perPageOptions = customPerPageOptions ?? DEFAULT_PER_PAGE_OPTIONS

  const nbColumns = useMemo(() => columns.length + 1, [columns])

  const handleFilterClose = useCallback(() => {
    setIsFiltersOpen(false)
  }, [])

  const handleFilterOpen = useCallback(() => {
    setIsFiltersOpen(true)
  }, [])

  const handleSortingChange: OnChangeFn<SortingState> = (newSorting) => {
    if (!sort) return
    sort.onSortingChange(functionalUpdate(newSorting, sort.sorting))
  }

  const handleFilterValueString = useCallback((name: string, value: string) => {
    setFiltersValues((prev) => {
      const newFilters = prev.filter((filter) => filter.name !== name)
      return [...newFilters, { name, value }]
    })
  }, [])

  const handleFilterValueRange = useCallback(
    (name: string, type: 'from' | 'to', value: string) => {
      const oldFilters = filtersValues.filter((filter) => filter.name === name)

      const oldValue = (oldFilters[0]?.value as RangeFilter) || { from: undefined, to: undefined }
      const valueRange = type === 'from' ? { from: value, to: oldValue.to } : { from: oldValue.from, to: value }

      setFiltersValues((prev) => {
        const newFilters = prev.filter((filter) => filter.name !== name)
        return [...newFilters, { name, value: valueRange }]
      })
    },
    [filtersValues]
  )

  const handleFilter = useCallback(async () => {
    selectable && selectable.onSelectedRowsChange([])
    await filters?.onFilter(filtersValues)
    notificationSuccess(t('tableFilterSuccess'))
    handleFilterClose()
  }, [filtersValues, selectable, t, filters])

  const handleResetFilters = useCallback(
    async (e) => {
      e.stopPropagation()
      selectable && selectable.onSelectedRowsChange([])
      setFiltersValues([])
      await filters?.onFilter([])
      notificationSuccess(t('tableFilterResetSuccess'))
      handleFilterClose()
    },
    [filtersValues, selectable, t, filters]
  )

  const dataMapped = data?.map((item: any) => item.id) || []

  const handleSelectAll = (e: any) => {
    if (!selectable) return
    if (e.target.checked) {
      const newSelectedRows = data
        .map((item: any) => item.id)
        .filter((id: string) => !selectable.selectedRows.includes(id))
      selectable.onSelectedRowsChange([...selectable.selectedRows, ...newSelectedRows])
    } else {
      const newSelectedRows = selectable.selectedRows.filter((id) => !data.map((item: any) => item.id).includes(id))
      selectable.onSelectedRowsChange(newSelectedRows)
    }
  }

  const handleSelectRow = (e: any) => {
    if (!selectable) return
    const idRow = e.target.id
    let newSelectedRows = []
    if (selectable.selectedRows.includes(idRow)) {
      newSelectedRows = selectable.selectedRows.filter((id) => id !== idRow)
    } else {
      newSelectedRows = [...selectable.selectedRows, idRow]
    }
    selectable.onSelectedRowsChange(newSelectedRows)
  }

  const tableData = useMemo(() => {
    return Array.isArray(data) ? data : []
  }, [data])

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    state: { sorting: sort?.sorting },
    onSortingChange: handleSortingChange,
    enableSortingRemoval: true,
    enableGlobalFilter: false,
    columns,
    data: tableData,
  })

  return (
    <div className={`table-custom ${selectable ? 'table-checkable' : ''}`}>
      <TableFilters
        activeFilters={activeFilters}
        dialogRef={dialogRef}
        filters={filters}
        filtersValues={filtersValues}
        handleFilter={handleFilter}
        handleFilterClose={handleFilterClose}
        handleFilterOpen={handleFilterOpen}
        handleFilterValueRange={handleFilterValueRange}
        handleFilterValueString={handleFilterValueString}
        handleResetFilters={handleResetFilters}
        header={header}
        isFiltersOpen={isFiltersOpen}
      />

      <div className="table-content">
        <table>
          <TableHeader
            flexRender={flexRender}
            headers={table.getHeaderGroups()}
            selectable={selectable}
            selectedData={dataMapped}
            onSelectAll={handleSelectAll}
          />

          <tbody>
            {onQuickAdd && (
              <tr key="quick-add-row" className="quick-add-row">
                <td className="quick-add-column" colSpan={nbColumns}>
                  <Button className="secondary" icon={<FaPlus />} onClick={onQuickAdd} />
                </td>
              </tr>
            )}
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                flexRender={flexRender}
                handleRowClick={onRowClick}
                handleSelectRow={handleSelectRow}
                row={row}
                selectable={selectable}
              />
            ))}
          </tbody>
        </table>
      </div>
      {pagination && onItemPerPageChange && onPageChange && perPage && currentPage && (
        <TablePagination
          currentPage={currentPage}
          extra={pagination.extra}
          loading={loading}
          pages={pages}
          perPage={perPage}
          perPageOptions={perPageOptions}
          selectable={selectable}
          onItemPerPageChange={onItemPerPageChange}
          onPageChange={onPageChange}
        />
      )}
    </div>
  )
}

export default Table
