import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { fetchShows } from '@/actions/fetch-shows'
import { SellerPayoutInfoContainer } from '@/components/SellerPayoutInfo/SellerPayoutInfo.container'
import { StripeAccountIssueBanner } from '@/components/Stripe/StripeAccountIssueBanner/StripeAccountIssueBanner'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import Loader from '@/components/ui/Loader/Loader'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import SellerNews from '@/components/Widgets/SellerNews'
import { useUser } from '@/contexts/user/User.context'
import { getFromLocalStorage, setToLocalStorage } from '@/helpers/localstorage'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { useAppSelector } from '@/reducers'
import { resetShowsState } from '@/reducers/shows'
import { trackEvent } from '@/util/eventTracker'
import BestCustomersCard from '@/views/Home/components/BestCustomersCard'
import KeyFigures from '@/views/Home/components/KeyFigures/KeyFigures'
import LastPurchasesCard from '@/views/Home/components/LastPurchasesCard'
import LastReviewsCard from '@/views/Home/components/LastReviewsCard'
import LastShippingCard from '@/views/Home/components/LastShippingCard'
import MetricsCard from '@/views/Home/components/MetricsCard'
import StreamConfig from '@/views/Home/components/StreamConfig'

import { Performances } from './components/Performances/Performances'

import './Home.scss'

const Home = () => {
  const [isSellerNewsOpen, setIsSellerNewsOpen] = useState(getFromLocalStorage('isSellerNewsOpen_') !== 'false')

  const { user: user2, isLoading } = useUser()
  const { sellerConfig } = user2 || {}
  const { hasBankAccountConfiguredInStripe, canAccessHomeMetrics = false, country } = sellerConfig || {}

  // TODO: Remove this and use user from the context once fixedFee & percentageFee fields will have been added to the user object
  const user = useAppSelector((state) => state.authentication.user)

  useEffect(() => {
    setToLocalStorage('isSellerNewsOpen_', JSON.stringify(isSellerNewsOpen))
  }, [isSellerNewsOpen])

  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  useDocumentTitle(t('sideMenuHomeLabel'))
  useEffect(() => {
    trackEvent('HOME_OPEN')
    return
  }, [])

  useEffect(() => {
    dispatch(fetchShows())

    return () => {
      dispatch(resetShowsState())
    }
  }, [])

  if (!user) return null
  if (isLoading) return <Loader />

  return (
    <>
      <ViewContainer
        id="home"
        leftContent={`${t('homePageHeaderSubTitle', {
          user,
          comma: user.firstName ? ',' : '',
        })} !`}
        // @todo : find a way to fix this, for now on mobile if we have a right content the menu is nos displayed
        rightContent={
          <div className="right-content">
            <Button
              className="toggle-action toggle-seller-news-action secondary"
              label={isSellerNewsOpen ? t('homeSellerNewClose') : t('homeSellerNewOpen')}
              onClick={() => setIsSellerNewsOpen(!isSellerNewsOpen)}
            />
          </div>
        }
      >
        <div className="content-home">
          {(country?.iso2Code === 'DE' || country?.iso2Code === 'AT') && dayjs().isBefore(dayjs('2024-10-01')) && (
            <Alert
              emphasis="low"
              type="info"
              button={
                <Button onClick={() => window.open(t('septemberDachCampaignLink'), '_blank')}>
                  {t('commonSeeMore')}
                </Button>
              }
            >
              <Trans i18nKey="septemberDachCampaign" />
            </Alert>
          )}

          {(country?.iso2Code === 'FR' || country?.iso2Code === 'BE') && dayjs().isBefore(dayjs('2024-09-29')) && (
            <Alert
              emphasis="low"
              type="info"
              button={
                <Button onClick={() => window.open(t('septemberVintageFairCampaignLink'), '_blank')}>
                  {t('commonSeeMore')}
                </Button>
              }
            >
              <Trans i18nKey="septemberVintageFairCampaign" />
            </Alert>
          )}

          {!canAccessHomeMetrics ? (
            <div className="new-content">
              <LastShippingCard />

              <StreamConfig />
            </div>
          ) : (
            <>
              {/*
                Below we need to explicitly check if hasBankAccountConfiguredInStripe is false because it is retrieved
                asynchronously and then will be undefined until then
              */}
              {hasBankAccountConfiguredInStripe === false && (
                <Alert
                  type="warning"
                  button={
                    <Button className="action secondary" href="/account">
                      {t('homeMissingBankAccountInfoLinkLabel')}
                    </Button>
                  }
                >
                  <span className="message">{t('homeMissingBankAccountInfoMessage')}</span>
                </Alert>
              )}
              <StripeAccountIssueBanner />

              <h2>{t('homeThisMonthTitle')}</h2>
              <Performances />

              <h2>{t('homeWhatsNewSectionTitle')}</h2>
              <div className="new-content">
                <LastShippingCard />
                <LastReviewsCard />
              </div>

              <h2>{t('homePerformancesSectionTitle')}</h2>
              <div className="perf-content">
                <KeyFigures />
                <MetricsCard />
              </div>

              <h2>{t('homeBuyersSectionTitle')}</h2>
              <div className="buyers-content">
                <BestCustomersCard />
                <LastPurchasesCard />
              </div>

              <div className="config-content">
                <StreamConfig />
                <SellerPayoutInfoContainer user={user} />
              </div>
            </>
          )}
        </div>
      </ViewContainer>
      {isSellerNewsOpen && (
        <div className="seller-news">
          <SellerNews />
        </div>
      )}
    </>
  )
}

export default Home
