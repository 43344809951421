import { memo } from 'react'

import { DEFAULT_USERTYPE_COLOR, getUsernameColorClass } from '@/views/Show/components/ChatBox/chatbox.helper'

import Avatar from '../Avatar/Avatar'

import type { UserType } from '@/views/Show/components/ChatBox/chatbox.helper'
import type { ReactNode } from 'react'

export interface UserProps {
  src: string | undefined | null
  children: React.ReactNode
  withDot?: boolean
  isSmall?: boolean
  userType?: UserType
  message?: ReactNode
}

const User = (props: UserProps) => {
  const { src, children, withDot, isSmall, userType, message } = props
  const classNames = `relative flex ${message ? 'items-start' : 'items-center'}`
  const childrenClassNames = `font-semibold pl-1 ${isSmall ? '' : ''} ${userType ? getUsernameColorClass(userType) : DEFAULT_USERTYPE_COLOR}`

  return (
    <div className={classNames}>
      <Avatar isSmall={isSmall} src={src} withDot={withDot}>
        {children}
      </Avatar>
      <span>
        <span className={childrenClassNames}>{children}</span>
        {message && <span className="text-gray-500">: {message}</span>}
      </span>
    </div>
  )
}

export default memo(User)
