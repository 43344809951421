import dayjs from 'dayjs'
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Avatar from '@/components/ui/Avatar/Avatar'
import Button from '@/components/ui/Button/Button'
import ContactIcon from '@/components/ui/Icons/ContactIcon/ContactIcon'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { useUser } from '@/contexts/user/User.context'

import { useReportOrderIssueToCustomerMutation } from '../../../operations.generated'

import './UsernameAndContactColumn.scss'

type UsernameAndContactColumn = {
  username: string
  userId?: string
  createdAt?: Date
  avatarUrl?: string | null
  name?: string
}

const UsernameAndContactColumn = (props: UsernameAndContactColumn) => {
  const { username, userId, createdAt, avatarUrl, name } = props
  const { t } = useTranslation()

  const MAX_DAYS = 30

  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canBeContactedByCustomersForOrderIssues } = sellerConfig || {}

  const [reportOrderIssueToCustomer] = useReportOrderIssueToCustomerMutation()

  const handleContactCustomer = useCallback(() => {
    if (!userId) return
    reportOrderIssueToCustomer({
      variables: {
        input: {
          customerId: userId,
        },
      },
      onCompleted: async (data) => {
        const email = data.reportOrderIssueToCustomer.customerEmailAlias
        await navigator.clipboard.writeText(email)
        notificationSuccess(t('reportIssueToCustomerCopySuccess'))
        window.open(`mailto:${email}`)
      },
      onError: (error) => {
        notificationDanger(error?.message)
      },
    })
  }, [userId])

  return (
    <div className="username-and-contact-column">
      <div>
        {avatarUrl !== undefined ? (
          <div className="avatar-and-name">
            <Avatar src={avatarUrl}>{username}</Avatar>
            <div className="name-and-username">
              <p className="username">@{username}</p>
              {name && <span>{name}</span>}
            </div>
          </div>
        ) : (
          <p>@{username}</p>
        )}
      </div>

      {canBeContactedByCustomersForOrderIssues &&
        createdAt &&
        dayjs(createdAt).isAfter(dayjs().subtract(MAX_DAYS, 'day')) && (
          <Button icon={<ContactIcon />} tooltip={t('reportIssueToCustomerTooltip')} onClick={handleContactCustomer} />
        )}
    </div>
  )
}

export default memo(UsernameAndContactColumn)
