import {
  useCurrentGiveawayParticipantsQuery,
  useShowCurrentGiveawayUpdateParticipantCountSubscription,
} from '@/views/Show/operations.generated'

type useGetParticipantsProps = {
  showId: number
}

const GiveawayParticipantsCount = (props: useGetParticipantsProps) => {
  const { showId } = props

  const { data, updateQuery } = useCurrentGiveawayParticipantsQuery({
    skip: !showId,
    variables: {
      showId: `Show|${showId}`,
    },
  })

  useShowCurrentGiveawayUpdateParticipantCountSubscription({
    skip: !showId,
    variables: {
      showId: `Show|${showId}`,
    },
    onData: (data) => {
      updateQuery((prev) => {
        if (prev?.node.__typename === 'Show' && prev.node.giveaway) {
          if (data.data.data?.showCurrentGiveawayUpdate.__typename === 'ShowGiveawayParticipantsCount') {
            const newData = {
              ...prev,
              node: {
                ...prev.node,
                giveaway: {
                  ...prev.node.giveaway,
                  participants: {
                    totalCount: data.data.data.showCurrentGiveawayUpdate.participantsCount,
                  },
                },
              },
            }
            return newData
          }
        }
        return prev
      })
    },
  })

  const totalParticipantCount = data?.node.__typename === 'Show' ? data?.node?.giveaway?.participants?.totalCount : null

  return <data>{totalParticipantCount ?? '?'}</data>
}

export default GiveawayParticipantsCount
