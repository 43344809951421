import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Empty from '@/components/Empty/Empty'

import SlotsList from '../SlotsList/SlotsList'

import ShowCard from './../ShowCard'

import type { Show } from '@/types'

import style from './style.module.scss'

type DayProps = {
  selectedCategory: string | number
  userId: number
  dailyShows: Show[]
  isDateBeforeToday: boolean
  allStartHours: string[]
  date: moment.Moment
  isSuperSeller: boolean
  parentCategories: any
}

const Day = (props: DayProps) => {
  const { selectedCategory, dailyShows, isDateBeforeToday, allStartHours, date, parentCategories } = props
  const { t } = useTranslation()

  const slotsBeginAt = 10
  const sortedShows = dailyShows.sort((a, b) => moment(a.startAt).diff(b.startAt))
  const firstShowIsAfterSlotListStart = moment(sortedShows[0]?.startAt).isAfter(moment(date).set('hour', slotsBeginAt))

  const hasCategory = selectedCategory !== 0 && typeof selectedCategory !== 'string'
  const hasShows = allStartHours.length > 0

  return (
    <div className={style.calendar__day_container}>
      <div className={style.calendar__day_body}>
        {hasShows && firstShowIsAfterSlotListStart && !isDateBeforeToday && hasCategory && (
          <div className={style.calendar__first_slotslist}>
            <SlotsList
              category={selectedCategory}
              from={moment(date).set('hour', slotsBeginAt).set('minutes', 0)}
              shows={dailyShows}
              showsToGet={100}
            />
          </div>
        )}
        {selectedCategory !== 0 && (
          <>
            {allStartHours.length === 0 && !isDateBeforeToday && (
              <div className={style.calendar__empty_day}>
                {selectedCategory !== 0 && typeof selectedCategory !== 'string' && (
                  <div className={style.calendar__empty_day_slots_list}>
                    <SlotsList
                      category={selectedCategory}
                      from={moment(date).set('hour', slotsBeginAt).set('minutes', 0)}
                      shows={dailyShows}
                      showsToGet={100}
                    />
                  </div>
                )}
                <div className={style.empty_day__empty_container}>
                  <Empty description={t('calendarDayNoShow')} type="save" />
                </div>
                <div></div>
              </div>
            )}

            {allStartHours.length === 0 && isDateBeforeToday && (
              <div className={style.calendar__past_day}>
                <span>{t('calendarDayAllShowsOver')}</span>
              </div>
            )}

            {allStartHours.sort().map((startHour: any, iHours) => {
              const showsByStartHour = dailyShows.filter(
                (show: any) => moment(show.startAt).format('HH:mm').substring(0, 2) === startHour.substring(0, 2)
              )

              const allStartMinutes: string[] = []

              showsByStartHour.forEach((show: any) => {
                const minutes = moment(show.startAt).format('HH:mm').substring(3, 5)
                if (!allStartMinutes.includes(minutes)) allStartMinutes.push(minutes)
              })

              return (
                <div key={iHours}>
                  {allStartMinutes.sort().map((startMinutes) => {
                    const showsStartingThisHourMinutes = dailyShows.filter(
                      (show: any) => moment(show.startAt).format('HH:mm') === `${startHour}:${startMinutes}`
                    )

                    return (
                      <div
                        key={`${moment(dailyShows[0].startAt).format('DD/MM/YYYY')}-${startHour}h${startMinutes}`}
                        className={style.slot__container}
                      >
                        <span className="callout_3">{`${startHour}h${startMinutes}`}</span>
                        {showsStartingThisHourMinutes.map((show: any) => {
                          const parentCategory = parentCategories?.parentCategoriesOrderedByPosition?.edges?.find(
                            (ef: any) =>
                              ef.node.allCategories.some(
                                (category: any) => category.id === `Category|${show.categoryId}`
                              )
                          )?.node

                          return <ShowCard key={show.slug} show={{ ...show, parentCategory }} />
                        })}
                        {selectedCategory !== 0 && typeof selectedCategory !== 'string' && (
                          <SlotsList
                            category={selectedCategory}
                            from={moment(date).set('hour', startHour).set('minutes', parseInt(startMinutes))}
                            shows={dailyShows}
                            showsToGet={100}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}

export default Day
