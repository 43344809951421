import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'
import {
  FaArrowUp,
  FaBell,
  FaGavel,
  // FaThumbtack
} from 'react-icons/fa6'
import { useHistory } from 'react-router-dom'

import ColumnImageAndName from '@/components/ProductsColumns/ColumnImageAndName'
import Link from '@/components/ui/Link/Link'
import Popover from '@/components/ui/Popover/Popover'
import { useShow } from '@/contexts/show/Show.context'
import { ProductSortColumn, SortDirection, ProductType } from '@/network/graphql/types.generated'

import Card from '../../ui/Card/Card'
import Table from '../../ui/Table/Table'
import DuplicateButton from '../DuplicateButton/DuplicateButton'
import LaunchActivityButton from '../LaunchActivityButton/LaunchActivityButton'

import type { Product } from '@/network/graphql/types.generated'
import type { SortingState } from '@tanstack/react-table'

import './Products.scss'

interface ProductsProps {
  isActivityRunning: boolean
  isShowBroadcasting?: boolean
  selectedRows: string[]
  handleUpdateProductToFirstPosition: (productId: string, productType: ProductType) => Promise<void>
  onProductEditClick: (product: Product) => void
  openGiveawayModal: (product: Product) => void
  onProductDuplicateClick: (product: Product) => void
  onSelectedRows: (selectedProducts: string[]) => void
}

const sortingOptionsMap: { [key: string]: ProductSortColumn } = {
  name: ProductSortColumn.Name,
  followingUsersCount: ProductSortColumn.FollowingUsersCount,
  availableQuantity: ProductSortColumn.AvailableQuantity,
  createdAt: ProductSortColumn.CreatedAt,
}

function GiveawayProducts(props: ProductsProps) {
  const {
    itemsPerPage,
    handleSetItemsPerPage,
    loadingGiveawayProducts,
    giveaways,
    totalGiveaways,
    fetchGiveawayProducts,
    setGiveawaySortBy,
    giveawaySortBy,
    currentPageGiveaway: currentPage,
    handleGiveawayPageChange,
  } = useShow()

  const {
    isActivityRunning,
    isShowBroadcasting,
    selectedRows,
    handleUpdateProductToFirstPosition,
    onProductEditClick,
    openGiveawayModal,
    onProductDuplicateClick,
    onSelectedRows,
  } = props

  const history = useHistory()
  const { t } = useTranslation()

  const [sorting, setSorting] = useState<SortingState>([])
  const [loading, setLoading] = useState(false)

  const onHandleUpdateProductToFirstPosition = async (id: string) => {
    await handleUpdateProductToFirstPosition(id, ProductType.Giveaway)
    handleGiveawayPageChange(1)
  }
  const handleQuickAdd = () => {
    const currentUrl = window.location.pathname
    const url = `${currentUrl}products/new?productType=giveaway`
    history.push(url)
  }

  const giveawayColumns = [
    {
      header: t('giveawayProductNameColumn'),
      id: 'name',
      accessorKey: 'name',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const { name, images, description, color, id } = record
        return (
          <ColumnImageAndName
            color={color}
            description={description}
            id={id}
            image={images?.[0]?.webPUrl}
            name={name}
            onClick={() => onProductEditClick(record)}
          />
        )
      },
    },

    {
      header: t('giveawayProductAudienceColumn'),
      id: 'giveawayAudience',
      enableColumnSort: false,
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        return (
          <div className="column-audience" onClick={() => onProductEditClick(record)}>
            <span className={`audience ${record.giveawayAudience && 'audience-selected'}`}>
              {record.giveawayAudience ? t(`giveawayAudience${record.giveawayAudience}`) : '-'}
            </span>
          </div>
        )
      },
    },
    {
      header: (
        <div className="column-quantity">
          {t('giveawayProductQuantityColumn')}
          <Popover
            content={
              <div className="following-user-tooltip">
                <p>
                  <FaBell className="text-gray-400" size={16} />
                  {t('showProductsListTableColumnInfoHelperPopoverProductFollowersNotice')}
                </p>
                <p>
                  <FaGavel className="text-gray-400" size={16} />
                  {t('showProductsListTableColumnInfoHelperPopoverAutoBidNotice')}
                </p>
              </div>
            }
          >
            <FaInfoCircle />
          </Popover>
        </div>
      ),
      id: 'availableQuantity',
      accessorKey: 'availableQuantity',
      cell: (item: any) => {
        const record = item.row.original
        const { availableQuantity } = record
        return (
          <div className="column-quantity" onClick={() => onProductEditClick(record)}>
            <span className={`quantity ${availableQuantity === 0 && 'sold-out'}`}>{availableQuantity}</span>
          </div>
        )
      },
    },
    {
      header: t('giveawayLaunchColumn'),
      id: 'launch',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { status, id } = record
        const isDisabled = record.availableQuantity === 0 || !isShowBroadcasting || isActivityRunning

        return (
          <div className="column-action">
            <Popover content={t('showProductsListPinnedItemTooltip')}>
              <span className="pin">
                <FaArrowUp className="pin" onClick={() => onHandleUpdateProductToFirstPosition(id)} />
              </span>
            </Popover>
            <DuplicateButton product={record} onProductDuplicateClick={onProductDuplicateClick} />
            <LaunchActivityButton
              disabled={isDisabled}
              product={record}
              status={status}
              disabledReason={
                record.availableQuantity === 0
                  ? 'no-quantity'
                  : isActivityRunning
                    ? 'activity-running'
                    : 'not-broadcasting'
              }
              onLaunch={openGiveawayModal}
            />
          </div>
        )
      },
    },
  ]

  const handleItemPerPageChange = async (itemsPerPage: number) => {
    handleSetItemsPerPage(itemsPerPage)
    handleGiveawayPageChange(1)
  }

  const handlePageChange = async (page: number) => {
    handleGiveawayPageChange(page)
    setLoading(true)
    await fetchGiveawayProducts(page)
    setLoading(false)
  }

  const handleSortingChange = async (sort: SortingState) => {
    setSorting(sort)
    setLoading(true)
    handleGiveawayPageChange(1)
    if (!sort[0]) {
      setGiveawaySortBy(undefined)
    } else {
      setGiveawaySortBy({
        column: sortingOptionsMap[sort[0]?.id],
        direction: sort[0]?.desc ? SortDirection.Desc : SortDirection.Asc,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (giveawaySortBy === undefined) {
      setSorting([])
    }
  }, [giveawaySortBy])

  return (
    <Card className="giveaway-products products-list" noPadding={true}>
      {giveaways && giveaways.length === 0 && totalGiveaways === 0 && !loadingGiveawayProducts ? (
        <div className="no-products">
          <svg fill="none" height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg">
            <path
              clipRule="evenodd"
              d="M52.5783 29.8722V44.9368C52.5783 51.3861 48.6399 55.6938 42.7568 55.6938H18.7569C12.8984 55.6938 8.95996 51.3861 8.95996 44.9368V29.8722C8.95996 24.3338 11.8646 20.3707 16.443 19.3615C17.1815 19.1892 17.9692 19.1154 18.7815 19.1154H42.7568C43.5691 19.1154 44.3322 19.1892 45.0706 19.3615C49.6491 20.3461 52.5783 24.3338 52.5783 29.8722Z"
              fill="black"
              fillRule="evenodd"
              opacity="0.4"
            />
            <path
              clipRule="evenodd"
              d="M39.643 22.1908H21.8952C19.6798 22.1908 17.6859 21.0831 16.4552 19.36C15.6183 18.2278 15.126 16.8001 15.126 15.2493C15.126 11.4339 18.1783 8.30774 21.8952 8.30774C26.4737 8.30774 29.206 11.68 30.7814 14.8062C32.3322 11.68 35.0645 8.30774 39.643 8.30774C43.3845 8.30774 46.4122 11.4339 46.4122 15.2493C46.4122 16.8001 45.9199 18.2278 45.0829 19.36C43.8522 21.0831 41.8829 22.1908 39.643 22.1908ZM28.3199 18.4985C27.4337 15.9385 25.4645 12 21.8952 12C20.1967 12 18.8183 13.4524 18.8183 15.2493C18.8183 17.0462 20.1967 18.4985 21.8952 18.4985H28.3199ZM42.7199 15.2493C42.7199 13.4524 41.3414 12 39.643 12C36.1229 12 34.1291 15.9385 33.2183 18.4985H39.643C41.3414 18.4985 42.7199 17.0462 42.7199 15.2493Z"
              fill="black"
              fillRule="evenodd"
            />
            <path
              d="M28.8705 25.1451C28.8705 24.8732 29.0911 24.6528 29.3628 24.6528C29.7101 24.6528 30.2116 24.6528 30.7691 24.6528C31.3266 24.6528 31.828 24.6528 32.1754 24.6528C32.4471 24.6528 32.6677 24.8732 32.6677 25.1451V34.7092H52.5781V38.5061H32.6677V55.6926H28.8705V38.5061H8.95996V34.7092H28.8705V25.1451Z"
              fill="black"
            />
          </svg>

          <p>{t('noGiveawayProducts')}</p>
          <Link onClick={handleQuickAdd}>{t('quickAddGiveawayProduct')}</Link>
        </div>
      ) : (
        <Table
          columns={giveawayColumns}
          data={giveaways}
          loading={loadingGiveawayProducts || loading}
          pagination={{
            total: totalGiveaways,
            currentPage: currentPage,
            perPage: itemsPerPage,
            onPageChange: handlePageChange,
            onItemPerPageChange: handleItemPerPageChange,
          }}
          selectable={{
            selectedRows,
            onSelectedRowsChange: onSelectedRows,
          }}
          sort={{
            sorting,
            onSortingChange: handleSortingChange,
          }}
          onQuickAdd={handleQuickAdd}
        />
      )}
    </Card>
  )
}

export default memo(GiveawayProducts)
