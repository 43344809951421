import { message, Modal } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'

import { useAppSelector } from '../../../reducers'

import {
  useAcceptStripeTermsOfServicesMutation,
  useGetShouldAcceptStripeTermsOfServicesQuery,
} from './operations.generated'

export const StripeTermsOfServicesModal = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const user = useAppSelector((state) => state.authentication?.user)
  const { data, refetch } = useGetShouldAcceptStripeTermsOfServicesQuery({
    skip: !user,
    fetchPolicy: 'network-only',
  })
  const [acceptStripeTermsOfServices, { loading }] = useAcceptStripeTermsOfServicesMutation()

  const shouldAcceptStripeTermsOfServices = useMemo(() => {
    return !!data?.viewer?.sellerConfig?.shouldAcceptStripeTermsOfServices
  }, [data])

  useEffect(() => {
    if (shouldAcceptStripeTermsOfServices) {
      setIsModalOpen(true)
    }
  }, [shouldAcceptStripeTermsOfServices])

  const onSubmit = () => {
    acceptStripeTermsOfServices({
      onCompleted: (data) => {
        if (data.acceptStripeTermsOfServices?.ok) {
          refetch().then(() => {
            setIsModalOpen(false)
          })
        }
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }

  return (
    <Modal closable={false} closeIcon={null} footer={null} open={isModalOpen} zIndex={2000}>
      <div className="flex flex-column items-center w-full">
        <div className="title_2 pt-3 pb-[24px] text-center">{t('StripeServicesAgreementTitleModal')}</div>
        <div className="body_4 text-center">{t('StripeServicesAgreementNoticeModal')}</div>
        <div className="flex justify-center w-full pt-[32px]">
          <Button
            className="validate-action accept-stripe-services-agreement-action primary"
            isLoading={loading}
            label={t('StripeServicesAgreementButtonLabelModal')}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  )
}
