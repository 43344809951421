import { useMemo, useState, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { isMobileOnly } from 'react-device-detect'
import { Trans, useTranslation } from 'react-i18next'
import { FaCalendar, FaDownload, FaPhone } from 'react-icons/fa6'
import { useLocation, useHistory, Link as RouterLink } from 'react-router-dom'

import CreateShowButton from '@/components/CreateShowButton/CreateShowButton'
import CreateShowModal from '@/components/CreateShowModal/CreateShowModal'
import { handleVideoUploadTranscodingSubscriptionData } from '@/components/CreateShowModal/VideoUploader/videoUploader.helper'
import { BreakerCannotCreateShow } from '@/components/SellerTier/BreakerCannotCreateShow/BreakerCannotCreateShow'
import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'
import Link from '@/components/ui/Link/Link'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { useUser } from '@/contexts/user/User.context'
import { getFromLocalStorage, removeFromLocalStorage } from '@/helpers/localstorage'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { trackEvent } from '@/util/eventTracker'

import { useOnPreShowTeaserVideoTranscodingStatusChangedSubscription } from '../Show/operations.generated'

import { ShowsSalesAveragePerDayOfTheWeek } from './components/charts/ShowsSalesAveragePerDayOfTheWeek'
import { LatestShowsPerformances } from './components/LatestShowsPerformances'
import { LiveShowCard } from './components/LiveShowCard'
import { ShowsList } from './components/ShowList/ShowsList'
import { useShows } from './hooks/use-shows'

import type { Show } from '@/network/graphql/types.generated'

import './Shows.scss'

const CSVHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'Date', key: 'date' },
  { label: 'Show', key: 'name' },
  { label: 'Show total', key: 'total' },
  { label: 'Commission', key: 'commission' },
  { label: 'Refunded', key: 'refunded' },
  { label: 'Shipping label', key: 'shipping' },
  { label: 'Net revenue', key: 'revenue' },
  { label: 'Currency', key: 'currency' },
]

const FASHION_PARENT_CATEGORY = 'fashion'

export const Shows = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const isQueryParamOpenModal = query.get('action') === 'create'

  const [showCreationModal, setShowCreationModal] = useState<boolean>(isQueryParamOpenModal)
  const [showCreated, setShowCreated] = useState<Show | null>(null)
  const [firstSellerConnexionModalIsOpen, setFirstSellerConnexionModalIsOpen] = useState<boolean>(
    !!getFromLocalStorage('isSellerRegistering')
  )

  const { shows, highlightedShow, loading } = useShows()
  const { user } = useUser()
  const { sellerConfig } = user || {}
  const { canAccessHomeMetrics, favoriteParentCategory, country } = sellerConfig || {}

  useDocumentTitle(t('sideMenuMyShowsTitle'))

  useEffect(() => {
    if (isQueryParamOpenModal) history.replace('/shows')
  }, [showCreationModal])

  const CSVData = useMemo(() => {
    return shows.map((s) => ({
      id: s.legacyId,
      date: s.startAt,
      name: s.name,
      total: s.totalAmountOrdersSuccessInCurrencyCents / 100,
      commission: s.totalCommissionInCurrencyCents / 100,
      shipping: s.totalDeductedShippingCostInCurrencyCents / 100,
      revenue: s.totalNetIncomeInCurrencyCents / 100,
      refunded: s.totalAmountRefundedInCurrencyCents / 100,
      currency: s.currency,
    }))
  }, [shows])

  const onShowCreated = (show: Show) => {
    setShowCreated(show)
  }

  // Subscribe to update the show teaser video url
  useOnPreShowTeaserVideoTranscodingStatusChangedSubscription({
    // We do not want to subscribe to this event if we already have the transcoding info
    skip: !showCreated?.id || !!showCreated?.preShowTeaserVideoInfo?.transcodingInfo,
    variables: {
      showId: showCreated?.id as string,
    },
    onData: (data) => {
      handleVideoUploadTranscodingSubscriptionData(data?.data)
    },
  })

  return (
    <ViewContainer isLoading={loading} leftContent={t('showsPageHeaderTitle')}>
      {showCreationModal && <CreateShowModal setIsModalVisible={setShowCreationModal} onShowCreated={onShowCreated} />}

      {!isMobileOnly ? (
        <>
          <div className="w-full mb-4">
            <BreakerCannotCreateShow className="mb-4" />

            {canAccessHomeMetrics && (
              <>
                <p className="text-lg font-semibold mb-2">{t('ShowsListMyPerformance')}</p>
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <LatestShowsPerformances shows={shows} />

                  <div className="w-full flex flex-col">
                    <ShowsSalesAveragePerDayOfTheWeek />
                  </div>
                </div>
              </>
            )}
          </div>

          {highlightedShow && (
            <div className="mb-4">
              <h2 className="text-lg font-semibold mb-2">
                {highlightedShow.isBroadcasting ? t('ShowsListShowInProgress') : t('ShowsListNextShow')}
              </h2>

              <LiveShowCard showId={highlightedShow.legacyId} />
            </div>
          )}

          <div className="w-full">
            <p className="text-lg font-semibold mb-2">{t('ShowsListMyShows')}</p>

            <div className="w-full layout-card">
              <div className="p-4 flex items-center justify-between border-b border-slate-200">
                <div className="flex gap-4 items-center">
                  <CreateShowButton handleOnClick={() => setShowCreationModal(true)} />
                  <RouterLink
                    className="flex items-center gap-2 bg-slate-100 hover:bg-slate-200 focus:bg-slate-300 active:bg-slate-300 text-slate-700 px-4 py-2 rounded-md"
                    to="/calendar"
                  >
                    <FaCalendar size={18} />
                    {t('sideMenuCalendarTitle')}
                  </RouterLink>
                </div>

                <CSVLink
                  className="cursor-pointer flex items-center gap-1"
                  data={CSVData}
                  filename={`shows-export-${new Date().getTime()}`}
                  headers={CSVHeaders}
                >
                  <Button
                    className="export-shows-action"
                    icon={<FaDownload className="h-[16x] w-[16px]" />}
                    label={t('ShowsListExportMe')}
                  />
                </CSVLink>
              </div>
              <ShowsList mobile={false} />
            </div>
          </div>
        </>
      ) : (
        <>
          <BreakerCannotCreateShow className="mb-2" />

          <ShowsList mobile={true} />
          <div className="fixed bottom-0 left-0 right-0 p-2 text-base">
            <CreateShowButton handleOnClick={() => setShowCreationModal(true)} />
          </div>
        </>
      )}
      <Dialog
        isOpen={firstSellerConnexionModalIsOpen}
        modal={true}
        title={t('firstSellerConnexionTitle')}
        onClose={() => {
          trackEvent('NEW_SELLER_CLOSED_ONBOARDING_DIALOG', { userId: user?.id })
          removeFromLocalStorage('isSellerRegistering')
          setFirstSellerConnexionModalIsOpen(false)
        }}
      >
        {(favoriteParentCategory && favoriteParentCategory.slug === FASHION_PARENT_CATEGORY) ||
        country?.iso2Code === 'FR' ? (
          <>
            <p>
              <Trans
                i18nKey="firstSellerConnexionFashionHelp"
                components={{
                  1: <Link href={t('firstSellerConnexionUniversityLink')} rel="noreferrer" target="_blank" />,
                }}
              />
            </p>

            <div className="shows-onboarding-fashion-actions">
              <Button
                className="primary request-onboarding-session-action"
                href={t('signUpFormRequestOnboardingSessionLink')}
                icon={<FaPhone />}
                label={t('onboardingModalFormRequestOnboardingSessionLinkLabelFashion')}
                target="_blank"
                onClick={() => {
                  trackEvent('NEW_SELLER_OPENED_ONBOARDING', { userId: user?.id })
                  removeFromLocalStorage('isSellerRegistering')
                  setFirstSellerConnexionModalIsOpen(false)
                  setShowCreationModal(true)
                }}
              />
            </div>
          </>
        ) : (
          <>
            <p>{t('firstSellerConnexionContent')}</p>
            <p>
              <Trans
                i18nKey="firstSellerConnexionHelp"
                components={{
                  1: <Link href={t('firstSellerConnexionUniversityLink')} rel="noreferrer" target="_blank" />,
                }}
              />
            </p>

            <div className="shows-onboarding-actions">
              <Button
                className="secondary request-onboarding-session-action"
                href={t('signUpFormRequestOnboardingSessionLink')}
                icon={<FaPhone />}
                label={t('onboardingModalFormRequestOnboardingSessionLinkLabel')}
                target="_blank"
              />
              <Button
                className="primary request-onboarding-session-action"
                label={t('firstSellerConnexionCTA')}
                onClick={() => {
                  removeFromLocalStorage('isSellerRegistering')
                  setFirstSellerConnexionModalIsOpen(false)
                  setShowCreationModal(true)
                }}
              />
            </div>
          </>
        )}
      </Dialog>
    </ViewContainer>
  )
}
