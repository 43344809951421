import axios, { isAxiosError } from 'axios'

import Config from '@/config/config'
import { store } from '@/reducers'

import { refreshAuthToken } from './refresh-auth-token'

import type { AxiosRequestConfig, AxiosResponse } from 'axios'

interface InternalRequest extends AxiosRequestConfig {
  dispatch?(any: any): void
}

const internalRequest = async ({
  dispatch = () => {},
  url,
  ...props
}: InternalRequest): Promise<AxiosResponse<any>> => {
  const _store = store.getState().authentication
  const userId = _store.user?.id

  const refreshAuthTokenResponse = await refreshAuthToken({
    userId,
    refreshToken: _store.refreshToken,
    tokenExpiresAt: _store.tokenExpiresAt,
  })

  if (refreshAuthTokenResponse?.token) {
    dispatch({ type: 'authentication/refreshAuthToken', payload: refreshAuthTokenResponse })
    if (props.headers && props.headers.authorization) {
      props.headers.authorization = refreshAuthTokenResponse.token
    }
  }

  try {
    const result = await axios({
      validateStatus: (status) => status >= 200 && status < 300,
      url: `${Config.SERVER_URL}${url}`,
      ...props,
    })

    return result
  } catch (error: Error | unknown) {
    if (isAxiosError(error)) {
      const { response } = error

      if (response?.status === 401) dispatch({ type: 'authentication/logout' })
    }

    throw error
  }
}

export { internalRequest }
