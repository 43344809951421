import { useCallback, useRef, useState } from 'react'

import Carousel from '@/components/Carousel'
import Dialog from '@/components/ui/Dialog/Dialog'

import ShowEventApplicationFormStep1 from './components/applicationSteps/ShowEventApplicationFormStep1'
import ShowEventApplicationFormStep2 from './components/applicationSteps/ShowEventApplicationFormStep2'
import ShowEventApplicationFormStep3 from './components/applicationSteps/ShowEventApplicationFormStep3'
import ApplyForShowEventModalHeader from './components/ApplyForShowEventModalHeader'

import './ApplyForShowEvent.scss'

type ApplyForShowEventModalProps = {
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  showId: string
  showGlobalParentCategoryId: string
  showCategorySlug: string
}

const ApplyForShowEventModal = (props: ApplyForShowEventModalProps) => {
  const { setIsOpen, isOpen, showId, showGlobalParentCategoryId, showCategorySlug } = props

  const [selectedSlide, setSelectedSlide] = useState<number>(0)
  const [featuredShowTargetInEuros, setFeaturedShowTargetInEuros] = useState<number>(0)

  const handleNextSlide = useCallback(() => {
    setSelectedSlide((prev) => prev + 1)
  }, [])

  const handlePreviousSlide = useCallback(() => {
    setSelectedSlide((prev) => prev - 1)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const carousel = useRef<any>()

  return (
    <Dialog
      className="apply-for-show-event custom-dialog"
      isOpen={isOpen}
      title={<ApplyForShowEventModalHeader handlePreviousSlide={handlePreviousSlide} selectedSlide={selectedSlide} />}
      modal
      onClose={handleClose}
    >
      <Carousel ref={carousel} accessibility={false} dots={false} selectedSlide={selectedSlide} swipe={false}>
        <ShowEventApplicationFormStep1
          handleNextSlide={handleNextSlide}
          setFeaturedShowTargetInEuros={setFeaturedShowTargetInEuros}
          showId={showId}
        />
        <ShowEventApplicationFormStep2
          featuredShowTargetInEuros={featuredShowTargetInEuros}
          handleNextSlide={handleNextSlide}
          showCategorySlug={showCategorySlug}
          showId={showId}
          showParentCategoryGlobalId={showGlobalParentCategoryId}
        />
        <ShowEventApplicationFormStep3 handleCloseModal={handleClose} />
      </Carousel>
    </Dialog>
  )
}
export default ApplyForShowEventModal
