import dayjs from 'dayjs'
import reactStringReplace from 'react-string-replace'

import User from '../../../../../../components/ui/User/User'
import { UserType } from '../../chatbox.helper'
import { useChatBoxContext } from '../../ChatBoxProvider'
import { ViewerActionsDropdown } from '../ViewerActionsDropdown/ViewerActionsDropdown'

import type { ItemFragment } from '../../../../../Shows/operations.generated'
import type { EmoteMap } from '../../hooks/useChat'

export interface CommentItemProps {
  item: ItemFragment
  emoteMap: EmoteMap | undefined
}

const EMOJI_REGEX = new RegExp(/(:.*?:)/, 'gi')

export const CommentItem = ({ item, emoteMap }: CommentItemProps) => {
  const { moderators, fontSize } = useChatBoxContext()

  const username = item.user.username ?? item.user.id

  const userType: UserType = item.user.isStaff
    ? UserType.Admin
    : moderators.some((moderator) => moderator.id === item.user.id)
      ? UserType.Moderator
      : UserType.User

  const renderText = () => {
    if (!emoteMap) return item.text
    return reactStringReplace(item.text, EMOJI_REGEX, (match, i) => {
      const emote = emoteMap[match]
      return emote ? <img key={i} alt={match} className="inline" src={emote.webPUrl} width="18" /> : match
    })
  }

  if (!item) return null

  const { user, isAuthoredByShowSeller, date } = item
  if (!user) return null

  return (
    <div
      className={`flex hover:bg-slate-100 py-1 break-words flex-row group/viewerOrComment chat-comment-message chat-comment-message-${fontSize}`}
    >
      <div className="basis-5/6">
        <User message={renderText()} src={user.avatarUrl} userType={userType} isSmall>
          {username}
        </User>
      </div>

      <div className="basis-1/6 text-xs text-black-120 text-right mr-2">
        <ViewerActionsDropdown hidden={user.isStaff || isAuthoredByShowSeller} viewer={user} />
        <span className="group-hover/viewerOrComment:hidden">{dayjs(date).format('HH:mm')}</span>
      </div>
    </div>
  )
}
