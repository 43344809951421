import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import MainNav from '@/components/Layout/Header/MainNav/MainNav'
import VoggtLogo from '@/components/ui/VoggtLogo/VoggtLogo'
import { setToLocalStorage } from '@/helpers/localstorage'

import './Header.scss'

const Header = () => {
  const location = useLocation()
  useEffect(() => {
    setToLocalStorage('currentPath', location.pathname)
  }, [location])

  const isShowOrShop = location.pathname.includes('/shops/') || location.pathname.includes('/shows/')

  return (
    <header className={`header ${isShowOrShop ? 'is-minimized' : ''}`} id="header">
      <VoggtLogo />
      <MainNav />
    </header>
  )
}

export default Header
