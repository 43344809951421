import { Trans, useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'

import Field from '@/components/Form/Field/Field'
import Popover from '@/components/ui/Popover/Popover'
import { ShowGiveawayAudience } from '@/network/graphql/types.generated'

import type { ChangeEvent } from 'react'

import './AudienceTypeField.scss'

type AudienceTypeFieldProps = {
  // !!! Note !!!
  // We use string values below because we don't want to set default value, but still want to have controlled inputs
  value: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

const AudienceTypeField = (props: AudienceTypeFieldProps) => {
  const { value: audience, onChange: handleAudienceChange, disabled = false } = props
  const { t } = useTranslation()

  const options = [
    {
      label: t('audienceOptionPresentUser'),
      value: ShowGiveawayAudience.PresentUsers,
    },
    {
      label: t('audienceOptionBuyers'),
      value: ShowGiveawayAudience.Buyers,
    },
    {
      label: t('audienceOptionBuyersOrders'),
      value: ShowGiveawayAudience.BuyersOrders,
    },
    {
      label: t('audienceOptionSharers'),
      value: ShowGiveawayAudience.SharersOpeners,
    },
    {
      label: t('audienceOptionFollowers'),
      value: ShowGiveawayAudience.Followers,
    },
  ]

  return (
    <Field
      className="audience-field"
      disabled={disabled}
      name="audience"
      options={options}
      required={true}
      type="select"
      value={audience}
      label={
        <Popover
          className="giveaway-audience-popover"
          content={
            <>
              <h2 className="giveaway-audience-title-popover">{t('giveawayLaunchModalAudienceTypeTitle')}</h2>
              <ul className="giveaway-audience-content-popover">
                <li>
                  <Trans i18nKey="giveawayLaunchModalCriteriaPresentUsers" />
                </li>
                <li>
                  <Trans i18nKey="giveawayLaunchModalCriteriaBuyers" />
                </li>
                <li>
                  <Trans i18nKey="giveawayLaunchModalCriteriaBuyersOrders" />
                </li>
                <li>
                  <Trans i18nKey="giveawayLaunchModalSharersOpeners" />
                </li>
                <li>
                  <Trans i18nKey="giveawayLaunchModalCriteriaFollowers" />
                </li>
              </ul>
            </>
          }
        >
          <FaInfoCircle />
          {t('productFormAudienceLabel')}
        </Popover>
      }
      onChange={handleAudienceChange}
    />
  )
}

export default AudienceTypeField
