const CelebrationStarsIcon = () => {
  return (
    <svg fill="none" height="62" viewBox="0 0 62 62" width="62" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8703 11.6393C15.897 11.6393 16.7303 10.806 16.7303 9.77926V9.75446C16.7303 8.72774 15.897 7.90686 14.8703 7.90686C13.8435 7.90686 13.0103 8.75254 13.0103 9.77926C13.0103 10.806 13.8435 11.6393 14.8703 11.6393Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M9.9202 18.258V18.2332C9.9202 17.2065 9.08692 16.3856 8.0602 16.3856C7.03348 16.3856 6.2002 17.2313 6.2002 18.258C6.2002 19.2847 7.03348 20.118 8.0602 20.118C9.08692 20.118 9.9202 19.2847 9.9202 18.258Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M19.3126 42.2895C20.2451 41.8803 21.3511 42.3069 21.7579 43.2542L25.1827 51.1307C25.5919 52.0731 25.1604 53.1668 24.218 53.576C23.9799 53.6826 23.727 53.7322 23.4765 53.7322C22.7598 53.7322 22.0753 53.3156 21.7727 52.6113L18.3479 44.7373C17.9387 43.7949 18.3702 42.6987 19.3126 42.2895Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M11.5184 45.693C10.762 44.9986 9.58648 45.0432 8.8896 45.8021C8.1952 46.5585 8.2448 47.734 8.99872 48.4309L14.2266 53.2396C14.5837 53.5695 15.035 53.7307 15.4864 53.7307C15.9874 53.7307 16.4883 53.5298 16.8529 53.1305C17.5498 52.3741 17.5002 51.1986 16.7438 50.5017L11.5184 45.693Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M48.9895 9.77926C48.9895 10.806 48.1563 11.6393 47.1295 11.6393C46.1028 11.6393 45.2695 10.806 45.2695 9.77926C45.2695 8.75254 46.1028 7.90686 47.1295 7.90686C48.1563 7.90686 48.9895 8.72774 48.9895 9.75446V9.77926Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M53.9401 16.3861C52.9134 16.3861 52.0801 17.2318 52.0801 18.2585C52.0801 19.2852 52.9134 20.1185 53.9401 20.1185C54.9668 20.1185 55.8001 19.2852 55.8001 18.2585V18.2337C55.8001 17.207 54.9668 16.3861 53.9401 16.3861Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M29.1401 35.9669C29.1401 34.9402 29.9734 34.1069 31.0001 34.1069C32.0269 34.1069 32.8601 34.9402 32.8601 35.9669V51.8712C32.8601 52.8979 32.0269 53.7312 31.0001 53.7312C29.9734 53.7312 29.1401 52.8979 29.1401 51.8712V35.9669Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M42.6878 42.2905C41.7603 41.8813 40.6517 42.3128 40.2425 43.2552L36.8176 51.1292C36.4084 52.0716 36.84 53.1678 37.7799 53.577C38.0204 53.6836 38.2734 53.7307 38.5214 53.7307C39.2381 53.7307 39.9251 53.3116 40.2276 52.6122L43.6525 44.7358C44.0617 43.7934 43.6302 42.6997 42.6878 42.2905Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M45.2564 50.5017L50.4818 45.693C51.2357 44.9986 52.4162 45.0432 53.1106 45.8021C53.805 46.5585 53.7554 47.734 53.0015 48.4309L47.7736 53.2396C47.419 53.5695 46.9652 53.7307 46.5138 53.7307C46.0128 53.7307 45.5119 53.5298 45.1473 53.1305C44.4504 52.3741 44.5 51.1986 45.2564 50.5017Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        clipRule="evenodd"
        d="M9.14843 33.1455C11.0655 33.7283 12.5584 35.2212 13.1387 37.1358C13.2999 37.6591 13.7811 38.0162 14.3267 38.0162C14.8747 38.0162 15.3559 37.6566 15.5121 37.1333C16.0924 35.2212 17.5829 33.7283 19.5024 33.1455C20.0257 32.9868 20.3803 32.5032 20.3803 31.9576C20.3803 31.4144 20.0257 30.9308 19.5049 30.7721C17.5829 30.1868 16.0924 28.6939 15.5121 26.7818C15.3559 26.2585 14.8747 25.8989 14.3267 25.8989C13.7811 25.8989 13.2999 26.256 13.1387 26.7793C12.5584 28.6939 11.063 30.1893 9.14595 30.7721C8.62515 30.9308 8.27051 31.4144 8.27051 31.9576C8.27051 32.5056 8.62515 32.9868 9.14843 33.1455Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M52.8518 30.7721C50.9373 30.1893 49.4416 28.6963 48.859 26.7793C48.6978 26.2585 48.2167 25.9014 47.6709 25.9014C47.123 25.9014 46.6419 26.261 46.4857 26.7818C45.9029 28.6963 44.4121 30.1893 42.4954 30.7721C41.9721 30.9308 41.6172 31.4144 41.6172 31.96C41.6172 32.5031 41.9721 32.9867 42.4954 33.1454C44.4149 33.7282 45.9029 35.2212 46.4857 37.1333C46.6419 37.6566 47.123 38.0162 47.6709 38.0162C48.2167 38.0162 48.6978 37.659 48.859 37.1358C49.4416 35.2212 50.9373 33.7282 52.8518 33.1454C53.3751 32.9867 53.7295 32.5031 53.7295 31.96C53.7295 31.4144 53.3751 30.9308 52.8518 30.7721Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M37.7592 27.1592C38.0047 26.6955 38.0915 26.1573 38.0047 25.6266L37.3103 21.8371L40.1549 19.1092C40.6187 18.6628 40.884 18.0626 40.8964 17.4178C40.9088 16.7755 40.6707 16.1654 40.2194 15.6992C39.8424 15.3123 39.339 15.0593 38.8207 14.9924L34.9246 14.4567L33.1563 10.9301C32.8563 10.3523 32.3503 9.92571 31.7303 9.72979C31.1153 9.53387 30.4556 9.58843 29.8827 9.88355C29.4339 10.1142 29.0594 10.4887 28.8312 10.94L28.8287 10.945L27.1101 14.422L23.1941 14.9874C22.5568 15.0692 21.9913 15.3966 21.597 15.905C21.2052 16.4159 21.0365 17.0508 21.1208 17.6856C21.1903 18.2188 21.4432 18.7198 21.8376 19.1042L24.6647 21.7652L23.9852 25.6415C23.8811 26.2813 24.0348 26.9212 24.4118 27.4444C24.7912 27.9677 25.3517 28.3149 26.0039 28.4191C26.5223 28.4984 27.0456 28.4116 27.5019 28.1711L30.9764 26.3508L34.4831 28.1661C34.8378 28.3546 35.2247 28.4513 35.614 28.4513C35.8521 28.4513 36.0927 28.4141 36.3307 28.3422C36.9483 28.1512 37.4567 27.7321 37.7592 27.1592Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default CelebrationStarsIcon
