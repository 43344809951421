import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaXmark } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import Dialog from '@/components/ui/Dialog/Dialog'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import { OrderStatus, type Currency } from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'
import { useRequestOrderShippingFeeCancellationMutation } from '@/views/Shipments/operations.generated'

import './CancelShippingFee.scss'

interface CancelShippingFeeProps {
  orderId?: string
  status?: OrderStatus
  shippingFeeAmount?: number
  cancellationRequestedAt?: Date | null
  currency?: Currency
  onCompleted?: () => void
}

export const CancelShippingFee = (props: CancelShippingFeeProps) => {
  const currencyFormatter = useCurrencyFormatter()
  const { t } = useTranslation()

  const { orderId, shippingFeeAmount, cancellationRequestedAt, currency, onCompleted, status } = props
  const isDisabled = !!cancellationRequestedAt

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [requestOrderShippingFeeCancellation] = useRequestOrderShippingFeeCancellationMutation()

  const onClickCancelShippingFeeOrder = () => {
    if (!orderId) return
    setIsLoading(true)
    requestOrderShippingFeeCancellation({
      variables: {
        input: {
          orderId: orderId,
        },
      },
      onCompleted: (data) => {
        notificationSuccess(t('ordersCancellationModalMessageSucces'))
        trackEvent('ORDER_CANCEL_SHIPPING_FEES_REQUEST', {
          orderId: data.requestOrderShippingFeeCancellation.order.id,
        })
        setIsLoading(false)
        setIsOpen(false)
        onCompleted && onCompleted()
      },
      onError: (error) => {
        notificationDanger(error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>))
        setIsLoading(false)
        onCompleted && onCompleted()
      },
    })
  }

  if (!orderId || !shippingFeeAmount || shippingFeeAmount <= 0 || !currency || status !== OrderStatus.Pending)
    return null

  return (
    <>
      <Button
        disabled={isDisabled}
        icon={<FaXmark />}
        tooltip={
          isDisabled
            ? t('cancelShippingFeeAlreadyCanceledTooltip') +
              ' ' +
              dayjs(cancellationRequestedAt).format('DD/MM/YYYY HH:mm')
            : t('cancelShippingFeeTooltip')
        }
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <Dialog
          className="cancel-shipping-fee-dialog"
          isOpen={true}
          modal={true}
          title={t('cancelShippingFeeTitle')}
          onClose={() => setIsOpen(false)}
        >
          <div className="cancel-shipping-fee-dialog-content">
            <p>{t('cancelShippingFeeConfirm', { amount: currencyFormatter(shippingFeeAmount, currency) })}</p>
            <div className="cancel-shipping-fee-dialog-action">
              <Button
                className="primary"
                isLoading={isLoading}
                label={t('cancelShippingFeeButton')}
                onClick={onClickCancelShippingFeeOrder}
              />
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}
