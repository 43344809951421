import { notification } from 'antd'
import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import type { UserFollowFragment } from '../../../../../Shows/operations.generated'

export interface FollowItemProps {
  item: UserFollowFragment
}

const FollowItem = ({ item }: FollowItemProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (item) {
      notification.success({
        message: t('showCommentsListUserFollowMessage', {
          username: item.user.username,
        }),
        duration: 5,
      })
    }
    return () => {
      notification.destroy()
    }
  }, [item])

  return null
}

export default memo(FollowItem)
