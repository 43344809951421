import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCircleInfo } from 'react-icons/fa6'

import Field from '@/components/Form/Field/Field'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import Loader from '@/components/ui/Loader/Loader'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import Popover from '@/components/ui/Popover/Popover'
import Config from '@/config/config'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import getDeliveryType from '@/helpers/shippingRouteTranslationKeyFinder'
import { logger, LOGGER_LEVEL } from '@/network/common/logger'
import { trackEvent } from '@/util/eventTracker'
import { useShipmentContext } from '@/views/Shipments/ShipmentProvider'
import { isMondialRelayShippingProvider, type ShipmentDimension } from '@/views/Shipments/utils/shipments.util'

import {
  useDispatchShipmentWithBoxtalMutation,
  useDispatchShipmentWithSendcloudMutation,
  useQuoteForBoxtalMutation,
  useQuoteForSendcloudMutation,
} from '../../operations.generated'
import { ShippingMethod } from '../../ShippingModal'

import { ShippingLabelFormat, ShippingLabelFormatToGraphQLDispatchLabelType } from './Dimensions'

import type {
  GetSellerShipmentsFieldsFragment,
  ShipmentFieldsFragment,
  UnShippedShipmentsFieldsFragment,
} from '@/views/Shipments/operations.generated'

type QuoteShippingProps = {
  defaultShippingProvider: ShippingMethod
  dimensions?: ShipmentDimension
  formatLabel: ShippingLabelFormat
  onBack: () => void
  onComplete: () => void
  shipment: UnShippedShipmentsFieldsFragment | ShipmentFieldsFragment | GetSellerShipmentsFieldsFragment
  onDispatching: () => void
  hasAlreadyShipped?: boolean
}

const { VITE_APP_LONG_TIMEOUT } = Config

type Quote = {
  id: string
  thumbnail?: string
  price_with_curency: string
  carrier_name: string
  info: string

  //boxtal only
  insurance_price?: number
  details?: string

  // the following are used for dispatch only
  carrier: string
  price: number
  //sendcloud
  servicePointId: string
  //boxtal
  service_code?: string
  operator_code?: string
  delivery_type?: string
  shipping_provider?: string
  shipping_offer_code?: string
}

export const QuoteShipping = (props: QuoteShippingProps) => {
  const {
    defaultShippingProvider,
    dimensions,
    formatLabel,
    onBack,
    onComplete,
    shipment,
    onDispatching,
    hasAlreadyShipped,
  } = props
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()

  const { onShippingMethodError } = useShipmentContext()

  const [isQuoteLoading, setIsQuoteLoading] = useState<boolean>(false)
  const [isDispatchLoading, setIsDispatchLoading] = useState<boolean>(false)
  const [quotes, setQuotes] = useState<Quote[]>([])
  const [sellerContractQuotes, setSellerContractQuotes] = useState<Quote[]>([])
  const [selectedQuote, setSelectedQuote] = useState<Quote | null>(null)
  const [isInsured, setIsInsured] = useState<boolean>(false)

  const isFormValid = useMemo(() => Boolean(selectedQuote), [selectedQuote])

  const [getQuotesForSendcloud] = useQuoteForSendcloudMutation({
    context: {
      timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
    },
  })
  const [dispatchShipmentWithSendcloud] = useDispatchShipmentWithSendcloudMutation({
    context: {
      timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
    },
  })

  const [getQuotesForBoxtal] = useQuoteForBoxtalMutation({
    context: {
      timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
    },
  })
  const [dispatchShipmentWithBoxtal] = useDispatchShipmentWithBoxtalMutation({
    context: {
      timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
    },
  })

  const extractSendcloudQuotes = useCallback(
    (data) => {
      const allQuotes = data.quoteForSendcloud.quotes.map((quote: any) => ({
        id: quote.id,
        price_with_curency: quote.price ? currencyFormatter(quote.price * 100, shipment.currency) : undefined,
        carrier_name: quote.name,
        info: `${getDeliveryType(quote.firstMile) !== 'PICKUP' ? t('commonDropoff') : t('commonPickup')} ${getDeliveryType(quote.firstMile)}, ${t('commonDelivery')} ${getDeliveryType(quote.lastMile)}`,

        // the following are used for dispatch
        carrier: quote.carrier,
        servicePointId: quote.servicePointId,
        price: quote.price,
      }))
      setSellerContractQuotes(allQuotes.filter((quote: Quote) => quote.price_with_curency === undefined))
      setQuotes(allQuotes.filter((quote: Quote) => quote.price_with_curency !== undefined))
    },
    [shipment]
  )

  const extractBoxtalQuotes = useCallback(
    (data) => {
      setQuotes(
        data.quoteForBoxtal.quotes.map((quote: any) => ({
          id: `${quote.shippingOfferCode}-${quote.serviceCode}-${quote.price}`,
          thumbnail: quote.logoUrl,
          price_with_curency: currencyFormatter(quote.price * 100, shipment.currency),
          carrier_name: `${quote.shippingProvider} - ${quote.service}`,
          info: `${quote.collectionType !== 'HOME' ? t('commonDropoff') : t('commonPickup')} ${getDeliveryType(quote.collectionType)}, ${t('commonDelivery')} ${getDeliveryType(quote.deliveryType)}`,
          details: quote.characteristics ? `${quote.characteristics.join('. ')}` : '',

          insurance_price: quote.insurancePrice,

          // the following are used for dispatch
          price: quote.price,
          service_code: quote.serviceCode,
          operator_code: quote.operatorCode,
          delivery_type: quote.deliveryType,
          shipping_provider: quote.shippingProvider,
          shipping_offer_code: quote.shippingOfferCode,
        }))
      )
    },
    [shipment]
  )

  useEffect(() => {
    if (!dimensions) return
    if (defaultShippingProvider === ShippingMethod.Sendcloud) {
      setIsQuoteLoading(true)
      getQuotesForSendcloud({
        variables: {
          input: {
            currency: shipment.currency,
            shipmentId: shipment.id,
            weight: dimensions.weight * 1000,
          },
        },
        onCompleted: (data) => {
          setIsQuoteLoading(false)
          extractSendcloudQuotes(data)
        },
        onError: (error) => {
          setIsQuoteLoading(false)
          notificationDanger(error.message)
        },
      })
    } else if (defaultShippingProvider === ShippingMethod.Boxtal) {
      if (!dimensions.height || !dimensions.width || !dimensions.depth) return
      setIsQuoteLoading(true)
      getQuotesForBoxtal({
        variables: {
          input: {
            shipmentId: shipment.id,
            weight: Number(dimensions.weight), // in kg, floats allowed
            height: dimensions.height, // in cm, integers only
            width: dimensions.width, // in cm, integers only
            length: dimensions.depth, // in cm, integers only
            insurance: true,
          },
        },
        onCompleted: (data) => {
          setIsQuoteLoading(false)
          extractBoxtalQuotes(data)
        },
        onError: (error) => {
          setIsQuoteLoading(false)
          notificationDanger(error.message)
        },
      })
    }
  }, [defaultShippingProvider, dimensions])

  const handleInsuranceChange = useCallback(() => {
    setIsInsured(!isInsured)
  }, [isInsured])

  useEffect(() => {
    setIsInsured(false)
  }, [selectedQuote])

  const handleSubmit = useCallback(() => {
    if (!selectedQuote) return
    setIsDispatchLoading(true)
    onDispatching()
    if (defaultShippingProvider === ShippingMethod.Sendcloud) {
      dispatchShipmentWithSendcloud({
        variables: {
          input: {
            shipmentId: shipment.id,
            parcelWeight: dimensions?.weight! * 1000,
            shippingMethodId: parseInt(selectedQuote.id!),
            carrier: selectedQuote.carrier,
            servicePointId: selectedQuote.servicePointId ? parseInt(selectedQuote.servicePointId) : undefined,
            shippingPriceCurrency: shipment.currency,
            shippingPriceValue: selectedQuote.price ?? 0,
          },
        },
        onCompleted: () => {
          trackEvent('SHIPMENT_SHIPPED', {
            type: 'sendcloud',
            from_country: shipment.shippingAddress.inputCountry,
          })
          notificationSuccess(t('orderTableShippingModalProviderSuccessRegister'))
          setIsDispatchLoading(false)
          onComplete()
        },
        onError: (error) => {
          onShippingMethodError(error, ShippingMethod.Sendcloud)
          logger({
            level: LOGGER_LEVEL.ERROR,
            message: error.message,
            meta: { error, shipment, selectedQuote, dimensions, provider: ShippingMethod.Sendcloud },
          })
          setIsDispatchLoading(false)
        },
      })
    } else if (defaultShippingProvider === ShippingMethod.Boxtal) {
      const usedLabelType = isMondialRelayShippingProvider(selectedQuote.shipping_provider ?? '')
        ? formatLabel
        : ShippingLabelFormat.A4
      dispatchShipmentWithBoxtal({
        variables: {
          input: {
            shipmentId: shipment.id,
            weight: dimensions?.weight!, // in kg, floats allowed
            height: dimensions?.height || 0, // in cm, integers only
            width: dimensions?.width || 0, // in cm, integers only
            length: dimensions?.depth || 0, // in cm, integers only
            priceTaxInclusive: Number(selectedQuote.price),
            shippingOfferCode: selectedQuote.shipping_offer_code ?? '',
            serviceCode: selectedQuote.service_code ?? '',
            operatorCode: selectedQuote.operator_code ?? '',
            insurancePriceTaxInclusive: selectedQuote.insurance_price ? Number(selectedQuote.insurance_price) : 0,
            deliveryType: selectedQuote.delivery_type ?? '',
            shippingProvider: selectedQuote.shipping_provider ?? '',
            insurance: isInsured,
            labelType: ShippingLabelFormatToGraphQLDispatchLabelType[usedLabelType],
          },
        },
        onCompleted: () => {
          trackEvent('SHIPMENT_SHIPPED', {
            type: 'boxtal',
            from_country: shipment.shippingAddress.inputCountry,
          })
          notificationSuccess(t('orderTableShippingModalProviderSuccessRegister'))
          setIsDispatchLoading(false)
          onComplete()
        },
        onError: (error) => {
          onShippingMethodError(error, ShippingMethod.Boxtal)
          logger({
            level: LOGGER_LEVEL.ERROR,
            message: error.message,
            meta: { error, shipment, selectedQuote, dimensions, provider: ShippingMethod.Boxtal },
          })
          setIsDispatchLoading(false)
        },
      })
    }
  }, [onComplete, selectedQuote, dimensions, shipment, isInsured, formatLabel])

  return (
    <div className="easy-shipment-quote">
      {isQuoteLoading && <Loader />}

      {!isQuoteLoading &&
        quotes &&
        quotes.length === 0 &&
        sellerContractQuotes &&
        sellerContractQuotes.length === 0 && (
          <div className="no-quotes">
            <svg fill="none" height="72" viewBox="0 0 73 72" width="73" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.4">
                <path
                  clipRule="evenodd"
                  d="M61.25 34.9917H56.297C55.055 34.9917 54.047 35.9997 54.047 37.2417C54.047 38.4837 55.055 39.4917 56.297 39.4917H61.25V45.2997C61.25 47.5197 59.48 49.2897 57.26 49.2897H56.78C56.03 46.7097 53.96 44.6397 51.35 43.8897V27.4797L58.37 30.5697C60.14 31.3197 61.25 33.0597 61.25 34.9797V34.9917ZM52.61 51.3897C52.58 51.4497 52.58 51.4797 52.58 51.5397C52.58 51.5997 52.58 51.6297 52.61 51.6897V51.7197C52.49 53.6097 50.96 55.0497 49.1 55.0497C47.45 55.0497 46.1 53.9397 45.71 52.4397C45.623 52.1457 45.593 51.8487 45.593 51.5547C45.593 51.5487 45.59 51.5427 45.59 51.5367C45.59 50.4597 46.07 49.4997 46.85 48.8697C47.45 48.3597 48.23 48.0597 49.1 48.0597C49.94 48.0597 50.75 48.3597 51.35 48.8997C52.07 49.4997 52.55 50.3397 52.61 51.3597V51.3897ZM35.984 29.5617L27.599 37.9617C27.179 38.3817 26.606 38.6217 26.009 38.6217C25.412 38.6217 24.842 38.3847 24.419 37.9647L19.973 33.5247C19.094 32.6457 19.094 31.2207 19.97 30.3417C20.849 29.4597 22.277 29.4627 23.153 30.3387L26.006 33.1887L32.798 26.3817C33.674 25.4997 35.099 25.4997 35.981 26.3787C36.86 27.2577 36.86 28.6827 35.984 29.5617ZM25.13 51.8097C25.01 53.6397 23.51 55.0497 21.65 55.0497C19.76 55.0497 18.2 53.5197 18.17 51.5997V51.5397C18.17 49.6197 19.73 48.0597 21.65 48.0597C23.6 48.0597 25.16 49.6197 25.16 51.5397C25.16 51.6297 25.16 51.7197 25.13 51.8097ZM60.2 26.4597L51.35 22.5597V20.9397C51.35 16.2597 47.54 12.4497 42.86 12.4497H15.65C11.03 12.4497 7.25 16.2297 7.25 20.8497V45.2997C7.25 49.3497 10.13 52.7697 13.94 53.5797C14.81 56.9997 17.96 59.5497 21.65 59.5497C25.304 59.5497 28.361 57.1257 29.324 53.8017H41.423C42.389 57.1257 45.476 59.5497 49.1 59.5497C52.73 59.5497 55.82 57.1197 56.78 53.7897H57.26C61.94 53.7897 65.75 49.9797 65.75 45.2997V34.9797C65.75 31.2597 63.56 27.8997 60.2 26.4597Z"
                  fill="#171514"
                  fillRule="evenodd"
                />
              </g>
            </svg>

            <p>{t('noQuotesAvailable')}</p>
          </div>
        )}

      {!isQuoteLoading && sellerContractQuotes && sellerContractQuotes.length > 0 && (
        <>
          <h2 className="easy-shipment-quote-form-title">{t('sellerContractsTitle')}</h2>
          <div className="easy-shipment-quote-form">
            {sellerContractQuotes.map((quote: Quote) => (
              <Field
                key={quote.id}
                checked={selectedQuote?.id === quote.id}
                className="easy-shipment-quote-item"
                name={`quote-${quote.id}`}
                type="checkbox"
                value={quote.id}
                label={
                  <>
                    {quote.thumbnail && (
                      <div className="easy-shipment-quote-item-thumbnail">
                        <img alt={quote.carrier} src={quote.thumbnail} />
                      </div>
                    )}
                    <div>
                      <div className="easy-shipment-quote-item-name">
                        <p>{quote.carrier_name}</p>
                      </div>
                      <div className="easy-shipment-quote-item-delivery">
                        <p>{quote.info} </p>
                        {quote.details && (
                          <Popover content={quote.details}>
                            <FaCircleInfo />
                          </Popover>
                        )}
                      </div>
                    </div>
                  </>
                }
                onChange={() => setSelectedQuote(quote)}
              />
            ))}
          </div>
          <h2 className="easy-shipment-quote-form-title">{t('voggtContractsTitle')}</h2>
        </>
      )}

      {!isQuoteLoading && quotes && quotes.length > 0 && (
        <>
          <div className="easy-shipment-quote-form">
            {quotes.map((quote: Quote) => (
              <Field
                key={quote.id}
                checked={selectedQuote?.id === quote.id}
                className="easy-shipment-quote-item"
                name={`quote-${quote.id}`}
                type="checkbox"
                value={quote.id}
                label={
                  <>
                    {quote.thumbnail && (
                      <div className="easy-shipment-quote-item-thumbnail">
                        <img alt={quote.carrier} src={quote.thumbnail} />
                      </div>
                    )}
                    <div>
                      <div className="easy-shipment-quote-item-name">
                        <p>{quote.carrier_name}</p>
                        <span className="easy-shipment-quote-item-price">{quote.price_with_curency}</span>
                      </div>
                      <div className="easy-shipment-quote-item-delivery">
                        <p>{quote.info} </p>
                        {quote.details && (
                          <Popover content={quote.details}>
                            <FaCircleInfo />
                          </Popover>
                        )}
                      </div>
                    </div>
                  </>
                }
                onChange={() => setSelectedQuote(quote)}
              />
            ))}
          </div>

          {defaultShippingProvider === ShippingMethod.Boxtal && (
            <Field
              checked={isInsured}
              disabled={!selectedQuote?.insurance_price}
              label={`${t('orderTableShippingModalInsurance')} ${selectedQuote?.insurance_price ? ' (' + currencyFormatter(selectedQuote.insurance_price * 100, shipment.currency) + ')' : ' (' + t('commonUnavailable') + ')'} `}
              name="insurance"
              type="checkbox"
              onChange={handleInsuranceChange}
            />
          )}

          <Alert emphasis="high" type="info">
            {t('orderTableShippingModalProviderAlert')}
          </Alert>

          {!hasAlreadyShipped && <Alert type="info">{t('orderTableShippingModalFirstShipment')}</Alert>}
        </>
      )}

      <div className="easy-shipment-quote-form-button">
        <Button className="secondary" label={t('commonBack')} onClick={onBack} />
        <Button
          className="primary"
          disabled={!isFormValid}
          isLoading={isDispatchLoading}
          label={t('commonValidate')}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}
