import { EditOutlined } from '@ant-design/icons'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaDownload } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import Config from '@/config/config'
import { ShipmentStatus } from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import { useSetShipmentStatusLabelDownloadedMutation } from '@/views/Shipments/operations.generated'

import { useDownloadShippingLabelLinkForSendcloudLazyQuery } from '../../../ShippingFlow/Simple/operations.generated'
import { ShippingModalSimple } from '../../../ShippingFlow/Simple/ShippingModal'

import type { ShipmentFieldsFragment } from '@/views/Shipments/operations.generated'

interface CreateLabelButtonProps {
  shipment: ShipmentFieldsFragment
  onCompleted: () => void
}

const { VITE_APP_LONG_TIMEOUT } = Config

export const CreateLabelFromShipmentButton = ({ shipment, onCompleted }: CreateLabelButtonProps) => {
  const { t } = useTranslation()

  const [isShippingModalOpen, setIsShippingModalOpen] = useState<boolean>(false)

  const isEditMode = useMemo(() => shipment.status === ShipmentStatus.Shipped, [shipment])

  const [setShipmentStatusLabelDownloaded] = useSetShipmentStatusLabelDownloadedMutation()

  const [getShippingLabelForSendcloud, { loading: sendcloudLabelDownloadLoading }] =
    useDownloadShippingLabelLinkForSendcloudLazyQuery({
      variables: {
        nodeId: shipment.id,
      },
      context: {
        timeout: VITE_APP_LONG_TIMEOUT ? parseInt(VITE_APP_LONG_TIMEOUT) : 45000,
      },
      onCompleted: async (data) => {
        // @ts-expect-error downloadLinkLabelPrintUrl is only defined when __typename is Shipment
        const { downloadLinkLabelPrintUrl } = data?.node

        if (downloadLinkLabelPrintUrl) {
          openPdfLabel(downloadLinkLabelPrintUrl)
          await onLabelGenerated()
        }
      },
    })

  const openPdfLabel = (labelPrintUrl: string) => {
    window.open(labelPrintUrl)
  }

  const onLabelGenerated = async () => {
    const shipmentId = shipment.id

    await setShipmentStatusLabelDownloaded({
      variables: {
        input: { shipmentId },
      },
      onError: (err: Error) => {
        trackError(err, {
          meta: {
            shipmentId,
            scope: 'CreateLabelFromShipmentButton.setShipmentStatusLabelDownloaded',
          },
        })
      },
    })
  }

  const handleButtonClick = async () => {
    // Case: Boxtal
    if (shipment.labelPrintUrl?.includes('.pdf')) {
      openPdfLabel(shipment.labelPrintUrl)
      await onLabelGenerated()
    } else {
      // Case: Sendcloud
      await getShippingLabelForSendcloud()
    }

    trackEvent('SHIPMENT_DOWNLOAD_LABEL')
  }

  const hasAlreadyBeenDownloaded = Boolean(shipment.labelGeneratedAt)
  const downloadActionLabel = !hasAlreadyBeenDownloaded
    ? t('orderTableShippingModalDownloadButtonLabel')
    : t('shipmentsShippingLabelDownloadAgainButtonLabel')
  const downloadActionTitle = !hasAlreadyBeenDownloaded
    ? t('orderTableShippingModalDownloadButtonTitle')
    : t('shipmentsShippingLabelDownloadAgainButtonTitle')
  const { externalShippingOrderId, labelPrintUrl, status } = shipment

  return (
    <div>
      {externalShippingOrderId && (
        <>
          {labelPrintUrl && (
            <Button
              className={`download-shipping-label-action ${hasAlreadyBeenDownloaded ? 'secondary' : 'primary'}`}
              icon={<FaDownload />}
              isLoading={sendcloudLabelDownloadLoading}
              label={downloadActionLabel}
              tooltip={downloadActionTitle}
              tooltipPosition="bottom right"
              onClick={handleButtonClick}
            />
          )}
          {!labelPrintUrl && <span>{t('orderTableShippingLabelDownloadPending')}</span>}
        </>
      )}

      {!externalShippingOrderId && [ShipmentStatus.ReadyToShip, ShipmentStatus.Shipped].includes(status) && (
        <>
          {shipment && <ShippingModalSimple open={isShippingModalOpen} shipment={shipment} onCompleted={onCompleted} />}
          <Button
            className="edit-shipping-info-action primary"
            icon={isEditMode && <EditOutlined />}
            label={isEditMode ? t('orderTableShippingModalStatusButtonTrackingLabel') : t('createShipmentLabelButton')}
            onClick={() => setIsShippingModalOpen(true)}
          />
        </>
      )}
    </div>
  )
}
