import { useTranslation } from 'react-i18next'
import { FaDownload } from 'react-icons/fa6'
import { connect } from 'react-redux'

import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import { DEFAULT_COUNTRY_ID } from '@/config/config'
import { Country } from '@/data/countriesClusters'
import { trackEvent } from '@/util/eventTracker'

import style from './style.module.scss'

const BrandPack = ({ sellerCountry }: { sellerCountry: string }) => {
  const { t } = useTranslation()

  const countryKey: any = {
    GB: 'UK',
    BE: Country.FR,
    CH: Country.FR,
    AT: Country.DE,
  }

  const sellerCountryFormatted = countryKey[sellerCountry]
    ? countryKey[sellerCountry]?.toLowerCase()
    : sellerCountry.toLowerCase()

  const handleDownloadBrandPack = () => {
    const link = document.createElement('a')
    link.href = 'https://cdn.production.voggt.io/unoptimized/brand/Voggt-Toolkit-sellers.zip'
    link.click()

    trackEvent('DOWNLOAD_BRAND_PACK')
  }

  return (
    <Card className="brand-pack" title={t('accountBrandPackCardTitle')}>
      <div className={style.brand_pack__description_1}>{t('accountBrandPackDescription1')}</div>
      <div className={style.brand_pack__description_2}>
        {t('accountBrandPackDescription2', { sellerCountry: sellerCountryFormatted })}
      </div>
      <div className="actions flex justify-end">
        <Button
          className="download-action download-brand-pack-action primary"
          icon={<FaDownload />}
          label={t('commonDownload')}
          onClick={handleDownloadBrandPack}
        />
      </div>
    </Card>
  )
}

function mapStateToProps(state: any) {
  return {
    sellerCountry: state.authentication.user?.sellerConfig?.countryId || DEFAULT_COUNTRY_ID,
  }
}

export default connect(mapStateToProps)(BrandPack)
