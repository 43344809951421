import { ConfigProvider, message } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, connect } from 'react-redux'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import 'moment/min/locales'

import { fetchLastSellerContract } from '@/actions/fetch-last-seller-contract'
import { fetchLastSellerContractDate } from '@/actions/fetch-last-seller-contract-date'
import { fetchMe } from '@/actions/fetch-me'
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary'
// import { useUser } from '@/contexts/user/User.context'
import Header from '@/components/Layout/Header/Header'
import getAntdLocale from '@/helpers/antdLocales'
import browserLanguage from '@/helpers/browserLanguage'
import { useAppSelector } from '@/reducers'
import { signLastContract, storeToken } from '@/reducers/authentication'
import { trackEvent } from '@/util/eventTracker'
import Account from '@/views/Account/Account'
import Calendar from '@/views/Calendar/Calendar'
import Home from '@/views/Home/Home'
import { InventoryContainer } from '@/views/Inventory/InventoryContainer'
import Ledger from '@/views/Ledger/Ledger'
import Login from '@/views/Login/Login'
import Logout from '@/views/Logout/Logout'
import OrdersExport from '@/views/OrdersExport'
import SellerContract from '@/views/SellerContract/SellerContract'
import { Shipments } from '@/views/Shipments/Shipments'
import Show from '@/views/Show/Show'
import { Shows } from '@/views/Shows/Shows'
import Signup from '@/views/Signup/Signup'

import {
  useLoginWithImpersonationTokenMutation,
  useLoginWithMagicTokenMutation,
  useLoginWithSsoMutation,
} from '../Login/operations.generated'
import { Ratings } from '../Ratings/Ratings'
import { Shops } from '../Shops/Shops'

const IMPERSONATION_TOKEN_ID = 'impersonationTokenId'
export const PAYPAL_SSO_PARAM_NAME = 'paypalSSOFlow'
const MAGIC_TOKEN_QUERY_PARAM_NAME = 'magicToken'

type RouterProps = {
  lastSellerContractDate: string
  isDateLoading: boolean
  hasSignedLastContract: boolean
  isContractError: boolean
  canAccessStripeTransactions: boolean
}

const Router = (props: RouterProps) => {
  const { lastSellerContractDate, isDateLoading, hasSignedLastContract, isContractError } = props

  const queryParams = new URLSearchParams(window.location.search)
  const magicToken = queryParams.get(MAGIC_TOKEN_QUERY_PARAM_NAME)
  const impersonateToken = queryParams.get(IMPERSONATION_TOKEN_ID)
  const paypalSsoToken = queryParams.get(PAYPAL_SSO_PARAM_NAME)

  const dispatch = useDispatch<any>()
  const { i18n } = useTranslation()
  const userLanguage = browserLanguage()
  const locale = getAntdLocale(i18n.language.replace('-', '_'))
  moment.locale(i18n.language.split('-')[0])

  // TODO: Uncomment the following to be able to replace the fetchMe redux action by using the user context,
  // when everything will be ready
  // const { user: user2, isLoading: isUser2Loading } = useUser()

  const { isLogged, user } = useAppSelector((state) => state.authentication)
  const [isLoaded, setIsLoaded] = useState(false)

  const userId = user?.id
  const lastSellerContractSignatureDate = user?.sellerConfig?.acceptedStudioConditionsOn
  const sellerNeedsToSignUpAsASeller = isLogged && user && !user.isSeller && user.isSelected
  const sellerNeedsToSign =
    !lastSellerContractSignatureDate ||
    moment(lastSellerContractSignatureDate).isBefore(moment(lastSellerContractDate), 'day')

  if (location.hash.startsWith('#/')) {
    location.href = location.href.replace('#/', '')
  }

  const getMe = async () => {
    const result = await dispatch(fetchMe())
    const { sellerConfig } = result?.payload || {}
    const { countryId } = sellerConfig || {}
    if (countryId) {
      trackEvent(`LOGIN_${countryId}`)
    }
  }

  const [loginWithImpersonateToken] = useLoginWithImpersonationTokenMutation()

  const handleConnectionWithImpersonateToken = async (impersonationToken: string) => {
    await loginWithImpersonateToken({
      variables: { impersonationToken },
      onCompleted: async ({ loginWithImpersonationToken }) => {
        const currentUrl = new URL(window.location.href)
        queryParams.delete(IMPERSONATION_TOKEN_ID)
        const newParams = queryParams.size > 0 ? `?${queryParams.toString()}` : ''
        // hides the impersonationTokenId from the URL
        window.history.replaceState({}, '', `${currentUrl.pathname}${newParams}`)
        dispatch(storeToken(loginWithImpersonationToken))
      },
      onError: (error) => message.error(error.message),
    })
  }

  const [loginWithMagicToken] = useLoginWithMagicTokenMutation()

  const handleConnectionWithMagicToken = async (magicToken: string) => {
    await loginWithMagicToken({
      variables: { magicToken },
      onCompleted: async ({ loginWithMagicToken }) => {
        const currentUrl = new URL(window.location.href)
        queryParams.delete(MAGIC_TOKEN_QUERY_PARAM_NAME)
        const newParams = queryParams.size > 0 ? `?${queryParams.toString()}` : ''
        // hides the magicToken from the URL
        window.history.replaceState({}, '', `${currentUrl.pathname}${newParams}`)
        dispatch(storeToken(loginWithMagicToken))
      },
      onError: (error) => message.error(error.message),
    })
  }

  const [loginWithSSO] = useLoginWithSsoMutation()
  const handleConnectionWithPaypal = async (paypalSsoToken: string) => {
    loginWithSSO({
      variables: { idToken: paypalSsoToken },
      onCompleted: async ({ loginWithSso }) => {
        const currentUrl = new URL(window.location.href)
        queryParams.delete(PAYPAL_SSO_PARAM_NAME)
        queryParams.forEach((_, param) => {
          queryParams.delete(param)
        })
        const newParams = queryParams.size > 0 ? `?${queryParams.toString()}` : ''
        window.history.replaceState({}, '', `${currentUrl.pathname}${newParams}`)
        dispatch(storeToken(loginWithSso))
      },
      onError: (error) => message.error(error.message),
    })
  }

  // TODO: split this useEffect into smaller unitary ones
  useEffect(() => {
    ;(async () => {
      if (magicToken) {
        await handleConnectionWithMagicToken(magicToken)
        return
      }

      if (impersonateToken) {
        await handleConnectionWithImpersonateToken(impersonateToken)
        return
      }

      const idToken = queryParams.get('id_token')
      if (paypalSsoToken && typeof idToken === 'string') {
        await handleConnectionWithPaypal(idToken)
        return
      }

      if (isLogged) {
        await getMe()
      }

      setIsLoaded(true)

      if (isLogged) {
        if (!hasSignedLastContract) {
          dispatch(fetchLastSellerContractDate())
        }

        if (lastSellerContractDate) {
          if (sellerNeedsToSign) {
            dispatch(signLastContract(false))
            dispatch(fetchLastSellerContract(userLanguage))
          } else if (!sellerNeedsToSign) {
            dispatch(signLastContract(true))
          }
        }
      }
    })()
  }, [
    dispatch,
    sellerNeedsToSignUpAsASeller,
    isLogged,
    userId,
    lastSellerContractDate,
    hasSignedLastContract,
    sellerNeedsToSign,
    userLanguage, // TODO: probably not needed. Test and remove if not.
    magicToken,
    impersonateToken,
  ])

  if (!isLoaded) return null

  if (sellerNeedsToSignUpAsASeller) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/signup" render={() => <Signup />} />
          <Redirect to="/signup" />
        </Switch>
      </BrowserRouter>
    )
  }

  if (
    isLogged &&
    userId &&
    sellerNeedsToSign &&
    !isDateLoading &&
    !hasSignedLastContract &&
    !isContractError &&
    user?.isSeller
  ) {
    // TODO:
    //  - Create a specific path for the seller contract,
    //  - Add this it the routes below
    //  - In the view, make sure the user needs to sign the contract, otherwise redirect to the home page
    //  - Below, just redirect to the seller contract path
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" render={() => <SellerContract userId={userId} />} />
        </Switch>
      </BrowserRouter>
    )
  }

  const shouldRedirectToLogin = !isLogged || !user || !user.isSelected

  if (shouldRedirectToLogin) {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            render={() => {
              const redirectTo = queryParams.get('redirectTo') ?? ''
              return <Login redirectTo={redirectTo} />
            }}
            strict
          />
        </Switch>
      </BrowserRouter>
    )
  }

  return (
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <ErrorBoundary>
          <Header />
          <Switch>
            <Redirect from="/signup" to="/shows" />

            <Route component={Home} path="/" exact />
            <Route path="/calendar" render={() => <Calendar />} exact />
            <Route path="/shows/:showId/products/:productId" render={() => <Show />} exact />
            <Route path="/shows/:showId" render={() => <Show />} exact />
            <Route path="/shows" render={() => <Shows />} exact />
            <Redirect from="/shows" to="/shows?status=coming" />
            <Route path="/shops/:showId/products/:productId" render={() => <Show isShop={true} />} exact />
            <Route path="/shops/:showId" render={() => <Show isShop={true} />} exact />
            <Route path="/shops" render={() => <Shops />} exact />
            <Route path="/orders" render={() => <Redirect to="/shipments" />} exact />
            <Route path="/export" render={() => <OrdersExport />} exact />
            <Route path="/account" render={() => <Account />} exact />
            <Route path="/ledger" render={() => <Ledger />} exact />
            <Route component={InventoryContainer} path="/inventory" exact />
            <Route component={Shipments} path="/shipments" exact />
            <Route path="/ratings" render={() => <Ratings />} exact />
            <Route component={Logout} path="/logout" exact />
          </Switch>
        </ErrorBoundary>
      </BrowserRouter>
    </ConfigProvider>
  )
}

const mapStateToProps = (state: any) => {
  return {
    canAccessStripeTransactions: state.authentication.user?.sellerConfig?.canAccessStripeTransactions || false,
    hasSignedLastContract: state.authentication.hasSignedLastSellerContract,
    lastSellerContractDate: state.sellerContract.lastSellerContractDate,
    isDateLoading: state.sellerContract.isDateLoading,
    isContractError: state.sellerContract.isError,
  }
}

export default connect(mapStateToProps)(Router)
