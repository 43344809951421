import { useCallback, useEffect, useState, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useAddBulkProductsToInventoryAndShowMutation } from '@/components/ImportProducts/operations.generated'
import ProductsBulkImporterHowTo from '@/components/ImportProducts/ProductsBulkImporterHowTo/ProductsBulkImporterHowTo'
import {
  guessSellingMode,
  isEmpty,
  sellingModeValueToGraphQlProductType,
  validateRow,
} from '@/components/ImportProducts/ProductsBulkImportForm2/helpers'
import ProductsImportPreviewTable from '@/components/ImportProducts/ProductsBulkImportForm2/ProductsImportPreviewTable/ProductsImportPreviewTable'
import useParseBulkProductImport from '@/components/ImportProducts/useBulkProductImport'
import useDroppable from '@/components/ImportProducts/useDroppable'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import Loader from '@/components/ui/Loader/Loader'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { trackError } from '@/util/sentry'

import type { BulkProductImportError, ProductInput } from '@/components/ImportProducts/ProductsBulkImportForm2/types'
import type { InventoryProductInput } from '@/network/graphql/types.generated'

export const PRODUCT_BULK_IMPORT_MAX_PRODUCTS = 2000

import './ProductsBulkImportForm2.scss'

type ProductsBulkImportForm2Props = {
  showId?: number
  onImported?: () => void
  onCancel?: () => void
}

const ProductsBulkImportForm2 = (props: ProductsBulkImportForm2Props) => {
  const { showId, onImported = () => undefined, onCancel = () => undefined } = props

  const { t } = useTranslation()
  const { parseCSV } = useParseBulkProductImport()

  const [products, setProducts] = useState<ProductInput[]>([])
  const [parseError, setParseError] = useState<string>('')
  const [errors, setErrors] = useState<BulkProductImportError[]>([])
  const [serverErrors, setServerErrors] = useState<string[]>([])
  const [importedCount, setImportedCount] = useState<number>(0)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [isParsing, setIsParsing] = useState(false)
  const [isImporting, setIsImporting] = useState(false)

  const [addBulkProductsToInventoryAndShow] = useAddBulkProductsToInventoryAndShowMutation()

  const handleFileChange = useCallback(
    async (file?: File) => {
      if (!file) {
        return
      }

      setIsParsing(true)
      const { products } = await parseCSV(file)

      if (!products?.length) {
        setParseError(t('productsBulkImporterEmptyErrorMessage'))
        return
      }

      handleUpdateProducts(products || [])

      setIsParsing(false)
    },
    [t]
  )

  const { isDraggingOver, handleDragOver, handleDragLeave, handleDrop } = useDroppable({ onDrop: handleFileChange })

  const handleUpdateProducts = useCallback((newProducts: ProductInput[]) => {
    setProducts(newProducts)
  }, [])

  const addBulkProducts = useCallback(
    (inventoryProducts: InventoryProductInput[]) => {
      const input = { products: inventoryProducts, showId: showId ? `Show|${showId}` : null }
      return addBulkProductsToInventoryAndShow({
        // @ts-expect-error: fix this
        variables: { input },
        onError: (error: any) => {
          trackError(error, {
            meta: { input, scope: 'ProductsBulkImport.addBulkProductsToInventoryAndShow' },
          })
        },
      })
    },
    [addBulkProductsToInventoryAndShow, showId]
  )

  const handleImportProducts = useCallback(
    async (products: InventoryProductInput[]) => {
      if (!products) {
        return
      }

      // const errorsCount = 0
      let i = 0
      const productsLength = products.length

      const serverErrors: string[] = []
      const chunkSize = 10

      while (i < productsLength) {
        if (i >= PRODUCT_BULK_IMPORT_MAX_PRODUCTS) {
          trackError(
            new Error(t('productsBulkImporterTooManyProductsError', { max: PRODUCT_BULK_IMPORT_MAX_PRODUCTS })),
            {
              meta: { productsToImport: products, scope: 'ProductsBulkImport.handleImportProducts' },
            }
          )
          break
        }
        const productsChunk = products.slice(i, i + chunkSize)
        const result = await addBulkProducts(productsChunk)

        // @ts-expect-error: fix this
        const firstGraphQLError = result.errors?.graphQLErrors?.[0]

        const isSuccess = result?.data?.addBulkProductsToInventoryAndShow?.ok === true

        if (firstGraphQLError) {
          const { message, extensions } = firstGraphQLError
          const isImageError = extensions?.translationKey?.includes('invalid-images')
          let errorMessage = message

          if (isImageError) {
            const invalidImagesErrors =
              extensions?.meta?.invalidImages
                ?.map(({ url, reason }: { url: string; reason: string }) => `${url}: ${reason}`)
                .join('\n') || ''
            errorMessage = message + '\n' + invalidImagesErrors
          }

          serverErrors.push(errorMessage)
          break
        } else if (!isSuccess) {
          serverErrors.push(t('productsBulkImporterGenericError'))
          notificationDanger(t('productsBulkImporterGenericError'))
          break
        }

        i += chunkSize
        setImportedCount(i)
      }

      if (serverErrors.length) {
        setServerErrors(serverErrors)
        return
      }

      notificationSuccess(t('productsBulkImporterSuccessMessage'))

      onImported()
    },
    [onImported]
  )

  const resetForm = useCallback(() => {
    setErrors([])
    setProducts([])
    setServerErrors([])
    setSelectedRows([])
    setIsImporting(false)
    setIsParsing(false)
  }, [])

  const handleCancelClick = useCallback(() => {
    resetForm()
    onCancel()
  }, [onCancel, resetForm])

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      if (!products?.length) {
        return
      }

      if (!confirm(t('productsBulkImporterConfirmationMessage', { count: products.length }))) {
        return
      }

      setIsImporting(true)

      const inventoryProducts = products.map((productInput) => {
        const { sellingMode, category, name, description, imagesUrls } = productInput
        const { quantity, startingPrice, instantBuyPrice } = productInput
        const { brand, model, color, size, gender, condition } = productInput
        const { cardType, cardCondition, cardLanguage, cardGrade, cardGradingService } = productInput
        const type = sellingModeValueToGraphQlProductType(
          sellingMode || guessSellingMode(startingPrice, instantBuyPrice)
        )

        return {
          type,
          categoryName: category,
          name: name as string,
          description,
          availableQuantity: parseInt(quantity as unknown as string, 10),
          startingAmount: !isEmpty(startingPrice) ? parseFloat(startingPrice as string) * 100 : undefined,
          fixedAmount: !isEmpty(instantBuyPrice) ? parseFloat(instantBuyPrice as string) * 100 : undefined,
          imagesUrls: imagesUrls ? imagesUrls.split('|') : [],
          brand: brand || null,
          color: color || null,
          model: model || null,
          size: size || null,
          gender: gender || null,
          condition: condition || null,
          cardType: cardType || null,
          cardCondition: cardCondition || null,
          cardLanguage: cardLanguage || null,
          cardGrade: cardGrade || null,
          cardGradingService: cardGradingService || null,
        }
      })

      await handleImportProducts(inventoryProducts)
      setIsImporting(false)
    },
    [products, showId, t, handleImportProducts]
  )

  const handleUpdateSelectedRows = useCallback((newSelectedRows: number[]) => {
    setSelectedRows(newSelectedRows)
  }, [])

  const validateProducts = useCallback(() => {
    const newErrors: BulkProductImportError[] = []
    products.forEach((product, index) => {
      const { isValid, errors: rowErrors } = validateRow(product, index, t)

      if (!isValid) {
        newErrors.push(...rowErrors)
      }
    })

    setErrors(newErrors)
  }, [products])

  useEffect(() => {
    if (!products.length) {
      return
    }

    validateProducts()
  }, [products, validateProducts])

  const isSubmitDisabled = errors.length > 0 || products.length === 0

  if (isParsing) {
    return <Loader />
  }

  return (
    <form className={`bulk-product-import-form`} onSubmit={handleSubmit}>
      {!products?.length && (
        <>
          <ProductsBulkImporterHowTo />
          <div
            className={`bulk-products-import-drop-zone ${isDraggingOver ? 'is-dragging-over' : ''}`}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <label className="placeholder">{t('productsBulkImporterDropzonePlaceholder')}</label>
            <span className="separator">{t('commonOrLabel')}</span>
            <label className="products-bulk-import-button">
              <input
                accept="text/csv"
                className="products-bulk-import-button"
                type="file"
                onChange={async (e: ChangeEvent<HTMLInputElement>) => await handleFileChange(e?.target.files?.[0])}
              />
              {t('bulkImportChooseFileButton')}
            </label>

            {parseError && <div className="error">{parseError}</div>}
          </div>
        </>
      )}
      {products.length > 0 && (
        <div className="wrapper table-wrapper products-import-preview-table-wrapper">
          <ProductsImportPreviewTable
            errors={errors}
            products={products}
            selected={selectedRows}
            onUpdateProducts={handleUpdateProducts}
            onUpdateSelected={handleUpdateSelectedRows}
          />
        </div>
      )}
      {products.length > 0 && (
        <div className="actions">
          {serverErrors?.length > 0 && (
            <Alert className="errors" emphasis="high" type="danger">
              <ul className="errors-list">
                {serverErrors.map((serverError, i) => (
                  <li key={i} className="error-list-item">
                    <span dangerouslySetInnerHTML={{ __html: serverError.replace(/\n/g, '<br/>') }}></span>
                  </li>
                ))}
              </ul>
            </Alert>
          )}
          <Button
            className="secondary cancel-action"
            label={t('productsBulkImporterCancelActionLabel')}
            onClick={handleCancelClick}
          />
          <Button
            className="primary validate-action"
            disabled={isSubmitDisabled}
            isLoading={isImporting}
            label={t('productsBulkImporterValidateActionLabel')}
            type="submit"
          />
          {isImporting && (
            <span className="progress">
              <span className="current">{importedCount}</span>
              <span className="separator">/</span>
              <span className="total">{products?.length}</span>
            </span>
          )}
        </div>
      )}
    </form>
  )
}

export default ProductsBulkImportForm2
