import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { trackEvent } from '@/util/eventTracker'

import { VideoUploader } from './VideoUploader'

import type { CustomUrlPreshow } from '@/types'

export enum VideoUploadedStatus {
  VIDEO_UPLOADED = 'uploaded',
  VIDEO_PREVIOUSLY_UPLOADED = 'previously_uploaded',
  VIDEO_UPLOADING = 'uploading',
  VIDEO_TRANSCODING = 'transcoding',
  NO_VIDEO = 'none',
}

export enum VideoTranscodingStatus {
  SUCCEEDED = 'SUCCEEDED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
}

// 100Mb
const DEFAULT_VIDEO_MAX_FILE = 100000000

enum VIDEO_SUPPORTED_MIME_TYPES {
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
  MOV = 'video/quicktime',
  AVI = 'video/avi',
  WMV = 'video/x-ms-wmv',
}

// Used for "accept" check
const videoSupportedMimeTypes = Object.values(VIDEO_SUPPORTED_MIME_TYPES).join(', ')

// Used for error message
const videoSupportedTypes = Object.keys(VIDEO_SUPPORTED_MIME_TYPES).join(', ').toLocaleLowerCase()

const isVideoTypeSupported = (type: string) => {
  return Object.values(VIDEO_SUPPORTED_MIME_TYPES).includes(type as VIDEO_SUPPORTED_MIME_TYPES)
}

interface VideoUploaderContainerProps {
  label: string
  optional?: boolean
  titlePopover?: string | null
  contentPopover?: any
  contentDragAndDrop?: any
  customUrl: CustomUrlPreshow
  maxSize?: number
  preShowTeaserVideoInfo?: any
  setVideoUploadStatus: (status: VideoUploadedStatus) => void
  videoUploadStatus: VideoUploadedStatus
  onDelete: () => void
  onVideoChange: (info: any) => void
}

export const VideoUploaderContainer = ({
  label,
  optional = true,
  titlePopover,
  contentPopover,
  contentDragAndDrop,
  customUrl,
  maxSize,
  preShowTeaserVideoInfo,
  setVideoUploadStatus,
  videoUploadStatus,
  onDelete,
  onVideoChange,
}: VideoUploaderContainerProps) => {
  const { t } = useTranslation()
  const [parsedUrl, setParsedUrl] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [progress, setProgress] = useState<number>(0)

  const checkVideoFileConformity = (file: File) => {
    if (maxSize ? file.size > maxSize : file.size > DEFAULT_VIDEO_MAX_FILE) {
      throw new Error(t('uploadVideoMaxSizeError'))
    }

    if (!isVideoTypeSupported(file.type)) {
      throw new Error(
        t('uploadVideoAllowedTypeError', {
          fileFormats: videoSupportedTypes,
          interpolation: { escapeValue: false },
        })
      )
    }
    return true
  }

  useEffect(() => {
    if (error !== '') setError('')
    if (customUrl.get !== '') setParsedUrl(customUrl.get)
  }, [customUrl])

  const uploadVideo = async (data: any) => {
    try {
      setError('')
      await checkVideoFileConformity(data.file)
    } catch (error: any) {
      trackEvent('PRESHOW_UPLOAD_ERROR_BEFORE_UPLOAD')
      setError(error.message ?? error)
      setVideoUploadStatus(VideoUploadedStatus.NO_VIDEO)
      if (data.onError) data.onError(error)
      return
    }
    try {
      const res = await axios.put(customUrl.put, data.file, {
        headers: {
          'Content-Type': data.file.type,
          ...(await getAdditionalHeaders()),
        },
        onUploadProgress: (e) => {
          setProgress(e.total ? Math.round((e.loaded / e.total) * 100) : 0)
        },
      })

      if (res.status === 200) {
        trackEvent('PRESHOW_UPLOAD_SUCCESS')
        if (data.onSuccess) data.onSuccess(res)
        setVideoUploadStatus(VideoUploadedStatus.VIDEO_UPLOADED)
      }
    } catch (error: any) {
      if (data.onError) {
        trackEvent('PRESHOW_UPLOAD_FAILED')
        setVideoUploadStatus(VideoUploadedStatus.NO_VIDEO)
        data.onError(error)
      }
    }
  }

  const onChange = (info: any) => {
    onVideoChange(info)
    if (info.file.status === 'done') {
      const newUrl = new URL(customUrl.get)
      newUrl.search = new Date().getTime().toString()
      setParsedUrl(newUrl.toString())
      setVideoUploadStatus(VideoUploadedStatus.VIDEO_UPLOADED)
    } else if (info.file.status === 'uploading') {
      setVideoUploadStatus(VideoUploadedStatus.VIDEO_UPLOADING)
    } else {
      setVideoUploadStatus(VideoUploadedStatus.NO_VIDEO)
    }
  }

  const onVideoDelete = () => {
    setVideoUploadStatus(VideoUploadedStatus.NO_VIDEO)
    onDelete()
  }

  const videoUrl = preShowTeaserVideoInfo?.mp4HdFileInfo?.url || null
  const originalFileInfo = preShowTeaserVideoInfo?.originalFileInfo

  return (
    <VideoUploader
      accept={videoSupportedMimeTypes}
      contentDragAndDrop={contentDragAndDrop}
      customRequest={uploadVideo}
      disabled={videoUploadStatus === VideoUploadedStatus.VIDEO_TRANSCODING}
      errorMessage={error}
      info={optional ? t('videoUploaderOptionnal') : null}
      maxCount={1}
      name="teaser"
      originalFileInfo={originalFileInfo}
      parsedVideoUrl={parsedUrl}
      progress={progress}
      title={label}
      uploadStatus={videoUploadStatus}
      videoUrl={videoUrl}
      noticePopover={
        contentPopover && {
          title: titlePopover,
          content: contentPopover,
        }
      }
      onChange={onChange}
      onRemove={onVideoDelete}
    />
  )
}
