import { TCGCardCondition } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardConditionField/ProductCardConditionField'
import { productCardGradeOptions } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardGradeField/ProductCardGradeField'
import { productCardGradingServicesOptions } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardGradingServiceField/ProductCardGradingServiceField'
import { TCGCardLanguage } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardLanguageField/ProductCardLanguageField'
import { TCGCardType } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardTypeField/ProductCardTypeField'
import {
  BUY_NOW_PRICE_MAX_IN_CENTS,
  BUY_NOW_PRICE_MIN_IN_CENTS,
} from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/ProductBuyNowPriceField/ProductBuyNowPriceField'
import {
  STARTING_PRICE_MAX_IN_CENTS,
  STARTING_PRICE_MIN_IN_CENTS,
} from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/ProductStartingPriceField/ProductStartingPriceField'
import { PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT } from '@/config/constants'
import { fashionProductColorsTranslationKeys } from '@/data/fashionProductColors'
import { fashionProductConditionsTranslationKeys } from '@/data/fashionProductConditions'
import { fashionProductGendersTranslationKeys } from '@/data/fashionProductGenders'
import { ProductType } from '@/network/graphql/types.generated'

import type {
  BulkImportRowValidationResult,
  ProductInput,
} from '@/components/ImportProducts/ProductsBulkImportForm2/types'
import type { TFunction } from 'i18next'

const cardConditionValues = Object.values(TCGCardCondition)
const cardGradingServicesValues = productCardGradingServicesOptions.map(({ value }) => value)
const cardGradeValues = productCardGradeOptions.map(({ value }) => value)
const cardLanguageValues = Object.values(TCGCardLanguage)
const cardTypeValues = Object.values(TCGCardType)

export const isNumber = (value: string) => {
  const parsed = parseInt(value.trim(), 10)
  return !Number.isNaN(parsed)
}

export const isEmpty = (value?: string | null): boolean => {
  return value === undefined || value === null || value?.trim().length === 0
}

export const isValidImageURL = (url: string) => {
  let isValidURL = false
  try {
    new URL(url)
    isValidURL = true
  } catch (e) {
    // Nothing here for the moment
  }
  return /^http(s):\/\/.+/.test(url) && isValidURL
}

export const guessSellingMode = (startingPrice?: string, instantBuyPrice?: string) => {
  if (startingPrice) {
    return 'auction'
  }

  if (!isEmpty(instantBuyPrice)) {
    return 'instant-buy'
  }

  return 'giveaway'
}

export const sellingModeValueToGraphQlProductType = (value: 'auction' | 'instant-buy' | 'giveaway') => {
  switch (value) {
    case 'auction':
      return ProductType.Auction
    case 'instant-buy':
      return ProductType.InstantBuy
    case 'giveaway':
      return ProductType.Giveaway
  }
}

export const parseImagesUrls = (imagesUrls?: string) => {
  return imagesUrls
    ? (imagesUrls || '')
        .replace(/^\|/, '') // Remove any superfluous pipe at the beginning
        .replace(/\|$/, '') // Remove any superfluous pipe at the end
        .replace(/\|\|/g, '|') // Remove any superfluous double pipe
        .split('|') // Split the string by pipe
        .map((image) => image.trim()) // Make sure to remove any leading or trailing spaces in each image URL
        .filter(Boolean)
    : []
}

export const validateRow = (product: ProductInput, index: number, t: TFunction) => {
  const {
    name,
    // description,
    // category,
    sellingMode,
    quantity,
    startingPrice,
    instantBuyPrice,
    imagesUrls: parsedImagesUrls,
    gender,
    // brand,
    // model,
    color,
    // size,
    condition,
    cardType,
    cardLanguage,
    cardGrade,
    cardGradingService,
    cardCondition,
  } = product

  const appliedSellingMode = sellingMode || guessSellingMode(startingPrice, instantBuyPrice)
  const imagesUrls = parseImagesUrls(parsedImagesUrls)
  const invalidImageUrls = (imagesUrls || []).filter((imageUrl) => imageUrl && !isValidImageURL(imageUrl))

  const errors = []

  // Name
  if (!name) {
    errors.push({ row: index, property: 'name', code: 'required', message: 'Missing name' })
  }

  // Category
  // if (!category) {
  //   errors.push({ row: index, property: 'category', code: 'missing', message: 'Choose a category' })
  // }

  // Selling mode
  if (!appliedSellingMode) {
    errors.push({ row: index, property: 'sellingMode', code: 'required', message: 'Choose a selling mode' })
  }

  // Quantity
  if (isEmpty(quantity)) {
    errors.push({ row: index, property: 'quantity', type: 'required', message: 'Missing quantity' })
  }
  if (!isEmpty(quantity) && !isNumber(quantity as string)) {
    errors.push({ row: index, property: 'quantity', type: 'format', message: 'Invalid quantity' })
  }

  // Starting price
  if (sellingMode === 'auction' && isEmpty(startingPrice)) {
    errors.push({ row: index, property: 'startingPrice', type: 'required', message: 'Missing starting price' })
  }
  if (!isEmpty(startingPrice) && !isNumber(startingPrice as string)) {
    errors.push({ row: index, property: 'startingPrice', type: 'format', message: 'Invalid starting price' })
  }
  if (!isEmpty(startingPrice) && isNumber(startingPrice as string)) {
    const sanitizedStartingPrice = parseInt(startingPrice as string)

    if (sanitizedStartingPrice !== parseFloat(startingPrice as string)) {
      errors.push({
        row: index,
        property: 'startingPrice',
        type: 'format',
        message: t('startingPriceShouldBeInt'),
      })
    }
    if (sanitizedStartingPrice < STARTING_PRICE_MIN_IN_CENTS / 100) {
      errors.push({
        row: index,
        property: 'startingPrice',
        type: 'format',
        message: t('bulkProductsImportStartingPriceMinErrorMessage', { min: STARTING_PRICE_MIN_IN_CENTS / 100 }),
      })
    }
    if (sanitizedStartingPrice > STARTING_PRICE_MAX_IN_CENTS / 100) {
      errors.push({
        row: index,
        property: 'startingPrice',
        type: 'format',
        message: t('bulkProductsImportStartingPriceMaxErrorMessage', { max: STARTING_PRICE_MAX_IN_CENTS / 100 }),
      })
    }
  }

  // Instant buy price
  if (sellingMode === 'instant-buy' && isEmpty(instantBuyPrice)) {
    errors.push({ row: index, property: 'instantBuyPrice', type: 'required', message: 'Missing instant buy price' })
  }
  if (!isEmpty(instantBuyPrice) && !isNumber(instantBuyPrice as string)) {
    errors.push({ row: index, property: 'instantBuyPrice', type: 'format', message: 'Invalid instant buy price' })
  }
  if (!isEmpty(instantBuyPrice) && isNumber(instantBuyPrice as string)) {
    const sanitizedInstantBuyPrice = parseInt(instantBuyPrice as string)

    if (sanitizedInstantBuyPrice < BUY_NOW_PRICE_MIN_IN_CENTS / 100) {
      errors.push({
        row: index,
        property: 'instantBuyPrice',
        type: 'format',
        message: t('bulkProductsImportBuyNowPriceMinErrorMessage', { min: BUY_NOW_PRICE_MIN_IN_CENTS / 100 }),
      })
    }
    if (sanitizedInstantBuyPrice > BUY_NOW_PRICE_MAX_IN_CENTS / 100) {
      errors.push({
        row: index,
        property: 'instantBuyPrice',
        type: 'format',
        message: t('bulkProductsImportBuyNowPriceMaxErrorMessage', { max: BUY_NOW_PRICE_MAX_IN_CENTS / 100 }),
      })
    }

    if (
      sanitizedInstantBuyPrice &&
      !isEmpty(startingPrice) &&
      isNumber(startingPrice as string) &&
      sanitizedInstantBuyPrice <= parseInt(startingPrice as string)
    ) {
      errors.push({
        row: index,
        property: 'instantBuyPrice',
        type: 'format',
        message: t('instantBuyPriceShouldNotBeLowerThanStarting'),
      })
    }
  }

  // Color
  if (!isEmpty(color) && !fashionProductColorsTranslationKeys.map((key) => t(key)).includes(color as string)) {
    errors.push({ row: index, property: 'color', type: 'format', message: 'Invalid color' })
  }

  // Gender
  if (!isEmpty(gender) && !fashionProductGendersTranslationKeys.map((key) => t(key)).includes(gender as string)) {
    errors.push({ row: index, property: 'gender', type: 'format', message: 'Invalid gender' })
  }

  // Condition
  if (
    !isEmpty(condition) &&
    !fashionProductConditionsTranslationKeys.map((key) => t(key)).includes(condition as string)
  ) {
    errors.push({ row: index, property: 'condition', type: 'format', message: 'Invalid condition' })
  }

  // Card Type
  if (!isEmpty(cardType) && !cardTypeValues.includes(cardType as TCGCardType)) {
    errors.push({ row: index, property: 'cardType', type: 'format', message: 'Invalid card type' })
  }

  // Card Language
  if (!isEmpty(cardLanguage) && !cardLanguageValues.includes(cardLanguage as TCGCardLanguage)) {
    errors.push({ row: index, property: 'cardLanguage', type: 'format', message: 'Invalid card language' })
  }

  // Card Grade
  if (!isEmpty(cardGrade) && !cardGradeValues.includes(cardGrade as string)) {
    errors.push({ row: index, property: 'cardGrade', type: 'format', message: 'Invalid card grade' })
  }

  // Card Grading Service
  if (!isEmpty(cardGradingService) && !cardGradingServicesValues.includes(cardGradingService as string)) {
    errors.push({ row: index, property: 'cardGradingService', type: 'format', message: 'Invalid grading service' })
  }

  // Card condition
  if (!isEmpty(cardCondition) && !cardConditionValues.includes(cardCondition as TCGCardCondition)) {
    errors.push({ row: index, property: 'cardCondition', type: 'format', message: 'Invalid card condition' })
  }

  // Images
  if (imagesUrls.length > PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT) {
    errors.push({
      row: index,
      property: 'imagesUrls',
      type: 'format',
      message: t('bulkProductsImportMaxImagesErrorMessage', { max: PRODUCT_IMAGES_MAX_IMAGES_PER_PRODUCT }),
    })
  }
  if (invalidImageUrls.length) {
    errors.push({ row: index, property: 'imagesUrls', type: 'format', message: 'Invalid image(s)' })
  }

  const isValid = !errors.length

  return { isValid, errors } as BulkImportRowValidationResult
}
