import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { computeColorFieldOptions } from '@/components/CreateOrEditProduct2/CreateOrEditProduct2.helpers'
import Field from '@/components/Form/Field/Field'

import type React from 'react'

type ProductColorFieldProps = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

const ProductColorField = (props: ProductColorFieldProps) => {
  const { value: color, onChange: handleColorChange, disabled = false } = props
  const { t } = useTranslation()

  const colorOptions = useMemo(() => computeColorFieldOptions(t), [t])

  return (
    <Field
      disabled={disabled}
      label={t('productFormColorLabel')}
      name="color"
      options={colorOptions}
      placeholder={t('productFormColorPlaceholder')}
      type="select"
      value={color}
      onChange={handleColorChange}
    />
  )
}

export default ProductColorField
