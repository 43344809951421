import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import Alert from '../ui/Alert/Alert'

import './MaintenanceBanner.scss'

export const MaintenanceBanner = () => {
  const { t } = useTranslation()

  if (dayjs().isBefore(dayjs('2024-05-17 18:00'))) return null
  if (dayjs().isAfter(dayjs('2024-05-22 06:00'))) return null

  return (
    <Alert className="maintenance-alert" emphasis="low" title={t('maintenanceBannerTitle')} type="warning" dismissible>
      <span>{t('maintenanceBannerText')}</span>
    </Alert>
  )
}
