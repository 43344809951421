import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle } from 'react-icons/fa'
import { FaArrowUp, FaBell, FaGavel } from 'react-icons/fa6'
import { useHistory } from 'react-router-dom'

import ColumnImageAndName from '@/components/ProductsColumns/ColumnImageAndName'
import Link from '@/components/ui/Link/Link'
import Popover from '@/components/ui/Popover/Popover'
import { useShow } from '@/contexts/show/Show.context'
import { useCurrencyFormatter } from '@/helpers/currencyFormatter'
import {
  ProductSortColumn,
  ProductStatusInAuction,
  SortDirection,
  ProductType,
} from '@/network/graphql/types.generated'

import Card from '../../ui/Card/Card'
import Table from '../../ui/Table/Table'
import DuplicateButton from '../DuplicateButton/DuplicateButton'
import FollowsAndAutoBidsCount from '../FollowsAndAutoBidsCounts/FollowsAndAutoBidsCounts'
import LaunchActivityButton from '../LaunchActivityButton/LaunchActivityButton'

import type { Product } from '@/network/graphql/types.generated'
import type { SortingState } from '@tanstack/react-table'

import './Products.scss'

interface ProductsProps {
  isActivityRunning: boolean
  onProductEditClick: (product: Product) => void
  onProductDuplicateClick: (product: Product) => void
  launchAuction: (product: Product) => void
  handleUpdateProductToFirstPosition: (productId: string, productType: ProductType) => Promise<void>
  isShowBroadcasting?: boolean
  onSelectedRows: (selectedProducts: string[]) => void
  selectedRows: string[]
}

const sortingOptionsMap: { [key: string]: ProductSortColumn } = {
  name: ProductSortColumn.Name,
  startingAmount: ProductSortColumn.StartingAmount,
  followingUsersCount: ProductSortColumn.FollowingUsersCount,
  availableQuantity: ProductSortColumn.AvailableQuantity,
  createdAt: ProductSortColumn.CreatedAt,
}
function AuctionProducts(props: ProductsProps) {
  const {
    itemsPerPage,
    handleSetItemsPerPage,

    loadingAuctionProducts,
    auctions,
    totalAuctions,
    fetchAuctionsProducts,
    setAuctionSortBy,
    auctionSortBy,
    currentPageAuction: currentPage,
    handleAuctionPageChange,
  } = useShow()

  const {
    isActivityRunning,
    isShowBroadcasting,
    selectedRows,
    handleUpdateProductToFirstPosition,
    onProductEditClick,
    launchAuction,
    onProductDuplicateClick,
    onSelectedRows,
  } = props

  const { t } = useTranslation()

  const history = useHistory()
  const currencyFormatter = useCurrencyFormatter()

  const [sorting, setSorting] = useState<SortingState>([])
  const [loading, setLoading] = useState(false)

  const onHandleUpdateProductToFirstPosition = async (id: string) => {
    await handleUpdateProductToFirstPosition(id, ProductType.Auction)
    handleAuctionPageChange(1)
  }

  const handleQuickAdd = () => {
    const currentUrl = window.location.pathname
    const url = `${currentUrl}products/new?productType=auction`
    history.push(url)
  }

  const auctionColumns = [
    {
      header: t('auctionProductNameColumn'),
      id: 'name',
      accessorKey: 'name',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const { name, images, description, color, id } = record
        return (
          <ColumnImageAndName
            color={color}
            description={description}
            id={id}
            image={images?.[0]?.webPUrl}
            name={name}
            onClick={() => onProductEditClick(record)}
          />
        )
      },
    },
    {
      header: t('auctionProductStartingAmountColumn'),
      id: 'startingAmount',
      accessorKey: 'startingAmount',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const { startingAmount, currency } = record
        return (
          <div className="column-amount" onClick={() => onProductEditClick(record)}>
            <span className="amount">{currencyFormatter(startingAmount, currency)}</span>
          </div>
        )
      },
    },
    {
      header: (
        <div className="column-quantity">
          {t('auctionProductQuantityColumn')}
          <Popover
            content={
              <div className="following-user-tooltip">
                <p>
                  <FaBell className="text-gray-400" size={16} />
                  {t('showProductsListTableColumnInfoHelperPopoverProductFollowersNotice')}
                </p>
                <p>
                  <FaGavel className="text-gray-400" size={16} />
                  {t('showProductsListTableColumnInfoHelperPopoverAutoBidNotice')}
                </p>
              </div>
            }
          >
            <FaInfoCircle />
          </Popover>
        </div>
      ),
      id: 'availableQuantity',
      accessorKey: 'availableQuantity',
      enableColumnFilter: false,
      cell: (item: any) => {
        const record = item.row.original
        const { availableQuantity, followingUsersCount, preBidsInfo } = record
        return (
          <div className="column-quantity" onClick={() => onProductEditClick(record)}>
            <span className={`quantity ${availableQuantity === 0 && 'sold-out'}`}>{availableQuantity}</span>
            <FollowsAndAutoBidsCount
              autoBidsCount={preBidsInfo ? preBidsInfo.preBidsCount : 0}
              followingUsersCount={followingUsersCount}
            />
          </div>
        )
      },
    },
    {
      header: t('auctionLaunchColumn'),
      id: 'launch',
      enableColumnFilter: false,
      enableColumnSort: false,
      cell: (item: any) => {
        const record = item.row.original
        const { status, id } = record
        const isAlreadySold = status === ProductStatusInAuction.Sold

        const isDisabled = record.availableQuantity === 0 || !isShowBroadcasting || isActivityRunning || isAlreadySold

        return (
          <div className="column-action">
            <Popover content={t('showProductsListPinnedItemTooltip')}>
              <span className="pin">
                <FaArrowUp className="pin" onClick={() => onHandleUpdateProductToFirstPosition(id)} />
              </span>
            </Popover>
            <DuplicateButton product={record} onProductDuplicateClick={onProductDuplicateClick} />
            <LaunchActivityButton
              disabled={isDisabled}
              product={record}
              status={status}
              disabledReason={
                record.availableQuantity === 0 || isAlreadySold
                  ? 'no-quantity'
                  : isActivityRunning
                    ? 'activity-running'
                    : 'not-broadcasting'
              }
              onLaunch={launchAuction}
            />
          </div>
        )
      },
    },
  ]

  const handleItemPerPageChange = async (itemsPerPage: number) => {
    handleSetItemsPerPage(itemsPerPage)
    handleAuctionPageChange(1)
  }

  const handlePageChange = async (page: number) => {
    handleAuctionPageChange(page)
    setLoading(true)
    await fetchAuctionsProducts(page)
    setLoading(false)
  }

  const handleSortingChange = async (sort: SortingState) => {
    setSorting(sort)
    setLoading(true)
    handleAuctionPageChange(1)
    if (!sort[0]) {
      setAuctionSortBy(undefined)
    } else {
      setAuctionSortBy({
        column: sortingOptionsMap[sort[0]?.id],
        direction: sort[0]?.desc ? SortDirection.Desc : SortDirection.Asc,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (auctionSortBy === undefined) {
      setSorting([])
    }
  }, [auctionSortBy])

  return (
    <Card className="auction-products products-list" noPadding={true}>
      {auctions && auctions.length === 0 && totalAuctions === 0 && !loadingAuctionProducts ? (
        <div className="no-products">
          <svg fill="none" height="53" viewBox="0 0 53 53" width="53" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.2661 32.3662L20.0348 32.5975L20.1542 32.9021C21.0217 35.1163 20.5603 37.729 18.7728 39.5166C16.3645 41.9248 12.46 41.9248 10.0518 39.5166L2.5093 31.9739L2.15574 32.3275L2.5093 31.9739C0.101068 29.5657 0.101068 25.6612 2.5093 23.253C4.29683 21.4655 6.90957 21.0041 9.12356 21.8717L9.42818 21.991L9.65954 21.7597L21.7577 9.66147L21.9891 9.43011L21.8697 9.12548C21.0021 6.9115 21.4636 4.29876 23.2511 2.51125L22.903 2.16314L23.2511 2.51125C25.6593 0.103021 29.5638 0.103021 31.972 2.51125L32.3255 2.1577L31.972 2.51126L39.5146 10.0537C41.9228 12.4619 41.9228 16.3665 39.5146 18.7747C37.7271 20.5622 35.1144 21.0237 32.9002 20.1561L32.5956 20.0367L32.3642 20.2681L30.2549 22.3774L29.8752 22.7571L30.281 23.1088L49.9943 40.1938L50.3218 39.816L49.9943 40.1938C53.042 42.835 53.2087 47.5071 50.357 50.3587C47.5054 53.2103 42.8333 53.0436 40.1922 49.9963L23.1071 30.2827L22.7554 29.8769L22.3757 30.2566L20.2661 32.3662Z"
              fill="#222C36"
              fillOpacity="0.5"
              stroke="black"
            />
          </svg>

          <p>{t('noAuctionProducts')}</p>
          <Link onClick={handleQuickAdd}>{t('quickAddAuctionProduct')}</Link>
        </div>
      ) : (
        <Table
          columns={auctionColumns}
          data={auctions}
          loading={loadingAuctionProducts || loading}
          pagination={{
            total: totalAuctions,
            currentPage: currentPage,
            perPage: itemsPerPage,
            onPageChange: handlePageChange,
            onItemPerPageChange: handleItemPerPageChange,
          }}
          selectable={{
            selectedRows,
            onSelectedRowsChange: onSelectedRows,
          }}
          sort={{
            sorting,
            onSortingChange: handleSortingChange,
          }}
          onQuickAdd={handleQuickAdd}
        />
      )}
    </Card>
  )
}

export default memo(AuctionProducts)
