import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Field from '@/components/Form/Field/Field'
import Form from '@/components/Form/Form'
import StickerPdf from '@/components/StickerPdf/StickerPdf'
import Alert from '@/components/ui/Alert/Alert'
import Button from '@/components/ui/Button/Button'
import { notificationDanger, notificationSuccess } from '@/components/ui/Notification/Notification'
import { useUser } from '@/contexts/user/User.context'
import { SHIPPING_PROVIDER_HAND_DELIVERY_VALUE } from '@/data/shippingProvidersByCountry'
import { getShippingProvidersForCountry } from '@/helpers/getShippingProvidersForCountry'
import { ShipmentStatus } from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'

import {
  useDispatchShipmentByOwnMethodsMutation,
  useUpdateShipmentShippingInfoByOwnMethodsMutation,
} from './operations.generated'

import type {
  GetSellerShipmentsFieldsFragment,
  ShipmentFieldsFragment,
  UnShippedShipmentsFieldsFragment,
} from '../../operations.generated'
import type { ChangeEvent } from 'react'

type CustomShippingProps = {
  shipment: UnShippedShipmentsFieldsFragment | ShipmentFieldsFragment | GetSellerShipmentsFieldsFragment
  onCompleted: () => void
}

type SelectAndCheckboxChangeEvent = ChangeEvent<HTMLSelectElement>

type ShippingProviderOption = { label: string; value: string }

export const CustomShipping = (props: CustomShippingProps) => {
  const { t } = useTranslation()
  const { shipment, onCompleted } = props

  const { user } = useUser()
  const { sellerConfig, shippingAddresses } = user || {}
  const { shippingAddress } = sellerConfig || {}

  const shippingProviderOptions: ShippingProviderOption[] = useMemo(() => {
    const sellerAddressCountryCode = shippingAddress
      ? shippingAddress.countryIso2Code
      : shippingAddresses?.[0]?.resolvedCountry?.iso2Code
    return getShippingProvidersForCountry(sellerAddressCountryCode)
  }, [user])

  const { shippingProvider: currentShippingProvider, trackingNumber: currentTrackingNumber } = shipment

  const [shippingProvider, setShippingProvider] = useState<string | null>(
    currentShippingProvider ?? shippingProviderOptions[0].value
  )
  const [trackingNumber, setTrackingNumber] = useState<string | null>(currentTrackingNumber ?? null)
  const [refundShippingFeesValue, setRefundShippingFeesValue] = useState<boolean>(false)

  const isHandDelivery = !!shippingProvider && shippingProvider === SHIPPING_PROVIDER_HAND_DELIVERY_VALUE
  const isTrackingNumberRequired = !isHandDelivery
  const isFormValid = useMemo(() => {
    return isHandDelivery ? Boolean(shippingProvider) : Boolean(shippingProvider && trackingNumber)
  }, [shippingProvider, trackingNumber])

  const handleShippingProviderChange = useCallback((event: SelectAndCheckboxChangeEvent) => {
    setShippingProvider(event.target.value)
    setRefundShippingFeesValue(false)
  }, [])

  const handleTrackingNumberChange = useCallback((event: ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value
    setTrackingNumber(value.toUpperCase())
  }, [])

  const handleRefundShippingFeesChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    setRefundShippingFeesValue(checked)
  }, [])

  const [dispatchShipmentByOwnMethods] = useDispatchShipmentByOwnMethodsMutation({
    onCompleted: () => {
      notificationSuccess(t('orderTableShippingModalCustomSuccessShipped'))
      onCompleted()
    },
    onError: (error) => {
      notificationDanger(error?.message)
    },
    update: () => {},
  })
  const [updateShipmentShippingInfoByOwnMethods] = useUpdateShipmentShippingInfoByOwnMethodsMutation({
    onCompleted: () => {
      notificationSuccess(t('orderTableShippingModalCustomSuccessTrackingNumber'))
      onCompleted()
    },
    onError: (error) => {
      notificationDanger(error?.message)
    },
  })

  const handleSubmit = useCallback(async () => {
    const shipmentId = shipment.id

    trackEvent('SHIPMENT_SHIPPED_CUSTOM', { shipmentId })

    const formattedTrackingNumber = trackingNumber?.replace(/\s/g, '') || null

    if (!shippingProvider) {
      return
    }

    const input = {
      shipmentId,
      shippingProvider,
      trackingNumber: formattedTrackingNumber,
    }

    if (shipment.status === ShipmentStatus.Shipped) {
      await updateShipmentShippingInfoByOwnMethods({
        variables: { input },
      })
    } else {
      await dispatchShipmentByOwnMethods({
        variables: {
          input: {
            ...input,
            shouldRefundShippingFeesToCustomer: isHandDelivery && refundShippingFeesValue,
          },
        },
      })
    }
    onCompleted()
  }, [shipment, shippingProvider, trackingNumber, refundShippingFeesValue])

  return (
    <Form className="custom-shipment-form" onSubmit={handleSubmit}>
      <Alert className="custom-shipment-sticker-alert" type="info">
        <StickerPdf />
      </Alert>

      <Field
        label={t('orderTableShippingModalCustomFormInputProviderTitle')}
        name="shippingProvider"
        options={shippingProviderOptions}
        type="select"
        value={shippingProvider || ''}
        required
        onChange={handleShippingProviderChange}
      />
      {isTrackingNumberRequired && (
        <Field
          label={t('orderTableShippingModalCustomFormInputTrackingNumberTitle')}
          name="trackingNumber"
          type="text"
          value={trackingNumber || ''}
          required
          onChange={handleTrackingNumberChange}
        />
      )}
      {isHandDelivery && !currentShippingProvider && (
        <Field
          checked={refundShippingFeesValue}
          label={t('orderTableShippingModalCustomFormCheckboxRefundShippingFeesMessage')}
          name="refundShippingFees"
          type="checkbox"
          onChange={handleRefundShippingFeesChange}
        />
      )}

      <div className="custom-shipment-form-button">
        <Button className="primary" disabled={!isFormValid} label={t('commonValidate')} type="submit" />
      </div>
    </Form>
  )
}
