import { Popover } from 'antd'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa6'

import Button from '@/components/ui/Button/Button'
import { useGetSellerConfigQuery } from '@/network/graphql/common/query/operations.generated'

import { useSellerTier } from '../SellerTier/hooks/use-seller-tier'
import Link from '../ui/Link/Link'

type CreateShowButtonProps = {
  handleOnClick: (value: boolean) => void
  isShop?: boolean
}

enum CANT_CREATE_SHOW_REASON {
  seller_tier = 'seller_tier',
  seller_config = 'seller_config',
}

// TODO : Refactor this component to use the new seller tier "canCreateShow" instead of the seller config

const CreateShowButton = ({ handleOnClick, isShop }: CreateShowButtonProps) => {
  const { t } = useTranslation()
  const { sellerTier } = useSellerTier()

  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [cantCreateShowReason, setCantCreateShowReason] = useState<CANT_CREATE_SHOW_REASON | null>(null)

  const { data: getSellerConfigData } = useGetSellerConfigQuery()

  const canCreateShow = useMemo(() => {
    // We test the seller config first because it's the most restrictive and it must prevail
    if (!getSellerConfigData?.viewer?.sellerConfig?.canCreateShow) {
      setCantCreateShowReason(CANT_CREATE_SHOW_REASON.seller_config)
      return false
    }
    if (!sellerTier?.canCreateShow) {
      setCantCreateShowReason(CANT_CREATE_SHOW_REASON.seller_tier)
      return false
    }
    return true
  }, [getSellerConfigData, sellerTier])

  const UnauthorizedPopoverMessage = () => {
    if (cantCreateShowReason === CANT_CREATE_SHOW_REASON.seller_config) {
      return (
        <span>
          {t('createShowButtonUnauthorizedPopoverMessage')}{' '}
          <Link className="!text-white" href={`mailto:${t('commonSupportContactEmailAddressForSellers')}`}>
            {t('commonSupportContactEmailAddressForSellers')}
          </Link>
        </span>
      )
    }
    if (cantCreateShowReason === CANT_CREATE_SHOW_REASON.seller_tier) {
      return (
        <span>
          <Trans
            i18nKey="sellerTierCannotCreateShowButtonPopover"
            components={{
              1: <Link className="!text-white" href="/account"></Link>,
            }}
          />
        </span>
      )
    }
  }

  return (
    <Popover
      content={UnauthorizedPopoverMessage}
      open={!canCreateShow && isPopoverVisible}
      overlayStyle={{ width: '90%', maxWidth: '300px' }}
      placement="leftTop"
      onOpenChange={(isVisible) => setIsPopoverVisible(isVisible)}
    >
      <Button
        className="go-to-action primary"
        disabled={!canCreateShow}
        icon={<FaPlus />}
        label={isShop ? t('calendarButtonCreateShop') : t('calendarButtonCreateShow')}
        onClick={() => handleOnClick(true)}
      />
    </Popover>
  )
}

export default CreateShowButton
