import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'

import { useGetFeaturedShowApplicationCriteriaQuery } from '../../../../operations.generated'
import EligibilityCard from '../EligibilityCard'

type ShowEventApplicationFormStep1Props = {
  showId: string
  handleNextSlide: () => void
  setFeaturedShowTargetInEuros: (value: number) => void
}

const ShowEventApplicationFormStep1 = ({
  showId,
  handleNextSlide,
  setFeaturedShowTargetInEuros,
}: ShowEventApplicationFormStep1Props) => {
  const { t } = useTranslation()

  const { data, loading: auctionDurationLoading } = useGetFeaturedShowApplicationCriteriaQuery({
    skip: !showId,
    variables: { showId: showId },
  })

  const {
    hasDoneEnoughShowsOnVoggt = false,
    isSellerRatingHighEnough = false,
    hasCreatedEnoughItemsWithPhotos = false,
    isShowDateInFutureEnough = false,
    featuredShowTarget: { isShowGMVTargetHighEnough = false, featuredShowTargetInEuros = 0 } = {},
  } = data?.node?.__typename === 'Show' ? data.node.featuredShowApplicationCriteria : {}

  setFeaturedShowTargetInEuros(featuredShowTargetInEuros)

  const canApplyToShowEvent =
    hasDoneEnoughShowsOnVoggt &&
    isSellerRatingHighEnough &&
    hasCreatedEnoughItemsWithPhotos &&
    isShowDateInFutureEnough &&
    isShowGMVTargetHighEnough

  const criteria = [
    { isCompleted: hasDoneEnoughShowsOnVoggt, title: t('showEventApplicationCriteriaHasDoneEnoughShowsOnVoggt') },
    { isCompleted: isSellerRatingHighEnough, title: t('showEventApplicationCriteriaIsSellerRatingHighEnough') },
    {
      isCompleted: hasCreatedEnoughItemsWithPhotos,
      title: t('showEventApplicationCriteriaHasCreatedEnoughItemsWithPhotos'),
    },
    { isCompleted: isShowDateInFutureEnough, title: t('showEventApplicationCriteriaIsShowDateInFutureEnough') },
    {
      isCompleted: isShowGMVTargetHighEnough,
      title: t('showEventApplicationCriteriaIsShowGMVTargetHighEnough', { featuredShowTargetInEuros }),
    },
  ]

  return (
    <div className="form-step1">
      <div className="criteria-list">
        {criteria.map((criterium) => (
          <EligibilityCard key={criterium.title} isCompleted={criterium.isCompleted} title={criterium.title} />
        ))}
      </div>
      <div className="next-slide-container">
        <Button
          className="primary next-slide-button"
          disabled={!canApplyToShowEvent || auctionDurationLoading}
          onClick={handleNextSlide}
        >
          {t('commonNext')}
        </Button>
      </div>
    </div>
  )
}

export default ShowEventApplicationFormStep1
