import * as Types from '../../../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShowQueryVariables = Types.Exact<{
  nodeId: Types.Scalars['ID']['input'];
}>;


export type GetShowQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign' } | { __typename?: 'AutocompleteSearchItem' } | { __typename?: 'Badge' } | { __typename?: 'Category' } | { __typename?: 'CommercialEvent' } | { __typename?: 'Country' } | { __typename?: 'Emote' } | { __typename?: 'FavoriteProduct' } | { __typename?: 'FavoriteShow' } | { __typename?: 'ImageUpload' } | { __typename?: 'InventoryProduct' } | { __typename?: 'Language' } | { __typename?: 'Order' } | { __typename?: 'OrderedProduct' } | { __typename?: 'ParentCategory' } | { __typename?: 'Product' } | { __typename?: 'ProductForOrdering' } | { __typename?: 'Promotion' } | { __typename?: 'PromotionV2' } | { __typename?: 'RatingFromCustomer' } | { __typename?: 'SellerLedgerPayout' } | { __typename?: 'SellerLedgerTransaction' } | { __typename?: 'SellerTier' } | { __typename?: 'Shipment' } | { __typename?: 'Shop' } | { __typename?: 'Show', id: string, shippingAmount?: number | null, totalNetIncomeInCurrencyCents: number, totalCommissionInCurrencyCents: number, totalAmountOrdersSuccessInCurrencyCents: number, totalDeductedShippingCostInCurrencyCents: number, totalAmountOrdersNetInCurrencyCents: number, totalAmountRefundedInCurrencyCents: number, totalShippingFeesInCurrencyCents: number, totalProductsSoldInCurrencyCents: number } | { __typename?: 'ShowOrdersGroup' } | { __typename?: 'ShowProductNode' } | { __typename?: 'User' } };


export const GetShowDocument = gql`
    query GetShow($nodeId: ID!) {
  node(id: $nodeId) {
    ... on Show {
      id
      shippingAmount
      totalNetIncomeInCurrencyCents
      totalCommissionInCurrencyCents
      totalAmountOrdersSuccessInCurrencyCents
      totalDeductedShippingCostInCurrencyCents
      totalAmountOrdersNetInCurrencyCents
      totalAmountRefundedInCurrencyCents
      totalShippingFeesInCurrencyCents
      totalProductsSoldInCurrencyCents
    }
  }
}
    `;

/**
 * __useGetShowQuery__
 *
 * To run a query within a React component, call `useGetShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetShowQuery(baseOptions: Apollo.QueryHookOptions<GetShowQuery, GetShowQueryVariables> & ({ variables: GetShowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShowQuery, GetShowQueryVariables>(GetShowDocument, options);
      }
export function useGetShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShowQuery, GetShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShowQuery, GetShowQueryVariables>(GetShowDocument, options);
        }
export function useGetShowSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShowQuery, GetShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShowQuery, GetShowQueryVariables>(GetShowDocument, options);
        }
export type GetShowQueryHookResult = ReturnType<typeof useGetShowQuery>;
export type GetShowLazyQueryHookResult = ReturnType<typeof useGetShowLazyQuery>;
export type GetShowSuspenseQueryHookResult = ReturnType<typeof useGetShowSuspenseQuery>;
export type GetShowQueryResult = Apollo.QueryResult<GetShowQuery, GetShowQueryVariables>;