import { useCallback, useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { createParentCategoriesOptionGroups } from '@/components/CreateOrEditProduct2/CreateOrEditProduct2.helpers'
import { computeCardConditionFieldOptions } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardConditionField/ProductCardConditionField'
import { productCardGradeOptions } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardGradeField/ProductCardGradeField'
import { productCardGradingServicesOptions } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardGradingServiceField/ProductCardGradingServiceField'
import { computeCardLanguageFieldOptions } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardLanguageField/ProductCardLanguageField'
import { computeCardTypeFieldOptions } from '@/components/CreateOrEditProduct2/CreateOrEditProductForm2/_fields/_productAttributes/tcg/ProductCardTypeField/ProductCardTypeField'
import useParentCategories from '@/components/CreateOrEditProduct2/useParentCategories'
import Contenteditable from '@/components/ImportProducts/ProductsBulkImportForm2/Contenteditable/Contenteditable'
import { guessSellingMode } from '@/components/ImportProducts/ProductsBulkImportForm2/helpers'
import CategoryCell from '@/components/ImportProducts/ProductsBulkImportForm2/ProductsImportPreviewTable/CategoryCell/CategoryCell'
import ImagesCell from '@/components/ImportProducts/ProductsBulkImportForm2/ProductsImportPreviewTable/ImagesCell/ImagesCell'
import ProductPreviewTableHeaders from '@/components/ImportProducts/ProductsBulkImportForm2/ProductsImportPreviewTable/ProductsPreviewTableHeaders/ProductsPreviewTableHeaders'
import { fashionProductColorsTranslationKeys } from '@/data/fashionProductColors'
import { fashionProductConditionsTranslationKeys } from '@/data/fashionProductConditions'
import { fashionProductGendersTranslationKeys } from '@/data/fashionProductGenders'
// import { dasherize } from '@/util/strings'

import type { SelectOption } from '@/components/Form/Field/Select/Select'
import type { BulkProductImportError, ProductInput } from '@/components/ImportProducts/ProductsBulkImportForm2/types'

import './ProductsImportPreviewTable.scss'

type ProductsImportPreviewTableProps = {
  products: ProductInput[]
  errors: BulkProductImportError[]
  selected: number[]
  onUpdateProducts: (products: ProductInput[]) => void
  onUpdateSelected: (selected: number[]) => void
}

const ProductsImportPreviewTable = (props: ProductsImportPreviewTableProps) => {
  const { products, selected, errors, onUpdateProducts, onUpdateSelected } = props
  const { t } = useTranslation()

  const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedRows = event.target.checked ? products.map((_, index) => index) : []
    onUpdateSelected(newSelectedRows)
  }

  const handleChangeAllCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value

    if (!confirm(`Apply this to all products below?\nYou will still be able to update then individually after that.`)) {
      return false
    }

    const newProducts = products.map((product) => ({ ...product, category }))
    onUpdateProducts(newProducts)
  }

  const handleSetAllSellingMode = (event: ChangeEvent<HTMLSelectElement>) => {
    const sellingMode = event.target.value

    if (!confirm(`Apply this to all products below?\nYou will still be able to update then individually after that.`)) {
      return false
    }

    const newProducts = products.map((product) => {
      return {
        ...product,
        sellingMode,
      } as ProductInput
    })
    onUpdateProducts(newProducts)
  }

  const handleToggleRow = (index: number) => {
    const newSelectedRows = selected.includes(index)
      ? selected.filter((selectedIndex) => selectedIndex !== index)
      : [...selected, index]

    onUpdateSelected(newSelectedRows)
  }

  const handleUpdateValue = useCallback(
    (property: string, index: number, value: string | number | string[]) => {
      const newProducts = [...products]
      // @ts-expect-error: fix this properly?
      newProducts[index][property] = value

      if (property === 'sellingMode') {
        if (value === 'giveaway') {
          handleUpdateValue('startingPrice', index, '')
          handleUpdateValue('instantBuyPrice', index, '')
        }
      }

      onUpdateProducts(newProducts)
    },
    [products, onUpdateProducts]
  )

  const { parentCategories } = useParentCategories()
  const fashionColorOptions = useMemo(
    () =>
      [{ label: '', value: '' }].concat(
        Object.values(fashionProductColorsTranslationKeys).map((color) => ({
          // value: dasherize(color.replace('color', '')),
          value: t(color),
          label: t(color),
        }))
      ),
    [t]
  )
  const categoriesOptionGroups = useMemo(() => createParentCategoriesOptionGroups(parentCategories), [parentCategories])
  const fashionGenderOptions = useMemo(
    () =>
      [{ label: '', value: '' }].concat(
        fashionProductGendersTranslationKeys.map((gender) => ({
          // value: gender.replace('gender', '').toLowerCase(),
          value: t(gender),
          label: t(gender),
        }))
      ),
    [t]
  )

  const fashionConditionOptions = useMemo(
    () =>
      [{ label: '', value: '' }].concat(
        fashionProductConditionsTranslationKeys.map((condition) => ({
          // value: dasherize(condition.replace('showProductsDrawerFormCondition', '')),
          value: t(condition),
          label: t(condition),
        }))
      ),
    [t]
  )
  const cardConditionOptions = useMemo(() => computeCardConditionFieldOptions(t), [t])
  const cardLanguageOptions = useMemo(() => computeCardLanguageFieldOptions(t), [t])
  const cardTypeOptions = useMemo(() => computeCardTypeFieldOptions(t), [t])

  return (
    <table className="product-import-preview">
      <ProductPreviewTableHeaders />
      <tbody>
        {products?.length > 0 && (
          <tr className="apply-all">
            <td className="row-number"></td>
            <td className="row-select">
              <input type="checkbox" onChange={handleToggleAll} />{' '}
            </td>
            <td className="name"></td>
            <td className="category">
              <select value={''} onChange={handleChangeAllCategory}>
                {categoriesOptionGroups.map((group) => {
                  // @ts-expect-error: to fix this
                  const { label, options = [] } = group
                  return (
                    <optgroup key={label} label={label}>
                      {options.map((option: SelectOption) => {
                        const { label, value } = option
                        return (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        )
                      })}
                    </optgroup>
                  )
                })}
              </select>
            </td>
            <td className="selling-mode">
              <select value="" onChange={handleSetAllSellingMode}>
                <option value=""></option>
                <option value="auction">Auction</option>
                <option value="instant-buy">Instant buy</option>
                <option value="giveaway">Giveaway</option>
              </select>
            </td>
            <td className="description"></td>
            <td className="quantity"></td>
            <td className="price starting-price"></td>
            <td className="price instant-buy-price"></td>
            <td className="images"></td>
            <td className="brand"></td>
            <td className="model"></td>
            <td className="size"></td>
            <td className="color"></td>
            <td className="gender"></td>
            <td className="condition"></td>
            <td className="card-type"></td>
            <td className="card-language"></td>
            <td className="card-grade"></td>
            <td className="card-grading-service"></td>
            <td className="card-condition"></td>
          </tr>
        )}
        {products.map((parsedProduct, index) => {
          const { name, description, quantity, category, sellingMode } = parsedProduct
          const {
            startingPrice: parsedStartingPrice,
            instantBuyPrice: parsedInstantBuyPrice,
            imagesUrls,
          } = parsedProduct
          const { brand, model, color, size, gender, condition } = parsedProduct
          const { cardType, cardCondition, cardLanguage, cardGrade, cardGradingService } = parsedProduct

          const startingPrice = parsedStartingPrice || ''
          const instantBuyPrice = parsedInstantBuyPrice || ''

          const appliedSellingMode = sellingMode || guessSellingMode(startingPrice, instantBuyPrice)
          const images = imagesUrls ? imagesUrls.split('|') : []

          const rowErrors = errors.filter((error) => error.row === index)

          const isSelected = selected.includes(index)

          const nameError = rowErrors.find(({ property }) => property === 'name')?.message
          const categoryError = rowErrors.find(({ property }) => property === 'category')?.message
          const sellingModeError = rowErrors.find(({ property }) => property === 'sellingMode')?.message
          const descriptionError = rowErrors.find(({ property }) => property === 'descriptionError')?.message
          const quantityError = rowErrors.find(({ property }) => property === 'quantity')?.message
          const startPriceError = rowErrors.find(({ property }) => property === 'startingPrice')?.message
          const instantBuyPriceError = rowErrors.find(({ property }) => property === 'instantBuyPrice')?.message
          const imagesUrlsError = rowErrors.find(({ property }) => property === 'imagesUrls')?.message
          const brandError = rowErrors.find(({ property }) => property === 'brand')?.message
          const modelError = rowErrors.find(({ property }) => property === 'model')?.message
          const colorError = rowErrors.find(({ property }) => property === 'color')?.message
          const sizeError = rowErrors.find(({ property }) => property === 'size')?.message
          const genderError = rowErrors.find(({ property }) => property === 'gender')?.message
          const conditionError = rowErrors.find(({ property }) => property === 'condition')?.message
          const cardTypeError = rowErrors.find(({ property }) => property === 'cardType')?.message
          const cardLanguageError = rowErrors.find(({ property }) => property === 'cardLanguage')?.message
          const cardGradeError = rowErrors.find(({ property }) => property === 'cardGrade')?.message
          const cardGradingServiceError = rowErrors.find(({ property }) => property === 'cardGradingService')?.message
          const cardConditionError = rowErrors.find(({ property }) => property === 'cardCondition')?.message

          const isNameValid = !nameError
          // const isCategoryValid = !categoryError
          const isSellingModeValid = !sellingModeError
          const isDescriptionValid = !descriptionError
          const isQuantityValid = !quantityError
          const isStartingPriceValid = !startPriceError
          const isInstantBuyPriceValid = !instantBuyPriceError
          const isBrandValid = !brandError
          const isModelValid = !modelError
          const isColorValid = !colorError
          const isSizeValid = !sizeError
          const isGenderValid = !genderError
          const isConditionValid = !conditionError
          const isCardTypeValid = !cardTypeError
          const isCardLanguageValid = !cardLanguageError
          const isCardGradeValid = !cardGradeError
          const isCardGradingServiceValid = !cardGradingServiceError
          const isCardConditionValid = !cardConditionError

          // @ts-expect-error: fix this
          const isRowValid = errors.filter((error, i) => error?.index === i)
          const classNames = `product-${index + 1} ${isRowValid ? 'is-valid' : 'is-error'}`

          return (
            <tr key={index} className={classNames}>
              <th className="row-number">{index + 1}</th>
              <td className="row-select">
                <input checked={isSelected} type="checkbox" onChange={() => handleToggleRow(index)} />
              </td>
              <td className={`name ${isNameValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <Contenteditable value={name || ''} onChange={(value) => handleUpdateValue('name', index, value)} />
                </data>
                {nameError && <span className="error">{nameError}</span>}
              </td>
              <CategoryCell
                category={category}
                error={categoryError}
                options={categoriesOptionGroups}
                onUpdate={(e: ChangeEvent<HTMLSelectElement>) => handleUpdateValue('category', index, e.target.value)}
              />
              <td className={`selling-mode ${isSellingModeValid ? 'is-valid' : 'is-error'}`}>
                <span className="value">
                  <select
                    value={appliedSellingMode}
                    onChange={(e) => handleUpdateValue('sellingMode', index, e.target.value)}
                  >
                    <option value="auction">Auction</option>
                    <option value="instant-buy">Instant buy</option>
                    <option value="giveaway">Giveaway</option>
                  </select>
                </span>
                {sellingModeError && <span className="error">{sellingModeError}</span>}
              </td>
              <td className={`description ${isDescriptionValid ? 'is-valid' : 'is-error'}`}>{description}</td>
              <td className={`quantity ${isQuantityValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <Contenteditable value={quantity} onChange={(value) => handleUpdateValue('quantity', index, value)} />
                </data>
                {quantityError && <span className="error">{quantityError}</span>}
              </td>
              <td className={`price starting-price ${isStartingPriceValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <Contenteditable
                    value={startingPrice}
                    onChange={(value) => handleUpdateValue('startingPrice', index, value)}
                  />
                  {startingPrice && !startPriceError && <span className="currency">€</span>}
                </data>
                {startPriceError && <span className="error">{startPriceError}</span>}
              </td>
              <td className={`price instant-buy-price ${isInstantBuyPriceValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <Contenteditable
                    value={instantBuyPrice}
                    onChange={(value) => handleUpdateValue('instantBuyPrice', index, value)}
                  />
                  {instantBuyPrice && !instantBuyPriceError && <span className="currency">€</span>}
                </data>
                {instantBuyPriceError && <span className="error">{instantBuyPriceError}</span>}
              </td>
              <ImagesCell
                error={imagesUrlsError}
                images={images}
                onUpdate={(updatedImagesUrls) => handleUpdateValue('imagesUrls', index, updatedImagesUrls)}
              />

              <td className={`brand ${isBrandValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <Contenteditable value={brand} onChange={(value) => handleUpdateValue('brand', index, value)} />
                </data>
              </td>
              <td className={`model ${isModelValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <Contenteditable value={model} onChange={(value) => handleUpdateValue('model', index, value)} />
                </data>
              </td>
              <td className={`size ${isSizeValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <Contenteditable value={size} onChange={(value) => handleUpdateValue('size', index, value)} />
                </data>
              </td>
              <td className={`color ${isColorValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <select value={color} onChange={(e) => handleUpdateValue('color', index, e.target.value)}>
                    {fashionColorOptions.map((option) => {
                      const { label, value } = option
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    })}
                    {colorError && (
                      <option disabled={true} value={color}>
                        {color} [invalid value]
                      </option>
                    )}
                  </select>
                  {colorError && <span className="error">{colorError}</span>}
                </data>
              </td>
              <td className={`gender ${isGenderValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <select value={gender} onChange={(e) => handleUpdateValue('gender', index, e.target.value)}>
                    {fashionGenderOptions.map((option) => {
                      const { label, value } = option
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    })}
                    {genderError && (
                      <option disabled={true} value={gender}>
                        {gender} [invalid value]
                      </option>
                    )}
                  </select>
                  {genderError && <span className="error">{genderError}</span>}
                </data>
              </td>
              <td className={`condition ${isConditionValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <select value={condition} onChange={(e) => handleUpdateValue('condition', index, e.target.value)}>
                    {fashionConditionOptions.map((option) => {
                      const { label, value } = option
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    })}
                    {conditionError && (
                      <option disabled={true} value={condition}>
                        {condition} [invalid value]
                      </option>
                    )}
                  </select>
                  {conditionError && <span className="error">{conditionError}</span>}
                </data>
              </td>
              <td className={`card-type ${isCardTypeValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <select value={cardType} onChange={(e) => handleUpdateValue('cardType', index, e.target.value)}>
                    {cardTypeOptions.map((option) => {
                      const { label, value } = option
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    })}
                    {cardTypeError && (
                      <option disabled={true} value={cardType}>
                        {cardType} [invalid value]
                      </option>
                    )}
                  </select>
                  {cardTypeError && <span className="error">{cardTypeError}</span>}
                </data>
              </td>
              <td className={`card-language ${isCardLanguageValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <select
                    value={cardLanguage}
                    onChange={(e) => handleUpdateValue('cardLanguage', index, e.target.value)}
                  >
                    {cardLanguageOptions.map((option) => {
                      const { label, value } = option
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    })}
                    {cardLanguageError && (
                      <option disabled={true} value={cardLanguage}>
                        {cardLanguage} [invalid value]
                      </option>
                    )}
                  </select>
                  {cardLanguageError && <span className="error">{cardLanguageError}</span>}
                </data>
              </td>
              <td className={`card-grade ${isCardGradeValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <select value={cardGrade} onChange={(e) => handleUpdateValue('cardGrade', index, e.target.value)}>
                    {productCardGradeOptions.map((option) => {
                      const { value } = option
                      const label = value
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    })}
                    {cardGradeError && (
                      <option disabled={true} value={cardGrade}>
                        {cardGrade} [invalid value]
                      </option>
                    )}
                  </select>
                  {cardGradeError && <span className="error">{cardGradeError}</span>}
                </data>
              </td>
              <td className={`card-grading-service ${isCardGradingServiceValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <select
                    value={cardGradingService}
                    onChange={(e) => handleUpdateValue('cardGradingService', index, e.target.value)}
                  >
                    {productCardGradingServicesOptions.map((option) => {
                      const { label, value } = option
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    })}
                    {cardGradingServiceError && (
                      <option disabled={true} value={cardGradingService}>
                        {cardGradingService} [invalid value]
                      </option>
                    )}
                  </select>
                  {cardGradingServiceError && <span className="error">{cardGradingServiceError}</span>}
                </data>
              </td>
              <td className={`card-condition ${isCardConditionValid ? 'is-valid' : 'is-error'}`}>
                <data className="value">
                  <select
                    value={cardCondition}
                    onChange={(e) => handleUpdateValue('cardCondition', index, e.target.value)}
                  >
                    {cardConditionOptions.map((option) => {
                      const { label, value } = option
                      return (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      )
                    })}
                    {cardConditionError && (
                      <option disabled={true} value={cardCondition}>
                        {cardCondition} [invalid value]
                      </option>
                    )}
                  </select>
                  {cardConditionError && <span className="error">{cardConditionError}</span>}
                </data>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ProductsImportPreviewTable
