import { useTranslation } from 'react-i18next'

import Button from '@/components/ui/Button/Button'
import EventIcon from '@/components/ui/Icons/EventIcon/EventIcon'

import { FeaturedShowApplicationStatus } from '../../../../network/graphql/types.generated'

import './ApplyForShowEvent.scss'

type ApplyForShowEventProps = {
  handleOnClick: (value: boolean) => void
  featuredShowApplicationStatus: FeaturedShowApplicationStatus | null
}

const ApplyForShowEvent = ({ handleOnClick, featuredShowApplicationStatus }: ApplyForShowEventProps) => {
  const { t } = useTranslation()

  const getButtonProps = (status: FeaturedShowApplicationStatus | null) => {
    switch (status) {
      case FeaturedShowApplicationStatus.Pending:
        return {
          className: 'secondary status-button status-pending',
          label: t('showEventApplicationStatusButtonInReview'),
          disabled: true,
        }
      case FeaturedShowApplicationStatus.Approved:
        return {
          className: 'secondary status-button status-approved',
          label: t('showEventApplicationStatusButtonApproved'),
          disabled: true,
        }
      case FeaturedShowApplicationStatus.Refused:
        return {
          className: 'secondary status-button status-rejected',
          label: t('showEventApplicationStatusButtonRejected'),
          disabled: true,
        }
      default:
        return {
          className: 'apply-for-show-event-action',
          label: t('showEventTypeformLinkText'),
          icon: <EventIcon />,
          tooltip: `${t('showEventTypeformLinkText')}. ${t('showEventTypeformLinkPopover')}`,
          // Here we need to explicitly cast tooltipPosition value in order to prevent type error we get if we only use 'bottom right'
          tooltipPosition: 'bottom right' as const,
          onClick: () => handleOnClick(true),
        }
    }
  }

  const buttonProps = getButtonProps(featuredShowApplicationStatus)

  return <Button {...buttonProps} />
}

export default ApplyForShowEvent
