import { type ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'

type NavItemProps = {
  to: string
  icon: ReactNode
  label: string
  name?: string
  className?: string
  target?: string
  rel?: string
}

const NavItem = (props: NavItemProps) => {
  const { to = '', name, className, icon, label, target, rel } = props

  const location = useLocation()

  const isCurrent = location.pathname === to.replace(/\?.*/, '')
  const usedName = name || to.replace(/^\//, '')
  const classNames = `nav-item ${usedName}-nav-item ${className || ''} ${isCurrent ? 'is-current' : ''}`

  return (
    <li className={classNames}>
      <Link
        className="link"
        data-tooltip={label}
        data-tooltip-position="right center"
        rel={rel}
        target={target}
        to={to.startsWith('http') ? { pathname: to } : to}
      >
        <span className="icon">{icon}</span>
        <span className="label">{label}</span>
      </Link>
    </li>
  )
}

export default NavItem
