import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from '@/components/ui/Dialog/Dialog'
import { TabBar } from '@/components/ui/TabBar/TabBar'
import { EasyShippingMethod, ShipmentStatus } from '@/network/graphql/types.generated'

import { CreatePackingSlipButton } from '../../Details/components/CreatePackingSlipButton/CreatePackingSlipButton'
import { useUnbundleAllShipmentsForCustomerMutation, type ShipmentFieldsFragment } from '../../operations.generated'

import { CustomShipping } from './Custom'
import { EasyShipping } from './Easy/Easy'

import './ShippingModal.scss'

interface ShippingModalProps {
  shipment: ShipmentFieldsFragment
  open: boolean
  onCompleted: () => void
  hasAlreadyShipped?: boolean
}

export enum ShippingMethod {
  Sendcloud = 'sendcloud',
  Boxtal = 'boxtal',
  Custom = 'custom',
}

export const ShippingModalSimple = (props: ShippingModalProps) => {
  const { t } = useTranslation()
  const { shipment, open, hasAlreadyShipped, onCompleted } = props
  const { id, customer, status } = shipment || {}
  const { id: customerId } = customer || {}
  const isEdit = status === ShipmentStatus.Shipped

  const [isDispatching, setIsDispatching] = useState<boolean>(false)

  const [unbundleAllShipmentsForCustomer] = useUnbundleAllShipmentsForCustomerMutation({
    variables: { input: { customerId } },
  })

  const handleDispatching = useCallback(() => {
    setIsDispatching(true)
  }, [])

  const onCancel = async () => {
    if (!isDispatching) await unbundleAllShipmentsForCustomer()
    setIsDispatching(false)
    onCompleted()
  }

  const defaultShippingMethod = useMemo(
    () =>
      shipment.shippingOptions?.availableShippingProviders === EasyShippingMethod.Boxtal
        ? ShippingMethod.Boxtal
        : shipment.shippingOptions?.availableShippingProviders === EasyShippingMethod.Sendcloud
          ? ShippingMethod.Sendcloud
          : ShippingMethod.Custom,
    [shipment]
  )

  const tabs = useMemo(() => {
    const tabs = []
    if (!(defaultShippingMethod === ShippingMethod.Custom || isEdit)) {
      tabs.push({
        title: t('shippingModalEasyShippingTab'),
        content: (
          <EasyShipping
            defaultShippingProvider={defaultShippingMethod}
            hasAlreadyShipped={hasAlreadyShipped}
            shipment={shipment}
            onComplete={onCompleted}
            onDispatching={handleDispatching}
          />
        ),
        key: 'easy-shipping',
      })
    }
    tabs.push({
      title: t('shippingModalCustomTab'),
      content: <CustomShipping shipment={shipment} onCompleted={onCompleted} />,
      key: 'custom',
    })
    return tabs
  }, [t])

  return (
    <Dialog
      className="shipping-modal-simple"
      isOpen={open}
      title={isEdit ? t('shippingModalSimpleTitleEdit') : t('shippingModalSimpleTitle')}
      onClose={onCancel}
    >
      <div className="shipping-modal-selector">
        <TabBar
          tabs={tabs}
          extraContent={
            <div className="shipping-modal-selector-extra-content">
              {id && <CreatePackingSlipButton shipmentId={id} />}
            </div>
          }
        />
      </div>
    </Dialog>
  )
}
