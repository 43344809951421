export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BCP47LanguageTag: { input: any; output: any; }
  Date: { input: Date; output: Date; }
  ISO2CountryCode: { input: any; output: any; }
  RGB: { input: any; output: any; }
};

export type AcceptStripeDebitAgreementPayload = {
  __typename?: 'AcceptStripeDebitAgreementPayload';
  ok: Scalars['Boolean']['output'];
};

export type AcceptStripeTermsOfServicesPayload = {
  __typename?: 'AcceptStripeTermsOfServicesPayload';
  ok: Scalars['Boolean']['output'];
};

export type AcceptTermsAndConditionsInput = {
  id: Scalars['ID']['input'];
};

export type AcceptTermsAndConditionsPayload = {
  __typename?: 'AcceptTermsAndConditionsPayload';
  ok: Scalars['Boolean']['output'];
};

export type AddBulkProductToShopInput = {
  products: Array<AddBulkProductToShopProductsInput>;
  shopId: Scalars['ID']['input'];
};

export type AddBulkProductToShopPayload = {
  __typename?: 'AddBulkProductToShopPayload';
  products: Array<Product>;
};

export type AddBulkProductToShopProductsInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type AddBulkProductsToInventoryAndShowInput = {
  products: Array<ProductInput>;
  showId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddBulkProductsToInventoryAndShowPayload = {
  __typename?: 'AddBulkProductsToInventoryAndShowPayload';
  inventoryProductIds: Array<Scalars['ID']['output']>;
  ok: Scalars['Boolean']['output'];
  productIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type AddInventoryProductsToShowInput = {
  inventoryProductIds: Array<Scalars['ID']['input']>;
  showId: Scalars['ID']['input'];
};

export type AddInventoryProductsToShowPayload = {
  __typename?: 'AddInventoryProductsToShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type AddProductToShopInput = {
  availableQuantity?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  shopId: Scalars['ID']['input'];
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type AddProductToShopPayload = {
  __typename?: 'AddProductToShopPayload';
  product: Product;
};

export type AddReactionToShowInput = {
  emoji: Scalars['String']['input'];
  showId: Scalars['ID']['input'];
};

export type AddReactionToShowPayload = {
  __typename?: 'AddReactionToShowPayload';
  feedItem: ReactionFeedItem;
};

export type AddShippingAddressV2Input = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  countryCodeIso2: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
};

export type AddShippingAddressV2Payload = {
  __typename?: 'AddShippingAddressV2Payload';
  shippingAddress: ShippingAddress;
};

export type AddShowCommentMutationPayload = {
  __typename?: 'AddShowCommentMutationPayload';
  feedItem: UserCommentFeedItem;
};

export type AmountWithCurrency = {
  __typename?: 'AmountWithCurrency';
  amount: Scalars['Int']['output'];
  currency: Currency;
};

export type AnswerPollInput = {
  answerIndex: Scalars['Int']['input'];
  pollId: Scalars['ID']['input'];
};

export type AnswerPollPayload = {
  __typename?: 'AnswerPollPayload';
  ok: Scalars['Boolean']['output'];
};

export type AppConfigInput = {
  platform: Platform;
  /** The application version */
  version: Scalars['String']['input'];
};

export type AppConfigPayload = {
  __typename?: 'AppConfigPayload';
  /**
   * Tell if the apps needs to be updated.
   * We will try setting the current version as low as possible on the backend
   * So we avoid pushing updates too much unless necessary
   */
  clientUpdateRequired: Scalars['Boolean']['output'];
  /**
   * This tells the clients which features are enabled for the user.
   * Each feature is a string identifier.
   */
  featureFlags: Array<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
  /**
   * Indicates if updated terms and conditions should be accepted by the user before using the app
   *
   * null if no change since last acceptance
   */
  termsAndConditionsToAccept?: Maybe<TermsAndConditions>;
};

export type ApplicablePromotionAmountV2Input = {
  productId: Scalars['ID']['input'];
  promotionId: Scalars['ID']['input'];
};

export type ApplyForFeaturedShowInput = {
  /**
   * The concepts that the show will be about.
   * There should be at least one.
   */
  concepts: Array<Scalars['String']['input']>;
  showId: Scalars['ID']['input'];
};

export type ApplyForFeaturedShowPayload = {
  __typename?: 'ApplyForFeaturedShowPayload';
  ok: Scalars['Boolean']['output'];
};

export enum AuctionInitialDurationPreset {
  Time_5Seconds = 'TIME_5_SECONDS',
  Time_15Seconds = 'TIME_15_SECONDS',
  Time_30Seconds = 'TIME_30_SECONDS',
  Time_60Seconds = 'TIME_60_SECONDS'
}

export enum AuctionResetDurationPreset {
  Time_00Seconds = 'TIME_00_SECONDS',
  Time_03Seconds = 'TIME_03_SECONDS',
  Time_10Seconds = 'TIME_10_SECONDS'
}

export type AutoFollowUserCampaign = CustomNode & Node & {
  __typename?: 'AutoFollowUserCampaign';
  category: Category;
  country: Country;
  createdAt: Scalars['Date']['output'];
  /** The number of followers the user had when the campaign was created */
  createdAtFollowerCount: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  /** The number of followers when the campaign has been disabled */
  deletedAtFollowerCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** The target number of followers we aim for the user to have at the end of the campaign */
  targetFollowerCount: Scalars['Int']['output'];
  user: User;
};

export type AutoFollowUserCampaignConnection = Connection & {
  __typename?: 'AutoFollowUserCampaignConnection';
  edges: Array<AutoFollowUserCampaignEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AutoFollowUserCampaignEdge = Edge & {
  __typename?: 'AutoFollowUserCampaignEdge';
  cursor: Scalars['String']['output'];
  node: AutoFollowUserCampaign;
};

export type AutocompleteSearchConnection = Connection & {
  __typename?: 'AutocompleteSearchConnection';
  edges: Array<AutocompleteSearchEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AutocompleteSearchEdge = Edge & {
  __typename?: 'AutocompleteSearchEdge';
  cursor: Scalars['String']['output'];
  node: AutocompleteSearchItem;
};

export type AutocompleteSearchItem = CustomNode & Node & {
  __typename?: 'AutocompleteSearchItem';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** ⭐️ A user badge. */
export type Badge = Node & {
  __typename?: 'Badge';
  /** The badge color accent */
  colorAccent?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Can be used instead of the icon, to allow more flexibility */
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BanUserFromSellerInput = {
  sellerId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type BanUserFromSellerPayload = {
  __typename?: 'BanUserFromSellerPayload';
  ok: Scalars['Boolean']['output'];
};

export type BanUserFromShowInput = {
  showId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type BanUserFromShowPayload = {
  __typename?: 'BanUserFromShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type BlockUserFromBiddingInAnyShowInput = {
  /** If null, the user will be blocked indefinitely. */
  blockedUntil?: InputMaybe<Scalars['Date']['input']>;
  userId: Scalars['ID']['input'];
};

export type BlockUserFromBiddingInAnyShowPayload = {
  __typename?: 'BlockUserFromBiddingInAnyShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type BlockUserFromCommentingInAnyShowInput = {
  /** If null, the user will be blocked indefinitely. */
  blockedUntil?: InputMaybe<Scalars['Date']['input']>;
  userId: Scalars['ID']['input'];
};

export type BlockUserFromCommentingInAnyShowPayload = {
  __typename?: 'BlockUserFromCommentingInAnyShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type BlockUserFromCommentingInShowMutationPayload = {
  __typename?: 'BlockUserFromCommentingInShowMutationPayload';
  ok: Scalars['Boolean']['output'];
};

export type BoxtalShippingMethod = {
  __typename?: 'BoxtalShippingMethod';
  characteristics?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  collectionType?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  insurancePrice?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  operatorCode?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  serviceCode?: Maybe<Scalars['String']['output']>;
  shippingOfferCode: Scalars['String']['output'];
  shippingProvider?: Maybe<Scalars['String']['output']>;
};

export type BulkUnshippedShippingAddressStickersPayload = {
  __typename?: 'BulkUnshippedShippingAddressStickersPayload';
  /** Signed URL to download the file. Expires after a while */
  label: Scalars['String']['output'];
};

export type BundleShipmentFromUnshippedOrderedProductsForCustomerInput = {
  customerId: Scalars['ID']['input'];
};

export type BundleShipmentFromUnshippedOrderedProductsForCustomerPayload = {
  __typename?: 'BundleShipmentFromUnshippedOrderedProductsForCustomerPayload';
  shipment: Shipment;
};

export type BundleShipmentInput = {
  customerId: Scalars['ID']['input'];
  orderedProductIds: Array<Scalars['ID']['input']>;
};

export type BundleShipmentPayload = {
  __typename?: 'BundleShipmentPayload';
  shipment: Shipment;
};

export type CampaignPromotionSource = {
  __typename?: 'CampaignPromotionSource';
  promotionId: Scalars['Int']['output'];
  type: PromotionType;
};

export type CanCreatePayoutFromSellerLedgerNo = {
  __typename?: 'CanCreatePayoutFromSellerLedgerNo';
  canCreatePayout: Scalars['Boolean']['output'];
  reason: Scalars['String']['output'];
};

export type CanCreatePayoutFromSellerLedgerPayload = CanCreatePayoutFromSellerLedgerNo | CanCreatePayoutFromSellerLedgerYes;

export type CanCreatePayoutFromSellerLedgerYes = {
  __typename?: 'CanCreatePayoutFromSellerLedgerYes';
  canCreatePayout: Scalars['Boolean']['output'];
};

export type CanProductBeUpdatedPayload = {
  __typename?: 'CanProductBeUpdatedPayload';
  canAmountQuantityOrTypeBeUpdated: Scalars['Boolean']['output'];
  canProductBeRenamed: Scalars['Boolean']['output'];
  canProductBeUpdated: Scalars['Boolean']['output'];
};

export type CancelAndRefundOrderInput = {
  cancellationReason: OrderCancellationReason;
  orderId: Scalars['ID']['input'];
};

export type CancelAndRefundOrderPayload = {
  __typename?: 'CancelAndRefundOrderPayload';
  order: Order;
};

export type CancelCurrentRaidInShowInput = {
  showId: Scalars['ID']['input'];
};

export type CancelCurrentRaidInShowPayload = {
  __typename?: 'CancelCurrentRaidInShowPayload';
  ok: Scalars['Boolean']['output'];
};

/** ⭐️ A category of Show on Voggt. Ex: Pokemon, etc. */
export type Category = Node & {
  __typename?: 'Category';
  /** The category color accent */
  colorAccent?: Maybe<Scalars['String']['output']>;
  /**
   * The category illustration picture
   * @deprecated Use `coverWebPUrl` instead
   */
  coverUrl?: Maybe<Scalars['String']['output']>;
  /** The category illustration picture */
  coverWebPUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Indicates if the user is following the category. */
  isFollowing: Scalars['Boolean']['output'];
  /**
   * If the category should be promoted for being new
   * Managed by the category managers.
   */
  isNew: Scalars['Boolean']['output'];
  /** Indicates if this category is reserved for offline shows/shops. */
  isOffline: Scalars['Boolean']['output'];
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  /** Translated category name, e.g. "Pokémon Cards" */
  name: Scalars['String']['output'];
  /** The parent category of the category */
  parentCategory: ParentCategory;
  products: ProductConnection;
  /**
   * The recommended products for the category
   *
   * deviceId is required for the recommendation engine to work properly when user is not authenticated.
   * it's a fingerprint calculated from the user's device (web only)
   *
   * token is a case-insensitive string used by the product recommendation algorithm to filter and return a list of relevant products.
   * The algorithm searches for the token as a substring within the product names and returns all matching products.
   * For now, it'll be mainly used for market pages (a page dedicated to all 'dracaufeu' products for instance).
   * Note that currently, the algorithm does not support compound tokens (e.g., hyphenated or multi-word titles).
   * This limitation should be considered to avoid unexpected results or no results.
   */
  recommendedProducts: ProductConnection;
  /**
   * The list of recommended sellers for the category paginated.
   *
   * deviceId is required for the recommendation engine to work properly when user is not authenticated.
   * it's a fingerprint calculated from the user's device (web only)
   */
  recommendedSellers: UserConnection;
  /**
   * The recommended shows for the category
   *
   * deviceId is required for the recommendation engine to work properly when user is not authenticated.
   * it's a fingerprint calculated from the user's device (web only)
   */
  recommendedShows: ShowConnection;
  shows: ShowConnection;
  /** Raw category name, e.g. "pokemon-cards" */
  slug: Scalars['String']['output'];
  /** The category sticker picture */
  stickerUrl: Scalars['String']['output'];
};


/** ⭐️ A category of Show on Voggt. Ex: Pokemon, etc. */
export type CategoryCoverWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};


/** ⭐️ A category of Show on Voggt. Ex: Pokemon, etc. */
export type CategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A category of Show on Voggt. Ex: Pokemon, etc. */
export type CategoryRecommendedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  isOnlyFromLiveShows?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortByPrice?: InputMaybe<SortByPriceOptions>;
  token?: InputMaybe<Scalars['String']['input']>;
};


/** ⭐️ A category of Show on Voggt. Ex: Pokemon, etc. */
export type CategoryRecommendedSellersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A category of Show on Voggt. Ex: Pokemon, etc. */
export type CategoryRecommendedShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A category of Show on Voggt. Ex: Pokemon, etc. */
export type CategoryShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryConnection = Connection & {
  __typename?: 'CategoryConnection';
  edges: Array<CategoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryEdge = Edge & {
  __typename?: 'CategoryEdge';
  cursor: Scalars['String']['output'];
  node: Category;
};

export type ChangeViewerBiographyInput = {
  biography: Scalars['String']['input'];
};

export type ChangeViewerBiographyPayload = {
  __typename?: 'ChangeViewerBiographyPayload';
  user: User;
};

export type ChangeViewerEmailInput = {
  email: Scalars['String']['input'];
};

export type ChangeViewerEmailPayload = {
  __typename?: 'ChangeViewerEmailPayload';
  user: User;
};

export type ChangeViewerFavouritePickupPointInput = {
  code: Scalars['String']['input'];
  network: Scalars['String']['input'];
};

export type ChangeViewerFavouritePickupPointPayload = {
  __typename?: 'ChangeViewerFavouritePickupPointPayload';
  user: User;
};

export type ChangeViewerFirstNameInput = {
  firstName: Scalars['String']['input'];
};

export type ChangeViewerFirstNamePayload = {
  __typename?: 'ChangeViewerFirstNamePayload';
  user: User;
};

export type ChangeViewerLastNameInput = {
  lastName: Scalars['String']['input'];
};

export type ChangeViewerLastNamePayload = {
  __typename?: 'ChangeViewerLastNamePayload';
  user: User;
};

/** A user Channel */
export type Channel = {
  __typename?: 'Channel';
  /** HLS streaming identifier */
  hls: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Identify the streaming provider */
  provider: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  /** Restream configuration to duplicate stream on other platforms (Facebook, Twitch, Youtube) */
  restreamUrls: Array<RestreamUrl>;
  /** RTMP url */
  rtmp: Scalars['String']['output'];
  streamKey: Scalars['String']['output'];
};

export type CheckEmailAvailabilityForSsoRegistrationInput = {
  email: Scalars['String']['input'];
};

export type CheckEmailAvailabilityForSsoRegistrationPayload = CheckEmailAvailable | CheckEmailUnavailable;

export type CheckEmailAvailabilityInput = {
  email: Scalars['String']['input'];
};

export type CheckEmailAvailabilityPayload = CheckEmailAvailable | CheckEmailUnavailable;

export type CheckEmailAvailable = {
  __typename?: 'CheckEmailAvailable';
  isAvailable: Scalars['Boolean']['output'];
};

export type CheckEmailUnavailable = {
  __typename?: 'CheckEmailUnavailable';
  isAvailable: Scalars['Boolean']['output'];
  reason: Scalars['String']['output'];
};

export type CheckPasswordValidityInput = {
  password: Scalars['String']['input'];
};

export type CheckPasswordValidityPayload = {
  __typename?: 'CheckPasswordValidityPayload';
  isValid: Scalars['Boolean']['output'];
};

export type CheckReferralCodeDuringFlowInput = {
  referralCode: Scalars['String']['input'];
  /** Will only work with a stepToken from the registrationFlowReferralCodeScreen */
  stepToken: Scalars['String']['input'];
};

export type CheckReferralCodeDuringFlowPayload = {
  __typename?: 'CheckReferralCodeDuringFlowPayload';
  isValid: Scalars['Boolean']['output'];
  /** Can be null when no promotion is available (neither partner program nor referral). */
  promotionAmountOrPercentage?: Maybe<PromotionAmountOrPercentage>;
};

export type CheckReferralCodeForSsoRegistrationInput = {
  referralCode: Scalars['String']['input'];
};

export type CheckReferralCodeForSsoRegistrationPayload = {
  __typename?: 'CheckReferralCodeForSsoRegistrationPayload';
  isValid: Scalars['Boolean']['output'];
  /** Can be null when no promotion is available (neither partner program nor referral). */
  promotionAmountOrPercentage?: Maybe<PromotionAmountOrPercentage>;
};

export type CheckReferralCodeInput = {
  referralCode: Scalars['String']['input'];
};

export type CheckReferralCodePayload = {
  __typename?: 'CheckReferralCodePayload';
  isValid: Scalars['Boolean']['output'];
  /** Can be null when no promotion is available (neither partner program nor referral). */
  promotionAmountOrPercentage?: Maybe<PromotionAmountOrPercentage>;
};

export type CheckUsernameAvailabilityDuringFlowInput = {
  /** Will only work with a stepToken from the registrationFlowUsernameScreen */
  stepToken: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CheckUsernameAvailabilityDuringFlowPayload = CheckUsernameAvailableDuringFlow | CheckUsernameUnavailableDuringFlow;

export type CheckUsernameAvailabilityInput = {
  username: Scalars['String']['input'];
};

export type CheckUsernameAvailabilityPayload = CheckUsernameAvailable | CheckUsernameUnavailable;

export type CheckUsernameAvailable = {
  __typename?: 'CheckUsernameAvailable';
  isAvailable: Scalars['Boolean']['output'];
};

export type CheckUsernameAvailableDuringFlow = {
  __typename?: 'CheckUsernameAvailableDuringFlow';
  isAvailable: Scalars['Boolean']['output'];
};

export type CheckUsernameUnavailable = {
  __typename?: 'CheckUsernameUnavailable';
  isAvailable: Scalars['Boolean']['output'];
  reason: Scalars['String']['output'];
  /** 3 suggestions when the reason is the username is already taken, empty otherwise (i.e. username is invalid) */
  suggestions: Array<Scalars['String']['output']>;
};

export type CheckUsernameUnavailableDuringFlow = {
  __typename?: 'CheckUsernameUnavailableDuringFlow';
  isAvailable: Scalars['Boolean']['output'];
  reason: Scalars['String']['output'];
  /** 3 suggestions when the reason is the username is already taken, empty otherwise (i.e. username is invalid) */
  suggestions: Array<Scalars['String']['output']>;
};

/**
 * A Commercial Event on Voggt. Represent a group of Shows, Products for a given time period.
 * Exemple: Black Friday, Christmas, Summer Sales, etc.
 */
export type CommercialEvent = Node & {
  __typename?: 'CommercialEvent';
  /**
   * Banner of the event, used for display in the event
   * @deprecated Use `bannerWebPUrl` instead
   */
  bannerUrl?: Maybe<Scalars['String']['output']>;
  /** Banner of the event, used for display in the event */
  bannerWebPUrl?: Maybe<Scalars['String']['output']>;
  commercialEventType: CommercialEventType;
  country: Country;
  /**
   * Cover of the event, used for display in home.
   * @deprecated Use `coverWebPUrl` instead
   */
  coverUrl?: Maybe<Scalars['String']['output']>;
  /** Cover of the event, used for display in home. */
  coverWebPUrl?: Maybe<Scalars['String']['output']>;
  /** The date when the event ends. */
  endAt: Scalars['Date']['output'];
  /**
   * Icon of the event, used for display in the event
   * @deprecated Use `iconWebPUrl` instead
   */
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** Icon of the event, used for display in the event */
  iconWebPUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * Large banner of the event, used for display in the event
   * @deprecated Use `largeBannerWebPUrl` instead
   */
  largeBannerUrl?: Maybe<Scalars['String']['output']>;
  /** Large banner of the event, used for display in the event */
  largeBannerWebPUrl?: Maybe<Scalars['String']['output']>;
  /**
   * Large cover of the event, used for display in home.
   * @deprecated Use `largeCoverWebPUrl` instead
   */
  largeCoverUrl?: Maybe<Scalars['String']['output']>;
  /** Large cover of the event, used for display in home. */
  largeCoverWebPUrl?: Maybe<Scalars['String']['output']>;
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  /** The name of the event. */
  name: Scalars['String']['output'];
  /** Products present in the event. */
  products: ProductConnection;
  /**
   * Shows present in the event.
   *
   * deviceId is required for the recommendation engine to work properly when user is not authenticated.
   * it's a fingerprint calculated from the user's device (web only)
   */
  shows: ShowConnection;
  /** The date when the event starts. */
  startAt: Scalars['Date']['output'];
};


/**
 * A Commercial Event on Voggt. Represent a group of Shows, Products for a given time period.
 * Exemple: Black Friday, Christmas, Summer Sales, etc.
 */
export type CommercialEventBannerWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};


/**
 * A Commercial Event on Voggt. Represent a group of Shows, Products for a given time period.
 * Exemple: Black Friday, Christmas, Summer Sales, etc.
 */
export type CommercialEventCoverWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};


/**
 * A Commercial Event on Voggt. Represent a group of Shows, Products for a given time period.
 * Exemple: Black Friday, Christmas, Summer Sales, etc.
 */
export type CommercialEventIconWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};


/**
 * A Commercial Event on Voggt. Represent a group of Shows, Products for a given time period.
 * Exemple: Black Friday, Christmas, Summer Sales, etc.
 */
export type CommercialEventLargeBannerWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};


/**
 * A Commercial Event on Voggt. Represent a group of Shows, Products for a given time period.
 * Exemple: Black Friday, Christmas, Summer Sales, etc.
 */
export type CommercialEventLargeCoverWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};


/**
 * A Commercial Event on Voggt. Represent a group of Shows, Products for a given time period.
 * Exemple: Black Friday, Christmas, Summer Sales, etc.
 */
export type CommercialEventProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  refresh?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * A Commercial Event on Voggt. Represent a group of Shows, Products for a given time period.
 * Exemple: Black Friday, Christmas, Summer Sales, etc.
 */
export type CommercialEventShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CommercialEventConnection = Connection & {
  __typename?: 'CommercialEventConnection';
  edges: Array<CommercialEventEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CommercialEventEdge = Edge & {
  __typename?: 'CommercialEventEdge';
  cursor: Scalars['String']['output'];
  node: CommercialEvent;
};

export enum CommercialEventType {
  Featured = 'FEATURED',
  GoldenDays = 'GOLDEN_DAYS',
  Standard = 'STANDARD',
  VoggtAnniversary = 'VOGGT_ANNIVERSARY'
}

export type ConfigureStripeBankAccountForViewerInput = {
  /** Bank account number as string. */
  accountNumber: Scalars['String']['input'];
  /** Only the countries listed on `Query.sellerBankAccountSupportedCountries` are supported. */
  country: Scalars['ISO2CountryCode']['input'];
  /** Required for GB. */
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ConfigureStripeBankAccountForViewerMutationPayload = {
  __typename?: 'ConfigureStripeBankAccountForViewerMutationPayload';
  ok: Scalars['Boolean']['output'];
  viewer: User;
};

export type ConfirmOrdersGroupReceivedInput = {
  showOrdersGroupId: Scalars['ID']['input'];
};

export type ConfirmOrdersGroupReceivedPayload = {
  __typename?: 'ConfirmOrdersGroupReceivedPayload';
  showOrdersGroup: ShowOrdersGroup;
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['String']['output'];
  longitude: Scalars['String']['output'];
};

/** A country on Voggt. */
export type Country = Node & {
  __typename?: 'Country';
  id: Scalars['ID']['output'];
  iso2Code: Scalars['String']['output'];
  iso3Code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CreateAndLaunchPollInShowInput = {
  /**
   * Must contain between 2 and 4 entries (inclusive).
   * Each answer must be between 1 and 80 characters long.
   */
  answers: Array<Scalars['String']['input']>;
  /** Must be between 1 and 80 characters long. */
  question: Scalars['String']['input'];
  showId: Scalars['ID']['input'];
};

export type CreateAndLaunchPollInShowPayload = {
  __typename?: 'CreateAndLaunchPollInShowPayload';
  poll: ShowPoll;
};

export type CreateAutoFollowUserCampaignInput = {
  categoryId: Scalars['ID']['input'];
  countryId: Scalars['ID']['input'];
  targetFollowerCount: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type CreateAutoFollowUserCampaignPayload = {
  __typename?: 'CreateAutoFollowUserCampaignPayload';
  ok: Scalars['Boolean']['output'];
  userFollowUserAutoCampaignId: Scalars['ID']['output'];
};

export type CreateBancontactPaymentMethodOnStripeInput = {
  name: Scalars['String']['input'];
};

export type CreateBancontactPaymentMethodOnStripePayload = {
  __typename?: 'CreateBancontactPaymentMethodOnStripePayload';
  setupIntentClientSecret: Scalars['String']['output'];
};

export type CreateCsvExportOfOrdersForShowInput = {
  language: Scalars['BCP47LanguageTag']['input'];
  showId: Scalars['ID']['input'];
};

export type CreateCsvExportOfOrdersPayload = {
  __typename?: 'CreateCSVExportOfOrdersPayload';
  /** The URL to the CSV file. This URL is only valid 24h. */
  fileURL: Scalars['String']['output'];
};

export type CreateCsvExportOfOrdersSoldByViewerBetweeDatesInput = {
  after: Scalars['Date']['input'];
  before: Scalars['Date']['input'];
  language: Scalars['BCP47LanguageTag']['input'];
};

export type CreateCardPaymentMethodInput = {
  cvc: Scalars['String']['input'];
  expMonth: Scalars['Int']['input'];
  expYear: Scalars['Int']['input'];
  number: Scalars['String']['input'];
};

export type CreateCardPaymentMethodPayload = {
  __typename?: 'CreateCardPaymentMethodPayload';
  ok: Scalars['Boolean']['output'];
  /** @deprecated Deprecated in favor of `paymentMethodChanged` subscription events */
  paymentMethodCard: PaymentMethodCard;
};

export type CreateCardPaymentMethodPayloadV2 = {
  __typename?: 'CreateCardPaymentMethodPayloadV2';
  setupIntentClientSecret: Scalars['String']['output'];
};

export type CreateCardPaymentMethodUsingApplePayPayload = {
  __typename?: 'CreateCardPaymentMethodUsingApplePayPayload';
  setupIntentClientSecret: Scalars['String']['output'];
};

export type CreateCardPaymentMethodUsingGooglePayPayload = {
  __typename?: 'CreateCardPaymentMethodUsingGooglePayPayload';
  setupIntentClientSecret: Scalars['String']['output'];
};

export type CreateExportSellerLedgerInput = {
  filters?: InputMaybe<SellerLedgerTransactionsFilters>;
  language: Scalars['BCP47LanguageTag']['input'];
};

export type CreateExportSellerLedgerPayload = {
  __typename?: 'CreateExportSellerLedgerPayload';
  /** The URL to the CSV file. This URL is only valid 24h. */
  fileURL: Scalars['String']['output'];
};

export type CreateGiropayPaymentMethodInput = {
  name: Scalars['String']['input'];
};

export type CreateGiropayPaymentMethodPayload = {
  __typename?: 'CreateGiropayPaymentMethodPayload';
  ok: Scalars['Boolean']['output'];
};

export type CreateIdealPaymentMethodOnStripeInput = {
  bank: Scalars['String']['input'];
};

export type CreateIdealPaymentMethodOnStripePayload = {
  __typename?: 'CreateIdealPaymentMethodOnStripePayload';
  setupIntentClientSecret: Scalars['String']['output'];
};

export type CreateIndividualSellerInput = {
  accountToken: Scalars['String']['input'];
  canSellerShipFromTheirCountry?: InputMaybe<Scalars['Boolean']['input']>;
  externalSalesAmount?: InputMaybe<Scalars['String']['input']>;
  favoriteCategoryId?: InputMaybe<Scalars['ID']['input']>;
  favoriteParentCategoryId?: InputMaybe<Scalars['ID']['input']>;
  referrerId?: InputMaybe<Scalars['ID']['input']>;
  userCountry: Scalars['String']['input'];
};

export type CreateIndividualSellerPayload = {
  __typename?: 'CreateIndividualSellerPayload';
  ok: Scalars['Boolean']['output'];
};

export type CreatePayoutFromSellerLedgerPayload = CreatePayoutFromSellerLedgerPayloadError | CreatePayoutFromSellerLedgerPayloadOk;

export type CreatePayoutFromSellerLedgerPayloadError = {
  __typename?: 'CreatePayoutFromSellerLedgerPayloadError';
  error: Scalars['String']['output'];
  ok: Scalars['Boolean']['output'];
};

export type CreatePayoutFromSellerLedgerPayloadOk = {
  __typename?: 'CreatePayoutFromSellerLedgerPayloadOk';
  ok: Scalars['Boolean']['output'];
};

export type CreatePaypalPaymentMethodForRecurringPaymentsPayload = {
  __typename?: 'CreatePaypalPaymentMethodForRecurringPaymentsPayload';
  setupIntentClientSecret: Scalars['String']['output'];
};

export type CreatePreAuthorizedPaypalPaymentMethodPayload = {
  __typename?: 'CreatePreAuthorizedPaypalPaymentMethodPayload';
  paymentIntentClientSecret: Scalars['String']['output'];
};

export type CreatePreShowTeaserVideoUploadUrlPayload = {
  __typename?: 'CreatePreShowTeaserVideoUploadUrlPayload';
  deleteUrl: Scalars['String']['output'];
  ok: Scalars['Boolean']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type CreateProSellerInput = {
  accountToken: Scalars['String']['input'];
  canSellerShipFromTheirCountry?: InputMaybe<Scalars['Boolean']['input']>;
  externalSalesAmount?: InputMaybe<Scalars['String']['input']>;
  favoriteCategoryId?: InputMaybe<Scalars['ID']['input']>;
  favoriteParentCategoryId?: InputMaybe<Scalars['ID']['input']>;
  lucidId?: InputMaybe<Scalars['String']['input']>;
  personToken: Scalars['String']['input'];
  referrerId?: InputMaybe<Scalars['ID']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  taxIdRegistrar?: InputMaybe<Scalars['String']['input']>;
  userCountry: Scalars['String']['input'];
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProSellerPayload = {
  __typename?: 'CreateProSellerPayload';
  ok: Scalars['Boolean']['output'];
};

export type CreateSellerStudioMagicLinkInput = {
  refreshToken: Scalars['String']['input'];
};

export type CreateSellerStudioMagicLinkPayload = {
  __typename?: 'CreateSellerStudioMagicLinkPayload';
  magicLink: Scalars['String']['output'];
};

export type CreateSellerSubAccountInput = {
  email: Scalars['String']['input'];
};

export type CreateSellerSubAccountPayload = {
  __typename?: 'CreateSellerSubAccountPayload';
  sellerSubAccount: SellerSubAccount;
};

export type CreateShopInput = {
  categoryId: Scalars['ID']['input'];
  shippingCategory: ShippingCategory;
};

export type CreateShopPayload = {
  __typename?: 'CreateShopPayload';
  shop: Shop;
};

export type CreateShowInput = {
  categoryId: Scalars['Int']['input'];
  gmvTargetAmount?: InputMaybe<Scalars['Int']['input']>;
  isReusingPaidShippingFeesFromPreviousShows?: InputMaybe<Scalars['Boolean']['input']>;
  languageId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  paymentPreAuthorizationAmount?: InputMaybe<Scalars['Int']['input']>;
  shippingCategory: ShippingCategory;
  startAt: Scalars['Date']['input'];
};

export type CreateShowInvitationInput = {
  showId: Scalars['ID']['input'];
};

export type CreateShowInvitationPayload = {
  __typename?: 'CreateShowInvitationPayload';
  invitationCode: Scalars['String']['output'];
};

export type CreateShowPayload = {
  __typename?: 'CreateShowPayload';
  show: Show;
};

export enum Currency {
  Eur = 'eur',
  Gbp = 'gbp'
}

/** Allows marking a custom node type from a raw sql query, useful for paginating raw queries with the same interface as usual nodes */
export type CustomNode = {
  id: Scalars['ID']['output'];
};

export type CustomerConnection = Connection & {
  __typename?: 'CustomerConnection';
  edges: Array<CustomerEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CustomerEdge = Edge & {
  __typename?: 'CustomerEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type CustomerWithUnshippedOrderedProducts = {
  __typename?: 'CustomerWithUnshippedOrderedProducts';
  customer: User;
  oldestOrderedProductDate: Scalars['Date']['output'];
  orderedProductsAmountSum: AmountWithCurrency;
  orderedProductsCount: Scalars['Int']['output'];
  shippingAddress: ShippingAddress;
  statusSummary: CustomerWithUnshippedOrderedProductsStatusSummary;
  unshippedOrderedProducts: OrderedProductsConnection;
  unshippedShipments: ShipmentConnection;
};

export type CustomerWithUnshippedOrderedProductsConnection = Connection & {
  __typename?: 'CustomerWithUnshippedOrderedProductsConnection';
  edges: Array<CustomerWithUnshippedOrderedProductsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CustomerWithUnshippedOrderedProductsEdge = Edge & {
  __typename?: 'CustomerWithUnshippedOrderedProductsEdge';
  cursor: Scalars['String']['output'];
  node: User;
  oldestOrderedProductDate: Scalars['Date']['output'];
  orderedProductsAmountSum: AmountWithCurrency;
  orderedProductsCount: Scalars['Int']['output'];
  shippingAddress: ShippingAddress;
  statusSummary: CustomerWithUnshippedOrderedProductsStatusSummary;
};

export enum CustomerWithUnshippedOrderedProductsSort {
  AmountSumAsc = 'AMOUNT_SUM_ASC',
  AmountSumDesc = 'AMOUNT_SUM_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  CreatedAtDateAsc = 'CREATED_AT_DATE_ASC',
  CreatedAtDateDesc = 'CREATED_AT_DATE_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export enum CustomerWithUnshippedOrderedProductsStatusSummary {
  /** All orders in the group have been paid and are ready to ship. */
  ReadyToShip = 'READY_TO_SHIP',
  /** Some orders in this group have failed payment. */
  SomeFailed = 'SOME_FAILED',
  /** Some orders in this group have payment still in progress. */
  SomeNotPaidYet = 'SOME_NOT_PAID_YET'
}

export type DeletePreShowTeaserVideoInput = {
  showId: Scalars['ID']['input'];
};

export type DeletePreShowTeaserVideoPayload = {
  __typename?: 'DeletePreShowTeaserVideoPayload';
  ok: Scalars['Boolean']['output'];
  show: Show;
};

export type DeleteSellerSubAccountInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSellerSubAccountPayload = {
  __typename?: 'DeleteSellerSubAccountPayload';
  ok: Scalars['Boolean']['output'];
};

export type DeleteShippingAddressInput = {
  shippingAddressId: Scalars['ID']['input'];
};

export type DeleteShippingAddressPayload = {
  __typename?: 'DeleteShippingAddressPayload';
  ok: Scalars['Boolean']['output'];
};

export type DeleteViewerAccountPayload = {
  __typename?: 'DeleteViewerAccountPayload';
  ok: Scalars['Boolean']['output'];
};

export type DemoteUserFromModeratorInput = {
  userId: Scalars['ID']['input'];
};

export type DemoteUserFromModeratorPayload = {
  __typename?: 'DemoteUserFromModeratorPayload';
  ok: Scalars['Boolean']['output'];
};

export type DeviceSettings = {
  deviceBrand: Scalars['String']['input'];
  deviceModel: Scalars['String']['input'];
  networkType: Scalars['String']['input'];
  osVersion: Scalars['String']['input'];
  screenResolution: Scalars['String']['input'];
  streamType: Scalars['String']['input'];
};

export enum DispatchLabelType {
  Pdf_10x15 = 'PDF_10x15',
  PdfA4 = 'PDF_A4'
}

export type DispatchShipmentByOwnMethodsInput = {
  shipmentId: Scalars['String']['input'];
  shippingProvider: Scalars['String']['input'];
  shouldRefundShippingFeesToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type DispatchShipmentByOwnMethodsPayload = {
  __typename?: 'DispatchShipmentByOwnMethodsPayload';
  shipment: Shipment;
};

export type DispatchShipmentWithBoxtalInput = {
  deliveryType: Scalars['String']['input'];
  height: Scalars['Float']['input'];
  insurance?: InputMaybe<Scalars['Boolean']['input']>;
  insurancePriceTaxInclusive: Scalars['Float']['input'];
  labelType?: InputMaybe<DispatchLabelType>;
  length: Scalars['Float']['input'];
  operatorCode: Scalars['String']['input'];
  priceTaxInclusive: Scalars['Float']['input'];
  serviceCode: Scalars['String']['input'];
  shipmentId: Scalars['String']['input'];
  shippingOfferCode?: InputMaybe<Scalars['String']['input']>;
  shippingOfferId?: InputMaybe<Scalars['String']['input']>;
  shippingProvider: Scalars['String']['input'];
  weight: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
};

export type DispatchShipmentWithBoxtalPayload = {
  __typename?: 'DispatchShipmentWithBoxtalPayload';
  shipment: Shipment;
};

export type DispatchShipmentWithSendcloudInput = {
  carrier: Scalars['String']['input'];
  lastMile?: InputMaybe<Scalars['String']['input']>;
  parcelWeight: Scalars['Float']['input'];
  servicePointId?: InputMaybe<Scalars['Int']['input']>;
  shipmentId: Scalars['String']['input'];
  shippingMethodId: Scalars['Int']['input'];
  shippingPriceCurrency: Scalars['String']['input'];
  shippingPriceValue: Scalars['Float']['input'];
};

export type DispatchShipmentWithSendcloudPayload = {
  __typename?: 'DispatchShipmentWithSendcloudPayload';
  shipment: Shipment;
};

export enum EasyShippingMethod {
  Boxtal = 'BOXTAL',
  Sendcloud = 'SENDCLOUD'
}

export type Edge = {
  cursor: Scalars['String']['output'];
  node: Node;
};

export type EditShipmentRatingInput = {
  rating: Scalars['Int']['input'];
  ratingComment: Scalars['String']['input'];
  shipmentRatingId: Scalars['ID']['input'];
};

export type EditShipmentRatingPayload = {
  __typename?: 'EditShipmentRatingPayload';
  rating: RatingFromCustomer;
};

/** ⭐️ An emote to use in the comments section of a live. */
export type Emote = Node & {
  __typename?: 'Emote';
  id: Scalars['ID']['output'];
  /** The id of an emote is actually its unique name */
  name: Scalars['String']['output'];
  /** URL of the image file used as an emote */
  webPUrl: Scalars['String']['output'];
};


/** ⭐️ An emote to use in the comments section of a live. */
export type EmoteWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};

export type EmoteSet = {
  __typename?: 'EmoteSet';
  emotes: Array<Emote>;
  name: Scalars['String']['output'];
  /** Url of the picture representing the set */
  webPUrl: Scalars['String']['output'];
};


export type EmoteSetWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type FavoriteProduct = CustomNode & Node & {
  __typename?: 'FavoriteProduct';
  id: Scalars['ID']['output'];
  /**
   * Gives an optimized cover picture URL in webp format, with a maximum width that you can specify.
   * If original picture is narrower than the specified width, the original picture will be returned.
   */
  image?: Maybe<ImageUpload>;
  legacyProductId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** Used to query the generic product node (product description page) */
  productId: Scalars['ID']['output'];
  showIsBroadcasting: Scalars['Boolean']['output'];
  showStartAt: Scalars['Date']['output'];
  /** Null when no ordered products */
  soldPrice?: Maybe<FavoriteProductSoldPriceUnion>;
  startingAmount: AmountWithCurrency;
  /**
   * Available when it is in a future show and available. (available quantity > 0)
   *
   * Sold :
   * - when it is in a future show and not available anymore.
   * - when it is in a past show and has at least one ordered product.
   *
   * Unsold when it is in a past show and has no ordered product.
   */
  status: FavoriteProductStatus;
  type: ProductType;
};

export type FavoriteProductEdge = Edge & {
  __typename?: 'FavoriteProductEdge';
  cursor: Scalars['String']['output'];
  node: FavoriteProduct;
};

export type FavoriteProductSoldPriceRange = {
  __typename?: 'FavoriteProductSoldPriceRange';
  max: AmountWithCurrency;
  min: AmountWithCurrency;
};

export type FavoriteProductSoldPriceUnion = AmountWithCurrency | FavoriteProductSoldPriceRange;

export enum FavoriteProductStatus {
  Available = 'AVAILABLE',
  Sold = 'SOLD',
  Unsold = 'UNSOLD'
}

export type FavoriteProductsConnection = Connection & {
  __typename?: 'FavoriteProductsConnection';
  edges: Array<FavoriteProductEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum FavoriteShippingLabelFormat {
  A4 = 'A4',
  A6 = 'A6'
}

export type FavoriteShow = CustomNode & Node & {
  __typename?: 'FavoriteShow';
  id: Scalars['ID']['output'];
  isBroadcasting: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isPremier: Scalars['Boolean']['output'];
  legacyShowId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  seller: FavoriteShowSeller;
  /** Used to query the show node (to the show) */
  showId: Scalars['ID']['output'];
  startAt: Scalars['Date']['output'];
  thumbnailWebPUrl?: Maybe<Scalars['String']['output']>;
  viewerCount: Scalars['Int']['output'];
};


export type FavoriteShowThumbnailWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};

export type FavoriteShowEdge = Edge & {
  __typename?: 'FavoriteShowEdge';
  cursor: Scalars['String']['output'];
  node: FavoriteShow;
};

export type FavoriteShowSeller = {
  __typename?: 'FavoriteShowSeller';
  avatarWebPUrl?: Maybe<Scalars['String']['output']>;
  cosmetics: UserCosmetics;
  id: Scalars['ID']['output'];
  isViewer: Scalars['Boolean']['output'];
  legacyUserId: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
  username: Scalars['String']['output'];
};


export type FavoriteShowSellerAvatarWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};

export type FavoriteShowsConnection = Connection & {
  __typename?: 'FavoriteShowsConnection';
  edges: Array<FavoriteShowEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FeaturedShowApplication = {
  __typename?: 'FeaturedShowApplication';
  decisionReason?: Maybe<FeaturedShowApplicationDecisionReason>;
  id: Scalars['ID']['output'];
  status: FeaturedShowApplicationStatus;
  targetedGMV: Scalars['Int']['output'];
};

export type FeaturedShowApplicationCriteria = {
  __typename?: 'FeaturedShowApplicationCriteria';
  featuredShowTarget: FeaturedShowTarget;
  hasCreatedEnoughItemsWithPhotos: Scalars['Boolean']['output'];
  hasDoneEnoughShowsOnVoggt: Scalars['Boolean']['output'];
  isSellerRatingHighEnough: Scalars['Boolean']['output'];
  isShowDateInFutureEnough: Scalars['Boolean']['output'];
};

export enum FeaturedShowApplicationDecisionReason {
  FullCalendar = 'FULL_CALENDAR',
  GreatItems = 'GREAT_ITEMS',
  GreatTrackRecord = 'GREAT_TRACK_RECORD',
  LoyalSeller = 'LOYAL_SELLER',
  NotEnoughItems = 'NOT_ENOUGH_ITEMS',
  NotExperiencedEnough = 'NOT_EXPERIENCED_ENOUGH',
  NotQualitativeEnough = 'NOT_QUALITATIVE_ENOUGH'
}

export enum FeaturedShowApplicationStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Refused = 'REFUSED'
}

export type FeaturedShowTarget = {
  __typename?: 'FeaturedShowTarget';
  featuredShowTargetInEuros: Scalars['Int']['output'];
  isShowGMVTargetHighEnough: Scalars['Boolean']['output'];
};

export enum FileUploadType {
  ProductImage = 'PRODUCT_IMAGE'
}

export type FinalizeUserAccountForSsoRegistrationInput = {
  email: Scalars['String']['input'];
  referralCode?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type FinalizeUserAccountForSsoRegistrationPayload = {
  __typename?: 'FinalizeUserAccountForSsoRegistrationPayload';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type FollowCategoriesPayload = {
  __typename?: 'FollowCategoriesPayload';
  ok: Scalars['Boolean']['output'];
};

export type FollowCategoryPayload = {
  __typename?: 'FollowCategoryPayload';
  category: Category;
  ok: Scalars['Boolean']['output'];
};

export type FollowLanguagePayload = {
  __typename?: 'FollowLanguagePayload';
  ok: Scalars['Boolean']['output'];
};

export type FollowProductPayload = {
  __typename?: 'FollowProductPayload';
  product: Product;
};

export type FollowSellerPayload = {
  __typename?: 'FollowSellerPayload';
  ok: Scalars['Boolean']['output'];
};

export type FollowShowPayload = {
  __typename?: 'FollowShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type FollowUserPayload = {
  __typename?: 'FollowUserPayload';
  ok: Scalars['Boolean']['output'];
};

export type GenerateIvsWebRtcTokenInput = {
  showId: Scalars['ID']['input'];
};

export type GenerateIvsWebRtcTokenInputV2 = {
  showId: Scalars['ID']['input'];
};

export type GenerateIvsWebRtcTokenPayload = {
  __typename?: 'GenerateIVSWebRTCTokenPayload';
  token: Scalars['String']['output'];
};

export type GenerateIvsWebRtcTokenPayloadV2 = TokenGenerated | TokenNotGenerated;

export type GeneratePackingSlipForShipmentsInput = {
  shipmentIds: Array<Scalars['ID']['input']>;
};

export type GeneratePackingSlipForShipmentsPayload = {
  __typename?: 'GeneratePackingSlipForShipmentsPayload';
  url: Scalars['String']['output'];
};

export type GeneratePackingSlipForUnshippedOrderedProductsInput = {
  customerId: Scalars['ID']['input'];
  orderedProductIds: Array<Scalars['ID']['input']>;
  /** Ideally, there should be no shipment here but it can happen. One slip will be created for each shipment. */
  shipmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GeneratePackingSlipForUnshippedOrderedProductsPayload = {
  __typename?: 'GeneratePackingSlipForUnshippedOrderedProductsPayload';
  url: Scalars['String']['output'];
};

export enum GetSellerCancelledAndRefundedOrdersSort {
  CreatedAtDateAsc = 'CREATED_AT_DATE_ASC',
  CreatedAtDateDesc = 'CREATED_AT_DATE_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export enum GetSellerShipmentsByStatusSort {
  CreatedAtDateAsc = 'CREATED_AT_DATE_ASC',
  CreatedAtDateDesc = 'CREATED_AT_DATE_DESC',
  TrackingNumberAsc = 'TRACKING_NUMBER_ASC',
  TrackingNumberDesc = 'TRACKING_NUMBER_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export type GetUserIdByUsernamePayload = {
  __typename?: 'GetUserIdByUsernamePayload';
  id?: Maybe<Scalars['ID']['output']>;
};

export enum IvsTokenGenerationResult {
  InvalidShowDate = 'INVALID_SHOW_DATE',
  StageNotYetCreated = 'STAGE_NOT_YET_CREATED',
  Success = 'SUCCESS',
  Unknown = 'UNKNOWN'
}

export type ImageUpload = Node & {
  __typename?: 'ImageUpload';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
  webPUrl: Scalars['String']['output'];
};


export type ImageUploadWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};

export type ImageUploadConnection = Connection & {
  __typename?: 'ImageUploadConnection';
  edges: Array<ImageUploadEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ImageUploadEdge = Edge & {
  __typename?: 'ImageUploadEdge';
  cursor: Scalars['String']['output'];
  node: ImageUpload;
};

export type InitializeInventoryPayload = {
  __typename?: 'InitializeInventoryPayload';
  ok: Scalars['Boolean']['output'];
};

export type InventoryProduct = Node & {
  __typename?: 'InventoryProduct';
  availableProductCountInOfflineShows: Scalars['Int']['output'];
  availableProductCountInShows: Scalars['Int']['output'];
  availableQuantity: Scalars['Int']['output'];
  /** Product brand. Only used for fashion items or sneakers. */
  brand?: Maybe<Scalars['String']['output']>;
  /** Card condition. Only used for TCG items */
  cardCondition?: Maybe<Scalars['String']['output']>;
  /** Card grade, if any. Only used for TCG items */
  cardGrade?: Maybe<Scalars['String']['output']>;
  /** Grading service that grade the card, if any. Only used for TCG items */
  cardGradingService?: Maybe<Scalars['String']['output']>;
  /** Card language. Only used for TCG items */
  cardLanguage?: Maybe<Scalars['String']['output']>;
  /** Card type. Sealed Pak, Boxed Set or individual cards. Only used for TCG items */
  cardType?: Maybe<Scalars['String']['output']>;
  /**
   * Some shows may have a few products from a category that is not the one of the show.
   * Ex: A vintage-streetwear show can have a few sneakers.
   * A few categories might have related specific fields (ex: size, color, model, ...)
   * Possible inventory product categories: FASHION, SNEAKER
   */
  categoryName?: Maybe<Scalars['String']['output']>;
  /** Product color. Only used for fashion items or sneakers. */
  color?: Maybe<Scalars['String']['output']>;
  /** Product condition. Only used for fashion items or sneakers. */
  condition?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  defaultType: ProductType;
  description?: Maybe<Scalars['String']['output']>;
  fixedAmount?: Maybe<Scalars['Int']['output']>;
  /** Product gender. Only used for fashion items */
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * Gives an optimized cover picture URL in webp format, with a maximum width that you can specify.
   * If original picture is narrower than the specified width, the original picture will be returned.
   */
  images: Array<ImageUpload>;
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['String']['output'];
  /** Product model. Only used for some sneakers. */
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  seller: User;
  /** Product size. Only used for fashion items or sneakers. */
  size?: Maybe<Scalars['String']['output']>;
  startingAmount?: Maybe<Scalars['Int']['output']>;
  /** Inventory product total quantity = inventory product available quantity + sum of all related products' available quantity (from past shows) */
  totalQuantity: Scalars['Int']['output'];
};

export type InventoryProductConnection = Connection & {
  __typename?: 'InventoryProductConnection';
  edges: Array<InventoryProductEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InventoryProductEdge = Edge & {
  __typename?: 'InventoryProductEdge';
  cursor: Scalars['String']['output'];
  node: InventoryProduct;
};

export type InventoryProductInput = {
  availableQuantity: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  fixedAmount?: InputMaybe<Scalars['Int']['input']>;
  giveawayAudience?: InputMaybe<ShowGiveawayAudience>;
  imagesUrls: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  startingAmount?: InputMaybe<Scalars['Int']['input']>;
};

export enum InventoryProductSortColumn {
  AvailableQuantity = 'AVAILABLE_QUANTITY',
  CreatedAt = 'CREATED_AT',
  DefaultType = 'DEFAULT_TYPE',
  FixedAmount = 'FIXED_AMOUNT',
  Name = 'NAME',
  StartingAmount = 'STARTING_AMOUNT',
  TotalQuantity = 'TOTAL_QUANTITY'
}

export type IsPreBidEnabledPayload = {
  __typename?: 'IsPreBidEnabledPayload';
  reason?: Maybe<Scalars['String']['output']>;
  reasonCode?: Maybe<PreBidDisabledReasonCode>;
  result: Scalars['Boolean']['output'];
};

export type Language = Node & {
  __typename?: 'Language';
  /** The standard 2 letters code of the language. */
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Indicates if the user is following the language. */
  isFollowing: Scalars['Boolean']['output'];
};

export type LastSoldProductsWithCustomerConnection = Connection & {
  __typename?: 'LastSoldProductsWithCustomerConnection';
  edges: Array<LastSoldProductsWithCustomerEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LastSoldProductsWithCustomerEdge = Edge & {
  __typename?: 'LastSoldProductsWithCustomerEdge';
  cursor: Scalars['String']['output'];
  customer: User;
  node: OrderedProduct;
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type LoginWithFacebookSsoPayload = {
  __typename?: 'LoginWithFacebookSsoPayload';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type LoginWithGoogleSsoPayload = {
  __typename?: 'LoginWithGoogleSsoPayload';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type LoginWithImpersonationTokenPayload = {
  __typename?: 'LoginWithImpersonationTokenPayload';
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type LoginWithMagicTokenInput = {
  magicToken: Scalars['String']['input'];
};

export type LoginWithMagicTokenPayload = {
  __typename?: 'LoginWithMagicTokenPayload';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type LoginWithSsoPayload = {
  __typename?: 'LoginWithSsoPayload';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type MarkViewerPresenceInShowPayload = {
  __typename?: 'MarkViewerPresenceInShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type MondialRelayPickupPoint = {
  __typename?: 'MondialRelayPickupPoint';
  code?: Maybe<Scalars['String']['output']>;
  distanceFromSearchLocationInMeter?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<PickupPointLocation>;
  name?: Maybe<Scalars['String']['output']>;
  network?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Accept the Stripe debit agreement for the seller.
   *
   * 🔐 Authentication required
   */
  acceptStripeDebitAgreement: AcceptStripeTermsOfServicesPayload;
  /**
   * Accept the Stripe terms of services for the seller.
   *
   * 🔐 Authentication required
   */
  acceptStripeTermsOfServices: AcceptStripeTermsOfServicesPayload;
  acceptTermsAndConditions: AcceptTermsAndConditionsPayload;
  /**
   * Used by a seller to add multiple products
   *
   * 🔐 Authentication required - must be the seller of the shop
   */
  addBulkProductToShop: AddBulkProductToShopPayload;
  /**
   * Used by a seller to add multiple products to their inventory and the specified show (if provided) in the same transaction.
   *
   * If type is:
   * - `AUCTION` => `fixedAmount` is optional and `startingAmount` is mandatory
   * - `GIVEAWAY` => `startingAmount` must be `null` AND `fixedAmount` must be `null`
   * - `INSTANT_BUY` => `fixedAmount` is mandatory AND `startingAmount` must be `null`
   *
   * 🔐 Authentication required
   * 🔐 Viewer must be seller with access to an initialized inventory (`SellerConfig.canAccessInventory` and `SellerConfig.inventoryInitialized` must be true)
   * 🔐 Viewer must be the seller of the show (if provided)
   */
  addBulkProductsToInventoryAndShow: AddBulkProductsToInventoryAndShowPayload;
  /**
   * Add a comment to a Show.
   *
   * 🔐 Authentication required
   */
  addCommentToShow: AddShowCommentMutationPayload;
  /**
   * Used by a seller to add one or more products from their inventory to the specified show.
   * This will transfer all available quantities from the inventory product and associated products from past shows to the show.
   *
   * 🔐 Authentication required
   * 🔐 Viewer must be seller with access to an initialized inventory (`SellerConfig.canAccessInventory` and `SellerConfig.inventoryInitialized` must be true)
   * 🔐 Viewer must be the seller of the show
   * 🔐 Viewer must be the owner of specified inventory products
   */
  addInventoryProductsToShow: AddInventoryProductsToShowPayload;
  /**
   * Used by a seller to add a product to a shop
   *
   * 🔐 Authentication required - must be the seller of the shop
   */
  addProductToShop: AddProductToShopPayload;
  /**
   * Add a reaction to a Show.
   *
   * 🔐 Authentication required
   */
  addReactionToShow: AddReactionToShowPayload;
  /** 🔐 Authentication required */
  addShippingAddressV2: AddShippingAddressV2Payload;
  /** 🔐 Authentication required */
  answerPoll: AnswerPollPayload;
  applyForFeaturedShow: ApplyForFeaturedShowPayload;
  /**
   * Will block user from doing the following actions for all seller's shows:
   * - commenting in show
   * - participating in auctions
   * - participating in giveaways
   * - purchasing instant buys
   * - placing an auto-bid
   * Will also remove user's messages from chat
   *
   * 🔐 Authentication required
   */
  banUserFromSeller: BanUserFromSellerPayload;
  /**
   * Will block user from doing the following actions for this show:
   * - commenting in show
   * - participating in auctions
   * - participating in giveaways
   * - purchasing instant buys
   * - placing an auto-bid
   * Will also remove user's messages from chat
   *
   * 🔐 Authentication required
   */
  banUserFromShow: BanUserFromShowPayload;
  blockUserFromBiddingInAnyShow: BlockUserFromBiddingInAnyShowPayload;
  blockUserFromCommentingInAnyShow: BlockUserFromCommentingInAnyShowPayload;
  /**
   * Will block a User from commenting in a Show.
   *
   * 🔐 Authentication required - must be the seller of the show or a moderator or Voggt staff
   */
  blockUserFromCommentingInShow: BlockUserFromCommentingInShowMutationPayload;
  /** 🔐 Authentication required - only accessible to seller */
  bundleShipment: BundleShipmentPayload;
  /** 🔐 Authentication required - only accessible to seller */
  bundleShipmentFromUnshippedOrderedProductsForCustomer: BundleShipmentFromUnshippedOrderedProductsForCustomerPayload;
  /**
   * Cancel the order and automatically refund the customer.
   * Only possible if the status of this order is PENDING.
   *
   * 🔐 Authentication required - only accessible to the seller of the targeted order
   */
  cancelAndRefundOrder: CancelAndRefundOrderPayload;
  /**
   * Completely removes the raid from the show
   *
   * 🔐 Authentication required - must be the seller of the show (or Voggt staff)
   */
  cancelCurrentRaidInShow: CancelCurrentRaidInShowPayload;
  /**
   * Change current user biography
   *
   * 🔐 Authentication required
   */
  changeViewerBiography: ChangeViewerBiographyPayload;
  /**
   * Change current user email
   *
   * 🔐 Authentication required
   */
  changeViewerEmail: ChangeViewerEmailPayload;
  /**
   * Change viewer favourite pickup point.
   *
   * 🔐 Authentication required
   */
  changeViewerFavouritePickupPoint: ChangeViewerFavouritePickupPointPayload;
  /**
   * Change current user first name
   *
   * 🔐 Authentication required
   */
  changeViewerFirstName: ChangeViewerFirstNamePayload;
  /**
   * Change current user last name
   *
   * 🔐 Authentication required
   */
  changeViewerLastName: ChangeViewerLastNamePayload;
  /** Check if the provided password respects the requirements. */
  checkPasswordValidity: CheckPasswordValidityPayload;
  /**
   * Add the given bank account to the viewer seller.
   *
   * The user should have already done `/seller/create`.
   *
   * 🔐 Authentication required
   */
  configureStripeSellerBankAccountForViewer: ConfigureStripeBankAccountForViewerMutationPayload;
  /**
   * Allows the viewer to indicate that a group of orders was received on their end.
   *
   * 🔐 Authentication required
   */
  confirmOrdersGroupReceived: ConfirmOrdersGroupReceivedPayload;
  /** 🔐 Authentication required - must be the seller of the show */
  createAndLaunchPollInShow: CreateAndLaunchPollInShowPayload;
  /** 🔐 Authentication required - must be Voggt staff */
  createAutoFollowUserCampaign: CreateAutoFollowUserCampaignPayload;
  /**
   * Create a Bancontact payment method on Stripe.
   *
   * 🔐 Authentication required
   */
  createBancontactPaymentMethodOnStripe: CreateBancontactPaymentMethodOnStripePayload;
  /**
   * Create a CSV export of the orders for a given Show.
   *
   * Only orders with successful payment will be included.
   *
   * 🔐 Authentication required and viewer should be seller of the show (or admin).
   */
  createCSVExportOfOrdersForShow: CreateCsvExportOfOrdersPayload;
  /**
   * Create a CSV export of the orders sold by the viewer between 2 dates.
   *
   * Only orders with successful payment will be included.
   *
   * 🔐 Authentication required.
   */
  createCSVExportOfOrdersSoldByViewerBetweeDates: CreateCsvExportOfOrdersPayload;
  /**
   * Create a card payment method.
   *
   * 🔐 Authentication required
   * @deprecated Deprecated in favor of `createCardPaymentMethodV2`
   */
  createCardPaymentMethod: CreateCardPaymentMethodPayload;
  /**
   * Create a card payment method using Apple Pay.
   *
   * 🔐 Authentication required
   */
  createCardPaymentMethodUsingApplePay: CreateCardPaymentMethodUsingApplePayPayload;
  /**
   * Create a card payment method using Google Pay.
   *
   * 🔐 Authentication required
   */
  createCardPaymentMethodUsingGooglePay: CreateCardPaymentMethodUsingGooglePayPayload;
  /**
   * Create a card payment method.
   *
   * 🔐 Authentication required
   */
  createCardPaymentMethodV2: CreateCardPaymentMethodPayloadV2;
  createExportSellerLedgerTransactions: CreateExportSellerLedgerPayload;
  /**
   * Create an iDEAL payment method on Stripe.
   *
   * 🔐 Authentication required
   */
  createIdealPaymentMethodOnStripe: CreateIdealPaymentMethodOnStripePayload;
  /**
   * Create an individual (i.e. non professional) seller
   *
   * 🔐 Authentication required
   */
  createIndividualSeller: CreateIndividualSellerPayload;
  createPayoutFromSellerLedger: CreatePayoutFromSellerLedgerPayload;
  /**
   * Create a Paypal payment method with recurring payments enabled.
   *
   * 🔐 Authentication required
   */
  createPaypalPaymentMethodForRecurringPayments: CreatePaypalPaymentMethodForRecurringPaymentsPayload;
  /**
   * Will try to create an URL where to upload a pre-show teaser video.
   *
   * 🔐 Authentication required - must be a seller or Voggt staff
   */
  createPreShowTeaserVideoUploadUrl: CreatePreShowTeaserVideoUploadUrlPayload;
  /**
   * Create a professional seller
   *
   * 🔐 Authentication required
   */
  createProSeller: CreateProSellerPayload;
  /** Creates a magic link for the viewer to directly access the seller's studio */
  createSellerStudioMagicLink: CreateSellerStudioMagicLinkPayload;
  /** 🔐 Authentication required - only accessible to seller */
  createSellerSubAccount: CreateSellerSubAccountPayload;
  /**
   * Used by a seller to create a shop
   *
   * 🔐 Authentication required - must be a seller
   */
  createShop: CreateShopPayload;
  /**
   * Create a show.
   *
   * 🔐 Authentication required - only accessible to seller
   */
  createShow: CreateShowPayload;
  createShowInvitation: CreateShowInvitationPayload;
  /**
   * Create a Giropay payment method.
   *
   * 🔐 Authentication required
   */
  createViewerGiropayPaymentMethod: CreateGiropayPaymentMethodPayload;
  /**
   * Create a pre-authorized Paypal payment method.
   *
   * 🔐 Authentication required
   */
  createViewerPreAuthorizedPaypalPaymentMethod: CreatePreAuthorizedPaypalPaymentMethodPayload;
  /**
   * Delete the pre-show teaser video of the given show
   *
   * 🔐 Authentication required - must be a seller or Voggt staff
   */
  deletePreShowTeaserVideo: DeletePreShowTeaserVideoPayload;
  /** 🔐 Authentication required - only accessible to seller */
  deleteSellerSubAccount: DeleteSellerSubAccountPayload;
  /** 🔐 Authentication required */
  deleteShippingAddress: DeleteShippingAddressPayload;
  /**
   * Allows to delete the current user account.
   * It should disconnect the user immediately.
   *
   * 🔐 Authentication required
   */
  deleteViewerAccount: DeleteViewerAccountPayload;
  demoteUserFromModerator: DemoteUserFromModeratorPayload;
  /** 🔐 Authentication required - only accessible to seller */
  dispatchShipmentByOwnMethods: DispatchShipmentByOwnMethodsPayload;
  /** 🔐 Authentication required - only accessible to seller */
  dispatchShipmentWithBoxtal: DispatchShipmentWithBoxtalPayload;
  /** 🔐 Authentication required - only accessible to seller */
  dispatchShipmentWithSendcloud: DispatchShipmentWithSendcloudPayload;
  /** 🔐 Authentication required - must be the author of the rating */
  editShipmentRating: EditShipmentRatingPayload;
  /**
   * Set basic info of the user authenticated by registration token, registering by sso.
   *
   * After this step, the user account is fully created and cannot be overridden.
   */
  finalizeUserAccountForSsoRegistration: FinalizeUserAccountForSsoRegistrationPayload;
  followCategories: FollowCategoriesPayload;
  followCategory: FollowCategoryPayload;
  followLanguage: FollowLanguagePayload;
  /**
   * Following users will get a notification when the product will go on auction (during pre-auction timer)
   *
   * 🔐 Authentication required
   */
  followProduct: FollowProductPayload;
  /**
   * Following users will get a notification when the show will start
   *
   * 🔐 Authentication required
   */
  followShow: FollowShowPayload;
  followUser: FollowUserPayload;
  /** @deprecated Use generateIVSWebRTCTokenV2 instead */
  generateIVSWebRTCToken: GenerateIvsWebRtcTokenPayload;
  generateIVSWebRTCTokenV2: GenerateIvsWebRtcTokenPayloadV2;
  /** 🔐 Authentication required - must be a seller */
  generatePackingSlipForShipments: GeneratePackingSlipForShipmentsPayload;
  /**
   * Must not be called with multiple customers' order products
   *
   * 🔐 Authentication required - must be a seller
   */
  generatePackingSlipForUnshippedOrderedProducts: GeneratePackingSlipForUnshippedOrderedProductsPayload;
  /** 🔐 Authentication required - must be seller with access to an uninitialized inventory (`SellerConfig.canAccessInventory` must be true and `SellerConfig.inventoryInitialized` must be false) */
  initializeInventory: InitializeInventoryPayload;
  /**
   * Login with the credential & password provided.
   *
   * `credential` may either be the user's username, verified phone number or verified email.
   */
  login: LoginPayload;
  /** Login with the specified access token, issued by Facebook. */
  loginWithFacebookSso: LoginWithFacebookSsoPayload;
  /** Login with the specified authorization code, issued by Google. */
  loginWithGoogleSso: LoginWithGoogleSsoPayload;
  /**
   * Login with impersonation Token, co-created with the Voggtpit
   *
   * `impersonationToken` should be a valid ImpersonationToken
   */
  loginWithImpersonationToken: LoginWithImpersonationTokenPayload;
  /** Logs in the user with the magic token extracted from the `magicToken` query param on the seller studio */
  loginWithMagicToken: LoginWithMagicTokenPayload;
  /** Login with the specified ID JWT, issued by an SSO provider. */
  loginWithSso: LoginWithSsoPayload;
  /**
   * Called when the app is opened.
   * Used to exchange basic platform informations when the app is opened and the user is logged in.
   *
   * 🔐 Authentication required
   */
  openApp: OpenAppPayload;
  /**
   * This mutation allows a seller to send their viewers on another show, if they want to participate
   *
   * 🔐 Authentication required - must be the seller of the show (or Voggt staff)
   */
  openRaidInShow: OpenRaidInShowPayload;
  openShowInvitation: OpenShowInvitationPayload;
  /**
   * Adds the user as participant to the raid
   *
   * 🔐 Authentication required
   */
  participateToCurrentRaidInShow: ParticipateToCurrentRaidInShowPayload;
  /**
   * Enters a giveaway in a Show.
   *
   * 🔐 Authentication required
   */
  participateToGiveawayInShow: ParticipateToGiveawayInShowMutationPayload;
  /**
   * Place a bid  on an auction.
   *
   * 🔐 Authentication required
   * @deprecated Use `placeBidOnAuctionV2` instead
   */
  placeBidOnAuction: PlaceBidOnAuctionMutationPayload;
  /**
   * Place a bid  on an auction.
   *
   * 🔐 Authentication required
   */
  placeBidOnAuctionV2: PlaceBidOnAuctionMutationV2Payload;
  /** 🔐 Authentication required */
  placeFlashSaleOrder: PlaceFlashSaleOrderPayload;
  /**
   * Used to purchase a product immediately.
   * Error cases that can be encountered:
   * - WRONG_PROMOTION => promotion sent by client does not match promotion retrieved by backend. Please retrieve promotions again.
   * - PROMOTION_NOT_AVAILABLE => client sent a promotionId but backend couldn't retrieve any available promotion
   *
   * 🔐 Authentication required
   * @deprecated Use `placeInstantBuyProductOrderV2` instead, where promotion is chosen by client
   */
  placeInstantBuyProductOrder: PlaceInstantBuyProductOrderPayload;
  /**
   * Used to purchase a product immediately.
   *
   * 🔐 Authentication required
   */
  placeInstantBuyProductOrderV2: PlaceInstantBuyProductOrderV2Payload;
  /**
   * Place a pre-bid on the specified product.
   *
   * The user will automatically follow the product upon successful pre-authorization of the pre-bid.
   *
   * 🔐 Authentication required.
   * 🔐 Only allowed if:
   * - seller's config has `isPreBidEligible` set to `true`
   * - customer is not the seller
   * - customer has registered a shipping address
   * - customer has registered a card or PayPal as payment method
   * - customer has not already placed a pre-bid on the specified product yet
   * - customer doesn't have any order to regularize
   * - we're between `show.startAt` minus 5 days and `show.endedAt` (or `show.startAt` + 6h in case `show.endedAt` is not set)
   * - product belongs to a show
   * - product type is `auction` and product status is `pending`
   * - amount is:
   *   - higher than product starting price
   */
  placePreBidOnProduct: PlacePreBidOnProductPayload;
  /**
   * Process a pre-authorization on the viewer's active payment method, for a specific show that requires pre-authorization.
   *
   * 🔐 Authentication required
   */
  processPreAuthorizationOnActivePaymentMethod: ProcessPreAuthorizationOnActivePaymentMethodPayload;
  promoteUserToModerator: PromoteUserToModeratorPayload;
  /** 🔐 Authentication required - only accessible to seller */
  quoteForBoxtal: QuoteForBoxtalPayload;
  /** 🔐 Authentication required - only accessible to seller */
  quoteForSendcloud: QuoteForSendcloudPayload;
  /**
   * Allows the viewer to rate a group of orders they received.
   *
   * 🔐 Authentication required
   * @deprecated Use rateReceivedOrdersGroupV2 instead.
   */
  rateReceivedOrdersGroup: RateReceivedOrdersGroupPayload;
  /**
   * Allows the viewer to rate a group of orders they received.
   *
   * 🔐 Authentication required
   * @deprecated Use rateReceivedOrdersGroupV3 instead.
   */
  rateReceivedOrdersGroupV2: RateReceivedOrdersGroupPayloadV2;
  /**
   * Allows the viewer to rate a group of orders they received.
   * This V3 implies that the user consents for public display of their review
   *
   * 🔐 Authentication required
   */
  rateReceivedOrdersGroupV3: RateReceivedOrdersGroupPayloadV3;
  /**
   * Send the rating from the seller about his show
   *
   * 🔐 Authentication required - must be a seller on his own show
   */
  rateShowAsSeller: RateShowAsSellerPayload;
  /**
   * Recreates the notification with up to date rules.
   * ⚠️ Will overwrite any customization made on the 3rd party API/UI
   *
   * 🔐 Authentication required - must be Voggt staff
   */
  reCreateShowNotification: ReCreateShowNotificationPayload;
  /** Refresh the provided authentication token for the specified user. */
  refreshAuthenticationToken: RefreshAuthenticationTokenPayload;
  /** 🔐 Authentication required */
  registerPushToken: RegisterPushTokenPayload;
  /**
   * Create a new account with the specified email.
   *
   * This is the very 1st step of the registration process.
   *
   * The resulting registration token is to be used in the 2nd step: set up password.
   */
  registerWithEmail: RegisterWithEmailPayload;
  /**
   * Create a new account with the specified access token, issued by Facebook.
   * @deprecated registerWithFacebookSsoV2 should now be used as it contains an anti-fraud system that verifies phone number. Except for german android users.
   */
  registerWithFacebookSso: RegisterWithFacebookSsoPayload;
  /** Create a new account with the specified access token, issued by Facebook. */
  registerWithFacebookSsoV2: RegisterWithFacebookSsoV2Payload;
  /** Create a new account with the specified authorization code, issued by Google. */
  registerWithGoogleSso: RegisterWithGoogleSsoPayload;
  /**
   * Create a new account with the specified ID JWT, issued by an SSO provider.
   * @deprecated registerWithSsoV2 should now be used as it contains an anti-fraud system that verifies phone number. Except for german android users.
   */
  registerWithSso: RegisterWithSsoPayload;
  /** Create a new account with the specified ID JWT, issued by an SSO provider. */
  registerWithSsoV2: RegisterWithSsoV2Payload;
  registrationFlowCategoriesScreen: RegistrationFlowStepPayload;
  registrationFlowEmailScreen: RegistrationFlowStepPayload;
  registrationFlowParentCategoriesScreen: RegistrationFlowStepPayload;
  registrationFlowPasswordScreen: RegistrationFlowStepPayload;
  registrationFlowPhoneNumberScreen: RegistrationFlowStepPayload;
  registrationFlowPhoneNumberVerifyScreen: RegistrationFlowStepPayload;
  registrationFlowReferralCodeScreen: RegistrationFlowStepPayload;
  registrationFlowUsernameScreen: RegistrationFlowStepPayload;
  /**
   * Used by a seller to bulk remove products
   *
   * 🔐 Authentication required - must be the seller of the show
   */
  removeBulkProductFromShow: RemoveBulkProductFromShowPayload;
  /**
   * Used by a seller to remove a product from their inventory.
   *
   * Will fail if there is at least one associated product with status `in_progress`.
   *
   * Will automatically remove all associated products that are available (i.e. with status `pending` or `unsold`).
   *
   * 🔐 Authentication required
   * 🔐 Viewer must be seller with access to an initialized inventory (`SellerConfig.canAccessInventory` and `SellerConfig.inventoryInitialized` must be true)
   * 🔐 Viewer must be the owner of specified inventory product
   */
  removeInventoryProduct: RemoveInventoryProductPayload;
  /**
   * Used by a seller to remove multiple products from their inventory at once.
   *
   * Will fail if there is at least one associated product with status `in_progress`.
   *
   * Will automatically remove all associated products that are available (i.e. with status `pending` or `unsold`).
   *
   * 🔐 Authentication required
   * 🔐 Viewer must be seller with access to an initialized inventory (`SellerConfig.canAccessInventory` and `SellerConfig.inventoryInitialized` must be true)
   * 🔐 Viewer must be the owner of specified inventory products
   */
  removeInventoryProducts: RemoveInventoryProductsPayload;
  /**
   * Used by a seller to remove a product
   *
   * 🔐 Authentication required - must be the seller of the shop
   */
  removeProductFromShop: RemoveProductFromShopPayload;
  /**
   * Used by a seller to remove a product
   *
   * 🔐 Authentication required - must be the seller of the show (or Voggt staff)
   */
  removeProductFromShow: RemoveProductFromShowPayload;
  /**
   * Remove the Bancontact payment method attached to the viewer.
   *
   * 🔐 Authentication required
   */
  removeViewerBancontactPaymentMethod: RemoveViewerBancontactPaymentMethodPayload;
  /**
   * Remove the card payment method attached to the viewer.
   *
   * 🔐 Authentication required
   */
  removeViewerCardPaymentMethod: RemoveViewerCardPaymentMethodPayload;
  /**
   * Remove the Giropay payment method attached to the viewer.
   *
   * 🔐 Authentication required
   */
  removeViewerGiropayPaymentMethod: RemoveViewerGiropayPaymentMethodPayload;
  /**
   * Remove the iDEAL payment method attached to the viewer.
   *
   * 🔐 Authentication required
   */
  removeViewerIdealPaymentMethod: RemoveViewerIdealPaymentMethodPayload;
  /**
   * Remove the Paypal payment method attached to the viewer.
   *
   * 🔐 Authentication required
   */
  removeViewerPaypalPaymentMethod: RemoveViewerPaypalPaymentMethodPayload;
  /**
   * Remove the Paypal payment method with recurring payments attached to the viewer.
   *
   * 🔐 Authentication required
   */
  removeViewerPaypalPaymentMethodForRecurringPayments: RemoveViewerPaypalPaymentMethodForRecurringPaymentsPayload;
  /**
   * Will publish an event regarding the issue.
   *
   * 🔒 Authentication required
   */
  reportBugInShow: ReportBugInShowPayload;
  /**
   * Will create a Zendesk ticket regarding the issue.
   *
   * 🔒 Authentication required and viewer must be seller of the targeted show
   */
  reportIssueInShow: ReportIssueInShowPayload;
  /** 🔐 Authentication required - only accessible to seller */
  reportOrderIssueToCustomer: ReportOrderIssueToCustomerPayload;
  /** 🔐 Authentication required */
  reportOrderIssueToSeller: ReportOrderIssueToSellerPayload;
  /** 🔐 Authentication required */
  reportOrderIssueToSupport: ReportOrderIssueToSupportPayload;
  /**
   * Create a report about a particular product.
   *
   * 🔐 Authentication required.
   */
  reportProduct: ReportProductPayload;
  /**
   * Create a report about a particular show.
   *
   * 🔐 Authentication required.
   */
  reportShow: ReportShowPayload;
  /**
   * Create a report about a particular show comment.
   * The comment report will then be processed by moderators.
   *
   * 🔐 Authentication required.
   */
  reportShowComment?: Maybe<ReportShowCommentPayload>;
  /**
   * Create a report about a particular user.
   * The user report will then be processed by moderators.
   *
   * 🔐 Authentication required.
   * @deprecated Use reportUserProfile instead
   */
  reportUser: ReportUserPayload;
  /**
   * Create a report about a particular user profile.
   *
   * 🔐 Authentication required.
   */
  reportUserProfile: ReportUserProfilePayload;
  requestMagicLinkForSellerSubAccount: RequestMagicLinkForSellerSubAccountPayload;
  requestNewPhoneNumberVerificationCode: RequestNewPhoneNumberVerificationCodePayload;
  /**
   * Request the cancellation of an order.
   * Only possible if the status of this order is PENDING.
   *
   * 🔐 Authentication required - only accessible to the seller of the targeted order
   */
  requestOrderCancellation: RequestOrderCancellationPayload;
  /**
   * Request the cancellation of the shipping fee for an order.
   * Only possible if the status of this order is PENDING.
   *
   * 🔐 Authentication required - only accessible to the seller of the targeted order
   */
  requestOrderShippingFeeCancellation: RequestOrderShippingFeeCancellationPayload;
  /**
   * Request a password reset for the account identified by credential.
   *
   * `credential` may either be the user's email or username.
   */
  requestPasswordReset: RequestPasswordResetPayload;
  /**
   * Will request the (async) generation of a show teaser video.
   * Subscribe to the related ShowTeaserVideoStatusChanged subscription to get notified when the video is ready.
   * Won't work for past shows.
   *
   * 🔐 Authentication required - must be the seller of the show or Voggt staff
   */
  requestShowTeaserCreation: RequestShowTeaserCreationPayload;
  /** Reset user password */
  resetUserPassword: ResetUserPasswordPayload;
  /**
   * Retries a failed payment.
   *
   * 🔐 Authentication required.
   */
  retryPayment: RetryPaymentPayload;
  /**
   * Save (without following) one or more parent categories as user preferences to customize the initial feed upon account creation.
   *
   * Returns top categories to be displayed in the next step, matching the parent categories selection.
   *
   * Follows the same rules as the `topCategoriesOrderedByPosition` query:
   *
   *   Top categories are ordered by show count then position, top meaning categories with the most shows.
   *
   *   If the user follows EN language only, shows are not filtered.
   *   In all other cases, shows are filtered based on the authenticated user's followed languages.
   *
   *   If two or more categories have the same amount of shows, result is then ordered by category position index.
   *
   * 🔐 Authentication required
   */
  saveUserPreferredParentCategories: SaveUserPreferredParentCategoriesPayload;
  /**
   * Terminates the show and the raid, effectively sending participating users to the destination show
   *
   * 🔐 Authentication required - must be the seller of the show (or Voggt staff)
   */
  sendCurrentShowRaidAndEndShow: SendCurrentRaidAndEndShowPayload;
  /**
   * Will send the notification right now, indicating that the seller is ready.
   * Can only work if the notification has been approved beforehand by a staff member
   */
  sendNotificationForShow: SendNotificationForShowPayload;
  setSellerFavoriteShippingLabelFormat: SetSellerFavoriteShippingLabelFormatPayload;
  /**
   * Define the address used for shipping by the seller
   *
   * 🔐 Authentication required - only accessible to seller
   */
  setSellerShippingAddress: SetSellerShippingAddressPayload;
  /** 🔐 Authentication required - only accessible to seller */
  setShipmentStatusLabelDownloaded: SetShipmentStatusLabelDownloadedPayload;
  /**
   * Set basic info of the user authenticated by registration token, registering by email.
   *
   * After this step, the user account is fully created and cannot be overridden.
   */
  setViewerRemainingInformationForRegistrationWithEmail: SetViewerRemainingInformationForRegistrationWithEmailPayload;
  /**
   * Set basic info of the user authenticated by registration token.
   *
   * After this step, the user account is fully created and cannot be overridden.
   */
  setViewerRemainingRegistrationInformationV2: SetViewerRemainingRegistrationInformationV2Payload;
  /**
   * Start an auction for a given product in the given show.
   *
   * 🔐 Authentication required - only seller of the show allowed
   */
  startAuctionInShow: StartAuctionInShowMutationPayload;
  /** 🔐 Authentication required - must be the seller of the show */
  startFlashSaleInShow: StartFlashSaleInShowPayload;
  /**
   * Starts a giveaway in a Show.
   *
   * 🔐 Authentication required - must be the seller of the show (or Voggt staff)
   */
  startGiveawayInShow: StartGiveawayMutationPayload;
  startRegistrationFlow: RegistrationFlowStepPayload;
  startRegistrationFlowWithFacebookSSO: RegistrationFlowStepPayload;
  startRegistrationFlowWithOpenIdSSO: RegistrationFlowStepPayload;
  /** 🔒 Authentication required and viewer must be seller of the targeted show */
  terminateShow: TerminateShowPayload;
  /** Sends the code verification to the user's phone number. */
  triggerPhoneNumberVerification: TriggerPhoneNumberVerificationPayload;
  /** Sends the code verification to the user's phone number for sso registration. */
  triggerPhoneNumberVerificationForSsoRegistration: TriggerPhoneNumberVerificationForSsoRegistrationPayload;
  triggerSellerPhoneNumberVerification: TriggerSellerPhoneNumberVerificationPayload;
  /**
   * Reverts banUserFromSeller mutation
   * 🔐 Authentication required
   */
  unbanUserFromSeller: UnbanUserFromSellerPayload;
  /**
   * Reverts banUserFromShow mutation
   * 🔐 Authentication required
   */
  unbanUserFromShow: UnbanUserFromShowPayload;
  /**
   * Will unblock a User from commenting in a Show.
   *
   * 🔐 Authentication required - must be the seller of the show or a moderator or Voggt staff
   */
  unblockUserFromCommentingInShow: UnblockUserFromCommentingInShowMutationPayload;
  /** 🔐 Authentication required - only accessible to seller */
  unbundleAllUnshippedShipmentsForCustomer: UnbundleAllUnshippedShipmentsForCustomerPayload;
  /** 🔐 Authentication required - only accessible to seller */
  unbundleShipment: UnbundleShipmentPayload;
  unfollowCategory: UnfollowCategoryPayload;
  unfollowLanguage: UnfollowLanguagePayload;
  /**
   * Opposite of `followProduct`
   *
   * 🔐 Authentication required
   */
  unfollowProduct: UnfollowProductPayload;
  /**
   * Opposite of followShow
   * 🔐 Authentication required
   */
  unfollowShow: UnfollowShowPayload;
  unfollowUser: UnfollowUserPayload;
  /**
   * This is a debug feature, use with caution. It will remove the auction state and the giveaway state from a show.
   *
   * 🔐 Authentication required - seller or staff member
   */
  unstuckShowCurrentAuctionOrGiveaway: UnstuckShowCurrentAuctionOrGiveawayPayload;
  /**
   * Used by a seller to update:
   * - either an inventory product along with its associated products
   * - or a show product
   *
   * One and only one of `inventoryProductId` or `productId` must be provided. Providing none or both will lead to an error.
   *
   * The update is processed in one global transaction.
   *
   * If type is:
   * - `AUCTION` => `fixedAmount` is optional and `startingAmount` is mandatory
   * - `GIVEAWAY` => `startingAmount` must be `null` AND `fixedAmount` must be `null`
   * - `INSTANT_BUY` => `fixedAmount` is mandatory AND `startingAmount` must be `null`
   *
   * 🔐 Authentication required
   * 🔐 Viewer must be seller
   * 🔐 If `inventoryProductId` is provided, viewer must have access to an initialized inventory (`SellerConfig.canAccessInventory` and `SellerConfig.inventoryInitialized` must be true)
   * 🔐 Viewer must be owner of the inventory product / product to update.
   */
  updateInventoryProductOrProductInShow: UpdateInventoryProductOrProductInShowPayload;
  /**
   * Add note from the seller to an order.
   *
   * 🔐 Authentication required - only accessible to the seller of the targeted order
   */
  updateOrderNote: UpdateOrderNotePayload;
  /**
   * Will update the pre show teaser video URL of the given show with the given upload URL.
   *
   * 🔐 Authentication required - must be a seller or Voggt staff
   */
  updatePreShowTeaserVideoUrl: UpdatePreShowTeaserVideoUrlPayload;
  /**
   * Update a professional seller company
   *
   * 🔐 Authentication required
   */
  updateProSellerCompany: UpdateProSellerCompanyPayload;
  /**
   * Used by a seller to update a product
   *
   * 🔐 Authentication required - must be the seller of the shop
   */
  updateProductFromShop: UpdateProductFromShopPayload;
  /** 🔒 Authentication required and viewer must be seller of the targeted product */
  updateProductIsPinned: UpdateProductIsPinnedPayload;
  /** 🔐 Authentication required - must be the seller of the show */
  updateProductPositions: UpdateProductPositionsPayload;
  updateProductToFirstPosition: UpdateProductToFirstPositionPayload;
  /** 🔐 Authentication required - only accessible to seller */
  updateShipmentShippingInfoByOwnMethods: UpdateShipmentShippingInfoByOwnMethodsPayload;
  /**
   * Will update the duration of the auctions of the given show.
   *
   * The choices of duration are exposed as enum `AuctionInitialDurationPreset`
   *
   * 🔒 Authentication required and viewer must be seller of the targeted show
   */
  updateShowAuctionsInitialDuration: UpdateAuctionDurationShowPayload;
  /**
   * Will update the reset duration of the auctions of the given show.
   *
   * The choices of reset duration are exposed as enum `AuctionResetDurationPreset`
   *
   * 🔒 Authentication required and viewer must be seller of the targeted show
   */
  updateShowAuctionsResetDuration: UpdateAuctionResetDurationShowPayload;
  /** 🔒 Authentication required and viewer must be seller of the targeted show */
  updateShowLanguage: UpdateShowLanguagePayload;
  /** 🔒 Authentication required and viewer must be seller of the targeted show */
  updateShowName: UpdateShowNamePayload;
  /** 🔐 Authentication required - must be Voggt staff */
  updateShowNotificationSendingMethod: UpdateShowNotificationSendingMethodPayload;
  /**
   * Will update the shipping category of the given show.
   *
   * The choices of shipping categories are exposed as enum `ShippingCategory`
   *
   * 🔒 Authentication required and viewer must be seller of the targeted show
   */
  updateShowShippingCategory: UpdateShowShippingCategoryPayload;
  upsertSellerBankingInfo: UpsertSellerBankingInfoPayload;
  /**
   * Validate phone number with the registration token provided by registerWithEmail and the code received by SMS.
   * This is the 3rd step of the registration process.
   */
  verifyPhoneNumberForEmailRegistration: VerifyPhoneNumberForEmailRegistrationPayload;
  /**
   * Validate phone number with the registration token provided by registerWithSsoV2 and the code received by SMS.
   * This is the 3rd step of the registration process.
   */
  verifyPhoneNumberForSsoRegistration: VerifyPhoneNumberForSsoRegistrationPayload;
  verifyUserEmail: VerifyUserEmailPayload;
};


export type MutationAcceptTermsAndConditionsArgs = {
  input: AcceptTermsAndConditionsInput;
};


export type MutationAddBulkProductToShopArgs = {
  input: AddBulkProductToShopInput;
};


export type MutationAddBulkProductsToInventoryAndShowArgs = {
  input: AddBulkProductsToInventoryAndShowInput;
};


export type MutationAddCommentToShowArgs = {
  input: ShowCommentInput;
};


export type MutationAddInventoryProductsToShowArgs = {
  input: AddInventoryProductsToShowInput;
};


export type MutationAddProductToShopArgs = {
  input: AddProductToShopInput;
};


export type MutationAddReactionToShowArgs = {
  input: AddReactionToShowInput;
};


export type MutationAddShippingAddressV2Args = {
  input: AddShippingAddressV2Input;
};


export type MutationAnswerPollArgs = {
  input: AnswerPollInput;
};


export type MutationApplyForFeaturedShowArgs = {
  input: ApplyForFeaturedShowInput;
};


export type MutationBanUserFromSellerArgs = {
  input: BanUserFromSellerInput;
};


export type MutationBanUserFromShowArgs = {
  input: BanUserFromShowInput;
};


export type MutationBlockUserFromBiddingInAnyShowArgs = {
  input: BlockUserFromBiddingInAnyShowInput;
};


export type MutationBlockUserFromCommentingInAnyShowArgs = {
  input: BlockUserFromCommentingInAnyShowInput;
};


export type MutationBlockUserFromCommentingInShowArgs = {
  showId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationBundleShipmentArgs = {
  input: BundleShipmentInput;
};


export type MutationBundleShipmentFromUnshippedOrderedProductsForCustomerArgs = {
  input: BundleShipmentFromUnshippedOrderedProductsForCustomerInput;
};


export type MutationCancelAndRefundOrderArgs = {
  input: CancelAndRefundOrderInput;
};


export type MutationCancelCurrentRaidInShowArgs = {
  input: CancelCurrentRaidInShowInput;
};


export type MutationChangeViewerBiographyArgs = {
  input: ChangeViewerBiographyInput;
};


export type MutationChangeViewerEmailArgs = {
  input: ChangeViewerEmailInput;
};


export type MutationChangeViewerFavouritePickupPointArgs = {
  input: ChangeViewerFavouritePickupPointInput;
};


export type MutationChangeViewerFirstNameArgs = {
  input: ChangeViewerFirstNameInput;
};


export type MutationChangeViewerLastNameArgs = {
  input: ChangeViewerLastNameInput;
};


export type MutationCheckPasswordValidityArgs = {
  input: CheckPasswordValidityInput;
  registrationToken: Scalars['String']['input'];
};


export type MutationConfigureStripeSellerBankAccountForViewerArgs = {
  input: ConfigureStripeBankAccountForViewerInput;
};


export type MutationConfirmOrdersGroupReceivedArgs = {
  input: ConfirmOrdersGroupReceivedInput;
};


export type MutationCreateAndLaunchPollInShowArgs = {
  input: CreateAndLaunchPollInShowInput;
};


export type MutationCreateAutoFollowUserCampaignArgs = {
  input: CreateAutoFollowUserCampaignInput;
};


export type MutationCreateBancontactPaymentMethodOnStripeArgs = {
  input: CreateBancontactPaymentMethodOnStripeInput;
};


export type MutationCreateCsvExportOfOrdersForShowArgs = {
  input: CreateCsvExportOfOrdersForShowInput;
};


export type MutationCreateCsvExportOfOrdersSoldByViewerBetweeDatesArgs = {
  input: CreateCsvExportOfOrdersSoldByViewerBetweeDatesInput;
};


export type MutationCreateCardPaymentMethodArgs = {
  input: CreateCardPaymentMethodInput;
};


export type MutationCreateExportSellerLedgerTransactionsArgs = {
  input: CreateExportSellerLedgerInput;
};


export type MutationCreateIdealPaymentMethodOnStripeArgs = {
  input: CreateIdealPaymentMethodOnStripeInput;
};


export type MutationCreateIndividualSellerArgs = {
  input: CreateIndividualSellerInput;
};


export type MutationCreateProSellerArgs = {
  input: CreateProSellerInput;
};


export type MutationCreateSellerStudioMagicLinkArgs = {
  input: CreateSellerStudioMagicLinkInput;
};


export type MutationCreateSellerSubAccountArgs = {
  input: CreateSellerSubAccountInput;
};


export type MutationCreateShopArgs = {
  input: CreateShopInput;
};


export type MutationCreateShowArgs = {
  input: CreateShowInput;
};


export type MutationCreateShowInvitationArgs = {
  input: CreateShowInvitationInput;
};


export type MutationCreateViewerGiropayPaymentMethodArgs = {
  input: CreateGiropayPaymentMethodInput;
};


export type MutationDeletePreShowTeaserVideoArgs = {
  input: DeletePreShowTeaserVideoInput;
};


export type MutationDeleteSellerSubAccountArgs = {
  input: DeleteSellerSubAccountInput;
};


export type MutationDeleteShippingAddressArgs = {
  input: DeleteShippingAddressInput;
};


export type MutationDemoteUserFromModeratorArgs = {
  input: DemoteUserFromModeratorInput;
};


export type MutationDispatchShipmentByOwnMethodsArgs = {
  input: DispatchShipmentByOwnMethodsInput;
};


export type MutationDispatchShipmentWithBoxtalArgs = {
  input: DispatchShipmentWithBoxtalInput;
};


export type MutationDispatchShipmentWithSendcloudArgs = {
  input: DispatchShipmentWithSendcloudInput;
};


export type MutationEditShipmentRatingArgs = {
  input: EditShipmentRatingInput;
};


export type MutationFinalizeUserAccountForSsoRegistrationArgs = {
  input: FinalizeUserAccountForSsoRegistrationInput;
  registrationToken: Scalars['String']['input'];
};


export type MutationFollowCategoriesArgs = {
  categoryIds: Array<Scalars['ID']['input']>;
};


export type MutationFollowCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type MutationFollowLanguageArgs = {
  languageId: Scalars['ID']['input'];
};


export type MutationFollowProductArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationFollowShowArgs = {
  showId: Scalars['ID']['input'];
};


export type MutationFollowUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationGenerateIvsWebRtcTokenArgs = {
  input: GenerateIvsWebRtcTokenInput;
};


export type MutationGenerateIvsWebRtcTokenV2Args = {
  input: GenerateIvsWebRtcTokenInputV2;
};


export type MutationGeneratePackingSlipForShipmentsArgs = {
  input: GeneratePackingSlipForShipmentsInput;
};


export type MutationGeneratePackingSlipForUnshippedOrderedProductsArgs = {
  input: GeneratePackingSlipForUnshippedOrderedProductsInput;
};


export type MutationLoginArgs = {
  credential: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginWithFacebookSsoArgs = {
  accessToken: Scalars['String']['input'];
};


export type MutationLoginWithGoogleSsoArgs = {
  authorizationCode: Scalars['String']['input'];
};


export type MutationLoginWithImpersonationTokenArgs = {
  impersonationToken: Scalars['String']['input'];
};


export type MutationLoginWithMagicTokenArgs = {
  input: LoginWithMagicTokenInput;
};


export type MutationLoginWithSsoArgs = {
  idToken: Scalars['String']['input'];
};


export type MutationOpenAppArgs = {
  input: OpenAppInput;
};


export type MutationOpenRaidInShowArgs = {
  input: OpenRaidInShowInput;
};


export type MutationOpenShowInvitationArgs = {
  input: OpenShowInvitationInput;
};


export type MutationParticipateToCurrentRaidInShowArgs = {
  input: ParticipateToCurrentRaidInShowInput;
};


export type MutationParticipateToGiveawayInShowArgs = {
  input: ParticipateToGiveawayInShowInput;
};


export type MutationPlaceBidOnAuctionArgs = {
  amountEuroCents: Scalars['Int']['input'];
  auctionId: Scalars['ID']['input'];
};


export type MutationPlaceBidOnAuctionV2Args = {
  input: PlaceBidOnAuctionMutationV2Input;
};


export type MutationPlaceFlashSaleOrderArgs = {
  input: PlaceFlashSaleOrderInput;
};


export type MutationPlaceInstantBuyProductOrderArgs = {
  input: PlaceInstantBuyProductOrderInput;
};


export type MutationPlaceInstantBuyProductOrderV2Args = {
  input: PlaceInstantBuyProductOrderV2Input;
};


export type MutationPlacePreBidOnProductArgs = {
  input: PlacePreBidOnProductInput;
};


export type MutationProcessPreAuthorizationOnActivePaymentMethodArgs = {
  input: ProcessPreAuthorizationOnActivePaymentMethodInput;
};


export type MutationPromoteUserToModeratorArgs = {
  input: PromoteUserToModeratorInput;
};


export type MutationQuoteForBoxtalArgs = {
  input: QuoteForBoxtalInput;
};


export type MutationQuoteForSendcloudArgs = {
  input: QuoteForSencloudInput;
};


export type MutationRateReceivedOrdersGroupArgs = {
  input: RateReceivedOrdersGroupInput;
};


export type MutationRateReceivedOrdersGroupV2Args = {
  input: RateReceivedOrdersGroupInputV2;
};


export type MutationRateReceivedOrdersGroupV3Args = {
  input: RateReceivedOrdersGroupInputV3;
};


export type MutationRateShowAsSellerArgs = {
  input: RateShowAsSellerInput;
};


export type MutationReCreateShowNotificationArgs = {
  input: ReCreateShowNotificationInput;
};


export type MutationRefreshAuthenticationTokenArgs = {
  refreshToken: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRegisterPushTokenArgs = {
  input: RegisterPushTokenInput;
};


export type MutationRegisterWithEmailArgs = {
  input: RegisterWithEmailInput;
};


export type MutationRegisterWithFacebookSsoArgs = {
  input: RegisterWithFacebookSsoInput;
};


export type MutationRegisterWithFacebookSsoV2Args = {
  input: RegisterWithFacebookSsoV2Input;
};


export type MutationRegisterWithGoogleSsoArgs = {
  input: RegisterWithGoogleSsoInput;
};


export type MutationRegisterWithSsoArgs = {
  input: RegisterWithSsoInput;
};


export type MutationRegisterWithSsoV2Args = {
  input: RegisterWithSsoV2Input;
};


export type MutationRegistrationFlowCategoriesScreenArgs = {
  input: RegistrationFlowCategoriesScreenInput;
};


export type MutationRegistrationFlowEmailScreenArgs = {
  input: RegistrationFlowEmailScreenInput;
};


export type MutationRegistrationFlowParentCategoriesScreenArgs = {
  input: RegistrationFlowParentCategoriesScreenInput;
};


export type MutationRegistrationFlowPasswordScreenArgs = {
  input: RegistrationFlowPasswordScreenInput;
};


export type MutationRegistrationFlowPhoneNumberScreenArgs = {
  input: RegistrationFlowPhoneNumberScreenInput;
};


export type MutationRegistrationFlowPhoneNumberVerifyScreenArgs = {
  input: RegistrationFlowPhoneNumberVerifyScreenInput;
};


export type MutationRegistrationFlowReferralCodeScreenArgs = {
  input: RegistrationFlowReferralCodeScreenInput;
};


export type MutationRegistrationFlowUsernameScreenArgs = {
  input: RegistrationFlowUsernameScreenInput;
};


export type MutationRemoveBulkProductFromShowArgs = {
  input: RemoveBulkProductFromShowInput;
};


export type MutationRemoveInventoryProductArgs = {
  input: RemoveInventoryProductInput;
};


export type MutationRemoveInventoryProductsArgs = {
  input: RemoveInventoryProductsInput;
};


export type MutationRemoveProductFromShopArgs = {
  input: RemoveProductFromShopInput;
};


export type MutationRemoveProductFromShowArgs = {
  input: RemoveProductFromShowInput;
};


export type MutationReportBugInShowArgs = {
  input: ReportBugInShowInput;
};


export type MutationReportIssueInShowArgs = {
  input: ReportIssueInShowInput;
};


export type MutationReportOrderIssueToCustomerArgs = {
  input: ReportOrderIssueToCustomerInput;
};


export type MutationReportOrderIssueToSellerArgs = {
  input: ReportOrderIssueToSellerInput;
};


export type MutationReportOrderIssueToSupportArgs = {
  input: ReportOrderIssueToSupportInput;
};


export type MutationReportProductArgs = {
  input: ReportProductInput;
};


export type MutationReportShowArgs = {
  input: ReportShowInput;
};


export type MutationReportShowCommentArgs = {
  input: ReportShowCommentInput;
};


export type MutationReportUserArgs = {
  input: ReportUserInput;
};


export type MutationReportUserProfileArgs = {
  input: ReportUserProfileInput;
};


export type MutationRequestMagicLinkForSellerSubAccountArgs = {
  input: RequestMagicLinkForSellerSubAccountInput;
};


export type MutationRequestNewPhoneNumberVerificationCodeArgs = {
  input: RequestNewPhoneNumberVerificationCodeInput;
};


export type MutationRequestOrderCancellationArgs = {
  input: RequestOrderCancellationInput;
};


export type MutationRequestOrderShippingFeeCancellationArgs = {
  input: RequestOrderShippingFeeCancellationInput;
};


export type MutationRequestPasswordResetArgs = {
  credential: Scalars['String']['input'];
};


export type MutationRequestShowTeaserCreationArgs = {
  input: RequestShowTeaserCreationInput;
};


export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};


export type MutationRetryPaymentArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationSaveUserPreferredParentCategoriesArgs = {
  input: SaveUserPreferredParentCategoriesInput;
};


export type MutationSendCurrentShowRaidAndEndShowArgs = {
  input: SendCurrentRaidAndEndShowInput;
};


export type MutationSendNotificationForShowArgs = {
  input: SendNotificationForShowInput;
};


export type MutationSetSellerFavoriteShippingLabelFormatArgs = {
  input: SetSellerFavoriteShippingLabelFormatInput;
};


export type MutationSetSellerShippingAddressArgs = {
  input: SetSellerShippingAddressInput;
};


export type MutationSetShipmentStatusLabelDownloadedArgs = {
  input: SetShipmentStatusLabelDownloadedInput;
};


export type MutationSetViewerRemainingInformationForRegistrationWithEmailArgs = {
  input: SetViewerRemainingInformationForRegistrationWithEmailInput;
  registrationToken: Scalars['String']['input'];
};


export type MutationSetViewerRemainingRegistrationInformationV2Args = {
  input: SetViewerRemainingRegistrationInformationV2Input;
  registrationToken: Scalars['String']['input'];
};


export type MutationStartAuctionInShowArgs = {
  productId: Scalars['ID']['input'];
  showId: Scalars['ID']['input'];
};


export type MutationStartFlashSaleInShowArgs = {
  input: StartFlashSaleInShowInput;
};


export type MutationStartGiveawayInShowArgs = {
  input: StartGiveawayInput;
};


export type MutationStartRegistrationFlowArgs = {
  input: StartRegistrationFlowInput;
};


export type MutationStartRegistrationFlowWithFacebookSsoArgs = {
  input: StartRegistrationWithFacebookSso;
};


export type MutationStartRegistrationFlowWithOpenIdSsoArgs = {
  input: StartRegistrationFlowInputOpenIdSso;
};


export type MutationTerminateShowArgs = {
  input: TerminateShowInput;
};


export type MutationTriggerPhoneNumberVerificationArgs = {
  input: TriggerPhoneNumberVerificationInput;
  registrationToken: Scalars['String']['input'];
};


export type MutationTriggerPhoneNumberVerificationForSsoRegistrationArgs = {
  input: TriggerPhoneNumberVerificationForSsoRegistrationInput;
  registrationToken: Scalars['String']['input'];
};


export type MutationUnbanUserFromSellerArgs = {
  input: UnbanUserFromSellerInput;
};


export type MutationUnbanUserFromShowArgs = {
  input: UnbanUserFromShowInput;
};


export type MutationUnblockUserFromCommentingInShowArgs = {
  showId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUnbundleAllUnshippedShipmentsForCustomerArgs = {
  input: UnbundleAllUnshippedShipmentsForCustomerInput;
};


export type MutationUnbundleShipmentArgs = {
  input: UnbundleShipmentInput;
};


export type MutationUnfollowCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type MutationUnfollowLanguageArgs = {
  languageId: Scalars['ID']['input'];
};


export type MutationUnfollowProductArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationUnfollowShowArgs = {
  showId: Scalars['ID']['input'];
};


export type MutationUnfollowUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationUnstuckShowCurrentAuctionOrGiveawayArgs = {
  input: UnstuckShowCurrentAuctionOrGiveawayInput;
};


export type MutationUpdateInventoryProductOrProductInShowArgs = {
  input: UpdateInventoryProductOrProductInShowInput;
};


export type MutationUpdateOrderNoteArgs = {
  input: UpdateOrderNoteInput;
};


export type MutationUpdatePreShowTeaserVideoUrlArgs = {
  input: UpdatePreShowTeaserVideoUrlInput;
};


export type MutationUpdateProSellerCompanyArgs = {
  input: UpdateProSellerCompanyInput;
};


export type MutationUpdateProductFromShopArgs = {
  input: UpdateProductFromShopInput;
};


export type MutationUpdateProductIsPinnedArgs = {
  input: UpdateProductIsPinnedInput;
};


export type MutationUpdateProductPositionsArgs = {
  input: UpdateProductPositionsInput;
};


export type MutationUpdateProductToFirstPositionArgs = {
  input: UpdateProductToFirstPositionInput;
};


export type MutationUpdateShipmentShippingInfoByOwnMethodsArgs = {
  input: UpdateShipmentShippingInfoByOwnMethodsInput;
};


export type MutationUpdateShowAuctionsInitialDurationArgs = {
  input: UpdateAuctionDurationShowInput;
};


export type MutationUpdateShowAuctionsResetDurationArgs = {
  input: UpdateAuctionResetDurationShowInput;
};


export type MutationUpdateShowLanguageArgs = {
  input: UpdateShowLanguageInput;
};


export type MutationUpdateShowNameArgs = {
  input: UpdateShowNameInput;
};


export type MutationUpdateShowNotificationSendingMethodArgs = {
  input: UpdateShowNotificationSendingMethodInput;
};


export type MutationUpdateShowShippingCategoryArgs = {
  input: UpdateShowShippingCategoryInput;
};


export type MutationUpsertSellerBankingInfoArgs = {
  input: UpsertSellerBankingInfoInput;
  phoneVerificationToken: Scalars['String']['input'];
};


export type MutationVerifyPhoneNumberForEmailRegistrationArgs = {
  input: VerifyPhoneNumberForEmailRegistrationInput;
  registrationToken: Scalars['String']['input'];
};


export type MutationVerifyPhoneNumberForSsoRegistrationArgs = {
  input: VerifyPhoneNumberForSsoRegistrationInput;
  registrationToken: Scalars['String']['input'];
};


export type MutationVerifyUserEmailArgs = {
  input: VerifyUserEmailInput;
};

export type NewOrderInShowEvent = {
  __typename?: 'NewOrderInShowEvent';
  /** If an ordered product was ADDED, it will be present here */
  orderedProduct: OrderedProduct;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type OpenAppInput = {
  /**
   * The language of the device, that users prefer to use.
   * It should be defined as xx_YY or xx where xx is the language and YY the territory.
   */
  language: Scalars['String']['input'];
  platform: Platform;
  /** The application version */
  version: Scalars['String']['input'];
};

export type OpenAppPayload = {
  __typename?: 'OpenAppPayload';
  /**
   * Tell if the apps needs to be updated.
   * We will try setting the current version as low as possible on the backend
   * So we avoid pushing updates too much unless necessary
   */
  clientUpdateRequired: Scalars['Boolean']['output'];
  /**
   * This tells the clients which features are enabled for the user.
   * Each feature is a string identifier.
   */
  featureFlags: Array<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
  /**
   * Indicates if updated terms and conditions should be accepted by the user before using the app
   *
   * null if no change since last acceptance
   */
  termsAndConditionsToAccept?: Maybe<TermsAndConditions>;
};

export type OpenRaidInShowInput = {
  destinationShowId: Scalars['ID']['input'];
  showId: Scalars['ID']['input'];
};

export type OpenRaidInShowPayload = {
  __typename?: 'OpenRaidInShowPayload';
  showRaid: ShowRaid;
};

export type OpenShowInvitationInput = {
  invitationCode: Scalars['String']['input'];
};

export type OpenShowInvitationPayload = {
  __typename?: 'OpenShowInvitationPayload';
  ok: Scalars['Boolean']['output'];
};

/** ⭐️ An order placed during a show. */
export type Order = Node & {
  __typename?: 'Order';
  /**
   * The amount of the order in the cents.
   * ⚠️ In opposite to what its name implies, it's not necessarily in Euros but in the currency found on `Order.currency`.
   * @deprecated Deprecated because the name is misleading regarding currency. Use `amountInCurrencyCents` instead
   */
  amountEuroCents: Scalars['Int']['output'];
  /** The amount of the order in the cents of the currency found on `Order.currency` */
  amountInCurrencyCents: Scalars['Int']['output'];
  buyerServiceFee: AmountWithCurrency;
  /** the reason why this order was canceled */
  cancellationReason?: Maybe<OrderCancellationReason>;
  createdAt: Scalars['Date']['output'];
  currency: Currency;
  /** The customer of this order. */
  customer: User;
  /** The amount of deducted shipping cost on this order. */
  deductedShippingCostInCurrencyCents: Scalars['Int']['output'];
  /**
   * The id provided by the external shipper like UPS, when you use custom
   * shipping method this field stay null.
   */
  externalShippingOrderId?: Maybe<Scalars['String']['output']>;
  /** The amount of fee collected by Voggt on this order. */
  feeAmountInCurrencyCents: Scalars['Int']['output'];
  /** @deprecated Use `name` and `createdAt` instead */
  group: ShowOrdersGroup;
  id: Scalars['ID']['output'];
  /**
   * If true, it means that order's payment is awaiting a user
   * "next action" (eg. 3Dsecure).
   *
   * The user should visit `/payments/${stripePaymentIntentId}/action` where `stripePaymentIntentId`
   * is `Order.stripePaymentIntentId`.
   * @deprecated Payment confirmation & 3DS are now handled by client
   */
  isStripePaymentNextActionRequired: Scalars['Boolean']['output'];
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /**
   * The note from the seller
   *
   * 🔐 Authentication required - only accessible to seller
   */
  note?: Maybe<Scalars['String']['output']>;
  orderedProducts: OrderedProductsConnection;
  /**
   * Path to request on the API to get the payment's 3DSecure modal
   * @deprecated Payment confirmation & 3DS are now handled by client
   */
  payment3DSecureUrl?: Maybe<Scalars['String']['output']>;
  /**
   * Client secret obtained from payment intent to confirm a payment on Stripe.
   * Not null for the order's customer if it is needed, null in any other case
   */
  paymentIntentClientSecret?: Maybe<Scalars['String']['output']>;
  paymentStatus: OrderPaymentStatus;
  paymentType?: Maybe<PaymentType>;
  product: Product;
  /** The promotion applied to this order, if any. */
  promotion?: Maybe<PromotionV2>;
  /** The promotion amount used from the promotion applied to this order, if any. */
  promotionAmount?: Maybe<Scalars['Int']['output']>;
  seller: User;
  /**
   * The shipping address for the order
   *
   * 🔐 Authentication required — only accessible to the customer and seller of the targeted order.
   */
  shippingAddress: ShippingAddress;
  /**
   * The amount paid by the customer for shipping this Order.
   *
   * In the cents of the currency found on `Order.currency`.
   */
  shippingAmountInCurrencyCents: Scalars['Int']['output'];
  /** the date when the order shipping fees was canceled */
  shippingFeesCancellationRequestedAt?: Maybe<Scalars['Date']['output']>;
  /**
   * The carrier of the shipping
   * @deprecated Deprecated in favor of `ShowOrdersGroup.shippingProviderName`
   */
  shippingProvider?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated in favor of `ShowOrdersGroup.shippingProviderName` */
  shippingProviderName?: Maybe<Scalars['String']['output']>;
  /**
   * Can be null if the order was not done from a shop
   * @deprecated Use `name` instead
   */
  shop?: Maybe<Shop>;
  status: OrderStatus;
  /**
   * See `isStripePaymentNextActionRequired`.
   * @deprecated Deprecated in favor of `payment3DSecureUrl`
   */
  stripePaymentIntentId?: Maybe<Scalars['String']['output']>;
  /**
   * The tracking code that the carrier often gives to track the shipping
   * @deprecated Deprecated in favor of `ShowOrdersGroup.trackingNumber`
   */
  trackingNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated in favor of `ShowOrdersGroup.trackingUrl` */
  trackingUrl?: Maybe<Scalars['String']['output']>;
  /** The download URL for the shipping label. */
  urlLabel?: Maybe<Scalars['String']['output']>;
};


/** ⭐️ An order placed during a show. */
export type OrderOrderedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum OrderCancellationReason {
  BadItemConditions = 'BAD_ITEM_CONDITIONS',
  BuyerRefusal = 'BUYER_REFUSAL',
  FailedPayment = 'FAILED_PAYMENT',
  Misclick = 'MISCLICK',
  MissTakenAuction = 'MISS_TAKEN_AUCTION',
  OrderPreparationIssue = 'ORDER_PREPARATION_ISSUE'
}

export enum OrderCancellationRequesterType {
  Ops = 'OPS',
  Seller = 'SELLER'
}

export type OrderConnection = Connection & {
  __typename?: 'OrderConnection';
  edges: Array<OrderEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderEdge = Edge & {
  __typename?: 'OrderEdge';
  cursor: Scalars['String']['output'];
  node: Order;
};

export type OrderFilter = {
  paymentStatusIn?: InputMaybe<Array<OrderPaymentStatus>>;
  statusIn?: InputMaybe<Array<OrderStatus>>;
};

export enum OrderIssueResolutionActionType {
  /** Should lead to `reportOrderIssueToSeller` or `reportOrderIssueToSupport` */
  ContactSellerOrSupport = 'CONTACT_SELLER_OR_SUPPORT',
  /** Should lead to `reportOrderIssueToSupport` */
  ContactSupport = 'CONTACT_SUPPORT'
}

export type OrderIssueResolutionFlowAction = {
  __typename?: 'OrderIssueResolutionFlowAction';
  action: OrderIssueResolutionActionType;
};

export type OrderIssueResolutionFlowChoice = {
  __typename?: 'OrderIssueResolutionFlowChoice';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type OrderIssueResolutionFlowChoices = {
  __typename?: 'OrderIssueResolutionFlowChoices';
  choices: Array<OrderIssueResolutionFlowChoice>;
};

export type OrderIssueResolutionFlowChoicesOrAction = OrderIssueResolutionFlowAction | OrderIssueResolutionFlowChoices;

export type OrderIssueResolutionFlowInput = {
  /** null if first level */
  choiceId?: InputMaybe<Scalars['ID']['input']>;
  showOrdersGroupId: Scalars['ID']['input'];
};

export type OrderPaymentClientConfirmationRequiredEvent = {
  __typename?: 'OrderPaymentClientConfirmationRequiredEvent';
  order: Order;
};

export type OrderPaymentClientConfirmationRequiredInShowForCustomerEvent = {
  __typename?: 'OrderPaymentClientConfirmationRequiredInShowForCustomerEvent';
  order: Order;
};

export enum OrderPaymentStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  PendingSepaDebit = 'PENDING_SEPA_DEBIT',
  Success = 'SUCCESS'
}

export type OrderPaymentStatusChangedEvent = {
  __typename?: 'OrderPaymentStatusChangedEvent';
  order: Order;
};

export type OrderPaymentStatusChangedInShowEvent = {
  __typename?: 'OrderPaymentStatusChangedInShowEvent';
  order: Order;
};

export enum OrderStatus {
  Canceled = 'CANCELED',
  Delivered = 'DELIVERED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Shipped = 'SHIPPED'
}

/** A product that is part of an order */
export type OrderedProduct = CustomNode & Node & {
  __typename?: 'OrderedProduct';
  /** @deprecated Use `amountWithCurrency` instead */
  amount: AmountWithCurrency;
  amountWithCurrency: AmountWithCurrency;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images: Array<ImageUpload>;
  name: Scalars['String']['output'];
  /** Order this ordered product belongs to */
  order: Order;
  productId: Scalars['ID']['output'];
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  productLegacyId: Scalars['Int']['output'];
  productType: ProductType;
  weight?: Maybe<Scalars['Int']['output']>;
};

export type OrderedProductsConnection = Connection & {
  __typename?: 'OrderedProductsConnection';
  edges: Array<OrderedProductsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderedProductsEdge = Edge & {
  __typename?: 'OrderedProductsEdge';
  cursor: Scalars['String']['output'];
  node: OrderedProduct;
};

export type OriginalFileInfo = {
  __typename?: 'OriginalFileInfo';
  /** The name of the original (user) uploaded file. */
  name: Scalars['String']['output'];
  /** The size of the original (user) uploaded file, in bytes. */
  size?: Maybe<Scalars['Int']['output']>;
};

export type OriginalFileInfoInput = {
  name: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

/** ⭐️ A parent category of Show on Voggt. Ex: Trading cards, etc. */
export type ParentCategory = Node & {
  __typename?: 'ParentCategory';
  /** The list of child categories */
  allCategories: Array<Category>;
  /** @deprecated Use `coverWebPUrl` instead */
  coverUrl: Scalars['String']['output'];
  /** The parent category illustration picture */
  coverWebPUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  /** Translated parent category name, e.g. "Trading cards" */
  name: Scalars['String']['output'];
  /**
   * The list of recommended offline shows paginated for the given parent category.
   *
   * deviceId is required for the recommendation engine to work properly when user is not authenticated.
   * it's a fingerprint calculated from the user's device (web only)
   */
  offlineShows: ShowConnection;
  /** The parent category primary color */
  primaryColor: Scalars['String']['output'];
  products: ProductConnection;
  /**
   * The list of recommended products for the parent category paginated.
   *
   * deviceId is required for the recommendation engine to work properly when user is not authenticated.
   * it's a fingerprint calculated from the user's device (web only)
   */
  recommendedProducts: ProductConnection;
  /**
   * The list of recommended sellers for the parent category paginated.
   *
   * deviceId is required for the recommendation engine to work properly when user is not authenticated.
   * it's a fingerprint calculated from the user's device (web only)
   */
  recommendedSellers: UserConnection;
  /**
   * The list of recommended shows for the parent category paginated.
   *
   * deviceId is required for the recommendation engine to work properly when user is not authenticated.
   * it's a fingerprint calculated from the user's device (web only)
   */
  recommendedShows: ShowConnection;
  /** The parent category secondary color */
  secondaryColor: Scalars['String']['output'];
  /** The parent category stroke with a transparent background (svg) */
  shapeStrokeUrl: Scalars['String']['output'];
  /** The parent category illustration picture */
  shapeUrl: Scalars['String']['output'];
  shapedCoverUrl: Scalars['String']['output'];
  shows: ShowConnection;
  /** Raw parent category name, e.g. "trading-cards" */
  slug: Scalars['String']['output'];
  stickerUrl: Scalars['String']['output'];
};


/** ⭐️ A parent category of Show on Voggt. Ex: Trading cards, etc. */
export type ParentCategoryCoverWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};


/** ⭐️ A parent category of Show on Voggt. Ex: Trading cards, etc. */
export type ParentCategoryOfflineShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A parent category of Show on Voggt. Ex: Trading cards, etc. */
export type ParentCategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A parent category of Show on Voggt. Ex: Trading cards, etc. */
export type ParentCategoryRecommendedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  isOnlyFromLiveShows?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortByPrice?: InputMaybe<SortByPriceOptions>;
};


/** ⭐️ A parent category of Show on Voggt. Ex: Trading cards, etc. */
export type ParentCategoryRecommendedSellersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A parent category of Show on Voggt. Ex: Trading cards, etc. */
export type ParentCategoryRecommendedShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A parent category of Show on Voggt. Ex: Trading cards, etc. */
export type ParentCategoryShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ParentCategoryConnection = Connection & {
  __typename?: 'ParentCategoryConnection';
  edges: Array<ParentCategoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ParentCategoryEdge = Edge & {
  __typename?: 'ParentCategoryEdge';
  cursor: Scalars['String']['output'];
  node: ParentCategory;
};

export type ParticipateToCurrentRaidInShowInput = {
  showId: Scalars['ID']['input'];
};

export type ParticipateToCurrentRaidInShowPayload = {
  __typename?: 'ParticipateToCurrentRaidInShowPayload';
  showRaid: ShowRaid;
};

export type ParticipateToGiveawayInShowInput = {
  showGiveawayId: Scalars['ID']['input'];
};

export type ParticipateToGiveawayInShowMutationPayload = {
  __typename?: 'ParticipateToGiveawayInShowMutationPayload';
  giveaway: ShowGiveaway;
};

export type PaymentMethod = PaymentMethodBancontact | PaymentMethodCard | PaymentMethodGiropay | PaymentMethodIdeal | PaymentMethodPaypal | PaymentMethodPaypalRecurringPayment;

export type PaymentMethodAdded = {
  __typename?: 'PaymentMethodAdded';
  paymentMethod: PaymentMethod;
};

export type PaymentMethodBancontact = {
  __typename?: 'PaymentMethodBancontact';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  stripeSetupIntentId: Scalars['String']['output'];
};

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  fingerprint?: Maybe<Scalars['String']['output']>;
  funding: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isApplePay: Scalars['Boolean']['output'];
  isGooglePay: Scalars['Boolean']['output'];
  last4: Scalars['String']['output'];
  preAuthorizationExpireAt?: Maybe<Scalars['Date']['output']>;
  stripePaymentMethodId: Scalars['String']['output'];
};

export type PaymentMethodChangedEvent = PaymentMethodAdded | PreAuthorizationError;

export type PaymentMethodGiropay = {
  __typename?: 'PaymentMethodGiropay';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PaymentMethodIdeal = {
  __typename?: 'PaymentMethodIdeal';
  bank: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  stripeSetupIntentId: Scalars['String']['output'];
};

export type PaymentMethodPaypal = {
  __typename?: 'PaymentMethodPaypal';
  id: Scalars['ID']['output'];
  preAuthorizationExpireAt?: Maybe<Scalars['Date']['output']>;
};

export type PaymentMethodPaypalRecurringPayment = {
  __typename?: 'PaymentMethodPaypalRecurringPayment';
  id: Scalars['ID']['output'];
  preAuthorizationExpireAt?: Maybe<Scalars['Date']['output']>;
  stripePaymentMethodId: Scalars['String']['output'];
};

export enum PaymentType {
  Bancontact = 'BANCONTACT',
  Card = 'CARD',
  Giropay = 'GIROPAY',
  Ideal = 'IDEAL',
  Paypal = 'PAYPAL',
  PaypalRecurringPayment = 'PAYPAL_RECURRING_PAYMENT'
}

export type PendingShowOrdersGroupsShippingInfoPayload = {
  __typename?: 'PendingShowOrdersGroupsShippingInfoPayload';
  shippingInfosCustomers: Array<ShippingInfosCustomer>;
};

export type PickupPointLocation = {
  __typename?: 'PickupPointLocation';
  city: Scalars['String']['output'];
  countryIsoCode?: Maybe<Scalars['ISO2CountryCode']['output']>;
  position?: Maybe<Coordinates>;
  postalCode?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
};

export type PlaceBidOnAuctionMutationPayload = {
  __typename?: 'PlaceBidOnAuctionMutationPayload';
  /** @deprecated Does bring anything useful, since the websocket subscription already compute and send the same info batched with all users. */
  auction: ShowAuction;
  ok: Scalars['Boolean']['output'];
};

export type PlaceBidOnAuctionMutationV2Input = {
  amountEuroCents: Scalars['Int']['input'];
  auctionId: Scalars['ID']['input'];
  /**
   * The promotion to be used for this purchase. (List from `Viewers.promotionsV2`)
   * Null if the viewer does not want to use a promotion.
   */
  promotionId?: InputMaybe<Scalars['ID']['input']>;
};

export type PlaceBidOnAuctionMutationV2Payload = {
  __typename?: 'PlaceBidOnAuctionMutationV2Payload';
  ok: Scalars['Boolean']['output'];
};

export type PlaceFlashSaleOrderInput = {
  flashSaleId: Scalars['ID']['input'];
  /** null if the viewer does not want to use a promotion */
  promotionId?: InputMaybe<Scalars['ID']['input']>;
};

export type PlaceFlashSaleOrderPayload = {
  __typename?: 'PlaceFlashSaleOrderPayload';
  order: Order;
};

export type PlaceInstantBuyProductOrderInput = {
  /**
   * The cost at which the product was to be purchased.
   *
   * Used to prevent against occasional price change (edited product, won auction...).
   */
  price: Scalars['Int']['input'];
  productId: Scalars['ID']['input'];
  /**
   * The promotion to be used for this purchase, if applicable.
   * Needed to prevent against promotion being consumed (i.e: won auction or auto-bid) or if new promotion was acquired in the meantime.
   */
  promotionId?: InputMaybe<Scalars['ID']['input']>;
};

export type PlaceInstantBuyProductOrderPayload = {
  __typename?: 'PlaceInstantBuyProductOrderPayload';
  order: Order;
};

export type PlaceInstantBuyProductOrderV2Input = {
  /**
   * The cost at which the product was to be purchased.
   *
   * Used to prevent against occasional price change (edited product, won auction, consumed promotion...).
   * Get the applicable_promotion_amount from `Query.applicablePromotionAmountV2`
   *
   * Must correspond to the final price = product_price + shipping_price + service fee - applicable_promotion_amount
   */
  price: Scalars['Int']['input'];
  productId: Scalars['ID']['input'];
  /**
   * The promotion to be used for this purchase. (List from `Viewers.promotionsV2`)
   * Null if the viewer does not want to use a promotion.
   */
  promotionId?: InputMaybe<Scalars['ID']['input']>;
};

export type PlaceInstantBuyProductOrderV2Payload = {
  __typename?: 'PlaceInstantBuyProductOrderV2Payload';
  order: Order;
};

export type PlacePreBidOnProductInput = {
  amount: Scalars['Int']['input'];
  productId: Scalars['ID']['input'];
};

export type PlacePreBidOnProductPayload = {
  __typename?: 'PlacePreBidOnProductPayload';
  paymentIntentClientSecret: Scalars['String']['output'];
};

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type PongPayload = {
  __typename?: 'PongPayload';
  ok: Scalars['Boolean']['output'];
};

export type PreAuthorizationChangedEvent = {
  __typename?: 'PreAuthorizationChangedEvent';
  preAuthorizedPaymentMethod: PreAuthorizedPaymentMethod;
};

export type PreAuthorizationChangedForPreBidEvent = PreAuthorizationError | PreAuthorizationSuccess;

export type PreAuthorizationError = {
  __typename?: 'PreAuthorizationError';
  error: Error;
};

export type PreAuthorizationSuccess = {
  __typename?: 'PreAuthorizationSuccess';
  ok: Scalars['Boolean']['output'];
};

export type PreAuthorizedPaymentMethod = PaymentMethodCard | PaymentMethodPaypal | PaymentMethodPaypalRecurringPayment;

export enum PreBidDisabledReasonCode {
  AddressRequired = 'ADDRESS_REQUIRED',
  AlreadyPlacedPreBidOnProduct = 'ALREADY_PLACED_PRE_BID_ON_PRODUCT',
  InvalidDateRange = 'INVALID_DATE_RANGE',
  OrdersMustBeRegularized = 'ORDERS_MUST_BE_REGULARIZED',
  PaymentMethodNotCompatible = 'PAYMENT_METHOD_NOT_COMPATIBLE',
  PaymentMethodRequired = 'PAYMENT_METHOD_REQUIRED',
  ProductAlreadySold = 'PRODUCT_ALREADY_SOLD',
  ProductMustBeAttachedToAShow = 'PRODUCT_MUST_BE_ATTACHED_TO_A_SHOW',
  ProductNotEligible = 'PRODUCT_NOT_ELIGIBLE',
  SellerCannotPreBid = 'SELLER_CANNOT_PRE_BID',
  SellerNotEligible = 'SELLER_NOT_ELIGIBLE'
}

export type PreBidsInfo = {
  __typename?: 'PreBidsInfo';
  preBidsCount: Scalars['Int']['output'];
  /** The pre-bid placed by the viewer on the specified product, if any and only if authenticated. */
  viewerPreBidAmount?: Maybe<AmountWithCurrency>;
};

export type PreShowTeaserTranscodingInfo = {
  __typename?: 'PreShowTeaserTranscodingInfo';
  /** Code of the error in case of failure. */
  errorCode?: Maybe<Scalars['String']['output']>;
  /** Message to be used in case of failure to explain what when wrong. */
  message?: Maybe<Scalars['String']['output']>;
  /** Status of the transcoding job of the show teaser video. */
  status: Scalars['String']['output'];
};

export type PreShowTeaserVideoInfo = {
  __typename?: 'PreShowTeaserVideoInfo';
  /** File info for the HLS compatible version of the video. */
  hlsManifest?: Maybe<VideoFileInfo>;
  /** File info for the HD version of the video. */
  mp4HdFileInfo?: Maybe<VideoFileInfo>;
  /** File info for the SD version of the video. */
  mp4SdFileInfo?: Maybe<VideoFileInfo>;
  /** File info for the MPEG-DASH version of the video. */
  mpegDashManifest?: Maybe<VideoFileInfo>;
  /** File info for the original uploaded (user) file. */
  originalFileInfo?: Maybe<OriginalFileInfo>;
  /** File info for the HD version of the video. */
  transcodingInfo?: Maybe<PreShowTeaserTranscodingInfo>;
};

export enum PreShowTeaserVideoTranscodingStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Succeeded = 'SUCCEEDED'
}

export type PreShowTeaserVideoTranscodingStatusChanged = {
  __typename?: 'PreShowTeaserVideoTranscodingStatusChanged';
  show: Show;
  status: PreShowTeaserVideoTranscodingStatus;
};

export type ProcessPreAuthorizationOnActivePaymentMethodInput = {
  showId: Scalars['ID']['input'];
};

export type ProcessPreAuthorizationOnActivePaymentMethodPayload = {
  __typename?: 'ProcessPreAuthorizationOnActivePaymentMethodPayload';
  paymentIntentClientSecret: Scalars['String']['output'];
};

/** Represents a product on Voggt that can be purchased. */
export type Product = Node & {
  __typename?: 'Product';
  availableQuantity: Scalars['Int']['output'];
  /** Product brand. Only used for fashion items or sneakers. */
  brand?: Maybe<Scalars['String']['output']>;
  /**
   * Gives details about the buyer if you are allowed or null otherwise
   * You are always allowed to get your own infos.
   */
  buyer?: Maybe<User>;
  /** Card condition. Only used for TCG items */
  cardCondition?: Maybe<Scalars['String']['output']>;
  /** Card grade, if any. Only used for TCG items */
  cardGrade?: Maybe<Scalars['String']['output']>;
  /** Grading service that grade the card, if any. Only used for TCG items */
  cardGradingService?: Maybe<Scalars['String']['output']>;
  /** Card language. Only used for TCG items */
  cardLanguage?: Maybe<Scalars['String']['output']>;
  /** Card type. Sealed Pak, Boxed Set or individual cards. Only used for TCG items */
  cardType?: Maybe<Scalars['String']['output']>;
  /**
   * Some shows may have a few products from a category that is not the one of the show.
   * Ex: A vintage-streetwear show can have a few sneakers.
   * A few categories might have related specific fields (ex: size, color, model, ...)
   */
  categoryName?: Maybe<Scalars['String']['output']>;
  /** Product color. Only used for fashion items or sneakers. */
  color?: Maybe<Scalars['String']['output']>;
  /** Product condition. Only used for fashion items or sneakers. */
  condition?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currency: Currency;
  description?: Maybe<Scalars['String']['output']>;
  /** Returns the estimated shipping amount for the viewer. */
  estimatedShippingAmountEuroCentsForViewer: Scalars['Int']['output'];
  /**
   * Returns the product fixed amount (used for instant buy and buy now)
   * @deprecated Use fixedAmountV2 instead
   */
  fixedAmount: Scalars['Int']['output'];
  /**
   * Returns the product fixed amount (used for instant buy and buy now)
   * NULL if not buyable instantly
   */
  fixedAmountV2?: Maybe<Scalars['Int']['output']>;
  /**
   * Count of users that follow the Product.
   *
   * 🔐 Authentication required
   * 🔐 Access limited to the seller of the product
   */
  followingUsersCount: Scalars['Int']['output'];
  /** Product gender. Only used for fashion items */
  gender?: Maybe<Scalars['String']['output']>;
  giveawayAudience?: Maybe<ShowGiveawayAudience>;
  id: Scalars['ID']['output'];
  /**
   * Gives an optimized cover picture URL in webp format, with a maximum width that you can specify.
   * If original picture is narrower than the specified width, the original picture will be returned.
   */
  images: Array<ImageUpload>;
  initialQuantity?: Maybe<Scalars['Int']['output']>;
  inventoryProduct?: Maybe<InventoryProduct>;
  /**
   * Indicates whether the current authenticated user is following the product.
   *
   * 🔐 Authentication required
   */
  isFollowing: Scalars['Boolean']['output'];
  isPinned: Scalars['Boolean']['output'];
  /**
   * 🔐 Authentication required.
   * 🔐 Only true if:
   * - seller's config has `isPreBidEligible` set to `true`
   * - customer is not the seller
   * - customer has registered a shipping address
   * - customer has registered a card or PayPal as payment method
   * - customer has not already placed a pre-bid on the specified product yet
   * - customer doesn't have any order to regularize
   * - we're between `show.startAt` minus 5 days and `show.endedAt` (or `show.startAt` + 6h in case `show.endedAt` is not set)
   * - product belongs to a show
   * - product type is `auction` and product status is `pending`
   */
  isPreBidEnabled: IsPreBidEnabledPayload;
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  /** Product model. Only used for some sneakers. */
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  otherProductsFromSameShowWithImageFirst: ProductConnection;
  preBidsInfo: PreBidsInfo;
  /** Gives details about the seller */
  seller: User;
  serviceFeeForViewer: Scalars['Int']['output'];
  /** Returns associated shop if any */
  shop?: Maybe<Shop>;
  /** Returns associated show if any */
  show?: Maybe<Show>;
  /** Product size. Only used for fashion items or sneakers. */
  size?: Maybe<Scalars['String']['output']>;
  /** Price at which the item was sold */
  soldPrice?: Maybe<Scalars['Int']['output']>;
  /** Returns the product starting amount */
  startingAmount: Scalars['Int']['output'];
  status: ProductStatusInAuction;
  type: ProductType;
  /**
   * Order associated to the product excluding canceled and refunded ones.
   * If no viewer or the viewer is not authorized to see this order, because it's not
   * the seller nor the customer of this order, `null` will be returned
   * but it won't throw an error.
   */
  validOrderIfAuthorized?: Maybe<Order>;
  /** Returns the product weight in grams */
  weight?: Maybe<Scalars['Int']['output']>;
};


/** Represents a product on Voggt that can be purchased. */
export type ProductOtherProductsFromSameShowWithImageFirstArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductConnection = Connection & {
  __typename?: 'ProductConnection';
  edges: Array<ProductEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductEdge = Edge & {
  __typename?: 'ProductEdge';
  cursor: Scalars['String']['output'];
  node: Product;
};

export type ProductForOrdering = CustomNode & Node & {
  __typename?: 'ProductForOrdering';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProductForOrderingConnection = Connection & {
  __typename?: 'ProductForOrderingConnection';
  edges: Array<ProductForOrderingEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductForOrderingEdge = Edge & {
  __typename?: 'ProductForOrderingEdge';
  cursor: Scalars['String']['output'];
  node: ProductForOrdering;
};

export type ProductInput = {
  availableQuantity: Scalars['Int']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  cardCondition?: InputMaybe<Scalars['String']['input']>;
  cardGrade?: InputMaybe<Scalars['String']['input']>;
  cardGradingService?: InputMaybe<Scalars['String']['input']>;
  cardLanguage?: InputMaybe<Scalars['String']['input']>;
  cardType?: InputMaybe<Scalars['String']['input']>;
  categoryName?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  condition?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fixedAmount?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  giveawayAudience?: InputMaybe<ShowGiveawayAudience>;
  imagesPosition?: InputMaybe<Array<Scalars['ID']['input']>>;
  imagesUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
  startingAmount?: InputMaybe<Scalars['Int']['input']>;
  type: ProductType;
};

export enum ProductSortColumn {
  AvailableQuantity = 'AVAILABLE_QUANTITY',
  CreatedAt = 'CREATED_AT',
  FixedAmount = 'FIXED_AMOUNT',
  FollowingUsersCount = 'FOLLOWING_USERS_COUNT',
  Name = 'NAME',
  StartingAmount = 'STARTING_AMOUNT'
}

export enum ProductStatusInAuction {
  /** The product is currently in an auction */
  InProgress = 'IN_PROGRESS',
  /** The product is pending to be in an auction */
  Pending = 'PENDING',
  /** The product has been sold already */
  Sold = 'SOLD',
  /** The product is not available to buy anymore */
  Unavailable = 'UNAVAILABLE',
  /** The product was not sold after auction */
  Unsold = 'UNSOLD'
}

export enum ProductType {
  /** The product will be auctioned */
  Auction = 'AUCTION',
  /** The product will be given away */
  Giveaway = 'GIVEAWAY',
  /** The product will be sold as an instant buy item */
  InstantBuy = 'INSTANT_BUY'
}

export type PromoteUserToModeratorInput = {
  userId: Scalars['ID']['input'];
};

export type PromoteUserToModeratorPayload = {
  __typename?: 'PromoteUserToModeratorPayload';
  ok: Scalars['Boolean']['output'];
};

export type Promotion = Node & {
  __typename?: 'Promotion';
  currency: Currency;
  expireAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  promotionAmount: Scalars['Int']['output'];
  /** 🔐 Authentication required - only accessible to viewer */
  source: PromotionSource;
};

export type PromotionAmountOrPercentage = AmountWithCurrency | PromotionPercentage;

export type PromotionPercentage = {
  __typename?: 'PromotionPercentage';
  currency: Currency;
  maxAmount: Scalars['Int']['output'];
  percentage: Scalars['Int']['output'];
};

export type PromotionSource = CampaignPromotionSource | ReferralPromotionSource | ReferrerPromotionSource;

export type PromotionSourceV2 = CampaignPromotionSource | ReferralPromotionSource | ReferrerPromotionSource | WelcomePromotionSource;

export enum PromotionType {
  Campaign = 'CAMPAIGN',
  Referral = 'REFERRAL',
  Referrer = 'REFERRER',
  Welcome = 'WELCOME'
}

export type PromotionV2 = Node & {
  __typename?: 'PromotionV2';
  expireAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  promotionAmountOrPercentage: PromotionAmountOrPercentage;
  /** 🔐 Authentication required - only accessible to viewer */
  source: PromotionSourceV2;
};

export type PromotionV2Connection = Connection & {
  __typename?: 'PromotionV2Connection';
  edges: Array<PromotionV2Edge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PromotionV2Edge = Edge & {
  __typename?: 'PromotionV2Edge';
  cursor: Scalars['String']['output'];
  node: PromotionV2;
};

export type Query = {
  __typename?: 'Query';
  /** 🔐 Authentication required - must be Voggt staff */
  activeAutoFollowUserCampaigns: AutoFollowUserCampaignConnection;
  activeCommercialEvents: CommercialEventConnection;
  /**
   * All shows that are currently broadcasting.
   *
   * Non-paginated list fields should be exceptional and motivated:
   * - specific client convenience
   * - performance: the number of broadcasting shows will likely stay low
   */
  allBroadcastingShows: Array<Show>;
  allLanguages: Array<Language>;
  appConfig: AppConfigPayload;
  /** 🔐 Authentication required - only available for the owner of the specified promotion */
  applicablePromotionAmountV2: AmountWithCurrency;
  /** 🔐 Authentication required - must be Voggt staff */
  archivedAutoFollowUserCampaigns: AutoFollowUserCampaignConnection;
  /**
   * Generic search with autocomplete results.
   * 📄 Pagination max 20
   */
  autocompleteSearch: AutocompleteSearchConnection;
  /**
   * Shows that are broadcasting or will broadcast in the next 4 weeks by the sellers followed by the viewer.
   *
   * Resulting shows are ordered by broadcasting shows then start date ASC.
   */
  broadcastingAndUpcomingFollowedSellersShows: ShowConnection;
  /**
   * Shows that are currently broadcasting by the sellers followed by the viewer.
   *
   * Resulting shows are ordered by seller score DESC then start date ASC.
   */
  broadcastingFollowedSellersShows: ShowConnection;
  /**
   * Returns a combined PDF containing all shipping labels of selected Boxtal shipments.
   *
   * 🔐 Authentication required - must be a seller
   */
  bulkShippingLabelsForBoxtal: ShippingLabelForBoxtal;
  /**
   * Returns a combined PDF containing all shipping labels of selected Sendcloud shipments.
   *
   * 🔐 Authentication required - must be a seller
   */
  bulkShippingLabelsForSendcloud: ShippingLabelForSendcloud;
  bulkUnshippedShippingAddressStickers: BulkUnshippedShippingAddressStickersPayload;
  /** 🔐 Authentication required - must be the seller of the product */
  canProductBeUpdated: CanProductBeUpdatedPayload;
  /** The show categories ordered by position in the home page. */
  categoriesOrderedByPosition: CategoryConnection;
  checkEmailAvailability: CheckEmailAvailabilityPayload;
  checkEmailAvailabilityForSsoRegistration: CheckEmailAvailabilityForSsoRegistrationPayload;
  checkReferralCode: CheckReferralCodePayload;
  checkReferralCodeDuringFlow: CheckReferralCodeDuringFlowPayload;
  checkReferralCodeForSsoRegistration: CheckReferralCodeForSsoRegistrationPayload;
  checkUsernameAvailability: CheckUsernameAvailabilityPayload;
  checkUsernameAvailabilityDuringFlow: CheckUsernameAvailabilityDuringFlowPayload;
  /**
   * Get the current referral promotion, if any.
   * Depends on the viewer's country if the request is authenticated and the viewer's country is set, or on the request country code otherwise.
   */
  currentReferralPromotionAmountOrPercentage?: Maybe<PromotionAmountOrPercentage>;
  /**
   * Get the current welcome promotion, if any.
   * Depends on the viewer's country if the request is authenticated and the viewer's country is set, or on the request country code otherwise.
   */
  currentWelcomePromotionAmountOrPercentage?: Maybe<PromotionAmountOrPercentage>;
  /** 🔐 Authentication required - only accessible to seller */
  customerWithUnshippedOrderedProducts?: Maybe<CustomerWithUnshippedOrderedProducts>;
  /** 🔐 Authentication required - only accessible to seller */
  customersWithUnshippedOrderedProducts: CustomerWithUnshippedOrderedProductsConnection;
  /**
   * Personalized feed of products with optional filters for the viewer.
   *
   * 🔐 Authentication required
   */
  discoverProducts: ProductConnection;
  /**
   * Allows fetching emotes by name, for instance if it is not available in the show emote sets.
   * You can request multiple at once here
   */
  emotes: Array<Emote>;
  featuredShowConceptsForParentCategory: Array<Scalars['String']['output']>;
  /**
   * Returns a temporary link to download the invoice for the order
   * 🔐 Authentication required - only accessible to customer of the order
   */
  getOrderBuyerServiceFeeInvoiceLink: Scalars['String']['output'];
  /**
   * Query seller's orders that are cancelled / refunded sorted by most recent first.
   *
   * 🔐 Authentication required - only accessible to the seller of the targeted order
   */
  getSellerCancelledAndRefundedOrders: OrderConnection;
  /**
   * 🔐 Authentication required - only accessible to seller
   * @deprecated Use `sellerShipments` instead
   */
  getSellerShipmentsByStatus: ShipmentConnection;
  /** Get user id by username. */
  getUserIdByUsername: GetUserIdByUsernamePayload;
  /**
   * 🔐 Authentication required - must be seller with access to an inventory (`SellerConfig.canAccessInventory` must be true)
   * 📄 Pagination max 100
   */
  inventoryProducts: InventoryProductConnection;
  lastSoldProducts: LastSoldProductsWithCustomerConnection;
  lastTermsAndConditions: TermsAndConditions;
  /**
   * The nearest Mondial Relay pickup point to the user address
   * 🔒 Requires authentication
   */
  nearestMondialRelayPickupPoint: MondialRelayPickupPoint;
  /** Fetches an object given its ID. */
  node: Node;
  /**
   * The list of recommended offline shows paginated.
   *
   * deviceId is required for the recommendation engine to work properly when user is not authenticated.
   * it's a fingerprint calculated from the user's device (web only)
   */
  offlineShows: ShowConnection;
  /** 🔐 Authentication required */
  orderIssueResolutionFlow: OrderIssueResolutionFlowChoicesOrAction;
  /** The show parent categories ordered by position in the home page. */
  parentCategoriesOrderedByPosition: ParentCategoryConnection;
  /**
   * Get all shipping info of all showOrdersGroup pending for a seller
   *
   * 🔐 Authentication required - only accessible to the seller of the targeted ShowOrdersGroup
   */
  pendingShowOrdersGroupsShippingInfo: PendingShowOrdersGroupsShippingInfoPayload;
  /**
   * Personalized feed of products for the viewer.
   *
   * 🔐 Authentication required
   */
  personalizedProductFeed: ProductConnection;
  /**
   * Personnalized feed of shows for the viewer filtered by isBroadcasting = true.
   *
   * 🔐 Authentication required
   */
  personalizedShowFeedFilteredByBroadcasting: ShowConnection;
  /**
   * Personnalized feed of shows for the viewer.
   *
   * 🔐 Authentication required
   */
  personnalizedShowFeed: ShowConnection;
  /**
   * Previous Shows by the sellers with pending products.
   *
   * Resulting shows are ordered by start date ASC.
   */
  previousShowsWithPendingProducts: ShowConnection;
  /** 🔐 Authentication required - must be the seller of the show */
  productsForOrdering: ProductForOrderingConnection;
  /**
   * Search for Mondial Relay pickup points
   * 🔒 Requires authentication
   */
  searchMondialRelayPickupPoints: Array<MondialRelayPickupPoint>;
  /** Search products by name or description */
  searchProducts: ProductConnection;
  /** Search sellers by username */
  searchSellersByUsername: UserConnection;
  /** Search shows by name or seller username */
  searchShows: ShowConnection;
  /** Get seller Stripe balance transactions */
  sellerBalanceTransactions: SellerBalanceTransactionsPayload;
  /**
   * The list of country codes supported for seller's bank account.
   *
   * 🔒 Requires authentication and viewer should a seller or selected to be a
   * seller.
   */
  sellerBankAccountSupportedCountries: Array<SellerBankAccountSupportedCountries>;
  /**
   *   User must be seller to access this field
   *   Retrieve seller information (banking information, etc.)
   * 🔐 Authentication required
   */
  sellerInfo: SellerInfo;
  sellerLedgerBalance: SellerLedgerBalance;
  sellerLedgerPayouts: SellerLedgerPayoutConnection;
  sellerLedgerTransactions: SellerLedgerTransactionConnection;
  /** Get seller news by language, country and start/end date */
  sellerNews: Array<SellerNewsPayload>;
  /** 🔐 Authentication required - only accessible to seller */
  sellerShipments: ShipmentConnection;
  /**
   * 🔐 Authentication required - only accessible to seller
   * 📄 Pagination max 20
   */
  sellerSubAccounts: SellerSubAccountConnection;
  similarShowsBroadcastingFirst: ShowConnection;
  /** 🔐 Authentication required - must be the seller of the show */
  similarShowsToRaid: ShowConnection;
  tierOneCommercialEvent?: Maybe<CommercialEvent>;
  /**
   * Top categories ordered by show count then position, top meaning categories with the most shows.
   *
   * If the user follows EN language only, shows are not filtered.
   * In all other cases, shows are filtered based on the authenticated user's followed languages.
   *
   * If two or more categories have the same amount of shows, result is then ordered by category position index.
   *
   * 🔐 Authentication required
   */
  topCategoriesOrderedByPosition: CategoryConnection;
  /**
   * Shows that will broadcast in the next 4 weeks by the sellers followed by the viewer.
   *
   * Resulting shows are ordered by start date ASC.
   */
  upcomingFollowedSellersShows: ShowConnection;
  /** The shows that are in the next 4 weeks. */
  upcomingShowsByStartDateAsc: ShowConnection;
  /**
   * The currently authenticated user.
   *
   *
   * 🔐 Authentication required
   */
  viewer?: Maybe<User>;
};


export type QueryActiveAutoFollowUserCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  all?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryActiveCommercialEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllBroadcastingShowsArgs = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  country?: InputMaybe<Scalars['ISO2CountryCode']['input']>;
  filterByViewerCountryCluster?: InputMaybe<Scalars['Boolean']['input']>;
  filterByViewerFollowedLanguages?: InputMaybe<Scalars['Boolean']['input']>;
  filterPremierShows?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAppConfigArgs = {
  input: AppConfigInput;
};


export type QueryApplicablePromotionAmountV2Args = {
  input: ApplicablePromotionAmountV2Input;
};


export type QueryArchivedAutoFollowUserCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  all?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAutocompleteSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  textToSearch: Scalars['String']['input'];
};


export type QueryBroadcastingAndUpcomingFollowedSellersShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBroadcastingFollowedSellersShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBulkShippingLabelsForBoxtalArgs = {
  boxtalShipmentIds: Array<Scalars['ID']['input']>;
};


export type QueryBulkShippingLabelsForSendcloudArgs = {
  printerType: Scalars['String']['input'];
  sendcloudShipmentIds: Array<Scalars['ID']['input']>;
};


export type QueryCanProductBeUpdatedArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryCategoriesOrderedByPositionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCheckEmailAvailabilityArgs = {
  input: CheckEmailAvailabilityInput;
};


export type QueryCheckEmailAvailabilityForSsoRegistrationArgs = {
  input: CheckEmailAvailabilityForSsoRegistrationInput;
  registrationToken: Scalars['String']['input'];
};


export type QueryCheckReferralCodeArgs = {
  input: CheckReferralCodeInput;
  registrationToken: Scalars['String']['input'];
};


export type QueryCheckReferralCodeDuringFlowArgs = {
  input: CheckReferralCodeDuringFlowInput;
};


export type QueryCheckReferralCodeForSsoRegistrationArgs = {
  input: CheckReferralCodeForSsoRegistrationInput;
  registrationToken: Scalars['String']['input'];
};


export type QueryCheckUsernameAvailabilityArgs = {
  input: CheckUsernameAvailabilityInput;
  registrationToken: Scalars['String']['input'];
};


export type QueryCheckUsernameAvailabilityDuringFlowArgs = {
  input: CheckUsernameAvailabilityDuringFlowInput;
};


export type QueryCustomerWithUnshippedOrderedProductsArgs = {
  customerId: Scalars['ID']['input'];
};


export type QueryCustomersWithUnshippedOrderedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ShipmentsTabsFilter>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CustomerWithUnshippedOrderedProductsSort>;
};


export type QueryDiscoverProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isOnlyFromLiveShows?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  refresh: Scalars['Boolean']['input'];
  sortByPrice?: InputMaybe<SortByPriceOptions>;
};


export type QueryEmotesArgs = {
  names: Array<Scalars['String']['input']>;
};


export type QueryFeaturedShowConceptsForParentCategoryArgs = {
  parentCategoryId: Scalars['ID']['input'];
};


export type QueryGetOrderBuyerServiceFeeInvoiceLinkArgs = {
  orderId: Scalars['ID']['input'];
};


export type QueryGetSellerCancelledAndRefundedOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ShipmentsTabsFilter>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetSellerCancelledAndRefundedOrdersSort>;
};


export type QueryGetSellerShipmentsByStatusArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ShipmentsTabsFilter>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetSellerShipmentsByStatusSort>;
  statuses: Array<ShipmentStatus>;
};


export type QueryGetUserIdByUsernameArgs = {
  username: Scalars['String']['input'];
};


export type QueryInventoryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByPositiveTotalQuantityForAllShows?: InputMaybe<Scalars['Boolean']['input']>;
  filterByPositiveTotalQuantityForPastShows?: InputMaybe<Scalars['Boolean']['input']>;
  filterByShows?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  showIdToExclude?: InputMaybe<Scalars['ID']['input']>;
  sortBy?: InputMaybe<SortInventoryProductsBy>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOfflineShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrderIssueResolutionFlowArgs = {
  input: OrderIssueResolutionFlowInput;
};


export type QueryParentCategoriesOrderedByPositionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPersonalizedProductFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  refresh: Scalars['Boolean']['input'];
};


export type QueryPersonalizedShowFeedFilteredByBroadcastingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  refresh: Scalars['Boolean']['input'];
};


export type QueryPersonnalizedShowFeedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  refresh: Scalars['Boolean']['input'];
};


export type QueryPreviousShowsWithPendingProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductsForOrderingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  all?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  productType: ProductType;
  showId: Scalars['ID']['input'];
};


export type QuerySearchMondialRelayPickupPointsArgs = {
  input: SearchMondialRelayPickupPointsInput;
};


export type QuerySearchProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  textToSearch: Scalars['String']['input'];
};


export type QuerySearchSellersByUsernameArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  username: Scalars['String']['input'];
};


export type QuerySearchShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  textToSearch: Scalars['String']['input'];
};


export type QuerySellerBalanceTransactionsArgs = {
  lastBalanceTransactionId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySellerLedgerPayoutsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SellerLedgerPayoutsSort>;
};


export type QuerySellerLedgerTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<SellerLedgerTransactionsFilters>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SellerLedgerTransactionsSort>;
};


export type QuerySellerShipmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ShipmentsTabsFilter>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetSellerShipmentsByStatusSort>;
};


export type QuerySellerSubAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySimilarShowsBroadcastingFirstArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  showId: Scalars['ID']['input'];
};


export type QuerySimilarShowsToRaidArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  all?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  showId: Scalars['ID']['input'];
};


export type QueryTopCategoriesOrderedByPositionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUpcomingFollowedSellersShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUpcomingShowsByStartDateAscArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  country?: InputMaybe<Scalars['ISO2CountryCode']['input']>;
  filterByViewerCountryCluster?: InputMaybe<Scalars['Boolean']['input']>;
  filterByViewerFollowedLanguages?: InputMaybe<Scalars['Boolean']['input']>;
  filterPremierShows?: InputMaybe<Scalars['Boolean']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuoteForBoxtalInput = {
  height: Scalars['Int']['input'];
  insurance: Scalars['Boolean']['input'];
  length: Scalars['Int']['input'];
  shipmentId: Scalars['String']['input'];
  weight: Scalars['Float']['input'];
  width: Scalars['Int']['input'];
};

export type QuoteForBoxtalPayload = {
  __typename?: 'QuoteForBoxtalPayload';
  quotes: Array<BoxtalShippingMethod>;
};

export type QuoteForSencloudInput = {
  currency: Scalars['String']['input'];
  shipmentId: Scalars['String']['input'];
  weight: Scalars['Float']['input'];
};

export type QuoteForSendcloudPayload = {
  __typename?: 'QuoteForSendcloudPayload';
  quotes: Array<SendcloudShippingMethod>;
};

export type RateReceivedOrdersGroupInput = {
  /** From 1 to 5 */
  rating: Scalars['Int']['input'];
  /** ⚠️ Required if rating is not 5 */
  ratingComment?: InputMaybe<Scalars['String']['input']>;
  showOrdersGroupId: Scalars['ID']['input'];
};

export type RateReceivedOrdersGroupInputV2 = {
  /** From 1 to 5 */
  rating: Scalars['Int']['input'];
  /** ⚠️ Required if rating is not 5 */
  ratingComment?: InputMaybe<Scalars['String']['input']>;
  showOrdersGroupId: Scalars['ID']['input'];
};

export type RateReceivedOrdersGroupInputV3 = {
  /** From 1 to 5 */
  rating: Scalars['Int']['input'];
  /** Since v3, a comment is required. At least 1 character or emoji. */
  ratingComment: Scalars['String']['input'];
  showOrdersGroupId: Scalars['ID']['input'];
};

export type RateReceivedOrdersGroupPayload = {
  __typename?: 'RateReceivedOrdersGroupPayload';
  showOrdersGroup: ShowOrdersGroup;
};

export type RateReceivedOrdersGroupPayloadV2 = {
  __typename?: 'RateReceivedOrdersGroupPayloadV2';
  showOrdersGroup: ShowOrdersGroup;
};

export type RateReceivedOrdersGroupPayloadV3 = {
  __typename?: 'RateReceivedOrdersGroupPayloadV3';
  ok: Scalars['Boolean']['output'];
  rating: RatingFromCustomer;
  /**
   * ⚠️ Please only ask it if you absolutely need it
   * @deprecated Use `rating` instead
   */
  showOrdersGroup: ShowOrdersGroup;
};

export type RateShowAsSellerInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  showId: Scalars['ID']['input'];
};

export type RateShowAsSellerPayload = {
  __typename?: 'RateShowAsSellerPayload';
  ok: Scalars['Boolean']['output'];
};

export type Rating = {
  __typename?: 'Rating';
  comment?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Int']['output'];
};

export type RatingFromCustomer = CustomNode & Node & {
  __typename?: 'RatingFromCustomer';
  /** 🔐 Authentication required */
  canViewerEdit: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  customer: User;
  id: Scalars['ID']['output'];
  isModerated: Scalars['Boolean']['output'];
  /** null if it was never edited */
  lastEditedAt?: Maybe<Scalars['Date']['output']>;
  rating: Scalars['Int']['output'];
  ratingComment?: Maybe<Scalars['String']['output']>;
};

export type RatingFromCustomerConnection = Connection & {
  __typename?: 'RatingFromCustomerConnection';
  edges: Array<RatingFromCustomerEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RatingFromCustomerEdge = Edge & {
  __typename?: 'RatingFromCustomerEdge';
  cursor: Scalars['String']['output'];
  node: RatingFromCustomer;
};

export type ReCreateShowNotificationInput = {
  showId: Scalars['ID']['input'];
};

export type ReCreateShowNotificationPayload = {
  __typename?: 'ReCreateShowNotificationPayload';
  ok: Scalars['Boolean']['output'];
};

/** ⭐️ An emoji left by a User in a Show. */
export type ReactionFeedItem = ShowFeedItem & {
  __typename?: 'ReactionFeedItem';
  author: User;
  date: Scalars['Date']['output'];
  emoji: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Deprecated in favor of `date` which properly uses the usual Date scalar */
  timestamp: Scalars['Date']['output'];
};

export type ReactionFeedItemAddedEvent = {
  __typename?: 'ReactionFeedItemAddedEvent';
  reactionFeedItem: ReactionFeedItem;
};

export type ReferralPromotionSource = {
  __typename?: 'ReferralPromotionSource';
  promotionId: Scalars['Int']['output'];
  referrer: User;
  type: PromotionType;
};

export type ReferralStatistics = {
  __typename?: 'ReferralStatistics';
  totalCustomersReferredCount: Scalars['Int']['output'];
  /** @deprecated Use `totalPromotionAmountEarnedV2` instead */
  totalPromotionAmountEarned: Scalars['Int']['output'];
  totalPromotionAmountEarnedV2: AmountWithCurrency;
  totalUsersReferredCount: Scalars['Int']['output'];
};

export type ReferrerPromotionSource = {
  __typename?: 'ReferrerPromotionSource';
  promotionId: Scalars['Int']['output'];
  referral: User;
  type: PromotionType;
};

export type RefreshAuthenticationTokenPayload = {
  __typename?: 'RefreshAuthenticationTokenPayload';
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type RegisterPushTokenInput = {
  appVersion: Scalars['String']['input'];
  /** BCP-47 if possible; otherwise we will just work around */
  locale: Scalars['BCP47LanguageTag']['input'];
  osVersion: Scalars['String']['input'];
  platform: Platform;
  pushToken: Scalars['String']['input'];
};

export type RegisterPushTokenPayload = {
  __typename?: 'RegisterPushTokenPayload';
  ok: Scalars['Boolean']['output'];
};

export type RegisterWithEmailInput = {
  email: Scalars['String']['input'];
};

export type RegisterWithEmailPayload = {
  __typename?: 'RegisterWithEmailPayload';
  registrationToken: Scalars['String']['output'];
};

export type RegisterWithFacebookSsoInput = {
  accessToken: Scalars['String']['input'];
  /** Current device language, defined as xx_YY | xx-YY | xx where xx is the language and YY the territory. */
  language?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterWithFacebookSsoPayload = {
  __typename?: 'RegisterWithFacebookSsoPayload';
  email?: Maybe<Scalars['String']['output']>;
  registrationToken: Scalars['String']['output'];
};

export type RegisterWithFacebookSsoV2Input = {
  accessToken: Scalars['String']['input'];
};

export type RegisterWithFacebookSsoV2Payload = {
  __typename?: 'RegisterWithFacebookSsoV2Payload';
  /** If null/empty; frontend must ask for the email to the user as we need one */
  email?: Maybe<Scalars['String']['output']>;
  registrationToken: Scalars['String']['output'];
};

export type RegisterWithGoogleSsoInput = {
  authorizationCode: Scalars['String']['input'];
};

export type RegisterWithGoogleSsoPayload = {
  __typename?: 'RegisterWithGoogleSsoPayload';
  registrationToken: Scalars['String']['output'];
};

export type RegisterWithSsoInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  idToken: Scalars['String']['input'];
  /** Current device language, defined as xx_YY | xx-YY | xx where xx is the language and YY the territory. */
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterWithSsoPayload = {
  __typename?: 'RegisterWithSsoPayload';
  email?: Maybe<Scalars['String']['output']>;
  registrationToken: Scalars['String']['output'];
};

export type RegisterWithSsoV2Input = {
  /** Current device language, defined as xx_YY | xx-YY | xx where xx is the language and YY the territory. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  idToken: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterWithSsoV2Payload = {
  __typename?: 'RegisterWithSsoV2Payload';
  /** If null/empty; frontend must ask for the email to the user as we need one */
  email?: Maybe<Scalars['String']['output']>;
  registrationToken: Scalars['String']['output'];
};

export type RegistrationFlowCategoriesScreen = {
  __typename?: 'RegistrationFlowCategoriesScreen';
  categories: Array<Category>;
};

export type RegistrationFlowCategoriesScreenInput = {
  categories: Array<Scalars['ID']['input']>;
  stepToken: Scalars['String']['input'];
};

export type RegistrationFlowDone = {
  __typename?: 'RegistrationFlowDone';
  authenticationToken: Scalars['String']['output'];
  authenticationTokenExpiresAt: Scalars['Date']['output'];
  refreshToken: Scalars['String']['output'];
  viewer: User;
};

export type RegistrationFlowEmailScreen = {
  __typename?: 'RegistrationFlowEmailScreen';
  prefill?: Maybe<Scalars['String']['output']>;
};

export type RegistrationFlowEmailScreenInput = {
  email: Scalars['String']['input'];
  stepToken: Scalars['String']['input'];
};

export type RegistrationFlowExpectedScreen = RegistrationFlowCategoriesScreen | RegistrationFlowEmailScreen | RegistrationFlowParentCategoryScreen | RegistrationFlowPasswordScreen | RegistrationFlowPhoneNumberScreen | RegistrationFlowPhoneNumberVerifyScreen | RegistrationFlowReferralCodeScreen | RegistrationFlowUsernameScreen;

export type RegistrationFlowParentCategoriesScreenInput = {
  selectedParentCategories: Array<Scalars['ID']['input']>;
  stepToken: Scalars['String']['input'];
};

export type RegistrationFlowParentCategoryScreen = {
  __typename?: 'RegistrationFlowParentCategoryScreen';
  parentCategories: Array<ParentCategory>;
};

export type RegistrationFlowPasswordScreen = {
  __typename?: 'RegistrationFlowPasswordScreen';
  ok: Scalars['Boolean']['output'];
};

export type RegistrationFlowPasswordScreenInput = {
  password: Scalars['String']['input'];
  stepToken: Scalars['String']['input'];
};

export type RegistrationFlowPhoneNumberScreen = {
  __typename?: 'RegistrationFlowPhoneNumberScreen';
  prefillPhoneNumber?: Maybe<Scalars['String']['output']>;
};

export type RegistrationFlowPhoneNumberScreenInput = {
  phoneNumber: Scalars['String']['input'];
  stepToken: Scalars['String']['input'];
};

export type RegistrationFlowPhoneNumberVerifyScreen = {
  __typename?: 'RegistrationFlowPhoneNumberVerifyScreen';
  phoneNumber: Scalars['String']['output'];
};

export type RegistrationFlowPhoneNumberVerifyScreenInput = {
  code: Scalars['String']['input'];
  stepToken: Scalars['String']['input'];
};

export type RegistrationFlowReferralCodeScreen = {
  __typename?: 'RegistrationFlowReferralCodeScreen';
  ok: Scalars['Boolean']['output'];
};

export type RegistrationFlowReferralCodeScreenInput = {
  /** null if no referral code / skip step */
  referralCode?: InputMaybe<Scalars['String']['input']>;
  stepToken: Scalars['String']['input'];
};

export type RegistrationFlowStep = {
  __typename?: 'RegistrationFlowStep';
  expectScreen: RegistrationFlowExpectedScreen;
  /** Used by the backend to store the current state of the registration flow */
  nextStepToken: Scalars['String']['output'];
};

export type RegistrationFlowStepPayload = RegistrationFlowDone | RegistrationFlowStep;

export type RegistrationFlowUsernameScreen = {
  __typename?: 'RegistrationFlowUsernameScreen';
  prefill?: Maybe<Scalars['String']['output']>;
};

export type RegistrationFlowUsernameScreenInput = {
  stepToken: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RemoveBulkProductFromShowInput = {
  products: Array<Scalars['ID']['input']>;
  showId: Scalars['ID']['input'];
};

export type RemoveBulkProductFromShowPayload = {
  __typename?: 'RemoveBulkProductFromShowPayload';
  /** Number of products actually removed (sold products being ignored). */
  count: Scalars['Int']['output'];
  ok: Scalars['Boolean']['output'];
};

export type RemoveInventoryProductInput = {
  inventoryProductId: Scalars['ID']['input'];
};

export type RemoveInventoryProductPayload = {
  __typename?: 'RemoveInventoryProductPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveInventoryProductsInput = {
  inventoryProductIds: Array<Scalars['ID']['input']>;
};

export type RemoveInventoryProductsPayload = {
  __typename?: 'RemoveInventoryProductsPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveProductFromShopInput = {
  productId: Scalars['ID']['input'];
};

export type RemoveProductFromShopPayload = {
  __typename?: 'RemoveProductFromShopPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveProductFromShowInput = {
  productId: Scalars['ID']['input'];
  showId: Scalars['ID']['input'];
};

export type RemoveProductFromShowPayload = {
  __typename?: 'RemoveProductFromShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveViewerBancontactPaymentMethodPayload = {
  __typename?: 'RemoveViewerBancontactPaymentMethodPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveViewerCardPaymentMethodPayload = {
  __typename?: 'RemoveViewerCardPaymentMethodPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveViewerGiropayPaymentMethodPayload = {
  __typename?: 'RemoveViewerGiropayPaymentMethodPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveViewerIdealPaymentMethodPayload = {
  __typename?: 'RemoveViewerIdealPaymentMethodPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveViewerPaypalPaymentMethodForRecurringPaymentsPayload = {
  __typename?: 'RemoveViewerPaypalPaymentMethodForRecurringPaymentsPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveViewerPaypalPaymentMethodPayload = {
  __typename?: 'RemoveViewerPaypalPaymentMethodPayload';
  ok: Scalars['Boolean']['output'];
};

export type ReportBugInShowInput = {
  deviceSettings: DeviceSettings;
  message: Scalars['String']['input'];
  showId: Scalars['ID']['input'];
  timeSpentByUserInShowInSeconds: Scalars['Int']['input'];
};

export type ReportBugInShowPayload = {
  __typename?: 'ReportBugInShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type ReportIssueInShowInput = {
  message: Scalars['String']['input'];
  newStatus?: InputMaybe<Scalars['Boolean']['input']>;
  showId: Scalars['ID']['input'];
  showStartAt?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  topic: Scalars['String']['input'];
};

export type ReportIssueInShowPayload = {
  __typename?: 'ReportIssueInShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type ReportOrderIssueToCustomerInput = {
  customerId: Scalars['ID']['input'];
};

export type ReportOrderIssueToCustomerPayload = {
  __typename?: 'ReportOrderIssueToCustomerPayload';
  customerEmailAlias: Scalars['String']['output'];
};

export type ReportOrderIssueToSellerInput = {
  choiceId: Scalars['ID']['input'];
  showOrdersGroupId: Scalars['ID']['input'];
};

export type ReportOrderIssueToSellerPayload = {
  __typename?: 'ReportOrderIssueToSellerPayload';
  sellerEmailAlias: Scalars['String']['output'];
};

export type ReportOrderIssueToSupportInput = {
  choiceId: Scalars['ID']['input'];
  issue: Scalars['String']['input'];
  showOrdersGroupId: Scalars['ID']['input'];
};

export type ReportOrderIssueToSupportPayload = {
  __typename?: 'ReportOrderIssueToSupportPayload';
  ok: Scalars['Boolean']['output'];
};

export type ReportProductInput = {
  description: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
  reason: ReportProductReason;
};

export type ReportProductPayload = {
  __typename?: 'ReportProductPayload';
  ok: Scalars['Boolean']['output'];
};

export enum ReportProductReason {
  OffensiveContent = 'OFFENSIVE_CONTENT',
  Other = 'OTHER',
  ScamCounterfeit = 'SCAM_COUNTERFEIT'
}

export type ReportShowCommentInput = {
  commentAuthorId: Scalars['ID']['input'];
  commentId: Scalars['ID']['input'];
  /** You can put a description to give more context; not mandatory. */
  description?: InputMaybe<Scalars['String']['input']>;
  showId: Scalars['ID']['input'];
};

export type ReportShowCommentPayload = {
  __typename?: 'ReportShowCommentPayload';
  ok: Scalars['Boolean']['output'];
};

export type ReportShowInput = {
  description: Scalars['String']['input'];
  reason: ReportShowReason;
  showId: Scalars['ID']['input'];
};

export type ReportShowPayload = {
  __typename?: 'ReportShowPayload';
  ok: Scalars['Boolean']['output'];
};

export enum ReportShowReason {
  OffensiveContent = 'OFFENSIVE_CONTENT',
  Other = 'OTHER',
  ScamCounterfeit = 'SCAM_COUNTERFEIT'
}

export type ReportUserInput = {
  /** You can put a description to give more context; not mandatory. */
  description?: InputMaybe<Scalars['String']['input']>;
  showId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type ReportUserPayload = {
  __typename?: 'ReportUserPayload';
  ok: Scalars['Boolean']['output'];
};

export type ReportUserProfileInput = {
  description: Scalars['String']['input'];
  reason: ReportUserProfileReason;
  userId: Scalars['ID']['input'];
};

export type ReportUserProfilePayload = {
  __typename?: 'ReportUserProfilePayload';
  ok: Scalars['Boolean']['output'];
};

export enum ReportUserProfileReason {
  OffensiveContent = 'OFFENSIVE_CONTENT',
  Other = 'OTHER',
  ScamCounterfeit = 'SCAM_COUNTERFEIT'
}

export type RequestMagicLinkForSellerSubAccountInput = {
  sellerUsername: Scalars['String']['input'];
  subAccountEmail: Scalars['String']['input'];
};

export type RequestMagicLinkForSellerSubAccountPayload = {
  __typename?: 'RequestMagicLinkForSellerSubAccountPayload';
  ok: Scalars['Boolean']['output'];
};

export type RequestNewPhoneNumberVerificationCodeInput = {
  /** Will only work with a stepToken from the registrationFlowPhoneNumberScreen */
  stepToken: Scalars['String']['input'];
};

export type RequestNewPhoneNumberVerificationCodePayload = {
  __typename?: 'RequestNewPhoneNumberVerificationCodePayload';
  /** Must replace your current step token */
  replacementStepToken: Scalars['String']['output'];
};

export type RequestOrderCancellationInput = {
  cancellationReason: OrderCancellationReason;
  orderId: Scalars['ID']['input'];
};

export type RequestOrderCancellationPayload = {
  __typename?: 'RequestOrderCancellationPayload';
  order: Order;
};

export type RequestOrderShippingFeeCancellationInput = {
  orderId: Scalars['ID']['input'];
};

export type RequestOrderShippingFeeCancellationPayload = {
  __typename?: 'RequestOrderShippingFeeCancellationPayload';
  order: Order;
};

export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  ok: Scalars['Boolean']['output'];
};

export type RequestShowTeaserCreationInput = {
  /** List of the product IDs (db ids) that the user wants to show in the teaser, in the order they want them to appear. */
  productIds: Array<Scalars['ID']['input']>;
  showId: Scalars['ID']['input'];
};

export type RequestShowTeaserCreationPayload = {
  __typename?: 'RequestShowTeaserCreationPayload';
  ok: Scalars['Boolean']['output'];
};

export type ResetUserPasswordInput = {
  confirmPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};

export type ResetUserPasswordPayload = {
  __typename?: 'ResetUserPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type RestreamUrl = {
  __typename?: 'RestreamUrl';
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RetryPaymentPayload = {
  __typename?: 'RetryPaymentPayload';
  order: Order;
};

export type SaveUserPreferredParentCategoriesInput = {
  parentCategoryIds: Array<Scalars['ID']['input']>;
};

export type SaveUserPreferredParentCategoriesPayload = {
  __typename?: 'SaveUserPreferredParentCategoriesPayload';
  topCategoriesOrderedByPosition: Array<Category>;
};

export type SearchMondialRelayPickupPointsInput = {
  city: Scalars['String']['input'];
  countryIsoCode: Scalars['ISO2CountryCode']['input'];
  postalCode: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type SellerBalanceTransaction = {
  __typename?: 'SellerBalanceTransaction';
  amount?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  feeAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  labelAmount?: Maybe<Scalars['Int']['output']>;
  netIncomeAmount?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  orderStatus?: Maybe<Scalars['String']['output']>;
  payoutBankAccount?: Maybe<Scalars['Int']['output']>;
  payoutDescription?: Maybe<Scalars['String']['output']>;
  payoutETA?: Maybe<Scalars['Int']['output']>;
  payoutStatus?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  refundReason?: Maybe<Scalars['String']['output']>;
  shippingAmount?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type SellerBalanceTransactionsPayload = {
  __typename?: 'SellerBalanceTransactionsPayload';
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  transactions: Array<SellerBalanceTransaction>;
};

export type SellerBankAccountSupportedCountries = {
  __typename?: 'SellerBankAccountSupportedCountries';
  code: Scalars['ISO2CountryCode']['output'];
  label: Scalars['String']['output'];
};

export type SellerBankingInfo = {
  __typename?: 'SellerBankingInfo';
  bankAccountName?: Maybe<Scalars['String']['output']>;
  bic?: Maybe<Scalars['String']['output']>;
  ibanFourLastDigits: Scalars['String']['output'];
};

/**
 * Represents a config for a seller, this can be used to enable or disable
 * some features like cancel order from seller studio by the seller
 */
export type SellerConfig = {
  __typename?: 'SellerConfig';
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canAccessHomeMetrics: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canAccessInventory: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canAccessPreAuth: Scalars['Boolean']['output'];
  /**
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   * @deprecated Use `easyShippingMethod`  instead
   */
  canAccessSendcloud: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canAccessStripeTransactions: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canAccessWebRTC: Scalars['Boolean']['output'];
  /**
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   * @deprecated Should be considered as true for all sellers
   */
  canAddBulkProduct: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canBeContactedByCustomersForOrderIssues: Scalars['Boolean']['output'];
  /**
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   * @deprecated Should be considered as true for all sellers
   */
  canCreateGiveaway: Scalars['Boolean']['output'];
  /**
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   * @deprecated Should be considered as true for all sellers
   */
  canCreateInstantBuy: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canCreatePayoutFromSellerLedger: CanCreatePayoutFromSellerLedgerPayload;
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canCreateShop: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canCreateShow: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canManageAccountSettings: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canManageSubAccounts: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canRequestOrderCancellation: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canSkipStripeDebitAgreement: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canStreamAbove720p: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  canUseLedger: Scalars['Boolean']['output'];
  country: Country;
  createdAt: Scalars['Date']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  defaultShowLanguage: Language;
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  easyShippingMethod?: Maybe<EasyShippingMethod>;
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  favoriteParentCategory?: Maybe<ParentCategory>;
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  favoriteShippingLabelFormat: FavoriteShippingLabelFormat;
  /**
   * Seller's fixed fee to be applied to orders from their customers
   *
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   */
  fixedFee: AmountWithCurrency;
  /**
   * Returns true if the seller has a bank account configured in Stripe.
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   */
  hasBankAccountConfiguredInStripe: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  isInventoryInitialized: Scalars['Boolean']['output'];
  /**
   * Seller's percentage fee to be applied to orders from their customers
   *
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   */
  percentageFee: Scalars['Float']['output'];
  /** 🔐 Authentication required - must be the owner of the seller config (or Voggt staff) */
  sellerCurrency: Currency;
  /**
   * Indicates the seller tier
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   */
  sellerTier?: Maybe<SellerTier>;
  /**
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   *
   * null if not yet defined. This address is used for dispatching shipments
   */
  shippingAddress?: Maybe<SellerConfigShippingAddress>;
  /**
   * Returns true in case of modifications of business informations on Stripe
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   */
  shouldAcceptStripeDebitAgreement: Scalars['Boolean']['output'];
  /**
   * Returns true in case of modifications of business informations on Stripe
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   */
  shouldAcceptStripeTermsOfServices: Scalars['Boolean']['output'];
  streamingConfigs: Array<SellerStreamingConfig>;
  /**
   * Indicates that the seller is able to sell
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   */
  stripeAccountChargesEnabled: Scalars['Boolean']['output'];
  /**
   * Indicates that the seller will be payed out by us
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   */
  stripeAccountPayoutsEnabled: Scalars['Boolean']['output'];
  /**
   * Indicates that the seller needs to give us documents in order to keep selling and being payed out
   * 🔐 Authentication required - must be the owner of the seller config (or Voggt staff)
   */
  stripeMissingInfoDueDate?: Maybe<Scalars['Date']['output']>;
};

export type SellerConfigShippingAddress = {
  __typename?: 'SellerConfigShippingAddress';
  city: Scalars['String']['output'];
  countryIso2Code: Scalars['ISO2CountryCode']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  houseNumber?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type SellerInfo = {
  __typename?: 'SellerInfo';
  sellerBankingInfo: SellerBankingInfo;
};

export type SellerLedgerBalance = {
  __typename?: 'SellerLedgerBalance';
  availableSoon: AmountWithCurrency;
  availableforPayout: AmountWithCurrency;
};

export type SellerLedgerPayout = CustomNode & Node & {
  __typename?: 'SellerLedgerPayout';
  amount: AmountWithCurrency;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: SellerLedgerTransactionStatus;
  stripePayoutId?: Maybe<Scalars['String']['output']>;
};

export type SellerLedgerPayoutConnection = Connection & {
  __typename?: 'SellerLedgerPayoutConnection';
  edges: Array<SellerLedgerPayoutEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SellerLedgerPayoutEdge = Edge & {
  __typename?: 'SellerLedgerPayoutEdge';
  cursor: Scalars['String']['output'];
  node: SellerLedgerPayout;
};

export enum SellerLedgerPayoutsSort {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC'
}

export type SellerLedgerTransaction = CustomNode & Node & {
  __typename?: 'SellerLedgerTransaction';
  amount: AmountWithCurrency;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orderId?: Maybe<Scalars['Int']['output']>;
  shopId?: Maybe<Scalars['Int']['output']>;
  showId?: Maybe<Scalars['Int']['output']>;
  status: SellerLedgerTransactionStatus;
  stripePayoutId?: Maybe<Scalars['String']['output']>;
  type: SellerLedgerTransactionType;
};

export type SellerLedgerTransactionConnection = Connection & {
  __typename?: 'SellerLedgerTransactionConnection';
  edges: Array<SellerLedgerTransactionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SellerLedgerTransactionEdge = Edge & {
  __typename?: 'SellerLedgerTransactionEdge';
  cursor: Scalars['String']['output'];
  node: SellerLedgerTransaction;
};

export enum SellerLedgerTransactionStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Lock = 'LOCK',
  Ready = 'READY',
  Success = 'SUCCESS'
}

export enum SellerLedgerTransactionType {
  ManualRecovery = 'MANUAL_RECOVERY',
  OrderFeeAdjustement = 'ORDER_FEE_ADJUSTEMENT',
  OrderPaymentSuccessful = 'ORDER_PAYMENT_SUCCESSFUL',
  Payout = 'PAYOUT',
  Refund = 'REFUND',
  ShippingLabelGeneration = 'SHIPPING_LABEL_GENERATION',
  ShippingLabelRefund = 'SHIPPING_LABEL_REFUND'
}

export type SellerLedgerTransactionsFilters = {
  amount?: InputMaybe<SellerLedgerTransactionsFiltersAmount>;
  createdAt?: InputMaybe<SellerLedgerTransactionsFiltersCreatedAt>;
  description?: InputMaybe<Scalars['String']['input']>;
  orderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  showIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses?: InputMaybe<Array<SellerLedgerTransactionStatus>>;
  stripePayoutIds?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionTypes?: InputMaybe<Array<SellerLedgerTransactionType>>;
};

export type SellerLedgerTransactionsFiltersAmount = {
  from?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['Int']['input']>;
};

export type SellerLedgerTransactionsFiltersCreatedAt = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export enum SellerLedgerTransactionsSort {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  OrderIdAsc = 'ORDER_ID_ASC',
  OrderIdDesc = 'ORDER_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC'
}

export type SellerMonthlyStatistics = {
  __typename?: 'SellerMonthlyStatistics';
  commentCountLastMonth: Scalars['Int']['output'];
  commentCountMtd: Scalars['Int']['output'];
  newFollowerCountLastMonth: Scalars['Int']['output'];
  newFollowerCountMtd: Scalars['Int']['output'];
  showCountLastMonth: Scalars['Int']['output'];
  showCountMtd: Scalars['Int']['output'];
  soldItemsCountLastMonth: Scalars['Int']['output'];
  soldItemsCountMtd: Scalars['Int']['output'];
  totalGmvLastMonth: Scalars['Float']['output'];
  totalGmvMtd: Scalars['Float']['output'];
  viewerCountLastMonth: Scalars['Int']['output'];
  viewerCountMtd: Scalars['Int']['output'];
};

export type SellerNewsPayload = {
  __typename?: 'SellerNewsPayload';
  createdAt: Scalars['Date']['output'];
  message: Scalars['String']['output'];
  startAt?: Maybe<Scalars['Date']['output']>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type SellerStatistics = {
  __typename?: 'SellerStatistics';
  totalDaysSinceLastShow: Scalars['Int']['output'];
  totalDeliveredOrderCount: Scalars['Int']['output'];
  totalDeliveredProductCount: Scalars['Int']['output'];
  totalOrderAmount: Scalars['Int']['output'];
  totalShowCount: Scalars['Int']['output'];
  totalStreamingHours: Scalars['Int']['output'];
};

export type SellerStreamingConfig = {
  __typename?: 'SellerStreamingConfig';
  name: Scalars['String']['output'];
  streamKey: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SellerSubAccount = {
  __typename?: 'SellerSubAccount';
  createdAt: Scalars['Date']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
};

export type SellerSubAccountConnection = {
  __typename?: 'SellerSubAccountConnection';
  edges: Array<SellerSubAccountEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SellerSubAccountEdge = {
  __typename?: 'SellerSubAccountEdge';
  cursor: Scalars['String']['output'];
  node: SellerSubAccount;
};

export type SellerTier = CustomNode & Node & {
  __typename?: 'SellerTier';
  canCreateShow: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  showStartMaxTime?: Maybe<Scalars['Int']['output']>;
};

export type SendCurrentRaidAndEndShowInput = {
  showId: Scalars['ID']['input'];
};

export type SendCurrentRaidAndEndShowPayload = {
  __typename?: 'SendCurrentRaidAndEndShowPayload';
  showRaid: ShowRaid;
};

export type SendNotificationForShowInput = {
  showId: Scalars['ID']['input'];
};

export type SendNotificationForShowPayload = {
  __typename?: 'SendNotificationForShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type SendcloudShippingMethod = {
  __typename?: 'SendcloudShippingMethod';
  carrier: Scalars['String']['output'];
  firstMile?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastMile?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated Use `priceV2` instead */
  price: Scalars['Float']['output'];
  /** null if we can't have the pricing from the seller contract */
  priceV2?: Maybe<Scalars['Float']['output']>;
  servicePointId?: Maybe<Scalars['Int']['output']>;
};

export type SetSellerFavoriteShippingLabelFormatInput = {
  format: FavoriteShippingLabelFormat;
};

export type SetSellerFavoriteShippingLabelFormatPayload = {
  __typename?: 'SetSellerFavoriteShippingLabelFormatPayload';
  format: FavoriteShippingLabelFormat;
};

export type SetSellerShippingAddressInput = {
  city: Scalars['String']['input'];
  countryIso2Code: Scalars['ISO2CountryCode']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  street: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type SetSellerShippingAddressPayload = {
  __typename?: 'SetSellerShippingAddressPayload';
  city: Scalars['String']['output'];
  countryIso2Code: Scalars['ISO2CountryCode']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  houseNumber?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type SetShipmentStatusLabelDownloadedInput = {
  shipmentId: Scalars['ID']['input'];
};

export type SetShipmentStatusLabelDownloadedPayload = {
  __typename?: 'SetShipmentStatusLabelDownloadedPayload';
  ok: Scalars['Boolean']['output'];
};

export type SetViewerRemainingInformationForRegistrationWithEmailInput = {
  referralCode?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type SetViewerRemainingInformationForRegistrationWithEmailPayload = {
  __typename?: 'SetViewerRemainingInformationForRegistrationWithEmailPayload';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type SetViewerRemainingRegistrationInformationV2Input = {
  birthdate: Scalars['Date']['input'];
  email: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  referralCode?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type SetViewerRemainingRegistrationInformationV2Payload = {
  __typename?: 'SetViewerRemainingRegistrationInformationV2Payload';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tokenExpiresAt: Scalars['Date']['output'];
};

export type Shipment = Node & {
  __typename?: 'Shipment';
  allOrderedProductsHaveWeight: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  currency: Currency;
  customer: User;
  /** This is a link to download the label. It is only available for a limited time. */
  downloadLinkLabelPrintUrl?: Maybe<Scalars['String']['output']>;
  easyShippingAmount?: Maybe<Scalars['Int']['output']>;
  externalShippingOrderId?: Maybe<Scalars['String']['output']>;
  hasFailedPayments: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  labelGeneratedAt?: Maybe<Scalars['Date']['output']>;
  labelPrintUrl?: Maybe<Scalars['String']['output']>;
  orderedProducts: OrderedProductsConnection;
  orderedProductsCount: Scalars['Int']['output'];
  /** Rating can be null */
  rating?: Maybe<Scalars['Int']['output']>;
  ratingComment?: Maybe<Scalars['String']['output']>;
  seller: User;
  shippingAddress: ShippingAddress;
  /** null if not shippable */
  shippingOptions?: Maybe<ShipmentShippingOptions>;
  shippingProvider?: Maybe<Scalars['String']['output']>;
  status: ShipmentStatus;
  totalOrderedProductsAmount: Scalars['Int']['output'];
  totalOrderedProductsWeight: Scalars['Int']['output'];
  trackingNumber?: Maybe<Scalars['String']['output']>;
};


export type ShipmentOrderedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ShipmentConnection = Connection & {
  __typename?: 'ShipmentConnection';
  edges: Array<ShipmentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShipmentEdge = Edge & {
  __typename?: 'ShipmentEdge';
  cursor: Scalars['String']['output'];
  node: Shipment;
};

export type ShipmentShippingOptions = {
  __typename?: 'ShipmentShippingOptions';
  /**
   * null if not applicable.
   * A custom (by own means) method is always available.
   */
  availableShippingProviders?: Maybe<EasyShippingMethod>;
};

export enum ShipmentStatus {
  Delivered = 'DELIVERED',
  LabelCreated = 'LABEL_CREATED',
  ReadyToPickup = 'READY_TO_PICKUP',
  ReadyToShip = 'READY_TO_SHIP',
  Received = 'RECEIVED',
  Shipped = 'SHIPPED'
}

export type ShipmentsTabsFilter = {
  statuses?: InputMaybe<Array<ShipmentsTabsFilterStatus>>;
  /** Used to filter */
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum ShipmentsTabsFilterStatus {
  Delivered = 'DELIVERED',
  Shipped = 'SHIPPED'
}

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputCity: Scalars['String']['output'];
  inputCountry: Scalars['String']['output'];
  inputLine1: Scalars['String']['output'];
  inputLine2?: Maybe<Scalars['String']['output']>;
  inputPostalCode: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  resolvedCountry: Country;
};

export enum ShippingCategory {
  Colissimo = 'COLISSIMO',
  FashionLargePackage = 'FASHION_LARGE_PACKAGE',
  FashionLargePackageIncremental = 'FASHION_LARGE_PACKAGE_INCREMENTAL',
  FashionMediumPackage = 'FASHION_MEDIUM_PACKAGE',
  FashionMediumPackageIncremental = 'FASHION_MEDIUM_PACKAGE_INCREMENTAL',
  FashionSmallPackage = 'FASHION_SMALL_PACKAGE',
  FashionSmallPackageIncremental = 'FASHION_SMALL_PACKAGE_INCREMENTAL',
  Free = 'FREE',
  LargeLetter = 'LARGE_LETTER',
  LargeLetterPlus = 'LARGE_LETTER_PLUS',
  Letter = 'LETTER',
  LetterIncremental = 'LETTER_INCREMENTAL',
  LetterPlus = 'LETTER_PLUS',
  LetterRegistered = 'LETTER_REGISTERED',
  LetterRegisteredIncremental = 'LETTER_REGISTERED_INCREMENTAL',
  MondialRelay = 'MONDIAL_RELAY',
  Package = 'PACKAGE',
  PackageIncremental = 'PACKAGE_INCREMENTAL',
  PackagePlus = 'PACKAGE_PLUS',
  SneakersAffordable = 'SNEAKERS_AFFORDABLE',
  SneakersPremium = 'SNEAKERS_PREMIUM',
  SneakersStandard = 'SNEAKERS_STANDARD',
  Ups = 'UPS'
}

export type ShippingFeeConfig = ShippingFeeConfigInterval | ShippingFeeConfigOnce;

export type ShippingFeeConfigInterval = {
  __typename?: 'ShippingFeeConfigInterval';
  firstAmount: AmountWithCurrency;
  intervalAmount: AmountWithCurrency;
  intervalCount: Scalars['Int']['output'];
};

export type ShippingFeeConfigOnce = {
  __typename?: 'ShippingFeeConfigOnce';
  amount: AmountWithCurrency;
};

export type ShippingInfosCustomer = {
  __typename?: 'ShippingInfosCustomer';
  inputCity: Scalars['String']['output'];
  inputCountry: Scalars['String']['output'];
  inputLine1: Scalars['String']['output'];
  inputLine2?: Maybe<Scalars['String']['output']>;
  inputPostalCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ShippingLabelForBoxtal = {
  __typename?: 'ShippingLabelForBoxtal';
  /** Signed URL to download the file. Expires after a while */
  label: Scalars['String']['output'];
  /**
   * Shipments for which no label could be generated.
   * They may be available later.
   */
  missingShipments: ShipmentConnection;
};

export type ShippingLabelForSendcloud = {
  __typename?: 'ShippingLabelForSendcloud';
  /** Signed URL to download the file. Expires after a while */
  label: Scalars['String']['output'];
};

/** ⭐️ A seller's shop */
export type Shop = Node & {
  __typename?: 'Shop';
  category: Category;
  country: Country;
  currency: Currency;
  id: Scalars['ID']['output'];
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  products: ProductConnection;
  seller: User;
  shippingAmount?: Maybe<Scalars['Int']['output']>;
  shippingCategory: ShippingCategory;
};


/** ⭐️ A seller's shop */
export type ShopProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ShopConnection = Connection & {
  __typename?: 'ShopConnection';
  edges: Array<ShopEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShopEdge = Edge & {
  __typename?: 'ShopEdge';
  cursor: Scalars['String']['output'];
  node: Shop;
};

export type ShopOrdersFilter = {
  /** Filter by category: get orders for categories which `ID` is in the given array. */
  categoryIdIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum ShopOrdersSort {
  /** Sort by created at date ascending. */
  CreatedAtDateAsc = 'CREATED_AT_DATE_ASC',
  /** Sort by created at date descending. */
  CreatedAtDateDesc = 'CREATED_AT_DATE_DESC',
  /** Sort by customer's username ascending. */
  CustomerUsernameAsc = 'CUSTOMER_USERNAME_ASC',
  /** Sort by customer's username descending. */
  CustomerUsernameDesc = 'CUSTOMER_USERNAME_DESC'
}

/** ⭐️ A show on Voggt. */
export type Show = Node & {
  __typename?: 'Show';
  /** @deprecated Use `Show.orderedProducts` instead */
  allOrderedProducts: Array<OrderedProduct>;
  /** The preset auction duration related to the show */
  auctionInitialDurationPreset: AuctionInitialDurationPreset;
  /** 📄 Pagination max 50 */
  auctionProducts: ShowProductConnection;
  /**
   * 🔐 Authentication required - must be the seller of the show
   * 📄 Pagination max 100
   */
  auctionProductsForSeller: ShowProductConnection;
  /** The preset auction reset duration related to the show */
  auctionResetDurationPreset: AuctionResetDurationPreset;
  /** Returns EmoteSets that are available to use. */
  availableEmoteSets: Array<EmoteSet>;
  /**
   * Returns a list of users that are banned from the show.
   *   🔐 Authentication required
   */
  bannedUsersFromShow: Array<User>;
  /**
   * The url of the video stream (matches the channel hls)
   * @deprecated Deprecated in favor of streamInfo
   */
  broadcastUrl: Scalars['String']['output'];
  category: Category;
  country: Country;
  currency: Currency;
  /** 🎱 Note that, as a main show activity, it cannot be opened simultaneously to another main show activity */
  currentAuction?: Maybe<ShowAuction>;
  /** 🎱 Note that, as a main show activity, it cannot be opened simultaneously to another main show activity */
  currentFlashSale?: Maybe<ShowFlashSale>;
  /** 🎱 Note that, as a main show activity, it cannot be opened simultaneously to another main show activity */
  currentGiveaway?: Maybe<ShowGiveaway>;
  /** 🎱 Note that, as a main show activity, it cannot be opened simultaneously to another main show activity */
  currentRaid?: Maybe<ShowRaid>;
  /** 🎱 Note that, as a main show activity, it cannot be opened simultaneously to another main show activity */
  currentShowPoll?: Maybe<ShowPoll>;
  /** 📄 Pagination max 0 */
  customers: CustomerConnection;
  /** @deprecated Deprecated in favor of `note`. This field was never really used but was mandatory on the Seller Studio */
  description: Scalars['String']['output'];
  /** @deprecated Deprecated in favor of `endedAt` */
  endAt?: Maybe<Scalars['Date']['output']>;
  endedAt?: Maybe<Scalars['Date']['output']>;
  /** 🔐 Authentication required - must be seller of the show */
  featuredShowApplication?: Maybe<FeaturedShowApplication>;
  featuredShowApplicationCriteria: FeaturedShowApplicationCriteria;
  /**
   * Get Show's feed items with most recent first.
   * Only the 50 most recent feed items are available.
   *
   * For now, only `UserCommentFeedItem` will be returned.
   * Use the subscription `showFeedItemAdded` to receive other events.
   */
  feedItemsByTimestampDesc: ShowFeedItemConnection;
  /** Gives a few presentable products, max 4 */
  firstPresentableProducts: Array<Product>;
  /** 📄 Pagination max 50 */
  giveawayProducts: ShowProductConnection;
  /**
   * 🔐 Authentication required - must be the seller of the show
   * 📄 Pagination max 100
   */
  giveawayProductsForSeller: ShowProductConnection;
  /**
   * The amount of the gmv target set by the seller
   *
   * 🔐 Authentication required - only accessible to the seller of the show
   */
  gmvTargetAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** 📄 Pagination max 50 */
  instantBuyProducts: ShowProductConnection;
  /**
   * 🔐 Authentication required - must be the seller of the show
   * 📄 Pagination max 100
   */
  instantBuyProductsForSeller: ShowProductConnection;
  /** Indicates that the show is linked to the anniversary Commercial Event */
  isAnniversary: Scalars['Boolean']['output'];
  /** Indicates if the channel is streaming */
  isBroadcasting: Scalars['Boolean']['output'];
  /** A premier show is automatically featured */
  isFeatured: Scalars['Boolean']['output'];
  /**
   * Indicates whether the current authenticated user is following the show.
   *
   * 🔐 Authentication required
   */
  isFollowing: Scalars['Boolean']['output'];
  /** Indicates if this show is an offline show / similar to a shop. */
  isOffline: Scalars['Boolean']['output'];
  /**
   * Logic is based on both viewer & show.
   *
   * 🔐 Authentication required
   */
  isPaymentPreAuthorizationRequired: Scalars['Boolean']['output'];
  /** Indicates that the show is a Voggt Premier kind of show. */
  isPremier: Scalars['Boolean']['output'];
  isReusingPaidShippingFeesFromPreviousShows: Scalars['Boolean']['output'];
  /** 🔐 Authentication required - only accessible to the seller of the show */
  isSupplier: Scalars['Boolean']['output'];
  /**
   * Indicates whether the current authenticated user is banned from the seller's shows.
   *
   * 🔐 Authentication required
   */
  isViewerBannedFromSeller: Scalars['Boolean']['output'];
  /**
   * Indicates whether the current authenticated user is banned from this show.
   *
   * 🔐 Authentication required
   */
  isViewerBannedFromShow: Scalars['Boolean']['output'];
  /**
   * Will return true if the viewer is blocked from commenting in this show.
   * If the viewer is blocked from commenting in any show, this will return true as well.
   *
   * 🔐 Authentication required
   */
  isViewerBlockedFromCommenting: Scalars['Boolean']['output'];
  /**
   * Indicates whether the current authenticated user is moderator for the seller.
   *
   * 🔐 Authentication required
   */
  isViewerModeratorForSeller: Scalars['Boolean']['output'];
  language: Language;
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** Additional info that a seller wants to add their show */
  note?: Maybe<Scalars['String']['output']>;
  /**
   * This field contains the state of the notification on the 3rd party API.
   * Since this can be slower or even fail, please use a dedicated query.
   *
   * Can be null
   * - if the notif was removed
   * - if the notif was not created (yet)
   */
  notification?: Maybe<ShowNotification>;
  /** @deprecated Deprecated, will soon be removed */
  openTokSessionId?: Maybe<Scalars['String']['output']>;
  /** 📄 Pagination max 20 */
  orderedProducts: OrderedProductsConnection;
  paymentPreAuthorizationAmount: Scalars['Int']['output'];
  /**
   * The file info for the several possible versions of the pre show teaser video.
   * Only the mp4HdFileInfo is guaranteed to be always present for the moment.
   */
  preShowTeaserVideoInfo?: Maybe<PreShowTeaserVideoInfo>;
  /**
   * The URL of the pre-show teaser video.
   * @deprecated Deprecated in favor of `preShowTeaserVideoInfo`
   */
  preShowTeaserVideoUrl?: Maybe<Scalars['String']['output']>;
  /** List the Users that are currently present in the Show. */
  presentUsers: UserConnection;
  productCount: Scalars['Int']['output'];
  /** 📄 Pagination max 50 */
  productsV2: ShowProductConnection;
  /** Only accessible to the seller of the show, null otherwise. */
  replayAssets?: Maybe<Array<ShowReplayAsset>>;
  seller: User;
  shippingAmount?: Maybe<Scalars['Int']['output']>;
  shippingCategory: ShippingCategory;
  shippingFeeConfigForViewer: ShippingFeeConfig;
  /** The rating sent by the seller for their show */
  showSellerRating?: Maybe<Rating>;
  /**
   * Only used for search
   * @deprecated Not used anymore
   */
  similarity?: Maybe<Scalars['Float']['output']>;
  /** 📄 Pagination max 50 */
  soldGiveawayProducts: ShowProductConnection;
  /** 📄 Pagination max 50 */
  soldProducts: ShowProductConnection;
  /**
   * 🔐 Authentication required - must be the seller of the show
   * 📄 Pagination max 100
   */
  soldProductsForSeller: ShowProductConnection;
  /**
   * 🔐 Authentication required
   * 📄 Pagination max 50
   */
  soldProductsForViewer: ShowProductConnection;
  startAt: Scalars['Date']['output'];
  streamInfo: StreamInfo;
  /** @deprecated Deprecated in favor of streamInfo */
  streamingType: StreamingType;
  teaserUrl?: Maybe<Scalars['String']['output']>;
  terminatedAt?: Maybe<Scalars['Date']['output']>;
  /** Can be null for old shows or when not yet computed */
  thumbnailColorPalette?: Maybe<Array<Scalars['RGB']['output']>>;
  /** @deprecated Deprecated in favor of `thumbnailWebPUrl` because it gives optimized pictures urls */
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  /**
   * Gives an optimized show thumbnail URL in webp format, with a maximum width that you can specify.
   * If original picture is narrower than the specified width, the original picture will be returned.
   */
  thumbnailWebPUrl?: Maybe<Scalars['String']['output']>;
  /**
   * totalAmountOrdersSuccessInCurrencyCents - totalAmountRefundedInCurrencyCents
   *
   * 🔐 Authentication required - only accessible to the seller of the show
   */
  totalAmountOrdersNetInCurrencyCents: Scalars['Int']['output'];
  /**
   * sum of order shipping amount and order amount where
   * order paymentStatus is success
   *
   * 🔐 Authentication required - only accessible to the seller of the show
   */
  totalAmountOrdersSuccessInCurrencyCents: Scalars['Int']['output'];
  /**
   * sum of order refundedAmoun where
   * order refundDebitedAccount is 'seller'
   *
   * 🔐 Authentication required - only accessible to the seller of the show
   */
  totalAmountRefundedInCurrencyCents: Scalars['Int']['output'];
  /**
   * Sum of fee amount where payment status is success - Sum of fee amount where
   * (refund reasons is "Free shipping" OR "Delivered by Hand") AND (Refund Debited account is seller)
   *
   * 🔐 Authentication required - only accessible to the seller of the show
   */
  totalCommissionInCurrencyCents: Scalars['Int']['output'];
  /**
   * Sum of deducted shipping cost on order
   *
   * 🔐 Authentication required - only accessible to the seller of the show
   */
  totalDeductedShippingCostInCurrencyCents: Scalars['Int']['output'];
  /**
   * totalAmountOrdersValidateInCurrencyCents - (totalCommissionInCurrencyCents + totalDeductedShippingCostInCurrencyCents)
   *
   * 🔐 Authentication required - only accessible to the seller of the show
   */
  totalNetIncomeInCurrencyCents: Scalars['Int']['output'];
  /**
   * Sum of products sold amount where payment status is success
   *
   * 🔐 Authentication required - only accessible to the seller of the show
   */
  totalProductsSoldInCurrencyCents: Scalars['Int']['output'];
  /**
   * Sum of shipping fee amount where payment status is success
   *
   * 🔐 Authentication required - only accessible to the seller of the show
   */
  totalShippingFeesInCurrencyCents: Scalars['Int']['output'];
};


/** ⭐️ A show on Voggt. */
export type ShowAuctionProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowAuctionProductsForSellerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByPositiveQuantity?: InputMaybe<Scalars['Boolean']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortProductsBy>;
};


/** ⭐️ A show on Voggt. */
export type ShowCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowFeedItemsByTimestampDescArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowGiveawayProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowGiveawayProductsForSellerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByPositiveQuantity?: InputMaybe<Scalars['Boolean']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortProductsBy>;
};


/** ⭐️ A show on Voggt. */
export type ShowInstantBuyProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowInstantBuyProductsForSellerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByPositiveQuantity?: InputMaybe<Scalars['Boolean']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortProductsBy>;
};


/** ⭐️ A show on Voggt. */
export type ShowOrderedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowPresentUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowProductsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowSoldGiveawayProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowSoldProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowSoldProductsForSellerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortSoldProductsBy>;
};


/** ⭐️ A show on Voggt. */
export type ShowSoldProductsForViewerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterByText?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A show on Voggt. */
export type ShowThumbnailWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};

/** ⭐️ An auction in a show. */
export type ShowAuction = {
  __typename?: 'ShowAuction';
  additionalDurationMs: Scalars['Int']['output'];
  amountEuroCents: Scalars['Int']['output'];
  currency: Currency;
  endAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  initialDurationMs: Scalars['Int']['output'];
  /**
   * Returns the order created when the auction ends with a winner.
   *
   * If the viewer is not authorized to see this order, because it's not
   * the seller nor the customer of this order, `null` will be returned
   * but it won't throw an error.
   */
  orderIfAuthorized?: Maybe<Order>;
  product: Product;
  serverTime: Scalars['Date']['output'];
  /** The status of the auction. */
  status: ShowAuctionStatus;
  suggestedBidAmountCents: Scalars['Int']['output'];
  /**
   * If any, the user that is winning the auction.
   *
   * If the auction is closed, this will be the user that won the auction.
   */
  winningUser?: Maybe<User>;
};

export enum ShowAuctionStatus {
  /** The auction is closed, no bid can be made. */
  Closed = 'CLOSED',
  /** The auction will start soon. */
  Countdown = 'COUNTDOWN',
  /** The auction is opened, user can start bidding. */
  Opened = 'OPENED'
}

export type ShowBroadcastEndedEvent = {
  __typename?: 'ShowBroadcastEndedEvent';
  show: Show;
};

export type ShowBroadcastStartedEvent = {
  __typename?: 'ShowBroadcastStartedEvent';
  show: Show;
};

export type ShowCommentInput = {
  showId: Scalars['ID']['input'];
  textContent: Scalars['String']['input'];
};

export type ShowConnection = Connection & {
  __typename?: 'ShowConnection';
  edges: Array<ShowEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShowCurrentAuctionBidPlacedEvent = {
  __typename?: 'ShowCurrentAuctionBidPlacedEvent';
  auction: ShowAuction;
  /** @deprecated Unhelpful, this subscription is only about placed bids */
  show: Show;
  /** @deprecated Already included in `auction` */
  winningUser: User;
};

export type ShowCurrentAuctionOrderAddedEvent = {
  __typename?: 'ShowCurrentAuctionOrderAddedEvent';
  /** @deprecated Unhelpful, this subscription is only about the order */
  auction: ShowAuction;
  /**
   * If the viewer is not authorized to see this order, because it's not
   * the seller nor the customer of this order, `null` will be returned
   * but it won't throw an error.
   */
  orderIfAuthorized?: Maybe<Order>;
  /** @deprecated Unhelpful, this subscription is only about the order */
  show: Show;
};

export type ShowCurrentAuctionStatusChangedEvent = {
  __typename?: 'ShowCurrentAuctionStatusChangedEvent';
  auction: ShowAuction;
  /** @deprecated Unhelpful, this subscription is only about auction status changes */
  show: Show;
  status: ShowAuctionStatus;
  winnerInsights: WinnerInsights;
};

export type ShowCurrentGiveawayStatusChangedEvent = {
  __typename?: 'ShowCurrentGiveawayStatusChangedEvent';
  giveaway: ShowGiveaway;
};

export type ShowCurrentGiveawayUpdatePayload = ShowGiveawayEnd | ShowGiveawayParticipantsCount | ShowGiveawayParticipationPermission | ShowGiveawayStart;

export type ShowCurrentGiveawayUserParticipationEvent = {
  __typename?: 'ShowCurrentGiveawayUserParticipationEvent';
  /** @deprecated Please use `participantsCount` instead. */
  giveaway: ShowGiveaway;
  participantsCount: Scalars['Int']['output'];
  /** @deprecated Please stop using this field. */
  user: User;
};

export type ShowCurrentRaidStatusChangedEvent = {
  __typename?: 'ShowCurrentRaidStatusChangedEvent';
  showRaid: ShowRaid;
};

export type ShowCurrentRaidUserParticipationEvent = {
  __typename?: 'ShowCurrentRaidUserParticipationEvent';
  /** This is not a connection because clients don't need to know who is participating and its more scalable */
  participantsCount: Scalars['Int']['output'];
};

export type ShowEdge = Edge & {
  __typename?: 'ShowEdge';
  cursor: Scalars['String']['output'];
  node: Show;
};

/** ⭐️ An item of Show's feed (ex: a comment). */
export type ShowFeedItem = {
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Deprecated in favor of `date` which properly uses the usual Date scalar */
  timestamp: Scalars['Date']['output'];
};

export type ShowFeedItemAddedEvent = {
  __typename?: 'ShowFeedItemAddedEvent';
  feedItem: ShowFeedItem;
};

export enum ShowFeedItemAddedFilterOnlyTypes {
  BlockedChanges = 'BLOCKED_CHANGES',
  Comments = 'COMMENTS',
  Follows = 'FOLLOWS',
  Joins = 'JOINS',
  RaidIncoming = 'RAID_INCOMING',
  Reactions = 'REACTIONS'
}

export type ShowFeedItemAddedFilters = {
  /** If defined, will only subscribe to the specified types of feed items. */
  only?: InputMaybe<Array<ShowFeedItemAddedFilterOnlyTypes>>;
};

export type ShowFeedItemConnection = {
  __typename?: 'ShowFeedItemConnection';
  edges: Array<ShowFeedItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShowFeedItemEdge = {
  __typename?: 'ShowFeedItemEdge';
  cursor: Scalars['String']['output'];
  node: ShowFeedItem;
};

export type ShowFlashSale = {
  __typename?: 'ShowFlashSale';
  /** null if the seller does not offer a discount */
  discountPercentage?: Maybe<Scalars['Int']['output']>;
  durationMs: Scalars['Int']['output'];
  endAt: Scalars['Date']['output'];
  finalProductAmount: AmountWithCurrency;
  id: Scalars['ID']['output'];
  initialProductAmount: AmountWithCurrency;
  initialQuantity: Scalars['Int']['output'];
  product: Product;
  remainingQuantity: Scalars['Int']['output'];
  serverTime: Scalars['Date']['output'];
  status: ShowFlashSaleStatus;
};

export type ShowFlashSaleEnd = {
  __typename?: 'ShowFlashSaleEnd';
  id: Scalars['ID']['output'];
  serverTime: Scalars['Date']['output'];
  status: ShowFlashSaleStatus;
};

/** Only sent to current user */
export type ShowFlashSaleFeesUpdate = {
  __typename?: 'ShowFlashSaleFeesUpdate';
  /** Replaces the previous buyer service fee */
  buyerServiceFeeAmount: AmountWithCurrency;
  id: Scalars['ID']['output'];
  serverTime: Scalars['Date']['output'];
  /** Replaces the previous shipping fee */
  shippingFeeAmount: AmountWithCurrency;
};

export type ShowFlashSaleRemainingQuantityChange = {
  __typename?: 'ShowFlashSaleRemainingQuantityChange';
  id: Scalars['ID']['output'];
  remainingQuantity: Scalars['Int']['output'];
  serverTime: Scalars['Date']['output'];
};

export type ShowFlashSaleStart = {
  __typename?: 'ShowFlashSaleStart';
  /** null if the seller does not offer a discount */
  discountPercentage?: Maybe<Scalars['Int']['output']>;
  durationMs: Scalars['Int']['output'];
  endAt: Scalars['Date']['output'];
  finalProductAmount: AmountWithCurrency;
  id: Scalars['ID']['output'];
  initialProductAmount: AmountWithCurrency;
  initialQuantity: Scalars['Int']['output'];
  product: Product;
  remainingQuantity: Scalars['Int']['output'];
  serverTime: Scalars['Date']['output'];
  status: ShowFlashSaleStatus;
};

export enum ShowFlashSaleStatus {
  Closed = 'CLOSED',
  Opened = 'OPENED'
}

export type ShowFlashSaleUpdatePayload = ShowFlashSaleEnd | ShowFlashSaleFeesUpdate | ShowFlashSaleRemainingQuantityChange | ShowFlashSaleStart;

export type ShowGiveaway = {
  __typename?: 'ShowGiveaway';
  /** Indicates which audience can participate */
  audience: ShowGiveawayAudience;
  endAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isRestrictedToShowCountry?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Returns the order created when the giveaway ends with a winner.
   *
   * If the viewer is not authorized to see this order, because it's not
   * the seller nor the customer of this order, `null` will be returned
   * but it won't throw an error.
   */
  orderIfAuthorized?: Maybe<Order>;
  participants: UserConnection;
  product: Product;
  serverTime: Scalars['Date']['output'];
  status: ShowGiveawayStatus;
  /**
   * Indicates if the current user has the right to participate.
   * 🔐 Authentication required
   * @deprecated Please use `viewerParticipation` instead.
   */
  viewerCanParticipate: Scalars['Boolean']['output'];
  /**
   * Indicates if the current user would be allowed to participate if they were following the seller.
   * 🔐 Authentication required
   * @deprecated Please use `viewerParticipation` instead.
   */
  viewerCanParticipateAfterFollowingSeller: Scalars['Boolean']['output'];
  /**
   * Tells if the current user is participating or not
   *
   * 🔐 Authentication required
   * @deprecated Please use `viewerParticipation` instead.
   */
  viewerIsParticipating: Scalars['Boolean']['output'];
  /**
   * Indicates if the current user is participating or has the right to participate.
   * 🔐 Authentication required
   */
  viewerParticipation: ShowGiveawayParticipation;
  /** Returns the user who won the giveaway; null otherwise */
  winner?: Maybe<User>;
};


export type ShowGiveawayParticipantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum ShowGiveawayAudience {
  /** Anyone who won at least one auction during the show */
  Buyers = 'BUYERS',
  /** Each won auction grants an additional chance */
  BuyersOrders = 'BUYERS_ORDERS',
  /** Anyone who follows the seller */
  Followers = 'FOLLOWERS',
  /** Anyone */
  PresentUsers = 'PRESENT_USERS',
  /** Any show sharer/opener. Each show invitation opened grants an additional chance to the sharer */
  SharersOpeners = 'SHARERS_OPENERS'
}

export type ShowGiveawayEnd = {
  __typename?: 'ShowGiveawayEnd';
  id: Scalars['ID']['output'];
  participants: Array<ShowGiveawayParticipant>;
  participantsCount: Scalars['Int']['output'];
  serverTime: Scalars['Date']['output'];
  /** Returns the user who won the giveaway; null otherwise */
  winner?: Maybe<ShowGiveawayParticipant>;
};

export type ShowGiveawayParticipant = {
  __typename?: 'ShowGiveawayParticipant';
  avatarWebPUrl?: Maybe<Scalars['String']['output']>;
  cosmetics?: Maybe<UserCosmetics>;
  id: Scalars['ID']['output'];
  username: Scalars['String']['output'];
};


export type ShowGiveawayParticipantAvatarWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};

export type ShowGiveawayParticipantsCount = {
  __typename?: 'ShowGiveawayParticipantsCount';
  id: Scalars['ID']['output'];
  participantsCount: Scalars['Int']['output'];
  serverTime: Scalars['Date']['output'];
};

export enum ShowGiveawayParticipation {
  CannotParticipate = 'CANNOT_PARTICIPATE',
  CanFollowAndParticipate = 'CAN_FOLLOW_AND_PARTICIPATE',
  CanParticipate = 'CAN_PARTICIPATE',
  IsParticipating = 'IS_PARTICIPATING'
}

export type ShowGiveawayParticipationPermission = {
  __typename?: 'ShowGiveawayParticipationPermission';
  id: Scalars['ID']['output'];
  serverTime: Scalars['Date']['output'];
  viewerParticipation: ShowGiveawayParticipation;
};

export type ShowGiveawayProduct = {
  __typename?: 'ShowGiveawayProduct';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ShowGiveawayStart = {
  __typename?: 'ShowGiveawayStart';
  /** Indicates which audience can participate */
  audience: ShowGiveawayAudience;
  endAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  product: ShowGiveawayProduct;
  serverTime: Scalars['Date']['output'];
  status: ShowGiveawayStatus;
  /** 🔐 Authentication required */
  viewerParticipation: ShowGiveawayParticipation;
};

export enum ShowGiveawayStatus {
  /** Entries are closed. A winner is chosen. */
  Closed = 'CLOSED',
  /** Allowed people can enter and register to the giveaway. */
  Opened = 'OPENED'
}

export type ShowNotification = {
  __typename?: 'ShowNotification';
  /**
   * Returns true if the campaign is approved by staff member.
   * Only an approved notification can be sent.
   */
  isApproved: Scalars['Boolean']['output'];
  /** Message of the notification, as seen on 3rd party API */
  message: Scalars['String']['output'];
  /**
   * Date when this was sent
   * null if not sent
   */
  sentAt?: Maybe<Scalars['Date']['output']>;
  /** Title of the notification, as seen on 3rd party API */
  title: Scalars['String']['output'];
};

/**
 * ⭐️ Represents a group of ordered products that will be shipped together.
 * They are grouped either by shipment when the seller makes them, grouped by show/shop otherwise.
 * The name is misleading and we might proceed to rename it in the future, maybe to `OrderedProductsGroup`.
 */
export type ShowOrdersGroup = Node & {
  __typename?: 'ShowOrdersGroup';
  /** Indicates whether the group can be confirmed as received by the user or not. */
  canBeConfirmedAsReceived: Scalars['Boolean']['output'];
  /** Indicates whether the group can be rated by the user or not. */
  canBeRated: Scalars['Boolean']['output'];
  currency: Currency;
  customer: User;
  /**
   * The price paid by the customer for this group of orders, on paid orders
   *
   * It corresponds to:
   *  - (+) the total amount for the products (`totalPaidProductAmountInCurrencyCents`)
   *  - (+) what was paid for shipping (`totalPaidShippingAmountInCurrencyCents`)
   *  - (+) the buyer service fee (`totalBuyerServiceFee`)
   *
   * Caveat: refunded amounts are not accounted in this total.
   *
   * In cents of the currency found on `ShowOrdersGroup.currency`
   */
  customerTotalPriceInCurrencyCents: Scalars['Int']['output'];
  /** The value of the externalShippingOrderId of the orders of a group. */
  externalShippingOrderId?: Maybe<Scalars['String']['output']>;
  firstOrderDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Indicate whether the current user indicated the order as received or not. */
  isReceived: Scalars['Boolean']['output'];
  /** If any, will return the last order for which the payment failed. */
  lastFailedPaymentOrder?: Maybe<Order>;
  name: Scalars['String']['output'];
  orders: OrderConnection;
  /** @deprecated Use `statuses` instead */
  paymentStatusSummary: ShowOrdersGroupPaymentStatusSummary;
  /**
   * Indicate the rating value the user chose for this group.
   * Must be an integer from 1 to 5.
   *
   * Can be null if the user didn't give a rating.
   * @deprecated Use `ratingV2` instead
   */
  rating?: Maybe<Scalars['Int']['output']>;
  /**
   * Some paragraph of text describing the order.
   *
   * Can be null if the user didn't write anything.
   * @deprecated Use `ratingV2` instead
   */
  ratingComment?: Maybe<Scalars['String']['output']>;
  /** null if the user didn't give a rating */
  ratingV2?: Maybe<RatingFromCustomer>;
  seller: User;
  shippingAddress: ShippingAddress;
  /** @deprecated Use `shippingProviderName` and `trackingUrl` instead */
  shippingProvider?: Maybe<Scalars['String']['output']>;
  shippingProviderName?: Maybe<Scalars['String']['output']>;
  /**
   * Represents a summary of the statuses of the orders of a group that are
   * meant to be shipped in this group.
   *
   * Null if none of the order can be shipped.
   * @deprecated Use `statuses` instead
   */
  shippingStatusSummary?: Maybe<ShowOrdersGroupShippingStatusSummary>;
  /** @deprecated An ordered product group is not tied to a show anymore, use `name` and `orderDate` instead */
  show: Show;
  /** @deprecated Use `statuses` instead */
  statusSummary: ShowOrdersGroupStatusSummary;
  statuses: Array<ShowOrdersGroupStatusStep>;
  /**
   * The sum af all orders amounts and associated shipping amounts of this group.
   *
   * ⚠️ In opposite to what its name implies, it's not necessarily in Euros but in the currency found on `ShowOrdersGroup.currency`.
   */
  totalAmountEuroCents: Scalars['Int']['output'];
  /** Sum of orders buyer service fee */
  totalBuyerServiceFee: AmountWithCurrency;
  /**
   * The sum af all orders amounts of this group on paid orders.
   *
   * In cents of the currency found on `ShowOrdersGroup.currency`
   */
  totalPaidProductAmountInCurrencyCents: Scalars['Int']['output'];
  /**
   * The sum of all orders' shipping amount of this group on paid orders.
   *
   * Shipping amount corresponds to what customer paid for shipping the orders.
   *
   * In cents of the currency found on `ShowOrdersGroup.currency`
   */
  totalPaidShippingAmountInCurrencyCents: Scalars['Int']['output'];
  /**
   * The sum of all promotions amount of this group.
   *
   * In cents of the currency found on `ShowOrdersGroup.currency`
   */
  totalPromotionAmountInCurrencyCents: Scalars['Int']['output'];
  /**
   * The sum of all orders' shipping amount of this group.
   *
   * Compared to `totalPaidShippingAmountInCurrencyCents`, this fields does not exclude unpaid orders.
   *
   * In cents of the currency found on `ShowOrdersGroup.currency`
   */
  totalShippingAmountInCurrencyCents: Scalars['Int']['output'];
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  /** The download URL for the shipping label of the orders of a group. */
  urlLabel?: Maybe<Scalars['String']['output']>;
};


/**
 * ⭐️ Represents a group of ordered products that will be shipped together.
 * They are grouped either by shipment when the seller makes them, grouped by show/shop otherwise.
 * The name is misleading and we might proceed to rename it in the future, maybe to `OrderedProductsGroup`.
 */
export type ShowOrdersGroupOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrderFilter>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ShowOrdersGroupConnection = Connection & {
  __typename?: 'ShowOrdersGroupConnection';
  edges: Array<ShowOrdersGroupEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShowOrdersGroupEdge = Edge & {
  __typename?: 'ShowOrdersGroupEdge';
  cursor: Scalars['String']['output'];
  node: ShowOrdersGroup;
};

export enum ShowOrdersGroupPaymentStatusSummary {
  /** All orders in the group have been paid. */
  AllPaid = 'ALL_PAID',
  /** Some orders in this group have failed payment. */
  SomeFailed = 'SOME_FAILED',
  /** Some orders in this group have payment still in progress. */
  SomeInProgress = 'SOME_IN_PROGRESS',
  /** Some orders in this group have iDEAL payment still in progress, pending SEPA debit. */
  SomePendingSepaDebit = 'SOME_PENDING_SEPA_DEBIT'
}

/**
 * Represents a summary of the statuses of the orders of a group that are
 * meant to be shipped. It means that orders that are canceled or refunded
 * are excluded from this summary.
 */
export enum ShowOrdersGroupShippingStatusSummary {
  /** All non-refuned or non-canceled orders in the group have been delivered or marked as done. */
  AllDelivered = 'ALL_DELIVERED',
  /**
   * All non-refuned or non-canceled order in the group have been shipped.
   *
   * Partial delivery: even if some orders have been
   * delivered (but not al), `ALL_SHIPPED` will be used.
   */
  AllShipped = 'ALL_SHIPPED',
  /** Some orders in the group are pending. */
  SomePending = 'SOME_PENDING'
}

export enum ShowOrdersGroupStatus {
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
  Delivered = 'DELIVERED',
  InTransit = 'IN_TRANSIT',
  PendingPayment = 'PENDING_PAYMENT',
  ReadyToPickup = 'READY_TO_PICKUP',
  ReadyToShip = 'READY_TO_SHIP',
  Refunded = 'REFUNDED'
}

export type ShowOrdersGroupStatusStep = {
  __typename?: 'ShowOrdersGroupStatusStep';
  achievedAt?: Maybe<Scalars['Date']['output']>;
  status: ShowOrdersGroupStatus;
};

export enum ShowOrdersGroupStatusSummary {
  /** All orders in the group have been delivered. */
  AllDelivered = 'ALL_DELIVERED',
  /**
   * All orders in the group have been shipped.
   *
   * Partial delivery: even if some orders have been
   * delivered (but not al), `ALL_SHIPPED` will be used.
   */
  AllShipped = 'ALL_SHIPPED',
  /** Some orders in the group have been canceled. */
  SomeCanceled = 'SOME_CANCELED',
  /** Some orders in the group are pending. */
  SomePending = 'SOME_PENDING',
  /** Some orders in the group have been refunded. */
  SomeRefunded = 'SOME_REFUNDED'
}

export type ShowPoll = {
  __typename?: 'ShowPoll';
  answers: Array<Scalars['String']['output']>;
  durationMs: Scalars['Int']['output'];
  endAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  serverTime: Scalars['Date']['output'];
  status: ShowPollStatus;
  /**
   * The index of the viewer answer for this poll, if they provided it.
   *
   * 🔐 Authentication required
   */
  viewerAnswerIndex?: Maybe<Scalars['Int']['output']>;
  votes: ShowPollVotes;
};

export type ShowPollEnd = {
  __typename?: 'ShowPollEnd';
  id: Scalars['ID']['output'];
  serverTime: Scalars['Date']['output'];
  status: ShowPollStatus;
  votes: ShowPollVotes;
};

export type ShowPollStart = {
  __typename?: 'ShowPollStart';
  answers: Array<Scalars['String']['output']>;
  durationMs: Scalars['Int']['output'];
  endAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  serverTime: Scalars['Date']['output'];
  status: ShowPollStatus;
};

export enum ShowPollStatus {
  Closed = 'CLOSED',
  Opened = 'OPENED'
}

export type ShowPollUpdatePayload = ShowPollEnd | ShowPollStart | ShowPollUserParticipation;

export type ShowPollUserParticipation = {
  __typename?: 'ShowPollUserParticipation';
  id: Scalars['ID']['output'];
  serverTime: Scalars['Date']['output'];
  votes: ShowPollVotes;
};

export type ShowPollVotes = {
  __typename?: 'ShowPollVotes';
  /** Total number of participants that answered the poll. */
  participantCount: Scalars['Int']['output'];
  /**
   * Number of participants that answered each answer.
   * Same size as `answers`.
   */
  participations: Array<Scalars['Int']['output']>;
};

export type ShowPresentUsersChangedEvent = {
  __typename?: 'ShowPresentUsersChangedEvent';
  /** @deprecated Use `presentUsersTotalCount` intead */
  show: Show;
  showPresentUsersTotalCount: Scalars['Int']['output'];
};

export type ShowProduct = OrderedProduct | Product;

export type ShowProductConnection = Connection & {
  __typename?: 'ShowProductConnection';
  edges: Array<ShowProductEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShowProductEdge = Edge & {
  __typename?: 'ShowProductEdge';
  cursor: Scalars['String']['output'];
  node: ShowProductNode;
};

export type ShowProductNode = CustomNode & Node & {
  __typename?: 'ShowProductNode';
  id: Scalars['ID']['output'];
  showProduct: ShowProduct;
};

export type ShowProductsChangedEvent = {
  __typename?: 'ShowProductsChangedEvent';
  /** Indicates if an item was added, removed or edited. */
  movement: ShowProductsMovement;
  /** If a product was ADDED or UPDATED, it will be present here */
  product?: Maybe<Product>;
  /** If a product was REMOVED, you will only have its ID here */
  removedProductId?: Maybe<Scalars['ID']['output']>;
  show: Show;
};

export enum ShowProductsMovement {
  Added = 'ADDED',
  Changed = 'CHANGED',
  PreBidPlaced = 'PRE_BID_PLACED',
  ProductFollowChanged = 'PRODUCT_FOLLOW_CHANGED',
  Removed = 'REMOVED'
}

export type ShowRaid = {
  __typename?: 'ShowRaid';
  destinationShow: Show;
  id: Scalars['ID']['output'];
  /** 🔐 Authentication required */
  isViewerParticipating: Scalars['Boolean']['output'];
  /** This is not a connection because clients don't need to know who is participating and its more scalable */
  participantsCount: Scalars['Int']['output'];
  status: ShowRaidStatus;
};

export type ShowRaidIncomingFeedItem = ShowFeedItem & {
  __typename?: 'ShowRaidIncomingFeedItem';
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  originShow: Show;
  participantsCount: Scalars['Int']['output'];
  /** @deprecated Deprecated in favor of `date` which properly uses the usual Date scalar */
  timestamp: Scalars['Date']['output'];
};

export enum ShowRaidStatus {
  /** Raid has been cancelled. No redirection should happen */
  Cancelled = 'CANCELLED',
  /** Raid is over, clients should redirect users to the destination show if they are participating */
  Closed = 'CLOSED',
  /** Users can participate to the raid */
  Opened = 'OPENED'
}

export type ShowReplayAsset = {
  __typename?: 'ShowReplayAsset';
  downloadUrl: Scalars['String']['output'];
};

export enum ShowTeaserVideoCreationStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Succeeded = 'SUCCEEDED'
}

export type ShowTeaserVideoCreationStatusChangedEvent = {
  __typename?: 'ShowTeaserVideoCreationStatusChangedEvent';
  creationStatus: ShowTeaserVideoCreationStatus;
  show: Show;
};

export enum SoldProductSortColumn {
  Amount = 'AMOUNT',
  Name = 'NAME',
  OrderCreatedAt = 'ORDER_CREATED_AT',
  OrderStatus = 'ORDER_STATUS'
}

export enum SortByPriceOptions {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortInventoryProductsBy = {
  column: InventoryProductSortColumn;
  direction: SortDirection;
};

export type SortProductsBy = {
  column: ProductSortColumn;
  direction: SortDirection;
};

export type SortSoldProductsBy = {
  column: SoldProductSortColumn;
  direction: SortDirection;
};

export type StartAuctionInShowMutationPayload = {
  __typename?: 'StartAuctionInShowMutationPayload';
  auction: ShowAuction;
  show: Show;
};

export type StartFlashSaleInShowInput = {
  /** Must be greater than 0 and less than 100, or null if the seller does not offer a discount */
  discountPercentage?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['ID']['input'];
  showId: Scalars['ID']['input'];
};

export type StartFlashSaleInShowPayload = {
  __typename?: 'StartFlashSaleInShowPayload';
  flashSale: ShowFlashSale;
};

export type StartGiveawayInput = {
  audience: ShowGiveawayAudience;
  isRestrictedToShowCountry?: InputMaybe<Scalars['Boolean']['input']>;
  productId: Scalars['ID']['input'];
  showId: Scalars['ID']['input'];
};

export type StartGiveawayMutationPayload = {
  __typename?: 'StartGiveawayMutationPayload';
  giveaway: ShowGiveaway;
};

export type StartRegistrationFlowInput = {
  /** Please always set it when you have it */
  adjustId?: InputMaybe<Scalars['String']['input']>;
};

export type StartRegistrationFlowInputOpenIdSso = {
  /** Please always set it when you have it */
  adjustId?: InputMaybe<Scalars['String']['input']>;
  openIdToken: Scalars['String']['input'];
};

export type StartRegistrationWithFacebookSso = {
  accessToken: Scalars['String']['input'];
  /** Please always set it when you have it */
  adjustId?: InputMaybe<Scalars['String']['input']>;
};

export type StreamInfo = StreamInfoIvsHls | StreamInfoIvsWebRtc | StreamInfoMuxHls;

export type StreamInfoIvsHls = {
  __typename?: 'StreamInfoIVSHls';
  /**
   * Full url and streamKey.
   * null if not requested by th current show's seller
   */
  rtmpUrl?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type StreamInfoIvsWebRtc = {
  __typename?: 'StreamInfoIVSWebRTC';
  ok: Scalars['Boolean']['output'];
};

export type StreamInfoMuxHls = {
  __typename?: 'StreamInfoMuxHls';
  /**
   * Full url and streamKey.
   * null if not requested by th current show's seller
   */
  rtmpUrl?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export enum StreamingType {
  Rtmp = 'RTMP',
  WebRtc = 'WEB_RTC'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** This is emitted when the specified show ends the broadcast. */
  currentShowBroadcastEnded: ShowBroadcastEndedEvent;
  /** This is emitted when the specified show started to broadcast. */
  currentShowBroadcastStarted: ShowBroadcastStartedEvent;
  /**
   * Keep this subscription open to mark that the viewer is present
   * in the Show.
   *
   *
   * 🔐 Authentication required
   */
  markViewerPresenceInShow: MarkViewerPresenceInShowPayload;
  newOrderedProductInShow: NewOrderInShowEvent;
  /**
   * Emitted when a client needs to confirm a payment intent on stripe for the specified order.
   * @deprecated Deprecated in favor of `orderPaymentClientConfirmationRequiredInShowForCustomer`
   */
  orderPaymentClientConfirmationRequired: OrderPaymentClientConfirmationRequiredEvent;
  /**
   * Emitted when a client needs to confirm a payment intent on stripe for any order in the specified show where the viewer is the customer.
   *
   * 🔐 Authentication required
   */
  orderPaymentClientConfirmationRequiredInShowForCustomer: OrderPaymentClientConfirmationRequiredInShowForCustomerEvent;
  /** Emitted when the payment status changes on this order. */
  orderPaymentStatusChanged: OrderPaymentStatusChangedEvent;
  /**
   * Emitted when the payment status of one of the orders of the show changes.
   * 🔐 Authentication required - only accessible to the seller of the shows.
   */
  orderPaymentStatusChangedInShow: OrderPaymentStatusChangedInShowEvent;
  /**
   * Emitted when the payment status of any order in the specified show changes, where the viewer is the customer.
   *
   * 🔐 Authentication required
   */
  orderPaymentStatusChangedInShowForCustomer: OrderPaymentStatusChangedInShowForCustomerEvent;
  /**
   * This is emmited when the viewer's active payment method has been finalized, or an error occurred during pre-authorization.
   *
   * 🔐 Authentication required
   */
  paymentMethodChanged: PaymentMethodChangedEvent;
  /** Keep this subscription open to receive a pong event every X sec. */
  pong: PongPayload;
  /**
   * This is emmited when the viewer's active payment method pre-authorization state changed, for a high-profile show.
   *
   * 🔐 Authentication required
   */
  preAuthorizationChanged: PreAuthorizationChangedEvent;
  /**
   * This is emmited when the viewer's pre-authorization state changed, for a pre-bid.
   *
   * 🔐 Authentication required
   */
  preAuthorizationChangedForPreBid: PreAuthorizationChangedForPreBidEvent;
  /**
   * This is emitted when the show teaser video creation status changes
   *
   * 🔐 Authentication required
   */
  preShowTeaserVideoTranscodingStatusChanged: PreShowTeaserVideoTranscodingStatusChanged;
  /**
   * This is emitted when a new reaction is used in a show.
   * Used as a finer `showFeedItemAdded` with a type for better scalability.
   */
  reactionFeedItemAdded: ReactionFeedItemAddedEvent;
  /** Will be emitted when a user placed a bid on the show current auction. */
  showCurrentAuctionBidPlaced: ShowCurrentAuctionBidPlacedEvent;
  /**
   * Will be emitted when an order is created and added for the current auction in show.
   *
   * Use `ShowCurrentAuctionOrderAddedEvent.orderIfAuthorized` to access this order, but it
   * will only be present if viewer is the customer of this order or the seller.
   */
  showCurrentAuctionOrderAdded: ShowCurrentAuctionOrderAddedEvent;
  /**
   * Emitted when the current auction in the show changed its status.
   *
   * Will also be emitted when a new auction is started.
   */
  showCurrentAuctionStatusChanged: ShowCurrentAuctionStatusChangedEvent;
  /**
   * Emitted when a giveaway is opened or closed.
   * @deprecated Please use `showCurrentGiveawayUpdate` instead.
   */
  showCurrentGiveawayStatusChanged: ShowCurrentGiveawayStatusChangedEvent;
  /** Emitted when a giveaway is opened, closed or when user joined. */
  showCurrentGiveawayUpdate: ShowCurrentGiveawayUpdatePayload;
  /**
   * Emitted when a user participates in the giveaway
   * ⚠️
   * Edit: This event is now only here to provide updates about the participants count.
   * ⚠️
   * @deprecated Please use `showCurrentGiveawayUpdate` instead.
   */
  showCurrentGiveawayUserParticipation: ShowCurrentGiveawayUserParticipationEvent;
  /** Emitted when a raid is opened or closed */
  showCurrentRaidStatusChanged: ShowCurrentRaidStatusChangedEvent;
  /** Emitted when a user participates to the raid */
  showCurrentRaidUserParticipation: ShowCurrentRaidUserParticipationEvent;
  /** This is emitted when a new comment is added to a show chat. */
  showFeedItemAdded: ShowFeedItemAddedEvent;
  showFlashSaleUpdate: ShowFlashSaleUpdatePayload;
  showPollUpdate: ShowPollUpdatePayload;
  /** This is emitted periodically, when the number of present users in the Show changes */
  showPresentUsersChanged: ShowPresentUsersChangedEvent;
  showProductsChanged: ShowProductsChangedEvent;
  /**
   * This is emitted when the show teaser video creation status changes
   *
   * 🔐 Authentication required
   */
  showTeaserVideoCreationStatusChanged: ShowTeaserVideoCreationStatusChangedEvent;
  /** This is emitted when a new comment is added to a show chat. */
  viewerBannedChanged: ViewerBannedChangePayload;
  /**
   * This is emitted when the viewer ability to comment in the Show changed.
   *
   * 🔐 Authentication required
   * @deprecated Deprecated in favor of `showFeedItemAdded.feedItem` with the type `UserBlockedFromCommentingInShowFeedItem` and `UserUnblockedFromCommentingInShowFeedItem`
   */
  viewerBlockedFromCommentingInShowChanged: ViewerBlockedFromCommentingChangedEvent;
};


export type SubscriptionCurrentShowBroadcastEndedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionCurrentShowBroadcastStartedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionMarkViewerPresenceInShowArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionNewOrderedProductInShowArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionOrderPaymentClientConfirmationRequiredArgs = {
  orderId: Scalars['ID']['input'];
};


export type SubscriptionOrderPaymentClientConfirmationRequiredInShowForCustomerArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionOrderPaymentStatusChangedArgs = {
  orderId: Scalars['ID']['input'];
};


export type SubscriptionOrderPaymentStatusChangedInShowArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionOrderPaymentStatusChangedInShowForCustomerArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionPreShowTeaserVideoTranscodingStatusChangedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionReactionFeedItemAddedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowCurrentAuctionBidPlacedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowCurrentAuctionOrderAddedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowCurrentAuctionStatusChangedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowCurrentGiveawayStatusChangedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowCurrentGiveawayUpdateArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowCurrentGiveawayUserParticipationArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowCurrentRaidStatusChangedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowCurrentRaidUserParticipationArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowFeedItemAddedArgs = {
  filters?: InputMaybe<ShowFeedItemAddedFilters>;
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowFlashSaleUpdateArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowPollUpdateArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowPresentUsersChangedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowProductsChangedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionShowTeaserVideoCreationStatusChangedArgs = {
  showId: Scalars['ID']['input'];
};


export type SubscriptionViewerBlockedFromCommentingInShowChangedArgs = {
  showId: Scalars['ID']['input'];
};

export type TerminateShowInput = {
  showId: Scalars['ID']['input'];
};

export type TerminateShowPayload = {
  __typename?: 'TerminateShowPayload';
  show: Show;
};

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type TokenGenerated = {
  __typename?: 'TokenGenerated';
  token: Scalars['String']['output'];
};

export type TokenNotGenerated = {
  __typename?: 'TokenNotGenerated';
  message: Scalars['String']['output'];
  reason: IvsTokenGenerationResult;
};

export type TriggerPhoneNumberVerificationForSsoRegistrationInput = {
  phoneNumber: Scalars['String']['input'];
};

export type TriggerPhoneNumberVerificationForSsoRegistrationPayload = {
  __typename?: 'TriggerPhoneNumberVerificationForSsoRegistrationPayload';
  ok: Scalars['Boolean']['output'];
};

export type TriggerPhoneNumberVerificationInput = {
  phoneNumber: Scalars['String']['input'];
};

export type TriggerPhoneNumberVerificationPayload = {
  __typename?: 'TriggerPhoneNumberVerificationPayload';
  ok: Scalars['Boolean']['output'];
};

export type TriggerSellerPhoneNumberVerificationPayload = {
  __typename?: 'TriggerSellerPhoneNumberVerificationPayload';
  phoneVerificationToken: Scalars['String']['output'];
};

export type UnbanUserFromSellerInput = {
  sellerId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UnbanUserFromSellerPayload = {
  __typename?: 'UnbanUserFromSellerPayload';
  ok: Scalars['Boolean']['output'];
};

export type UnbanUserFromShowInput = {
  showId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UnbanUserFromShowPayload = {
  __typename?: 'UnbanUserFromShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type UnblockUserFromCommentingInShowMutationPayload = {
  __typename?: 'UnblockUserFromCommentingInShowMutationPayload';
  ok: Scalars['Boolean']['output'];
};

export type UnbundleAllUnshippedShipmentsForCustomerInput = {
  customerId: Scalars['ID']['input'];
};

export type UnbundleAllUnshippedShipmentsForCustomerPayload = {
  __typename?: 'UnbundleAllUnshippedShipmentsForCustomerPayload';
  ok: Scalars['Boolean']['output'];
};

export type UnbundleShipmentInput = {
  shipmentId: Scalars['ID']['input'];
};

export type UnbundleShipmentPayload = {
  __typename?: 'UnbundleShipmentPayload';
  ok: Scalars['Boolean']['output'];
};

export type UnfollowCategoryPayload = {
  __typename?: 'UnfollowCategoryPayload';
  category: Category;
  ok: Scalars['Boolean']['output'];
};

export type UnfollowLanguagePayload = {
  __typename?: 'UnfollowLanguagePayload';
  ok: Scalars['Boolean']['output'];
};

export type UnfollowProductPayload = {
  __typename?: 'UnfollowProductPayload';
  product: Product;
};

export type UnfollowSellerPayload = {
  __typename?: 'UnfollowSellerPayload';
  ok: Scalars['Boolean']['output'];
};

export type UnfollowShowPayload = {
  __typename?: 'UnfollowShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type UnfollowUserPayload = {
  __typename?: 'UnfollowUserPayload';
  ok: Scalars['Boolean']['output'];
};

export type UnstuckShowCurrentAuctionOrGiveawayInput = {
  showId: Scalars['ID']['input'];
};

export type UnstuckShowCurrentAuctionOrGiveawayPayload = {
  __typename?: 'UnstuckShowCurrentAuctionOrGiveawayPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAuctionDurationShowInput = {
  auctionInitialDurationPreset: AuctionInitialDurationPreset;
  showId: Scalars['ID']['input'];
};

export type UpdateAuctionDurationShowPayload = {
  __typename?: 'UpdateAuctionDurationShowPayload';
  show: Show;
};

export type UpdateAuctionResetDurationShowInput = {
  auctionResetDurationPreset: AuctionResetDurationPreset;
  showId: Scalars['ID']['input'];
};

export type UpdateAuctionResetDurationShowPayload = {
  __typename?: 'UpdateAuctionResetDurationShowPayload';
  show: Show;
};

export type UpdateInventoryProductOrProductInShowInput = {
  inventoryProductId?: InputMaybe<Scalars['ID']['input']>;
  product: ProductInput;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateInventoryProductOrProductInShowPayload = {
  __typename?: 'UpdateInventoryProductOrProductInShowPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateOrderNoteInput = {
  note: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

export type UpdateOrderNotePayload = {
  __typename?: 'UpdateOrderNotePayload';
  order: Order;
};

export type UpdatePreShowTeaserVideoUrlInput = {
  originalFileInfo?: InputMaybe<OriginalFileInfoInput>;
  showId: Scalars['ID']['input'];
  uploadUrl: Scalars['String']['input'];
};

export type UpdatePreShowTeaserVideoUrlPayload = {
  __typename?: 'UpdatePreShowTeaserVideoUrlPayload';
  ok: Scalars['Boolean']['output'];
  show: Show;
};

export type UpdateProSellerCompanyInput = {
  bankAccountNumber: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  bic?: InputMaybe<Scalars['String']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProSellerCompanyPayload = {
  __typename?: 'UpdateProSellerCompanyPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateProductFromShopInput = {
  availableQuantity?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['ID']['input'];
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProductFromShopPayload = {
  __typename?: 'UpdateProductFromShopPayload';
  product: Product;
};

export type UpdateProductIsPinnedPayload = {
  __typename?: 'UpdateProductIsPinnedPayload';
  product: Product;
};

export type UpdateProductPositionsInput = {
  productIds: Array<Scalars['ID']['input']>;
  productType: ProductType;
  showId: Scalars['ID']['input'];
};

export type UpdateProductPositionsPayload = {
  __typename?: 'UpdateProductPositionsPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateProductToFirstPositionInput = {
  productId: Scalars['ID']['input'];
  productType: ProductType;
  showId: Scalars['ID']['input'];
};

export type UpdateProductToFirstPositionPayload = {
  __typename?: 'UpdateProductToFirstPositionPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateShipmentShippingInfoByOwnMethodsInput = {
  shipmentId: Scalars['ID']['input'];
  shippingProvider: Scalars['String']['input'];
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShipmentShippingInfoByOwnMethodsPayload = {
  __typename?: 'UpdateShipmentShippingInfoByOwnMethodsPayload';
  shipment: Shipment;
};

export type UpdateShowLanguageInput = {
  languageId: Scalars['ID']['input'];
  showId: Scalars['ID']['input'];
};

export type UpdateShowLanguagePayload = {
  __typename?: 'UpdateShowLanguagePayload';
  show: Show;
};

export type UpdateShowNameInput = {
  name: Scalars['String']['input'];
  showId: Scalars['ID']['input'];
};

export type UpdateShowNamePayload = {
  __typename?: 'UpdateShowNamePayload';
  show: Show;
};

export type UpdateShowNotificationSendingMethodInput = {
  shouldUseNotificationsCenter: Scalars['Boolean']['input'];
  showId: Scalars['ID']['input'];
};

export type UpdateShowNotificationSendingMethodPayload = {
  __typename?: 'UpdateShowNotificationSendingMethodPayload';
  isNotificationsCenterOn: Scalars['Boolean']['output'];
};

export type UpsertSellerBankingInfoInput = {
  bankAccountName: Scalars['String']['input'];
  bic?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  phoneNumberVerificationCode: Scalars['String']['input'];
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertSellerBankingInfoPayload = {
  __typename?: 'UpsertSellerBankingInfoPayload';
  ok: Scalars['Boolean']['output'];
};

/** ⭐️ A user of Voggt. */
export type User = Node & {
  __typename?: 'User';
  allModerators: Array<User>;
  /**
   * Gives an optimized avatar picture URL in webp format, with a maximum width that you can specify.
   * If original picture is narrower than the specified width, the original picture will be returned.
   * Return null if user is hard blocked.
   */
  avatarWebPUrl?: Maybe<Scalars['String']['output']>;
  badges: Array<Badge>;
  /**
   * Returns a list of users that are banned from the seller's shows.
   *   🔐 Authentication required
   */
  bannedUsersFromSeller: Array<User>;
  /**
   * The customers of this user, ie the users that have placed at least
   * 1 order with this user as a seller.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  bestCustomers: CustomerConnection;
  biography?: Maybe<Scalars['String']['output']>;
  /** Currently broadcasting show of the user, null if not live */
  broadcastingShow?: Maybe<Show>;
  /** The Channel of the viewer. */
  channel?: Maybe<Channel>;
  /**
   * The orders groups placed by the user and that are confirmed as received by the user.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  confirmedShowOrdersGroupsByShowDateDesc: ShowOrdersGroupConnection;
  cosmetics: UserCosmetics;
  /**
   * Gives an optimized cover picture URL in webp format, with a maximum width that you can specify.
   * If original picture is narrower than the specified width, the original picture will be returned.
   * Return null if user is hard blocked.
   */
  coverWebPUrl?: Maybe<Scalars['String']['output']>;
  /**
   * The shows hosted by the user, currently live or upcoming.
   *
   * Sorted from oldest to most recent.
   */
  currentAndUpcomingShowsByStartDateAsc: ShowConnection;
  /**
   * Get the current referrer promotion, if any.
   * Depends on the viewer's country if set, or on the request country code otherwise.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  currentReferrerPromotionAmountOrPercentage?: Maybe<PromotionAmountOrPercentage>;
  /**
   * The customers of this user, ie the users that have placed at least
   * 1 order with this user as a seller.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  customers: CustomerConnection;
  /** 🔐 Authentication required - only accessible to viewer */
  email: Scalars['String']['output'];
  /**
   * Gives the favorite shows list ordered by show date, from now to future, always including broadcasting shows
   *
   * 📄 Pagination max 20
   */
  favoriteCurrentAndUpcomingShows: FavoriteShowsConnection;
  /**
   * Gives the favorite shows list ordered by show date, from now to past that are not broadcasting
   *
   * 📄 Pagination max 20
   */
  favoritePastShows: FavoriteShowsConnection;
  /**
   * Gives the favorite products list ordered by show date, from now to future, always including broadcasting shows
   *
   * 📄 Pagination max 20
   */
  favoriteProductsInCurrentAndUpcomingShows: FavoriteProductsConnection;
  /**
   * Gives the favorite products list ordered by show date, from now to past that are not broadcasting
   *
   * 📄 Pagination max 20
   */
  favoriteProductsInPastShows: FavoriteProductsConnection;
  favouriteMondialRelayPickupPoint?: Maybe<MondialRelayPickupPoint>;
  favouritePickupPoint?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  /**
   * Connection to get the users's followed users
   * 📄 Pagination max 50
   */
  followedUsers: UserConnection;
  /**
   * Connection to get the followers of the user.
   * 📄 Pagination max 50
   */
  followers: UserConnection;
  id: Scalars['ID']['output'];
  isApplePayEligible: Scalars['Boolean']['output'];
  isBancontactEligible: Scalars['Boolean']['output'];
  isBlockedFromCommenting?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Logic is based on badges & referral/referrer promotions availability.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  isEligibleToReferralProgram: Scalars['Boolean']['output'];
  /** Indicates if the user is a seller AND the viewer is following the user. */
  isFollowing: Scalars['Boolean']['output'];
  isGiropayEligible: Scalars['Boolean']['output'];
  isGooglePayEligible: Scalars['Boolean']['output'];
  isInfluencer: Scalars['Boolean']['output'];
  isPaypalEligible: Scalars['Boolean']['output'];
  isPaypalRecurringPaymentEligible: Scalars['Boolean']['output'];
  isProfessionalSeller: Scalars['Boolean']['output'];
  isSelected: Scalars['Boolean']['output'];
  isSeller: Scalars['Boolean']['output'];
  isStaff: Scalars['Boolean']['output'];
  isSupplierEligible: Scalars['Boolean']['output'];
  /**
   * Return true if this user is the viewer.
   *
   * If the query is not authenticated, it will return false.
   */
  isViewer: Scalars['Boolean']['output'];
  language?: Maybe<Scalars['String']['output']>;
  /**
   * If any, the last order with a failed payment.
   *
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  lastFailedPaymentOrder?: Maybe<Order>;
  lastName?: Maybe<Scalars['String']['output']>;
  /**
   * Get the last few ratings for the seller.
   *
   * 🔐 Authentication required - only accessible to seller
   * @deprecated Use `ratingsFromCustomers` instead, as it leverages pagination and gives more flexibility
   */
  latestRatingsFromCustomers: Array<RatingFromCustomer>;
  /** ⚠️ Only use it in specific cases: tracking; deep-linking; etc. */
  legacyId: Scalars['Int']['output'];
  /**
   * The shows hosted by the user, in the past or currently live.
   *
   * Sorted from most recent to oldest.
   */
  pastAndCurrentShowsByStartDateDesc: ShowConnection;
  /** 🔐 Authentication required - only accessible to viewer */
  paymentMethodBancontact?: Maybe<PaymentMethodBancontact>;
  /** 🔐 Authentication required - only accessible to viewer */
  paymentMethodCard?: Maybe<PaymentMethodCard>;
  /** 🔐 Authentication required - only accessible to viewer */
  paymentMethodGiropay?: Maybe<PaymentMethodGiropay>;
  /** 🔐 Authentication required - only accessible to viewer */
  paymentMethodIdeal?: Maybe<PaymentMethodIdeal>;
  /** 🔐 Authentication required - only accessible to viewer */
  paymentMethodPaypal?: Maybe<PaymentMethodPaypal>;
  /** 🔐 Authentication required - only accessible to viewer */
  paymentMethodPaypalRecurringPayment?: Maybe<PaymentMethodPaypalRecurringPayment>;
  /** 🔐 Authentication required - only accessible to viewer */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** 🔐 Authentication required - only accessible to viewer */
  promotionsV2: PromotionV2Connection;
  /**
   * Get public ratings for the seller.
   * Ordered by rating descending and date descending.
   *
   * 📄 Pagination max 20
   */
  publicRatingsFromCustomers: RatingFromCustomerConnection;
  /**
   * Get ratings for the seller.
   *
   * 🔐 Authentication required - only accessible to seller
   * 📄 Pagination max 50
   */
  ratingsFromCustomers: RatingFromCustomerConnection;
  /** 🔐 Authentication required - only accessible to viewer */
  referralCode: Scalars['String']['output'];
  referralStatistics?: Maybe<ReferralStatistics>;
  /**
   * Referred users, sorted by account creation date desc
   * 🔐 Authentication required - only accessible to viewer
   */
  referrals: UserConnection;
  referrer?: Maybe<User>;
  sellerConfig?: Maybe<SellerConfig>;
  sellerMonthlyStatistics?: Maybe<SellerMonthlyStatistics>;
  /**
   * Gives the average orders groups rating for the seller.
   * Gives a floating number from 1 to 5, rounded to the first decimal place.
   * null if the user is not a seller or doesn't have enough ratings (less than 10).
   */
  sellerRating?: Maybe<Scalars['Float']['output']>;
  /** Number of order group ratings for the seller. */
  sellerRatingsCount: Scalars['Int']['output'];
  sellerStatistics?: Maybe<SellerStatistics>;
  /**
   * Gives the total amount of revenue generated by this user for seller
   *
   * 🔐 Authentication required - only accessible to seller
   */
  sellerTotalRevenueInCents?: Maybe<AmountWithCurrency>;
  /** 🔐 Authentication required - only accessible to viewer */
  shippingAddresses: Array<ShippingAddress>;
  shops: ShopConnection;
  /**
   * The offline shops from this seller, excluding supplier shops.
   * Sorted from oldest to most recent.
   */
  shopsByStartAtDesc: ShowConnection;
  /**
   * The offline shops from this seller, including supplier shops.
   * Sorted from oldest to most recent.
   *
   * 🔐 Authentication required - only accessible to the seller of the shops
   */
  shopsByStartAtDescForSeller: ShowConnection;
  /**
   * The shows hosted by the user, currently live or upcoming,
   * Sorted from oldest to most recent.
   *
   * 🔐 Authentication required - only accessible to the seller of the shows
   */
  showsByStartAtDescForSeller: ShowConnection;
  /**
   * Only used for search
   * @deprecated Not used anymore
   */
  similarity?: Maybe<Scalars['Float']['output']>;
  /**
   * The orders shop sold by the user with status delivered.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  soldDeliveredShopOrders: OrderConnection;
  /**
   * The orders shop sold by the user with status pending.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  soldPendingShopOrders: OrderConnection;
  /**
   * The orders shop sold by the user with status shipped.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  soldShippedShopOrders: OrderConnection;
  /**
   * The orders shop sold by the user with status canceled and refunded.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  soldShopOrdersWithCanceledAndRefunded: OrderConnection;
  /**
   * The orders groups placed by the user and that are not yet confirmed as received by the user.
   *
   * 🔐 Authentication required - only accessible to viewer
   */
  unconfirmedShowOrdersGroupsByShowDateDesc: ShowOrdersGroupConnection;
  /**
   * Upcoming available products from shows, sorted by pinned+has image+creation date
   *
   * 📄 Pagination max 50
   */
  upcomingShowsProducts: ProductConnection;
  username: Scalars['String']['output'];
};


/** ⭐️ A user of Voggt. */
export type UserAvatarWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};


/** ⭐️ A user of Voggt. */
export type UserBestCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  all?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserConfirmedShowOrdersGroupsByShowDateDescArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserCoverWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};


/** ⭐️ A user of Voggt. */
export type UserCurrentAndUpcomingShowsByStartDateAscArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  all?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserFavoriteCurrentAndUpcomingShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserFavoritePastShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserFavoriteProductsInCurrentAndUpcomingShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserFavoriteProductsInPastShowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserFollowedUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserPastAndCurrentShowsByStartDateDescArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  lastNDaysOnly?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserPromotionsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserPublicRatingsFromCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserRatingsFromCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserReferralsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserShopsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserShopsByStartAtDescArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserShopsByStartAtDescForSellerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserShowsByStartAtDescForSellerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserSoldDeliveredShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ShopOrdersFilter>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ShopOrdersSort>;
};


/** ⭐️ A user of Voggt. */
export type UserSoldPendingShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ShopOrdersFilter>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ShopOrdersSort>;
};


/** ⭐️ A user of Voggt. */
export type UserSoldShippedShopOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ShopOrdersFilter>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ShopOrdersSort>;
};


/** ⭐️ A user of Voggt. */
export type UserSoldShopOrdersWithCanceledAndRefundedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ShopOrdersFilter>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ShopOrdersSort>;
};


/** ⭐️ A user of Voggt. */
export type UserUnconfirmedShowOrdersGroupsByShowDateDescArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** ⭐️ A user of Voggt. */
export type UserUpcomingShowsProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** ⭐️ A show feed item for when a User is blocked from commenting. */
export type UserBlockedFromCommentingInShowFeedItem = ShowFeedItem & {
  __typename?: 'UserBlockedFromCommentingInShowFeedItem';
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Deprecated in favor of `date` which properly uses the usual Date scalar */
  timestamp: Scalars['Date']['output'];
  user: User;
};

/** ⭐️ A show feed item for a comment left by a User in a Show. */
export type UserCommentFeedItem = ShowFeedItem & {
  __typename?: 'UserCommentFeedItem';
  author: User;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isAuthoredByModerator: Scalars['Boolean']['output'];
  /** Whether the comment has been authored by the Show's seller or not */
  isAuthoredByShowSeller: Scalars['Boolean']['output'];
  textContent: Scalars['String']['output'];
  /** @deprecated Deprecated in favor of `date` which properly uses the usual Date scalar */
  timestamp: Scalars['Date']['output'];
};

export type UserConnection = Connection & {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Define user profile customization */
export type UserCosmetics = {
  __typename?: 'UserCosmetics';
  /** Can be null if not defined */
  avatarFrame?: Maybe<UserCosmeticsAvatarFrame>;
};

/** AvatarFrame is used to decorate the user profile picture, usually as a frame around it. */
export type UserCosmeticsAvatarFrame = {
  __typename?: 'UserCosmeticsAvatarFrame';
  /** Colors can be used for other design elements (ribbons...) */
  primaryColor: Scalars['String']['output'];
  /** Colors can be used for other design elements (ribbons...) */
  secondaryColor: Scalars['String']['output'];
  webPUrl: Scalars['String']['output'];
};


/** AvatarFrame is used to decorate the user profile picture, usually as a frame around it. */
export type UserCosmeticsAvatarFrameWebPUrlArgs = {
  options?: InputMaybe<WebPProcessingOptions>;
};

export type UserEdge = Edge & {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserFollowFeedItem = ShowFeedItem & {
  __typename?: 'UserFollowFeedItem';
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Deprecated in favor of `date` which properly uses the usual Date scalar */
  timestamp: Scalars['Date']['output'];
  user: User;
};

/** ⭐️ A show feed item for when a User joins a Show. */
export type UserJoinedFeedItem = ShowFeedItem & {
  __typename?: 'UserJoinedFeedItem';
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Deprecated in favor of `date` which properly uses the usual Date scalar */
  timestamp: Scalars['Date']['output'];
  user: User;
};

/** ⭐️ A show feed item for when a User is unblocked from commenting. */
export type UserUnblockedFromCommentingInShowFeedItem = ShowFeedItem & {
  __typename?: 'UserUnblockedFromCommentingInShowFeedItem';
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Deprecated in favor of `date` which properly uses the usual Date scalar */
  timestamp: Scalars['Date']['output'];
  user: User;
};

export type VerifyPhoneNumberForEmailRegistrationInput = {
  code: Scalars['String']['input'];
};

export type VerifyPhoneNumberForEmailRegistrationPayload = {
  __typename?: 'VerifyPhoneNumberForEmailRegistrationPayload';
  ok: Scalars['Boolean']['output'];
};

export type VerifyPhoneNumberForSsoRegistrationInput = {
  code: Scalars['String']['input'];
};

export type VerifyPhoneNumberForSsoRegistrationPayload = {
  __typename?: 'VerifyPhoneNumberForSsoRegistrationPayload';
  email?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

export type VerifyUserEmailInput = {
  code: Scalars['String']['input'];
};

export type VerifyUserEmailPayload = {
  __typename?: 'VerifyUserEmailPayload';
  ok: Scalars['Boolean']['output'];
};

export type VideoFileInfo = {
  __typename?: 'VideoFileInfo';
  /** The size of the video file in bytes. */
  size?: Maybe<Scalars['Int']['output']>;
  /** The URL of the video file. */
  url: Scalars['String']['output'];
};

export type ViewerBannedChangePayload = ViewerBannedFromSellerChangePayload | ViewerBannedFromShowChangePayload;

export type ViewerBannedFromSellerChangePayload = {
  __typename?: 'ViewerBannedFromSellerChangePayload';
  sellerId: Scalars['ID']['output'];
  type: ViewerBannedFromSellerChangeTypes;
  userId: Scalars['ID']['output'];
};

export enum ViewerBannedFromSellerChangeTypes {
  BannedFromSeller = 'BANNED_FROM_SELLER',
  UnbannedFromSeller = 'UNBANNED_FROM_SELLER'
}

export type ViewerBannedFromShowChangePayload = {
  __typename?: 'ViewerBannedFromShowChangePayload';
  showId: Scalars['ID']['output'];
  type: ViewerBannedFromShowChangeTypes;
  userId: Scalars['ID']['output'];
};

export enum ViewerBannedFromShowChangeTypes {
  BannedFromShow = 'BANNED_FROM_SHOW',
  UnbannedFromShow = 'UNBANNED_FROM_SHOW'
}

export type ViewerBlockedFromCommentingChangedEvent = {
  __typename?: 'ViewerBlockedFromCommentingChangedEvent';
  isBlocked: Scalars['Boolean']['output'];
  show: Show;
};

export type WebPProcessingOptions = {
  /**
   * Try to hardcode the size depending on your UI components.
   * That way, we can preprocess most commonly used sizes in the future.
   *
   * This parameter can be omitted, it will give the full size picture, but still optimized in WebP format.
   *
   * Keep in mind that the picture *could* be of a different size than the one requested, for performance reasons.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type WelcomePromotionSource = {
  __typename?: 'WelcomePromotionSource';
  promotionId: Scalars['Int']['output'];
  type: PromotionType;
};

export type WinnerInsights = {
  __typename?: 'WinnerInsights';
  /** null if should not be displayed (less than 2) */
  currentStreak?: Maybe<Scalars['Int']['output']>;
  hasWinnerWonTheMostAuctions?: Maybe<Scalars['Boolean']['output']>;
  isWinnerNewBuyer?: Maybe<Scalars['Boolean']['output']>;
  isWinnerNewBuyerForCurrentSeller?: Maybe<Scalars['Boolean']['output']>;
  numberOfAuctionsWon?: Maybe<Scalars['Int']['output']>;
};

export type OrderPaymentStatusChangedInShowForCustomerEvent = {
  __typename?: 'orderPaymentStatusChangedInShowForCustomerEvent';
  order: Order;
};

export type UpdateProductIsPinnedInput = {
  productId: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};

export type UpdateShowShippingCategoryInput = {
  shippingCategory: ShippingCategory;
  showId: Scalars['ID']['input'];
};

export type UpdateShowShippingCategoryPayload = {
  __typename?: 'updateShowShippingCategoryPayload';
  show: Show;
};
