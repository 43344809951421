import { useMemo, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { createParentCategoriesOptionGroups } from '@/components/CreateOrEditProduct2/CreateOrEditProduct2.helpers'
import useParentCategories from '@/components/CreateOrEditProduct2/useParentCategories'
import Field from '@/components/Form/Field/Field'

import type { ProductFormValidity } from '@/components/CreateOrEditProduct2/types'

type ProductCategoryFieldProps = {
  value?: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  onValidityChange?: (name: keyof ProductFormValidity, isValid: boolean) => void
  disabled?: boolean
}

const ProductCategoryField = (props: ProductCategoryFieldProps) => {
  const { value: category, onChange, onValidityChange = () => undefined, disabled = false } = props
  const { t } = useTranslation()

  const { parentCategories } = useParentCategories()
  const parentCategoriesOptionGroups = useMemo(
    () => createParentCategoriesOptionGroups(parentCategories),
    [parentCategories]
  )

  return (
    <Field
      disabled={disabled}
      label={t('productFormCategoryLabel')}
      name="category"
      options={parentCategoriesOptionGroups}
      type="select"
      value={category}
      onChange={onChange}
      onValidityChange={(isValid: boolean) => onValidityChange('category', isValid)}
    />
  )
}

export default ProductCategoryField
